.page-news {
  .line {
    color: color("black");
    text-align: left;
  }

  small {
    opacity: $font-opacity;
  }

  h1,
  .h1 {
    font-size: rem($page-news-h1-font-size);
    line-height: rem($page-news-h1-line-height);

    @include media($from: tablet) {
      font-size: rem($page-news-h1-lg-font-size);
      line-height: rem($page-news-h1-lg-line-height);
    }
  }

  h4,
  .h4 {
    font-size: rem($page-news-h4-font-size);
    line-height: rem($page-news-h4-line-height);
    opacity: $font-opacity;

    @include media($from: tablet) {
      font-size: rem($page-news-h4-lg-font-size);
      line-height: rem($page-news-h4-lg-line-height);
    }
  }
}
