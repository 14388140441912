.section-nav {
  padding-left: 0;

  ul {
    padding-left: rem(16);

    ul {
      display: none;
    }
  }
}

.toc-entry {
  display: block;

  a {
    display: block;
    padding: rem(2) rem(24);

    &:hover {
      color: theme-color("primary");
      text-decoration: none;
    }
  }
}

.bd-sidebar {
  order: 0;

  @include media($from: tablet) {

    @supports (position: sticky) {
      position: sticky;
      top: $navbar-lg-height;
      z-index: 1000;
      height: calc(100vh - #{$navbar-lg-height});
    }
  }

  @include media($from: tablet) {
    flex: 0 1 320px;
  }
}

.bd-links {
  padding-top: rem(16);
  padding-bottom: rem(16);

  @include media($from: tablet) {
    display: block !important; /* stylelint-disable-line */

    @supports (position: sticky) {
      max-height: calc(100vh - #{rem(64)});
      overflow-y: auto;
    }
  }
}

.bd-search-docs-toggle {
  line-height: 1;
  color: gray("900");
}

.bd-sidenav {
  display: none;
}

.bd-toc-link {
  display: block;
  font-size: rem(20);
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: theme-color("primary");
    background-color: color("white");
  }
}

.bd-toc-item {
  padding: 10px 0;
  border-top: 1px solid gray("c");

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: 1px solid gray("c");
  }

  &.active {
    background-color: color("white");

    > .bd-toc-link {
      color: theme-color("primary");

      &:hover {
        background-color: transparent;
      }
    }

    > .bd-sidenav {
      display: block;
    }
  }
}

.bd-sidebar .nav > li > a {
  display: block;
  padding: rem(4) rem(24);
  font-size: rem(14.4);
  color: rgba(color("black"), $font-opacity);
}

.bd-sidebar .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  background-color: transparent;
}
