.card-news {
  border-color: invert-theme-color("accent");

  &:hover {
    color: invert-theme-color();
    border: 1px solid invert-theme-color();

    a {
      color: invert-theme-color();
    }
  }

  .card-news-body {
    > a {
      display: block;
      height: rem($card-news-link-height);
      margin: 0;
      overflow: hidden;

      @include media($from: tablet) {
        height: rem($card-news-lg-link-height);
      }
    }
  }
}
