.search {
  .form-control {
    background-color: invert-color("white");
  }

  .search-action {
    .btn {
      background-color: invert-color("white");
      border-color: invert-color("black");

      &:hover {
        color: invert-theme-color();
        background-color: invert-color("white");
        border-color: invert-color("black");
      }
    }
  }
}
