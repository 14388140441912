.card-data-list {
  margin-right: 0;
  margin-left: 0;

  @extend .row;

  .card-data {
    display: flex;
    align-items: center;
    height: rem($card-data-height);
    padding: $card-data-padding;
    border: 1px solid theme-color("accent");

    &:hover {
      color: color("white");
      background-color: theme-color() !important;  /* stylelint-disable-line */

      p {
        color: color("white");
      }
    }

    &:not(:last-child) {
      border-bottom: none;
    }

    @include media($from: tablet) {
      display: block;
      height: rem($card-data-lg-height);
    }

    @include media($from: desktop) {
      &:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid theme-color("accent");
      }
    }

    &:first-child {
      background-color: $card-data-first-bg-color;
    }

    @extend .col-12;

    @extend .col-lg;

    > .card-data-title {
      display: flex;
      align-items: center;

      @include media($from: tablet) {
        display: block;
      }

      > .card-data-quantity {
        width: rem($card-data-quantity-width);
        height: rem($card-data-quantity-line-height);
        padding-right: $card-data-quantity-separation;
        overflow: hidden;
        font-family: $accent-font;
        font-size: rem($card-data-quantity-font-size);
        line-height: rem($card-data-title-line-height);
        text-align: right;

        @include media($from: tablet) {
          width: auto;
          text-align: left;
        }
      }

      > h5 {
        margin-bottom: 0;
        overflow: hidden;
        font-size: rem($card-data-title-font-size);

        @include media($from: tablet) {
          height: rem($card-data-title-height);
          margin-bottom: $headings-margin-bottom;
        }
      }
    }

    > p {
      display: none;
      height: rem($card-data-body-height);
      overflow: hidden;
      font-size: rem($card-data-body-font-size);
      line-height: rem($card-data-body-line-height);

      @include media($from: tablet) {
        display: block;
      }
    }
  }
}

.card-data-tramite {
  .card-data {
    .card-data-title {
      font-family: "Roboto Slab";
      color: #000;

      .card-data-quantity {
        margin-bottom: 10px;
        font-size: 38px;
        font-weight: bold;

        &.text-xl {
          margin: 57px 10px;
          font-size: 130px;
        }
      }

      h5 {
        font-size: 23px;
        font-weight: 300;
        line-height: 37px;

        &.text-xl {
          font-size: 28px;
        }
      }
    }
  }
}
