footer {
  position: relative;
  display: block;
  width: 100%;
  //height: 64px;
  padding: $footer-lg-padding;
  overflow: hidden;
  background-color: theme-color("tertiary");

  ol {
    list-style: none;
  }

  .font-sm-footer {
    font-size: 14px;
  }

  .footer-icon {
    display: block;
    width: 30px;
    height: 30px;

    &.footer-icon-cc {
      background: url("../img/icon-cc.svg");
    }

    &.footer-icon-person {
      background: url("../img/icon-person.svg");
    }
  }

  .borderlgleft {
    border-left: 1px solid #fff;
  }

  .bordersmbottom {
    border-bottom: 0;
  }

  .bordersmleft {
    border-left: 1px solid #fff;
  }

  @media (max-width: 768px) {
    .borderlgleft {
      border-left: 0 !important;
    }

    .bordersmbottom {
      border-bottom: 1px solid #fff;
    }

    .md-pt {
      padding-top: 30px;
    }
  }

  @media (max-width: 480px) {
    .bordersmleft {
      border-left: 0 !important;
    }
  }


  @include media($from: tablet) {
    height: auto;
    padding: $footer-lg-padding;
  }

  @include media($from: desktop) {
    padding: $footer-lg-padding;
  }

  > .container > .row
  ,
  > .row {
    //display: none;

    @include media($from: tablet) {
      display: flex;
    }
  }

  @each $key, $value in $grid-breakpoints {
    &.footer-expand-#{$key} {
      height: auto;
      padding: $footer-padding;

      @include media-breakpoint-up($key) {
        padding: $footer-lg-padding;
      }

      > .container > .row
      ,
      > .row {
        display: none;

        @include media-breakpoint-up($key) {
          display: flex;
        }
      }

      .footer-logo {
        .footer-default-logo {
          display: none;

          @include media-breakpoint-up($key) {
            display: block;
          }
        }

        .footer-mobile-logo {
          display: block;

          @include media-breakpoint-up($key) {
            display: none;
          }
        }
      }
    }
  }

  .footer-logo {
    position: absolute;
    bottom: 0;

    .footer-default-logo {
      display: none;

      @include media($from: tablet) {
        display: block;
      }
    }

    .footer-mobile-logo {
      display: block;

      @include media($from: tablet) {
        display: none;
      }
    }
  }

  .footer-social {
    padding-left: 0;
    text-align: center;

    @include media($from: desktop) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    li {
      display: inline-block;
      margin-bottom: 10px;

      &:not(:first-child) {
        margin-left: 10px;
      }

      a {
        font-size: 32px;
        color: gray("c");
        text-align: center;
      }
    }
  }

  .footer-links {
    a {
      display: block;
      padding: $footer-link-padding;
      color: color("white");

      @if ($footer-links-ellipsized) {
        width: 100%;
        height: $footer-link-height;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include media($from: tablet) {
        padding: $footer-link-lg-padding;
      }
    }
  }
}

.footer-logo {
  .footer-default-logo {
    display: none;
    width: 236px;

    @include media($from: tablet) {
      display: block;
    }
  }

  .footer-mobile-logo {
    display: block;

    @include media($from: tablet) {
      display: none;
    }
  }
}

body {

  @for $i from 0 through $footer-links-max {
    &.footer-links-#{$i} {
      main {
        min-height: calc(100vh - #{get-not-main-height($i)});

        @include media-breakpoint-up(md) {
          min-height: calc(100vh - #{get-not-main-height($i, md)});
        }

        @include media-breakpoint-up(xl) {
          min-height: calc(100vh - #{get-not-main-height($i, xl)});
        }
      }

      footer {
        //height: get-footer-height($i);

        @include media-breakpoint-up(md) {
          //height: get-footer-height($i, md);
        }

        @include media-breakpoint-up(xl) {
          // height: get-footer-height($i, xl);
        }

        @each $key, $value in $grid-breakpoints {
          &.footer-expand-#{$key} {
            height: get-footer-height($i);

            padding: $footer-padding;

            @include media-breakpoint-up($key) {
              height: get-footer-height($i, $key);
              padding: $footer-lg-padding;
            }

            > .container > .row
            ,
            > .row {
              display: none;

              @include media-breakpoint-up($key) {
                display: flex;
              }
            }

            .footer-logo {
              .footer-default-logo {
                display: none;

                @include media-breakpoint-up($key) {
                  display: block;
                }
              }

              .footer-mobile-logo {
                display: block;

                @include media-breakpoint-up($key) {
                  display: none;
                }
              }
            }
          }
        }

        .footer-links {
          height: $i * $footer-link-height;

          @include media-breakpoint-up(md) {
            height: round($i / 2) * $footer-link-height;
          }
        }
      }
    }
  }
}

