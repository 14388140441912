body {
  color: invert-color("black");
  background-color: contrast-color("white");
}


@each $key, $value in $invert-colors {

  @include bg-variant(".bg-#{$key}", $value);

  .text-#{$key} {
    color: $value !important;  /* stylelint-disable-line */
  }

  .bg-layer-#{unquote($key)} {
    background-color: transparent !important;  /* stylelint-disable-line */

    &::before {
      background-color: $value;
    }
  }

  .col-separator-#{$key} {

    @include media($from: desktop) {
      > [class*="col-"]:not(:last-child) {
        &::after {
          border-left-color: $value;
        }
      }
    }
  }

  .border-#{$key} {
    border-color: $value !important; /* stylelint-disable-line */
  }

  .text-before-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .text-after-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-before-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-after-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }
}

@each $key, $value in $invert-grays {

  @include bg-variant(".bg-gray-#{$key}", $value);

  .text-gray-#{$key} {
    color: $value !important;  /* stylelint-disable-line */
  }

  .bg-layer-gray-#{unquote($key)} {
    background-color: transparent !important;  /* stylelint-disable-line */

    &::before {
      background-color: $value;
    }
  }

  .col-separator-gray-#{$key} {

    @include media($from: desktop) {
      > [class*="col-"]:not(:last-child) {
        &::after {
          border-left-color: $value;
        }
      }
    }
  }

  .border-gray-#{$key} {
    border-color: $value !important; /* stylelint-disable-line */
  }

  .text-gray-before-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .text-gray-after-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-gray-before-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-gray-after-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }
}


@each $key, $value in $invert-theme-colors {

  @include bg-variant(".bg-#{$key}", $value);

  .alert-#{$key} {

    @include alert-variant(theme-color-level($key, -10), theme-color-level($key, -9), theme-color-level($key, 6));
  }

  .bg-layer-#{unquote($key)} {
    background-color: transparent !important;  /* stylelint-disable-line */

    &::before {
      background-color: $value;
    }
  }

  .col-separator-#{$key} {

    @include media($from: desktop) {
      > [class*="col-"]:not(:last-child) {
        &::after {
          border-left-color: $value;
        }
      }
    }
  }

  .text-before-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .text-after-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-before-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-after-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .border-#{$key} {
    border-color: $value !important; /* stylelint-disable-line */
  }

  @include text-emphasis-variant(".text-#{$key}", $value);
}

.text-white {
  color: invert($white) !important; /* stylelint-disable-line */
}

.text-body {
  color: invert($body-color) !important; /* stylelint-disable-line */
}

.text-muted {
  color: invert($text-muted) !important; /* stylelint-disable-line */
}

.text-black-50 {
  color: invert(rgba($black, .5)) !important; /* stylelint-disable-line */
}

.text-white-50 {
  color: invert(rgba($white, .5)) !important; /* stylelint-disable-line */
}

@include make-sub-display-class(a11y-contrast);
