.banner {
  display: block;
  overflow: hidden;
  text-decoration: none;
  border-radius: 3px;

  &:hover {
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  .img_frj {
    position: absolute;
    top: 0;
    width: 100px;
  }

  .banner_content {
    margin-top: 20px;
    margin-bottom: 8px;

    .img_mrg {
      font-size: 37px;
    }

    .font_ligth {
      font-family: "gobCl_light";
    }

    .font_bold {
      font-family: "gobCL_Bold";
    }

    .d_row {
      span {
        font-size: 16px;
        line-height: 8px;
      }

      h3 {
        font-size: 20px;
        line-height: 12px;
      }
    }

    .d_col {
      span {
        display: inline-block;
        margin-right: 6px;
        font-size: 26px;
        line-height: 34px;
      }

      h3 {
        display: inline-block;
        font-size: 28px;
        line-height: 34px;
      }
    }
  }

  &.banner-grey {
    span, h3 {
      color: #424040;
    }
  }

  &.banner-blue {
    span, h3 {
      color: #006fb3;
    }
  }

  &.border-banner-shadow {
    box-shadow: 0 1px 2px 0 rgba(133, 133, 133, .8);
  }

  &.border-banner-blue {
    border: 1px solid #006fb3;
  }

  &.border-banner-grey {
    border: 1px solid #aaa;
  }

  .cl-grey {
    color: #424040;
  }
}
