html {
  font-size: $font-size-base-in-px; // 62.5% = 10px | 100% = 16px
}

.list-inline {
  li {
    position: relative;
    display: inline-block;
    padding-right: spacer(2);

    &:not(:last-child) {
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: "-";
      }
    }
  }
}

.text-underline {
  text-decoration: underline !important; /* stylelint-disable-line */
}

.text-normal {
  text-decoration: none !important;  /* stylelint-disable-line */
  text-transform: none !important;  /* stylelint-disable-line */
}


@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @if $infix != "" {
    .border#{$infix} {
      border: none !important; /* stylelint-disable-line */

      @include media-breakpoint-up($breakpoint) {
        border: $border-width solid $border-color !important; /* stylelint-disable-line */
      }
    }

    .border#{$infix}-top {
      border-top: none !important; /* stylelint-disable-line */

      @include media-breakpoint-up($breakpoint) {
        border-top: $border-width solid $border-color !important; /* stylelint-disable-line */
      }
    }

    .border#{$infix}-right {
      border-right: none !important; /* stylelint-disable-line */

      @include media-breakpoint-up($breakpoint) {
        border-right: $border-width solid $border-color !important; /* stylelint-disable-line */
      }
    }

    .border#{$infix}-bottom {
      border-bottom: none !important; /* stylelint-disable-line */

      @include media-breakpoint-up($breakpoint) {
        border-bottom: $border-width solid $border-color !important; /* stylelint-disable-line */
      }
    }

    .border#{$infix}-left {
      border-left: none !important; /* stylelint-disable-line */

      @include media-breakpoint-up($breakpoint) {
        border-left: $border-width solid $border-color !important; /* stylelint-disable-line */
      }
    }
  }
}

@each $key, $value in $colors {

  @include bg-variant(".bg-#{$key}", $value);

  .text-#{$key} {
    color: $value !important;  /* stylelint-disable-line */
  }

  a.text-#{$key}:focus {
    color: $value !important;  /* stylelint-disable-line */
  }

  .bg-layer-#{unquote($key)} {
    position: relative;
    background-color: transparent !important;  /* stylelint-disable-line */

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: "";
      background-color: $value;
    }
  }

  .col-separator-#{$key} {

    @include media($from: desktop) {
      > [class*="col-"]:not(:last-child) {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: "";
          border-left: 1px solid $value;
        }
      }
    }
  }

  .border-#{$key} {
    border-color: $value !important; /* stylelint-disable-line */
  }

  .text-before-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .text-after-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-before-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-after-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if $infix != "" {

      @include media-breakpoint-up($breakpoint) {
        .border#{$infix}-#{$key} {
          border: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-top {
          border-top: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-right {
          border-right: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-bottom {
          border-bottom: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-left {
          border-left: $border-width solid $value !important; /* stylelint-disable-line */
        }
      }
    }
  }
}

@each $key, $value in $grays {

  @include bg-variant(".bg-gray-#{$key}", $value);

  .text-gray-#{$key} {
    color: $value !important;  /* stylelint-disable-line */
  }

  a.text-gray-#{$key}:focus {
    color: $value !important;  /* stylelint-disable-line */
  }

  .bg-layer-gray-#{unquote($key)} {
    position: relative;
    background-color: transparent !important;  /* stylelint-disable-line */

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: "";
      background-color: $value;
    }
  }

  .col-separator-gray-#{$key} {

    @include media($from: desktop) {
      > [class*="col-"]:not(:last-child) {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: "";
          border-left: 1px solid $value;
        }
      }
    }
  }

  .border-gray-#{$key} {
    border-color: $value !important; /* stylelint-disable-line */
  }

  .text-gray-before-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .text-gray-after-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-gray-before-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-gray-after-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if $infix != "" {

      @include media-breakpoint-up($breakpoint) {
        .border-gray#{$infix}-#{$key} {
          border: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border-gray#{$infix}-#{$key}-top {
          border-top: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border-gray#{$infix}-#{$key}-right {
          border-right: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border-gray#{$infix}-#{$key}-bottom {
          border-bottom: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border-gray#{$infix}-#{$key}-left {
          border-left: $border-width solid $value !important; /* stylelint-disable-line */
        }
      }
    }
  }
}


@each $key, $value in $theme-colors {
  a.text-#{$key}:focus {
    color: $value !important;  /* stylelint-disable-line */
  }

  .bg-layer-#{unquote($key)} {
    position: relative;
    background-color: transparent !important;  /* stylelint-disable-line */

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: "";
      background-color: $value;
    }
  }

  .col-separator-#{$key} {

    @include media($from: desktop) {
      > [class*="col-"]:not(:last-child) {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: "";
          border-left: 1px solid $value;
        }
      }
    }
  }

  .text-before-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .text-after-#{$key} {
    &::before {
      color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-before-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  .bg-after-#{$key} {
    &::before {
      background-color: $value !important; /* stylelint-disable-line */
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if $infix != "" {

      @include media-breakpoint-up($breakpoint) {
        .border#{$infix}-#{$key} {
          border: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-top {
          border-top: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-right {
          border-right: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-bottom {
          border-bottom: $border-width solid $value !important; /* stylelint-disable-line */
        }

        .border#{$infix}-#{$key}-left {
          border-left: $border-width solid $value !important; /* stylelint-disable-line */
        }
      }
    }
  }
}

.close {
  font-style: normal;
}

.opacity {
  opacity: $font-opacity;
}

.container {
  max-width: 100% !important; /* stylelint-disable-line */

  @each $breakpoint, $container-max-width in $custom-container-max-width {

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width !important; /* stylelint-disable-line */
    }
  }
}

input[type="text"], select, button {
  height: 48px !important;
  border-color: #000;
  border-radius: 3px;
}

.btn-append-right {
  border-radius: 3px 0 0 3px !important;
}

select.form-control-select {
  background: url("../../img/arrow-select.svg") no-repeat 95% 50% #fff !important;
  border: 1px solid #ccc;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.gob-primary {
  background-color: #0f69b4 !important;
}

.gob-secondary {
  background-color: #e22c2c !important;
}

.gob-dark {
  background-color: #001c41 !important;
}
