// paths
$images-path: "../img/" !default;
$fonts-path: "../fonts/" !default;

$primary-font: "Roboto", sans-serif !default;
$accent-font: "Roboto Slab", serif !default;

@font-face {
  font-family: gobCl;
  src: url("#{$fonts-path}gobCL_Regular.otf");
}

@font-face {
  font-family: "gobCl_light";
  src: url("#{$fonts-path}gobCL_Light.otf");
}

@font-face {
  font-family: "gobCL_Bold";
  src: url("#{$fonts-path}gobCL_Bold.otf");
}

@font-face {
  font-family: "gobCL_Heavy";
  src: url("#{$fonts-path}gobCL_Heavy.otf");
}

$fonts: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$fonts: map-merge(
  (
    "primary": $primary-font,
    "accent": $accent-font
  ),
  $fonts
);

// media
$media-type: all !default;
$responsive: true !default;
$static-breakpoint: desktop !default;

// fonts
$font-size-base: 1rem !default;
$font-size-base-in-px: 16px !default;

$body-font-size: 14.4px !default;
$body-line-height: 21.6px !default;
$body-lg-font-size: $font-size-base-in-px !default;
$body-lg-line-height: 22px !default;

$h1-font-size: 24px !default;
$h2-font-size: 20px !default;
$h3-font-size: 19.7px !default;
$h4-font-size: 18px !default;
$h5-font-size: 17px !default;
$h6-font-size: 16px !default;
$h1-lg-font-size: 38.4px !default;
$h2-lg-font-size: 28px !default;
$h3-lg-font-size: 23px !default;
$h4-lg-font-size: 22px !default;
$h5-lg-font-size: 20px !default;
$h6-lg-font-size: 18px !default;

$h1-line-height: 46.08px !default;
$h2-line-height: 26px !default;
$h3-line-height: 26px !default;
$h4-line-height: 23.4px !default;
$h5-line-height: 19px !default;
$h6-line-height: 24px !default;
$h1-lg-line-height: 57.6px !default;
$h2-lg-line-height: 37px !default;
$h3-lg-line-height: 26px !default;
$h4-lg-line-height: 26px !default;
$h5-lg-line-height: 26px !default;
$h6-lg-line-height: 24px !default;

$a-font-size: 14.4px !default;
$a-line-height: 16px !default;
$a-lg-font-size: 16px !default;
$a-lg-line-height: 22px !default;

$small-font-size: 11px !default;
$small-line-height: 24px !default;
$small-lg-font-size: 11px !default;
$small-lg-line-height: 24px !default;

$p-font-size: $body-font-size !default;
$p-line-height: $body-line-height !default;
$p-lg-font-size: $font-size-base-in-px !default;
$p-lg-line-height: $body-lg-line-height !default;

$q-font-size: 16px !default;
$q-line-height: 19px !default;
$q-lg-font-size: 16px !default;
$q-lg-line-height: 19px !default;

$font-lg-sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$font-lg-sizes: map-merge(
  (
    1: ($h1-lg-font-size, $h1-lg-line-height),
    2: ($h2-lg-font-size, $h2-lg-line-height),
    3: ($h3-lg-font-size, $h3-lg-line-height),
    4: ($h4-lg-font-size, $h4-lg-line-height),
    5: ($h5-lg-font-size, $h5-lg-line-height),
    6: ($h6-lg-font-size, $h6-lg-line-height),
    7: ($body-lg-font-size, $body-lg-line-height),
    8: ($small-font-size, $small-line-height)
  ),
  $font-lg-sizes
);

$font-sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$font-sizes: map-merge(
  (
    1: ($h1-font-size, $h1-line-height),
    2: ($h2-font-size, $h2-line-height),
    3: ($h3-font-size, $h3-line-height),
    4: ($h4-font-size, $h4-line-height),
    5: ($h5-font-size, $h5-line-height),
    6: ($h6-font-size, $h6-line-height),
    7: ($body-font-size, $body-line-height),
    8: ($small-lg-font-size, $small-lg-line-height)
  ),
  $font-sizes
);

$font-opacity: .6 !default;

$font-weight-light: 300 !default;
$font-weight-normal:  400 !default;
$font-weight-bold:  700 !default;

// logo
$logo-width: 115px !default;

// footer
$footer-base-height: (
  xs: 64px,
  md: 245px,
  lg: 245px,
  xl: 223px,
) !default;

// footer links
$footer-link-height: 44px !default;
$footer-link-padding: 10px 40px 10px 0 !default;
$footer-link-lg-padding: 10px 0 !default;
$footer-links-max: 20 !default;
$footer-links-columns: 2 !default;
$footer-links-container-offset: 100px !default;
$footer-links-only-links: false !default;
$footer-links-ellipsized: true !default;
$footer-padding: 0 !default;
$footer-lg-padding: 40px 0 50px !default;

// colors
$shadow-color: #e3e5ef !default;

$black: #111 !default;
$gray-a: #4a4a4a !default;
$gray-b: #8a8a8a !default;
$gray-c: #eee !default;
$white: #fff !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "a": $gray-a,
    "b": $gray-b,
    "c": $gray-c
  ),
  $grays
);

$blue-light: #a8b7c7 !default;
$blue: #006fb3 !default;
$blue-dark: #0a132d !default;
$red: #fe6565 !default;
$gray: #a8b7c7 !default;
$yellow: #f2c728 !default;
$green: #2d717c !default;
$orangea: #e0701e !default;
$orangeb: #ffa11b !default;
$purple: #63c !default;
$colorlala:#e0701e !default;

.orange-a {
  background-color: $orangea !important;
}

.orange-b {
  background-color: $orangeb !important;
}

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "black": $black,
    "blue": $blue,
    "blue-dark": $blue-dark,
    "blue-light": $blue-light,
    "gray": $gray,
    "red": $red,
    "white": $white,
    "yellow": $yellow
  ),
  $colors
);

$primary: $blue !default;
$secondary: $red !default;
$tertiary: $blue-dark !default;
$accent: $blue-light !default;
$neutral: $gray-c !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "accent": $accent,
    "neutral": $neutral,
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary
  ),
  $theme-colors
);

$twitter: #00aced !default;
$facebook: #3b5998 !default;
$youtube: #f00 !default;
$instagram: #bc2a8d !default;

$social-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$social-colors: map-merge(
  (
    "twitter": $twitter,
    "youtube": $youtube,
    "instagram": $instagram,
    "facebook": $facebook
  ),
  $social-colors
);

$input-placeholder-color: $gray-b !default;
$hr-border-color: $accent !default;

// icons
$icons: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$icons: map-merge(
  (

    "search": ( "\e800", $black ),
    "agenda": ( "\e801", $black ),
    "agendarhora": ( "\e802", $black ),
    "alerta": ( "\e803", $black ),
    "ayuda": ( "\e804", $black ),
    "beneficios": ( "\e805", $black),
    "boot": ( "\e806", $black),
    "callcenter": ( "\e807", $black ),
    "cargar": ( "\e808", $black ),
    "cerrar": ( "\e809", $black ),
    "certificado_1": ( "\e80a", $black ),
    "claveunica": ( "\e80b", $black ),
    "contraste": ( "\e80c", $black ),
    "descargar": ( "\e80d", $black ),
    "editar": ( "\e80e", $black ),
    "facebook": ( "\e80f", $black ),
    "filtro": ( "\e810", $black ),
    "hablar": ( "\e811", $black ),
    "img": ( "\e812", $black ),
    "info": ( "\e813", $black ),
    "like": ( "\e814", $black ),
    "link": ( "\e815", $black ),
    "login": ( "\e816", $black ),
    "lupa": ( "\e817", $black ),
    "mas": ( "\e818", $black ),
    "menos": ( "\e819" $black ),
    "menu": ( "\e81a", $black ),
    "mess": ( "\e81b", $black ),
    "no-like": ( "\e81c", $black ),
    "notificacion": ( "\e81d", $black ),
    "pause": ( "\e81e", $black ),
    "play": ( "\e81f", $black ),
    "print": ( "\e820", $black ),
    "sound": ( "\e821", $black ),
    "sucursales": ( "\e822", $black ),
    "telefono": ( "\e823", $black ),
    "trash": ( "\e824", $black ),
    "twitter": ( "\e825", $black ),
    "ver": ( "\e826", $black ),
    "youtube": ( "\e827", $black ),
    "instagram": ( "\e828", $black ),
    "mail-alt": ( "\f0e0", $black ),

    "close-line": ("\e902", $accent),
    "logout": ("\e915", $accent),
    "download": ("\e908", $black),
    "breadcrumb-arrow": ("\e901", $accent),
    "arrow-line": ("\e90b", $primary),
    "close": ("\e909", $accent),
    "decrease-text": ("\e904", $accent),
    "increase-text": ("\e90a", $accent),
    "contrast": ("\e903", $accent),
    "messenger": ("\e930", $accent),
    "arrow": ("\e90e", $accent),
    "telephone": ("\e92c", $primary),
    "grant": ("\e920", $primary),
    "bonuses": ("\e91c", $primary),
    "burger": ("\e914", $black),
    "certificates": ("\e91e", $primary),
    "key-unique": ("\e905", $primary),
    "meet-authorities": ("\e922", $primary),
    "filter": ("\e91b", $primary),
    "procedures": ("\e91e", $primary),
    "login-fill": ("\e918", $primary),
    "question": ("\e91a", $accent),
    "doctor": ("\e91f", $primary),
    "woman": ("\e92d", $primary),
    "health": ("\e921", $primary),
    "modules": ("\e924", $primary),
    "navigation": ("\e925", $primary),
    "recent-news": ("\e927", $primary),
    "mobile-offices": ("\e923", $primary),
    "pensions": ("\e926", $primary),
    "social-networks": ("\e929", $primary),
    "subsidy-of-cesantia": ("\e92b", $primary),
    "subsidies": ("\e92a", $primary),
    "accessibility": ("\e900", $accent),
    "branches": ("\e91d", $primary),
    "arrow-right": ("\e90c", $accent),
    "add-circle-line": ("\e910", $accent),
    "add-circle-fill": ("\e90f", $accent),
    "m-arrow-right": ("\e90d", $black),
    "m-arrow-down": ("\e911", $black),
    "m-arrow-up": ("\e912", $black)
  ),
  $icons
);
$icon-sizes: 10 !default;


// navbar
$navbar-height: 54px !default;
$navbar-lg-height: 69px !default;
$navbar-light-color: $black !default;
$navbar-light-hover-color: $black !default;
$navbar-nav-link-line-height: 28px !default;

$navbar-nav-behavior-link-size: 25px !default;
$navbar-nav-behavior-link-line-height: $navbar-nav-behavior-link-size !default;
$navbar-nav-behavior-link-font-size: $small-font-size !default;

$navbar-nav-item-link-padding-x: 14.4px !default;
$navbar-nav-item-link-padding-y: 8px !default;

$navbar-lg-nav-item-link-padding-x: 14.4px !default;
$navbar-lg-nav-item-link-padding-y: 22.4px !default;

$navbar-lg-nav-behavior-padding-x: 14.4px !default;
$navbar-lg-nav-behavior-padding-y: 22.4px !default;

$navbar-nav-separator-width: 1px !default;

$navbar-dropdown-item-padding-x: 14.4px !default;
$navbar-dropdown-item-padding-y: 4px !default;

$navbar-heights: (
  xs: $navbar-height,
  md: $navbar-lg-height
) !default;

$custom-container-max-width: (
  lg: 960px,
  xl: 1140px
) !default;

// links
$link-icon-font-size: 22px !default;

// line component
$line-min-width: 600px !default;

// borders
$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// search component
$search-width: 100% !default;
$search-lg-width: 555px !default;

// button component
$button-height: 60px !default;
$button-line-height: 24px !default;
$button-padding-y: ((($button-height - $button-line-height) / 2)) !default;
$button-default-size-lg-width: 360px !default;

// card-featured component
$card-featured-image-height: 99px !default;
$card-featured-lg-image-height: 145px !default;
$card-featured-icon-margin: 6px !default;

$card-featured-padding: 13px !default;
$card-featured-title-lines: 2 !default;

$card-featured-body-lines: 3 !default;

$card-featured-title-line-height: $h4-line-height !default;
$card-featured-title-height: $card-featured-title-line-height * $card-featured-title-lines !default;
$card-featured-p-line-height: $p-line-height !default;
$card-featured-p-height: $card-featured-p-line-height * $card-featured-body-lines !default;
$card-featured-body-height: $card-featured-title-height + $card-featured-p-height + ($card-featured-padding * 3) !default;

$card-featured-lg-title-line-height: $h4-lg-line-height !default;
$card-featured-lg-title-height: $card-featured-lg-title-line-height * $card-featured-title-lines !default;
$card-featured-lg-p-line-height: $p-line-height !default;
$card-featured-lg-p-height: $card-featured-lg-p-line-height * $card-featured-body-lines !default;
$card-featured-lg-body-height: $card-featured-lg-title-height + $card-featured-lg-p-height + ($card-featured-padding * 3) !default;

// card-news component
$card-news-padding: 13px !default;

$card-news-image-height: 99px !default;
$card-news-lg-image-height: 145px !default;

$card-news-date-height: $small-line-height !default;
$card-news-lg-date-height: $small-lg-line-height !default;

$card-news-link-lines: 4 !default;
$card-news-link-line-height: $a-line-height !default;
$card-news-link-height: $card-news-link-line-height * $card-news-link-lines !default;
$card-news-body-height: $card-news-date-height + $card-news-link-height + ($card-news-padding * 3) !default;

$card-news-lg-link-line-height: $a-lg-line-height !default;
$card-news-lg-link-height: $card-news-lg-link-line-height * $card-news-link-lines !default;
$card-news-lg-body-height: $card-news-lg-date-height + $card-news-lg-link-height + ($card-news-padding * 3) !default;

$card-news-with-lead-h4-lines: 2 !default;
$card-news-with-lead-h4-line-height: $h4-line-height !default;
$card-news-with-lead-h4-height: $card-news-with-lead-h4-lines * $card-news-with-lead-h4-line-height !default;
$card-news-lg-with-lead-h4-line-height: $h4-lg-line-height !default;
$card-news-lg-with-lead-h4-height: $card-news-with-lead-h4-lines * $card-news-lg-with-lead-h4-line-height !default;

$card-news-with-lead-p-lines: 3 !default;
$card-news-with-lead-p-line-height: $p-line-height !default;
$card-news-with-lead-p-height: $card-news-with-lead-p-lines * $card-news-with-lead-p-line-height !default;
$card-news-lg-with-lead-p-line-height: $p-lg-line-height !default;
$card-news-lg-with-lead-p-height: $card-news-with-lead-p-lines * $card-news-lg-with-lead-p-line-height !default;

$card-news-with-lead-body-height: $card-news-with-lead-h4-height + $card-news-with-lead-p-height + ($card-news-padding * 3) !default;
$card-news-lg-width-lead-body-height: $card-news-lg-with-lead-h4-height + $card-news-lg-with-lead-p-height + ($card-news-padding * 3) !default;


// card-data component
$card-data-height: 94px !default;
$card-data-padding: 16px !default;

$card-data-title-lines: 2 !default;
$card-data-title-font-size: $h5-lg-font-size !default;
$card-data-title-line-height: $h5-lg-line-height !default;
$card-data-title-height: $card-data-title-line-height * $card-data-title-lines !default;

$card-data-quantity-line-height: 26px !default;
$card-data-quantity-font-size: 29px !default;
$card-data-quantity-width: 60px !default;
$card-data-quantity-separation: 10px !default;

$card-data-body-lines: 3 !default;
$card-data-body-font-size: 14.4px !default;
$card-data-body-line-height: $p-line-height !default;
$card-data-body-height: $card-data-body-line-height * $card-data-body-lines !default;

$card-data-first-bg-color: #f5f6f7 !default; // TODO: move.

$card-data-lg-height: $card-data-quantity-line-height + $card-data-title-height + $card-data-body-height
  + ($card-data-padding * 3) !default;

// card-links component
$card-links-link-lines: 2 !default;
$card-links-link-line-height: $body-line-height !default;
$card-links-link-height: $card-links-link-line-height * $card-links-link-lines !default;

$card-links-lg-link-line-height: $body-lg-line-height !default;
$card-links-lg-link-height: $card-links-lg-link-line-height * $card-links-link-lines !default;

$card-links-image-width: 50px !default;

// card-informative;
$card-informative-image-height: 99px !default;
$card-informative-lg-image-height: 200px !default;

$card-informative-padding: 13px !default;

$card-informative-title-lines: 1 !default;
$card-informative-title-line-height: $h3-line-height !default;
$card-informative-title-height: $card-informative-title-line-height * $card-informative-title-lines !default;

$card-informative-p-lines: 2 !default;
$card-informative-p-line-height: $p-line-height !default;
$card-informative-p-height: $card-informative-p-line-height * $card-informative-p-lines !default;

$card-informative-body: $card-informative-title-height + $card-informative-p-height + ($card-informative-padding * 3) !default;

$card-informative-lg-title-line-height: $h3-lg-line-height !default;
$card-informative-lg-title-height: $card-informative-lg-title-line-height * $card-informative-title-lines !default;

$card-informative-lg-p-line-height: $p-lg-line-height !default;
$card-informative-lg-p-height: $card-informative-lg-p-line-height * $card-informative-p-lines !default;

$card-informative-lg-body: $card-informative-lg-title-height + $card-informative-lg-p-height + ($card-informative-padding * 3) !default;

// profiles
$profile-p-line-height: 24px !default;

// profile component
$profile-charge-font-size: 22px !default;
$profile-charge-lines: 2 !default;
$profile-charge-line-height: 29px !default;
$profile-charge-height: $profile-charge-line-height * $profile-charge-lines !default;
$profile-charge-letter-spacing: 1.08px !default;
$profile-charge-border: 1px solid theme-color("accent") !default;

// social component
$social-icon-font-size: 32px !default;
$social-line-height: 16px !default;
$social-name-font-size: 16px !default;
$social-url-font-size: 12px !default;

// toolbar component
$toolbar-button-size: 38px !default;
$toolbar-button-icon-size: 16px !default;
$toolbar-button-read-width: $toolbar-button-size !default;

$toolbar-button-border-width: 1.77px !default;
$toolbar-button-padding: (
  $toolbar-button-size - $toolbar-button-icon-size - ($toolbar-button-border-width * 2)
) / 2 !default;

$toolbar-button-group-margin: 4px !default;
$toolbar-height: $toolbar-button-size !default;
$toolbar-width: 200px !default;

$toolbar-behavior-decrease-icon-size: 16px !default;
$toolbar-behavior-decrease-padding: (
  $toolbar-button-size - $toolbar-behavior-decrease-icon-size - ($toolbar-button-border-width * 2)
) / 2 !default;

$toolbar-behavior-increase-icon-size: 16px !default;
$toolbar-behavior-increase-padding: (
  $toolbar-button-size - $toolbar-behavior-increase-icon-size - ($toolbar-button-border-width * 2)
) / 2 !default;

$toolbar-behavior-read-icon-size: 16px !default;
$toolbar-behavior-read-padding: (
  $toolbar-button-size - $toolbar-behavior-read-icon-size - ($toolbar-button-border-width * 2)
) / 2 !default;

$toolbar-behavior-read-icon-content-padding: (
  $toolbar-button-size - $toolbar-behavior-read-icon-size - ($toolbar-button-border-width * 2)
) / 2 !default;


// toolbar with read speaker
$toolbar-read-speaker-width: 300px !default;
$toolbar-read-speaker-button-read-width: 160px !default;
$toolbar-read-speaker-behavior-read-padding: 0 !default;

// contingency section
$zindex-contingency: 1010 !default;

$contingency-footer-height: 60px !default;

$contingency-body-height: calc(100vh - #{$navbar-height + $contingency-footer-height}) !default;
$contingency-lg-body-height: calc(100vh - #{$navbar-lg-height + $contingency-footer-height}) !default;

$contingency-item-date-width: 70px !default;
$contingency-item-time-font-size: 14px !default;
$contingency-item-day-font-size: 10px !default;
$contingency-line-size: 2.5px !default;

// onboarding section
$zindex-onboarding: 1090 !default;

$onboarding-footer-height: 60px !default;

$onboarding-body-height: calc(100vh - #{$onboarding-footer-height}) !default;
$onboarding-lg-body-height: calc(100vh - #{$onboarding-footer-height}) !default;

// jumbotrom section
$section-jumbotron-h1-font-size: 24px !default;
$section-jumbotron-h1-line-height: 57.6px !default;
$section-jumbotron-p-font-size: 14.4px !default;
$section-jumbotron-p-line-height: 17px !default;

$section-jumbotron-lg-h1-font-size: 48px !default;
$section-jumbotron-lg-h1-line-height: 57.6px !default;
$section-jumbotron-lg-p-font-size: 20px !default;
$section-jumbotron-lg-p-line-height: 24px !default;

// redirecting section
$zindex-redirecting: 1100 !default;

$redirecting-footer-height: 60px !default;

$redirecting-body-min-height: calc(100vh - #{$redirecting-footer-height}) !default;
$redirecting-lg-body-min-height: calc(100vh - #{$redirecting-footer-height}) !default;

// breadcrumb component
$breadcrumb-divider-margin: 10px !default;
$breadcrumb-divider: nth(map-get($icons, "breadcrumb-arrow"), 1) !default;
$breadcrumb-divider-color: nth(map-get($icons, "breadcrumb-arrow"), 2) !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-min-height: 34px !default;

// page news
$page-news-h1-font-size: 20px !default;
$page-news-h1-line-height: 26px !default;

$page-news-h1-lg-font-size: 23px !default;
$page-news-h1-lg-line-height: 26px !default;

$page-news-h4-font-size: 15px !default;
$page-news-h4-line-height: 24px !default;

$page-news-h4-lg-font-size: 18px !default;
$page-news-h4-lg-line-height: 24px !default;

// line component
$line-background: linear-gradient(to right, $primary 0%, $primary 50%, $secondary 50%, $secondary 100%) !default;

// text-lines
$text-max-lines: 20 !default;
