@import "abstracts/variables",
  "abstracts/functions";

.a11y-contrast {

  @import "base/base",
    "base/typography",
    "base/icons";

  @import "components/toolbar",
    "components/search",
    "components/cards",
    "components/buttons",
    "components/breadcrumb",
    "components/social";

  @import "layout/navbar",
    "layout/sections",
    "layout/footer",
    "layout/forms",
    "layout/sidebar";

  @import "vendors/select2",
    "vendors/read-speaker";
}
