@mixin media(
  $from: false,
  $until: false,
  $and: false,
  $media-type: $media-type,
  $breakpoints: $breakpoints,
  $responsive: $responsive,
  $static-breakpoint: $static-breakpoint
) {
  $min-width: 0;
  $max-width: 0;
  $media-query: "";

  // From: this breakpoint (inclusive)

  @if $from {

    @if type-of($from) == number {
      $min-width: $from;
    }

    @else {
      $min-width: get-breakpoint-width($from, $breakpoints);
    }
  }

  // Until: that breakpoint (exclusive)

  @if $until {

    @if type-of($until) == number {
      $max-width: $until;
    }

    @else {
      $max-width: get-breakpoint-width($until, $breakpoints) - 1px;
    }
  }

  // Responsive support is disabled, rasterize the output outside @media blocks
  // The browser will rely on the cascade itself.

  @if $responsive == false {
    $static-breakpoint-width: get-breakpoint-width($static-breakpoint, $breakpoints);
    $target-width: $static-breakpoint-width;

    // Output only rules that start at or span our target width

    @if ($and == false and $min-width <= $target-width and ($until == false or $max-width >= $target-width)) {

      @content;
    }
    // Responsive support is enabled, output rules inside @media queries
  }

  @else {

    @if $min-width != 0 {
      $media-query: "#{$media-query} and (min-width: #{$min-width})";
    }

    @if $max-width != 0 {
      $media-query: "#{$media-query} and (max-width: #{$max-width})";
    }

    @if $and {
      $media-query: "#{$media-query} and #{$and}";
    }

    // Remove unnecessary media query prefix 'all and '

    @if ($media-type == "all" and $media-query != "") {
      $media-type: "";
      $media-query: str-slice(unquote($media-query), 6);
    }

    @media #{$media-type + $media-query} {

      @content;
    }
  }
}

@mixin split-background($color-a, $color-b) {
  background: linear-gradient(to right, $color-a 0%, $color-a 50%, $color-b 50%, $color-b 100%);
}

@mixin use-icon($icon-name) {

  @extend %cl;

  color: nth(map-get($icons, $icon-name), 2);
  content: nth(map-get($icons, $icon-name), 1);
}

@mixin font($level) {
  font-size: rem(font($level));
  line-height: rem(line-height($level));

  @include media($from: tablet) {
    font-size: rem(font($level, lg));
    line-height: rem(line-height($level, lg));
  }
}

@mixin only-font($level) {
  font-size: rem(font($level));

  @include media($from: tablet) {
    font-size: rem(font($level, lg));
  }
}

@mixin only-line-height($level) {
  line-height: rem(line-height($level));

  @include media($from: tablet) {
    line-height: rem(line-height($level, lg));
  }
}

@mixin lines($lines, $level: 7) {
  height: rem(line-height($level) * $lines);
  overflow: hidden;

  @include media($from: tablet) {
    height: rem(line-height($level, lg) * $lines);
    overflow: hidden;
  }
}

@mixin make-sub-display-class($base-class) {
  .#{$base-class}-d-none {
    display: none !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-inline {
    display: inline !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-inline-block {
    display: inline-block !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-block {
    display: block !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-table {
    display: table !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-table-row {
    display: table-row !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-table-cell {
    display: table-cell !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-flex {
    display: flex !important; /* stylelint-disable-line */
  }

  .#{$base-class}-d-inline-flex {
    display: inline-flex !important; /* stylelint-disable-line */
  }
}
