@charset "UTF-8";
@font-face {
  font-family: gobCl;
  src: url("../fonts/gobCL_Regular.otf"); }

@font-face {
  font-family: "gobCl_light";
  src: url("../fonts/gobCL_Light.otf"); }

@font-face {
  font-family: "gobCL_Bold";
  src: url("../fonts/gobCL_Bold.otf"); }

@font-face {
  font-family: "gobCL_Heavy";
  src: url("../fonts/gobCL_Heavy.otf"); }

.orange-a {
  background-color: #e0701e !important; }

.orange-b {
  background-color: #ffa11b !important; }

.cl, .breadcrumb .breadcrumb-item:not(:first-child)::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icongob-cl !important;
  /* stylelint-disable-line */
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* empty */
/* base vendors */
/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #006fb3;
  --indigo: #6610f2;
  --purple: #63c;
  --pink: #e83e8c;
  --red: #fe6565;
  --orange: #fd7e14;
  --yellow: #f2c728;
  --green: #2d717c;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #a8b7c7;
  --gray-dark: #343a40;
  --black: #111;
  --blue-dark: #0a132d;
  --blue-light: #a8b7c7;
  --primary: #006fb3;
  --secondary: #fe6565;
  --success: #2d717c;
  --info: #17a2b8;
  --warning: #f2c728;
  --danger: #fe6565;
  --light: #f8f9fa;
  --dark: #343a40;
  --accent: #a8b7c7;
  --neutral: #eee;
  --tertiary: #0a132d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(17, 17, 17, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #006fb3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #004067;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .card-informative .card-informative-body > a, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 20px; }

h3, .h3, .card-informative .card-informative-body > a {
  font-size: 19.7px; }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 17px; }

h6, .h6 {
  font-size: 16px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #a8b7c7; }

small,
.small {
  font-size: 11px;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 11px;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid, .pseudo-background .pseudo-src, .profile-image {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .section-social {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, .section-social {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .section-social {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .section-social {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .section-social {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .card-data-list, .card-featured-news, .profile, .profile-page, .profile-section {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .card-data-list .card-data, .profile .profile-body section.section:first-child .section-body, .profile-section .profile-body section.section:first-child .section-body, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .profile-page .profile-body, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .profile-section .profile-footer, .col-lg-5, .card-featured-news .card-featured-news-image, .col-lg-6, .col-lg-7, .card-featured-news .card-featured-news-body, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12, .card-data-list .card-data, .profile .profile-body section.section:first-child .section-body, .profile-section .profile-body section.section:first-child .section-body {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8, .profile-page .profile-body {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg, .card-data-list .card-data {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4, .profile-section .profile-footer {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5, .card-featured-news .card-featured-news-image {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7, .card-featured-news .card-featured-news-body {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(17, 17, 17, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(17, 17, 17, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d7ea; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ab4d7; }

.table-hover .table-primary:hover {
  background-color: #a4cce4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a4cce4; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffd4d4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #feafaf; }

.table-hover .table-secondary:hover {
  background-color: #ffbbbb; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ffbbbb; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4d7da; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #92b5bb; }

.table-hover .table-success:hover {
  background-color: #b4ccd0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b4ccd0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbefc3; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f8e28f; }

.table-hover .table-warning:hover {
  background-color: #f9e9ab; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f9e9ab; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffd4d4; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #feafaf; }

.table-hover .table-danger:hover {
  background-color: #ffbbbb; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffbbbb; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #e7ebef; }

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #d2dae2; }

.table-hover .table-accent:hover {
  background-color: #d8dee5; }
  .table-hover .table-accent:hover > td,
  .table-hover .table-accent:hover > th {
    background-color: #d8dee5; }

.table-neutral,
.table-neutral > th,
.table-neutral > td {
  background-color: #fafafa; }

.table-neutral th,
.table-neutral td,
.table-neutral thead th,
.table-neutral tbody + tbody {
  border-color: #f6f6f6; }

.table-hover .table-neutral:hover {
  background-color: #ededed; }
  .table-hover .table-neutral:hover > td,
  .table-hover .table-neutral:hover > th {
    background-color: #ededed; }

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #babdc4; }

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #808492; }

.table-hover .table-tertiary:hover {
  background-color: #acb0b8; }
  .table-hover .table-tertiary:hover > td,
  .table-hover .table-tertiary:hover > th {
    background-color: #acb0b8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(17, 17, 17, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(4, 4, 4, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(4, 4, 4, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #34b2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
  .form-control::placeholder {
    color: #8a8a8a;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #2d717c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(45, 113, 124, 0.9);
  border-radius: 0; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2d717c;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232d717c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #2d717c;
    box-shadow: 0 0 0 0.2rem rgba(45, 113, 124, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #2d717c;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232d717c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #2d717c;
    box-shadow: 0 0 0 0.2rem rgba(45, 113, 124, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2d717c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #2d717c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #2d717c; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3b93a1;
  background-color: #3b93a1; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(45, 113, 124, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2d717c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2d717c; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2d717c;
  box-shadow: 0 0 0 0.2rem rgba(45, 113, 124, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #fe6565; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(254, 101, 101, 0.9);
  border-radius: 0; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fe6565;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fe6565' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #fe6565;
    box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #fe6565;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fe6565' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #fe6565;
    box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fe6565; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #fe6565; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #fe6565; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fe9898;
  background-color: #fe9898; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fe6565; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fe6565; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fe6565;
  box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group, .form-inline .search:not(.with-label), .form-inline .search.with-label .search-content, .search.with-label .form-inline .search-content,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #006fb3;
  border-color: #006fb3; }
  .btn-primary:hover {
    color: #fff;
    background-color: #00578d;
    border-color: #004f80; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 133, 190, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #006fb3;
    border-color: #006fb3; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #004f80;
    border-color: #004773; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 133, 190, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #fe6565;
  border-color: #fe6565; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #fe3f3f;
    border-color: #fe3232; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 124, 124, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #fe6565;
    border-color: #fe6565; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fe3232;
    border-color: #fe2626; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 124, 124, 0.5); }

.btn-success {
  color: #fff;
  background-color: #2d717c;
  border-color: #2d717c; }
  .btn-success:hover {
    color: #fff;
    background-color: #235760;
    border-color: #1f4f57; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 134, 144, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #2d717c;
    border-color: #2d717c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1f4f57;
    border-color: #1c464d; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 134, 144, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f2c728;
  border-color: #f2c728; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e6b80e;
    border-color: #daae0d; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 175, 40, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f2c728;
    border-color: #f2c728; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #daae0d;
    border-color: #cea50c; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 175, 40, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #fe6565;
  border-color: #fe6565; }
  .btn-danger:hover {
    color: #fff;
    background-color: #fe3f3f;
    border-color: #fe3232; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 124, 124, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #fe6565;
    border-color: #fe6565; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #fe3232;
    border-color: #fe2626; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 124, 124, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-accent {
  color: #212529;
  background-color: #a8b7c7;
  border-color: #a8b7c7; }
  .btn-accent:hover {
    color: #212529;
    background-color: #91a4b8;
    border-color: #899db3; }
  .btn-accent:focus, .btn-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 161, 175, 0.5); }
  .btn-accent.disabled, .btn-accent:disabled {
    color: #212529;
    background-color: #a8b7c7;
    border-color: #a8b7c7; }
  .btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
  .show > .btn-accent.dropdown-toggle {
    color: #212529;
    background-color: #899db3;
    border-color: #8197ae; }
    .btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 161, 175, 0.5); }

.btn-neutral {
  color: #212529;
  background-color: #eee;
  border-color: #eee; }
  .btn-neutral:hover {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d5d4d4; }
  .btn-neutral:focus, .btn-neutral.focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5); }
  .btn-neutral.disabled, .btn-neutral:disabled {
    color: #212529;
    background-color: #eee;
    border-color: #eee; }
  .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-neutral.dropdown-toggle {
    color: #212529;
    background-color: #d5d4d4;
    border-color: #cecece; }
    .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5); }

.btn-tertiary {
  color: #fff;
  background-color: #0a132d;
  border-color: #0a132d; }
  .btn-tertiary:hover {
    color: #fff;
    background-color: #03060e;
    border-color: #010103; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 54, 77, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #fff;
    background-color: #0a132d;
    border-color: #0a132d; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #010103;
    border-color: black; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 54, 77, 0.5); }

.btn-outline-primary {
  color: #006fb3;
  border-color: #006fb3; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #006fb3;
    border-color: #006fb3; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #006fb3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #006fb3;
    border-color: #006fb3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.5); }

.btn-outline-secondary {
  color: #fe6565;
  border-color: #fe6565; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #fe6565;
    border-color: #fe6565; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fe6565;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fe6565;
    border-color: #fe6565; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.5); }

.btn-outline-success {
  color: #2d717c;
  border-color: #2d717c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #2d717c;
    border-color: #2d717c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(45, 113, 124, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #2d717c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #2d717c;
    border-color: #2d717c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(45, 113, 124, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #f2c728;
  border-color: #f2c728; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f2c728;
    border-color: #f2c728; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 199, 40, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f2c728;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f2c728;
    border-color: #f2c728; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 199, 40, 0.5); }

.btn-outline-danger {
  color: #fe6565;
  border-color: #fe6565; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #fe6565;
    border-color: #fe6565; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #fe6565;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #fe6565;
    border-color: #fe6565; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(254, 101, 101, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-accent {
  color: #a8b7c7;
  border-color: #a8b7c7; }
  .btn-outline-accent:hover {
    color: #212529;
    background-color: #a8b7c7;
    border-color: #a8b7c7; }
  .btn-outline-accent:focus, .btn-outline-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 183, 199, 0.5); }
  .btn-outline-accent.disabled, .btn-outline-accent:disabled {
    color: #a8b7c7;
    background-color: transparent; }
  .btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent.dropdown-toggle {
    color: #212529;
    background-color: #a8b7c7;
    border-color: #a8b7c7; }
    .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 183, 199, 0.5); }

.btn-outline-neutral {
  color: #eee;
  border-color: #eee; }
  .btn-outline-neutral:hover {
    color: #212529;
    background-color: #eee;
    border-color: #eee; }
  .btn-outline-neutral:focus, .btn-outline-neutral.focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5); }
  .btn-outline-neutral.disabled, .btn-outline-neutral:disabled {
    color: #eee;
    background-color: transparent; }
  .btn-outline-neutral:not(:disabled):not(.disabled):active, .btn-outline-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-outline-neutral.dropdown-toggle {
    color: #212529;
    background-color: #eee;
    border-color: #eee; }
    .btn-outline-neutral:not(:disabled):not(.disabled):active:focus, .btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5); }

.btn-outline-tertiary {
  color: #0a132d;
  border-color: #0a132d; }
  .btn-outline-tertiary:hover {
    color: #fff;
    background-color: #0a132d;
    border-color: #0a132d; }
  .btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0.2rem rgba(10, 19, 45, 0.5); }
  .btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
    color: #0a132d;
    background-color: transparent; }
  .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #0a132d;
    border-color: #0a132d; }
    .btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(10, 19, 45, 0.5); }

.btn-link {
  font-weight: 400;
  color: #006fb3; }
  .btn-link:hover {
    color: #004067;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 17, 17, 0.15);
  border-radius: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0 - 1px);
    border-top-right-radius: calc(0 - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0 - 1px);
    border-bottom-left-radius: calc(0 - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #006fb3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group, .btn-toolbar .search:not(.with-label), .btn-toolbar .search.with-label .search-content, .search.with-label .btn-toolbar .search-content {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group, .search:not(.with-label), .search.with-label .search-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .search:not(.with-label) > .form-control, .search.with-label .search-content > .form-control,
  .input-group > .form-control-plaintext,
  .search:not(.with-label) > .form-control-plaintext,
  .search.with-label .search-content > .form-control-plaintext,
  .input-group > .custom-select,
  .search:not(.with-label) > .custom-select,
  .search.with-label .search-content > .custom-select,
  .input-group > .custom-file,
  .search:not(.with-label) > .custom-file,
  .search.with-label .search-content > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .search:not(.with-label) > .form-control + .form-control, .search.with-label .search-content > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .search:not(.with-label) > .form-control + .custom-select,
    .search.with-label .search-content > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .search:not(.with-label) > .form-control + .custom-file,
    .search.with-label .search-content > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .search:not(.with-label) > .form-control-plaintext + .form-control,
    .search.with-label .search-content > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .search:not(.with-label) > .form-control-plaintext + .custom-select,
    .search.with-label .search-content > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .search:not(.with-label) > .form-control-plaintext + .custom-file,
    .search.with-label .search-content > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .search:not(.with-label) > .custom-select + .form-control,
    .search.with-label .search-content > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .search:not(.with-label) > .custom-select + .custom-select,
    .search.with-label .search-content > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .search:not(.with-label) > .custom-select + .custom-file,
    .search.with-label .search-content > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .search:not(.with-label) > .custom-file + .form-control,
    .search.with-label .search-content > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .search:not(.with-label) > .custom-file + .custom-select,
    .search.with-label .search-content > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file,
    .search:not(.with-label) > .custom-file + .custom-file,
    .search.with-label .search-content > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .search:not(.with-label) > .form-control:focus, .search.with-label .search-content > .form-control:focus,
  .input-group > .custom-select:focus,
  .search:not(.with-label) > .custom-select:focus,
  .search.with-label .search-content > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
  .search:not(.with-label) > .custom-file .custom-file-input:focus ~ .custom-file-label,
  .search.with-label .search-content > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus, .search:not(.with-label) > .custom-file .custom-file-input:focus, .search.with-label .search-content > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .search:not(.with-label) > .form-control:not(:last-child), .search.with-label .search-content > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child),
  .search:not(.with-label) > .custom-select:not(:last-child),
  .search.with-label .search-content > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .search:not(.with-label) > .form-control:not(:first-child), .search.with-label .search-content > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child),
  .search:not(.with-label) > .custom-select:not(:first-child),
  .search.with-label .search-content > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  
  .input-group > .custom-file,
  .search:not(.with-label) > .custom-file,
  .search.with-label .search-content > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label, .search:not(.with-label) > .custom-file:not(:last-child) .custom-file-label, .search.with-label .search-content > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after,
    .search:not(.with-label) > .custom-file:not(:last-child) .custom-file-label::after,
    .search.with-label .search-content > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label, .search:not(.with-label) > .custom-file:not(:first-child) .custom-file-label, .search.with-label .search-content > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append,
.search .search-action {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn,
  .search .search-action .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus,
    .search .search-action .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .search .search-action .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .search .search-action .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .search .search-action .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .search .search-action .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }


.input-group-append,
.search .search-action {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .search:not(.with-label) > .form-control:not(textarea), .search.with-label .search-content > .form-control:not(textarea),
.input-group-lg > .custom-select,
.search:not(.with-label) > .custom-select,
.search.with-label .search-content > .custom-select {
  height: calc(2.875rem + 2px); }

.input-group-lg > .form-control, .search:not(.with-label) > .form-control, .search.with-label .search-content > .form-control,
.input-group-lg > .custom-select,
.search:not(.with-label) > .custom-select,
.search.with-label .search-content > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.search:not(.with-label) > .input-group-prepend > .input-group-text,
.search.with-label .search-content > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.search:not(.with-label) > .input-group-append > .input-group-text,
.search.with-label .search-content > .input-group-append > .input-group-text,
.search .input-group-lg > .search-action > .input-group-text,
.search:not(.with-label) > .search-action > .input-group-text,
.search.with-label .search-content > .search-action > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.search:not(.with-label) > .input-group-prepend > .btn,
.search.with-label .search-content > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn,
.search:not(.with-label) > .input-group-append > .btn,
.search.with-label .search-content > .input-group-append > .btn,
.search .input-group-lg > .search-action > .btn,
.search:not(.with-label) > .search-action > .btn,
.search.with-label .search-content > .search-action > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.search .input-group-sm > .search-action > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn,
.search .input-group-sm > .search-action > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }


.input-group-lg > .custom-select,
.search:not(.with-label) > .custom-select,
.search.with-label .search-content > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .search:not(.with-label) > .input-group-prepend > .btn, .search.with-label .search-content > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.search:not(.with-label) > .input-group-prepend > .input-group-text,
.search.with-label .search-content > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.search:not(.with-label) > .input-group-append:not(:last-child) > .btn,
.search.with-label .search-content > .input-group-append:not(:last-child) > .btn,
.search .input-group > .search-action:not(:last-child) > .btn,
.search:not(.with-label) > .search-action:not(:last-child) > .btn,
.search.with-label .search-content > .search-action:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.search:not(.with-label) > .input-group-append:not(:last-child) > .input-group-text,
.search.with-label .search-content > .input-group-append:not(:last-child) > .input-group-text,
.search .input-group > .search-action:not(:last-child) > .input-group-text,
.search:not(.with-label) > .search-action:not(:last-child) > .input-group-text,
.search.with-label .search-content > .search-action:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.search:not(.with-label) > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.search.with-label .search-content > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.search .input-group > .search-action:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.search:not(.with-label) > .search-action:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.search.with-label .search-content > .search-action:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.search:not(.with-label) > .input-group-append:last-child > .input-group-text:not(:last-child),
.search.with-label .search-content > .input-group-append:last-child > .input-group-text:not(:last-child),
.search .input-group > .search-action:last-child > .input-group-text:not(:last-child),
.search:not(.with-label) > .search-action:last-child > .input-group-text:not(:last-child),
.search.with-label .search-content > .search-action:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .search:not(.with-label) > .input-group-append > .btn, .search.with-label .search-content > .input-group-append > .btn, .search .input-group > .search-action > .btn, .search:not(.with-label) > .search-action > .btn, .search.with-label .search-content > .search-action > .btn,
.input-group > .input-group-append > .input-group-text,
.search:not(.with-label) > .input-group-append > .input-group-text,
.search.with-label .search-content > .input-group-append > .input-group-text,
.search .input-group > .search-action > .input-group-text,
.search:not(.with-label) > .search-action > .input-group-text,
.search.with-label .search-content > .search-action > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.search:not(.with-label) > .input-group-prepend:not(:first-child) > .btn,
.search.with-label .search-content > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.search:not(.with-label) > .input-group-prepend:not(:first-child) > .input-group-text,
.search.with-label .search-content > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.search:not(.with-label) > .input-group-prepend:first-child > .btn:not(:first-child),
.search.with-label .search-content > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.search:not(.with-label) > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.search.with-label .search-content > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #006fb3;
    background-color: #006fb3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #34b2ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #67c5ff;
    border-color: #67c5ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #006fb3;
  background-color: #006fb3; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 111, 179, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 111, 179, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 111, 179, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 111, 179, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #34b2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 178, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #34b2ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #006fb3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #67c5ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #006fb3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #67c5ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #006fb3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #67c5ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #006fb3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container, .navbar > .section-social,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .section-social,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, .navbar-expand-sm > .section-social,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .section-social,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, .navbar-expand-md > .section-social,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .section-social,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, .navbar-expand-lg > .section-social,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .section-social,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, .navbar-expand-xl > .section-social,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .navbar-expand > .section-social,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, .navbar-expand > .section-social,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(17, 17, 17, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(17, 17, 17, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: #111; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #111; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(17, 17, 17, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(17, 17, 17, 0.9); }

.navbar-light .navbar-toggler {
  color: #111;
  border-color: rgba(17, 17, 17, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23111' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #111; }
  .navbar-light .navbar-text a {
    color: rgba(17, 17, 17, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(17, 17, 17, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(17, 17, 17, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(17, 17, 17, 0.03);
  border-bottom: 1px solid rgba(17, 17, 17, 0.125); }
  .card-header:first-child {
    border-radius: calc(0 - 1px) calc(0 - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(17, 17, 17, 0.03);
  border-top: 1px solid rgba(17, 17, 17, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0 - 1px) calc(0 - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0;
            border-top-right-radius: 0; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card {
  overflow: hidden; }
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #a8b7c7;
    content: ""; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #006fb3;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #004067;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 179, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #006fb3;
  border-color: #006fb3; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #006fb3; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #004f80; }

.badge-secondary {
  color: #fff;
  background-color: #fe6565; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #fe3232; }

.badge-success {
  color: #fff;
  background-color: #2d717c; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1f4f57; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #f2c728; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #daae0d; }

.badge-danger {
  color: #fff;
  background-color: #fe6565; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #fe3232; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }

.badge-accent {
  color: #212529;
  background-color: #a8b7c7; }
  a.badge-accent:hover, a.badge-accent:focus {
    color: #212529;
    background-color: #899db3; }

.badge-neutral {
  color: #212529;
  background-color: #eee; }
  a.badge-neutral:hover, a.badge-neutral:focus {
    color: #212529;
    background-color: #d5d4d4; }

.badge-tertiary {
  color: #fff;
  background-color: #0a132d; }
  a.badge-tertiary:hover, a.badge-tertiary:focus {
    color: #fff;
    background-color: #010103; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #084265;
  background-color: #cce2f0;
  border-color: #b8d7ea; }
  .alert-primary hr {
    border-top-color: #a4cce4; }
  .alert-primary .alert-link {
    color: #042336; }

.alert-secondary {
  color: #8c3d3d;
  background-color: #ffe0e0;
  border-color: #ffd4d4; }
  .alert-secondary hr {
    border-top-color: #ffbbbb; }
  .alert-secondary .alert-link {
    color: #682e2e; }

.alert-success {
  color: #204349;
  background-color: #d5e3e5;
  border-color: #c4d7da; }
  .alert-success hr {
    border-top-color: #b4ccd0; }
  .alert-success .alert-link {
    color: #102226; }

.alert-info {
  color: #145c68;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #0c363d; }

.alert-warning {
  color: #86701d;
  background-color: #fcf4d4;
  border-color: #fbefc3; }
  .alert-warning hr {
    border-top-color: #f9e9ab; }
  .alert-warning .alert-link {
    color: #5c4d14; }

.alert-danger {
  color: #8c3d3d;
  background-color: #ffe0e0;
  border-color: #ffd4d4; }
  .alert-danger hr {
    border-top-color: #ffbbbb; }
  .alert-danger .alert-link {
    color: #682e2e; }

.alert-light {
  color: #898a8a;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #707070; }

.alert-dark {
  color: #232629;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #0c0d0d; }

.alert-accent {
  color: #606770;
  background-color: #eef1f4;
  border-color: #e7ebef; }
  .alert-accent hr {
    border-top-color: #d8dee5; }
  .alert-accent .alert-link {
    color: #484e55; }

.alert-neutral {
  color: #848484;
  background-color: #fcfcfc;
  border-color: #fafafa; }
  .alert-neutral hr {
    border-top-color: #ededed; }
  .alert-neutral .alert-link {
    color: #6b6a6a; }

.alert-tertiary {
  color: #0d1220;
  background-color: #ced0d5;
  border-color: #babdc4; }
  .alert-tertiary hr {
    border-top-color: #acb0b8; }
  .alert-tertiary .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #006fb3;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(17, 17, 17, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #006fb3;
    border-color: #006fb3; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #084265;
  background-color: #b8d7ea; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #084265;
    background-color: #a4cce4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084265;
    border-color: #084265; }

.list-group-item-secondary {
  color: #8c3d3d;
  background-color: #ffd4d4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #8c3d3d;
    background-color: #ffbbbb; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #8c3d3d;
    border-color: #8c3d3d; }

.list-group-item-success {
  color: #204349;
  background-color: #c4d7da; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #204349;
    background-color: #b4ccd0; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #204349;
    border-color: #204349; }

.list-group-item-info {
  color: #145c68;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #145c68;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #145c68;
    border-color: #145c68; }

.list-group-item-warning {
  color: #86701d;
  background-color: #fbefc3; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #86701d;
    background-color: #f9e9ab; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #86701d;
    border-color: #86701d; }

.list-group-item-danger {
  color: #8c3d3d;
  background-color: #ffd4d4; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #8c3d3d;
    background-color: #ffbbbb; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8c3d3d;
    border-color: #8c3d3d; }

.list-group-item-light {
  color: #898a8a;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #898a8a;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #898a8a;
    border-color: #898a8a; }

.list-group-item-dark {
  color: #232629;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #232629;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #232629;
    border-color: #232629; }

.list-group-item-accent {
  color: #606770;
  background-color: #e7ebef; }
  .list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
    color: #606770;
    background-color: #d8dee5; }
  .list-group-item-accent.list-group-item-action.active {
    color: #fff;
    background-color: #606770;
    border-color: #606770; }

.list-group-item-neutral {
  color: #848484;
  background-color: #fafafa; }
  .list-group-item-neutral.list-group-item-action:hover, .list-group-item-neutral.list-group-item-action:focus {
    color: #848484;
    background-color: #ededed; }
  .list-group-item-neutral.list-group-item-action.active {
    color: #fff;
    background-color: #848484;
    border-color: #848484; }

.list-group-item-tertiary {
  color: #0d1220;
  background-color: #babdc4; }
  .list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
    color: #0d1220;
    background-color: #acb0b8; }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #fff;
    background-color: #0d1220;
    border-color: #0d1220; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #111;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #111;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(17, 17, 17, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 17, 17, 0.2);
  border-radius: 0;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #111; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #111; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #111; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #111; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #111; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #111;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 17, 17, 0.2);
  border-radius: 0; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(17, 17, 17, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(17, 17, 17, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(17, 17, 17, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(17, 17, 17, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #006fb3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #004f80 !important; }

.bg-secondary {
  background-color: #fe6565 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #fe3232 !important; }

.bg-success {
  background-color: #2d717c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1f4f57 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #f2c728 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #daae0d !important; }

.bg-danger {
  background-color: #fe6565 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #fe3232 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-accent {
  background-color: #a8b7c7 !important; }

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #899db3 !important; }

.bg-neutral {
  background-color: #eee !important; }

a.bg-neutral:hover, a.bg-neutral:focus,
button.bg-neutral:hover,
button.bg-neutral:focus {
  background-color: #d5d4d4 !important; }

.bg-tertiary {
  background-color: #0a132d !important; }

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #010103 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #006fb3 !important; }

.border-secondary {
  border-color: #fe6565 !important; }

.border-success {
  border-color: #2d717c !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #f2c728 !important; }

.border-danger {
  border-color: #fe6565 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-accent {
  border-color: #a8b7c7 !important; }

.border-neutral {
  border-color: #eee !important; }

.border-tertiary {
  border-color: #0a132d !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.333333333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(17, 17, 17, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(17, 17, 17, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(17, 17, 17, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100, .pseudo-background .pseudo-src, .profile-image {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #006fb3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004067 !important; }

.text-secondary {
  color: #fe6565 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #fe1919 !important; }

.text-success {
  color: #2d717c !important; }

a.text-success:hover, a.text-success:focus {
  color: #193e44 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #f2c728 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c29b0c !important; }

.text-danger {
  color: #fe6565 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #fe1919 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-accent {
  color: #a8b7c7 !important; }

a.text-accent:hover, a.text-accent:focus {
  color: #7990a9 !important; }

.text-neutral {
  color: #eee !important; }

a.text-neutral:hover, a.text-neutral:focus {
  color: #c8c8c8 !important; }

.text-tertiary {
  color: #0a132d !important; }

a.text-tertiary:hover, a.text-tertiary:focus {
  color: black !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .section-social {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #111; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html {
  font-size: 16px; }

.list-inline li {
  position: relative;
  display: inline-block;
  padding-right: 0.5rem; }
  .list-inline li:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "-"; }

.text-underline {
  text-decoration: underline !important;
  /* stylelint-disable-line */ }

.text-normal {
  text-decoration: none !important;
  /* stylelint-disable-line */
  text-transform: none !important;
  /* stylelint-disable-line */ }

.border-sm {
  border: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 576px) {
    .border-sm {
      border: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-sm-top {
  border-top: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 576px) {
    .border-sm-top {
      border-top: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-sm-right {
  border-right: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 576px) {
    .border-sm-right {
      border-right: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-sm-bottom {
  border-bottom: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 576px) {
    .border-sm-bottom {
      border-bottom: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-sm-left {
  border-left: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 576px) {
    .border-sm-left {
      border-left: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-md {
  border: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 768px) {
    .border-md {
      border: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-md-top {
  border-top: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 768px) {
    .border-md-top {
      border-top: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-md-right {
  border-right: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 768px) {
    .border-md-right {
      border-right: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-md-bottom {
  border-bottom: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 768px) {
    .border-md-bottom {
      border-bottom: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-md-left {
  border-left: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 768px) {
    .border-md-left {
      border-left: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-lg {
  border: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 992px) {
    .border-lg {
      border: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-lg-top {
  border-top: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 992px) {
    .border-lg-top {
      border-top: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-lg-right {
  border-right: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 992px) {
    .border-lg-right {
      border-right: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-lg-bottom {
  border-bottom: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 992px) {
    .border-lg-bottom {
      border-bottom: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-lg-left {
  border-left: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 992px) {
    .border-lg-left {
      border-left: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-xl {
  border: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 1200px) {
    .border-xl {
      border: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-xl-top {
  border-top: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 1200px) {
    .border-xl-top {
      border-top: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-xl-right {
  border-right: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 1200px) {
    .border-xl-right {
      border-right: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-xl-bottom {
  border-bottom: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 1200px) {
    .border-xl-bottom {
      border-bottom: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.border-xl-left {
  border-left: none !important;
  /* stylelint-disable-line */ }
  @media (min-width: 1200px) {
    .border-xl-left {
      border-left: 1px solid #dee2e6 !important;
      /* stylelint-disable-line */ } }

.bg-blue {
  background-color: #006fb3 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #004f80 !important; }

.text-blue {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

a.text-blue:focus {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

.bg-layer-blue {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-blue::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #006fb3; }

@media (min-width: 992px) {
  .col-separator-blue > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #006fb3; } }

.border-blue {
  border-color: #006fb3 !important;
  /* stylelint-disable-line */ }

.text-before-blue::before {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

.text-after-blue::before {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

.bg-before-blue::before {
  background-color: #006fb3 !important;
  /* stylelint-disable-line */ }

.bg-after-blue::before {
  background-color: #006fb3 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-blue {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-blue {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-blue {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-blue {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

.bg-indigo {
  background-color: #6610f2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }

.text-indigo {
  color: #6610f2 !important;
  /* stylelint-disable-line */ }

a.text-indigo:focus {
  color: #6610f2 !important;
  /* stylelint-disable-line */ }

.bg-layer-indigo {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-indigo::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #6610f2; }

@media (min-width: 992px) {
  .col-separator-indigo > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #6610f2; } }

.border-indigo {
  border-color: #6610f2 !important;
  /* stylelint-disable-line */ }

.text-before-indigo::before {
  color: #6610f2 !important;
  /* stylelint-disable-line */ }

.text-after-indigo::before {
  color: #6610f2 !important;
  /* stylelint-disable-line */ }

.bg-before-indigo::before {
  background-color: #6610f2 !important;
  /* stylelint-disable-line */ }

.bg-after-indigo::before {
  background-color: #6610f2 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-indigo {
    border: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-sm-indigo-top {
    border-top: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-sm-indigo-right {
    border-right: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-sm-indigo-bottom {
    border-bottom: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-sm-indigo-left {
    border-left: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-indigo {
    border: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-md-indigo-top {
    border-top: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-md-indigo-right {
    border-right: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-md-indigo-bottom {
    border-bottom: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-md-indigo-left {
    border-left: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-indigo {
    border: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-lg-indigo-top {
    border-top: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-lg-indigo-right {
    border-right: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-lg-indigo-bottom {
    border-bottom: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-lg-indigo-left {
    border-left: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-indigo {
    border: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-xl-indigo-top {
    border-top: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-xl-indigo-right {
    border-right: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-xl-indigo-bottom {
    border-bottom: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ }
  .border-xl-indigo-left {
    border-left: 1px solid #6610f2 !important;
    /* stylelint-disable-line */ } }

.bg-purple {
  background-color: #63c !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #5229a3 !important; }

.text-purple {
  color: #63c !important;
  /* stylelint-disable-line */ }

a.text-purple:focus {
  color: #63c !important;
  /* stylelint-disable-line */ }

.bg-layer-purple {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-purple::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #63c; }

@media (min-width: 992px) {
  .col-separator-purple > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #63c; } }

.border-purple {
  border-color: #63c !important;
  /* stylelint-disable-line */ }

.text-before-purple::before {
  color: #63c !important;
  /* stylelint-disable-line */ }

.text-after-purple::before {
  color: #63c !important;
  /* stylelint-disable-line */ }

.bg-before-purple::before {
  background-color: #63c !important;
  /* stylelint-disable-line */ }

.bg-after-purple::before {
  background-color: #63c !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-purple {
    border: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-sm-purple-top {
    border-top: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-sm-purple-right {
    border-right: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-sm-purple-bottom {
    border-bottom: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-sm-purple-left {
    border-left: 1px solid #63c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-purple {
    border: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-md-purple-top {
    border-top: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-md-purple-right {
    border-right: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-md-purple-bottom {
    border-bottom: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-md-purple-left {
    border-left: 1px solid #63c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-purple {
    border: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-lg-purple-top {
    border-top: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-lg-purple-right {
    border-right: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-lg-purple-bottom {
    border-bottom: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-lg-purple-left {
    border-left: 1px solid #63c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-purple {
    border: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-xl-purple-top {
    border-top: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-xl-purple-right {
    border-right: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-xl-purple-bottom {
    border-bottom: 1px solid #63c !important;
    /* stylelint-disable-line */ }
  .border-xl-purple-left {
    border-left: 1px solid #63c !important;
    /* stylelint-disable-line */ } }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.text-pink {
  color: #e83e8c !important;
  /* stylelint-disable-line */ }

a.text-pink:focus {
  color: #e83e8c !important;
  /* stylelint-disable-line */ }

.bg-layer-pink {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-pink::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #e83e8c; }

@media (min-width: 992px) {
  .col-separator-pink > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #e83e8c; } }

.border-pink {
  border-color: #e83e8c !important;
  /* stylelint-disable-line */ }

.text-before-pink::before {
  color: #e83e8c !important;
  /* stylelint-disable-line */ }

.text-after-pink::before {
  color: #e83e8c !important;
  /* stylelint-disable-line */ }

.bg-before-pink::before {
  background-color: #e83e8c !important;
  /* stylelint-disable-line */ }

.bg-after-pink::before {
  background-color: #e83e8c !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-pink {
    border: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-sm-pink-top {
    border-top: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-sm-pink-right {
    border-right: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-sm-pink-bottom {
    border-bottom: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-sm-pink-left {
    border-left: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-pink {
    border: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-md-pink-top {
    border-top: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-md-pink-right {
    border-right: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-md-pink-bottom {
    border-bottom: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-md-pink-left {
    border-left: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-pink {
    border: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-lg-pink-top {
    border-top: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-lg-pink-right {
    border-right: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-lg-pink-bottom {
    border-bottom: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-lg-pink-left {
    border-left: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-pink {
    border: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-xl-pink-top {
    border-top: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-xl-pink-right {
    border-right: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-xl-pink-bottom {
    border-bottom: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ }
  .border-xl-pink-left {
    border-left: 1px solid #e83e8c !important;
    /* stylelint-disable-line */ } }

.bg-red {
  background-color: #fe6565 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #fe3232 !important; }

.text-red {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

a.text-red:focus {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-layer-red {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-red::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #fe6565; }

@media (min-width: 992px) {
  .col-separator-red > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #fe6565; } }

.border-red {
  border-color: #fe6565 !important;
  /* stylelint-disable-line */ }

.text-before-red::before {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.text-after-red::before {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-before-red::before {
  background-color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-after-red::before {
  background-color: #fe6565 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-red {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-red-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-red-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-red-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-red-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-red {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-red-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-red-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-red-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-red-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-red {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-red-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-red-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-red-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-red-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-red {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-red-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-red-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-red-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-red-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

.bg-orange {
  background-color: #fd7e14 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important; }

.text-orange {
  color: #fd7e14 !important;
  /* stylelint-disable-line */ }

a.text-orange:focus {
  color: #fd7e14 !important;
  /* stylelint-disable-line */ }

.bg-layer-orange {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-orange::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #fd7e14; }

@media (min-width: 992px) {
  .col-separator-orange > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #fd7e14; } }

.border-orange {
  border-color: #fd7e14 !important;
  /* stylelint-disable-line */ }

.text-before-orange::before {
  color: #fd7e14 !important;
  /* stylelint-disable-line */ }

.text-after-orange::before {
  color: #fd7e14 !important;
  /* stylelint-disable-line */ }

.bg-before-orange::before {
  background-color: #fd7e14 !important;
  /* stylelint-disable-line */ }

.bg-after-orange::before {
  background-color: #fd7e14 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-orange {
    border: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-sm-orange-top {
    border-top: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-sm-orange-right {
    border-right: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-sm-orange-bottom {
    border-bottom: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-sm-orange-left {
    border-left: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-orange {
    border: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-md-orange-top {
    border-top: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-md-orange-right {
    border-right: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-md-orange-bottom {
    border-bottom: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-md-orange-left {
    border-left: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-orange {
    border: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-lg-orange-top {
    border-top: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-lg-orange-right {
    border-right: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-lg-orange-bottom {
    border-bottom: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-lg-orange-left {
    border-left: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-orange {
    border: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-xl-orange-top {
    border-top: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-xl-orange-right {
    border-right: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-xl-orange-bottom {
    border-bottom: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ }
  .border-xl-orange-left {
    border-left: 1px solid #fd7e14 !important;
    /* stylelint-disable-line */ } }

.bg-yellow {
  background-color: #f2c728 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #daae0d !important; }

.text-yellow {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

a.text-yellow:focus {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

.bg-layer-yellow {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-yellow::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #f2c728; }

@media (min-width: 992px) {
  .col-separator-yellow > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #f2c728; } }

.border-yellow {
  border-color: #f2c728 !important;
  /* stylelint-disable-line */ }

.text-before-yellow::before {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

.text-after-yellow::before {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

.bg-before-yellow::before {
  background-color: #f2c728 !important;
  /* stylelint-disable-line */ }

.bg-after-yellow::before {
  background-color: #f2c728 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-yellow {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-yellow-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-yellow-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-yellow-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-yellow-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-yellow {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-yellow-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-yellow-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-yellow-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-yellow-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-yellow {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-yellow-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-yellow-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-yellow-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-yellow-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-yellow {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-yellow-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-yellow-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-yellow-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-yellow-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

.bg-green {
  background-color: #2d717c !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #1f4f57 !important; }

.text-green {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

a.text-green:focus {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

.bg-layer-green {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-green::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #2d717c; }

@media (min-width: 992px) {
  .col-separator-green > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #2d717c; } }

.border-green {
  border-color: #2d717c !important;
  /* stylelint-disable-line */ }

.text-before-green::before {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

.text-after-green::before {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

.bg-before-green::before {
  background-color: #2d717c !important;
  /* stylelint-disable-line */ }

.bg-after-green::before {
  background-color: #2d717c !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-green {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-green-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-green-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-green-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-green-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-green {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-green-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-green-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-green-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-green-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-green {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-green-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-green-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-green-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-green-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-green {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-green-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-green-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-green-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-green-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

.bg-teal {
  background-color: #20c997 !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important; }

.text-teal {
  color: #20c997 !important;
  /* stylelint-disable-line */ }

a.text-teal:focus {
  color: #20c997 !important;
  /* stylelint-disable-line */ }

.bg-layer-teal {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-teal::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #20c997; }

@media (min-width: 992px) {
  .col-separator-teal > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #20c997; } }

.border-teal {
  border-color: #20c997 !important;
  /* stylelint-disable-line */ }

.text-before-teal::before {
  color: #20c997 !important;
  /* stylelint-disable-line */ }

.text-after-teal::before {
  color: #20c997 !important;
  /* stylelint-disable-line */ }

.bg-before-teal::before {
  background-color: #20c997 !important;
  /* stylelint-disable-line */ }

.bg-after-teal::before {
  background-color: #20c997 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-teal {
    border: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-sm-teal-top {
    border-top: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-sm-teal-right {
    border-right: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-sm-teal-bottom {
    border-bottom: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-sm-teal-left {
    border-left: 1px solid #20c997 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-teal {
    border: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-md-teal-top {
    border-top: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-md-teal-right {
    border-right: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-md-teal-bottom {
    border-bottom: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-md-teal-left {
    border-left: 1px solid #20c997 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-teal {
    border: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-lg-teal-top {
    border-top: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-lg-teal-right {
    border-right: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-lg-teal-bottom {
    border-bottom: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-lg-teal-left {
    border-left: 1px solid #20c997 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-teal {
    border: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-xl-teal-top {
    border-top: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-xl-teal-right {
    border-right: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-xl-teal-bottom {
    border-bottom: 1px solid #20c997 !important;
    /* stylelint-disable-line */ }
  .border-xl-teal-left {
    border-left: 1px solid #20c997 !important;
    /* stylelint-disable-line */ } }

.bg-cyan {
  background-color: #17a2b8 !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important; }

.text-cyan {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

a.text-cyan:focus {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.bg-layer-cyan {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-cyan::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #17a2b8; }

@media (min-width: 992px) {
  .col-separator-cyan > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #17a2b8; } }

.border-cyan {
  border-color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.text-before-cyan::before {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.text-after-cyan::before {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.bg-before-cyan::before {
  background-color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.bg-after-cyan::before {
  background-color: #17a2b8 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-cyan {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-cyan-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-cyan-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-cyan-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-cyan-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-cyan {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-cyan-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-cyan-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-cyan-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-cyan-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-cyan {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-cyan-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-cyan-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-cyan-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-cyan-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-cyan {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-cyan-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-cyan-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-cyan-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-cyan-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e5e5 !important; }

.text-white {
  color: #fff !important;
  /* stylelint-disable-line */ }

a.text-white:focus {
  color: #fff !important;
  /* stylelint-disable-line */ }

.bg-layer-white {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-white::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #fff; }

@media (min-width: 992px) {
  .col-separator-white > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #fff; } }

.border-white {
  border-color: #fff !important;
  /* stylelint-disable-line */ }

.text-before-white::before {
  color: #fff !important;
  /* stylelint-disable-line */ }

.text-after-white::before {
  color: #fff !important;
  /* stylelint-disable-line */ }

.bg-before-white::before {
  background-color: #fff !important;
  /* stylelint-disable-line */ }

.bg-after-white::before {
  background-color: #fff !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-white {
    border: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-sm-white-top {
    border-top: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-sm-white-right {
    border-right: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-sm-white-bottom {
    border-bottom: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-sm-white-left {
    border-left: 1px solid #fff !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-white {
    border: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-md-white-top {
    border-top: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-md-white-right {
    border-right: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-md-white-bottom {
    border-bottom: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-md-white-left {
    border-left: 1px solid #fff !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-white {
    border: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-lg-white-top {
    border-top: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-lg-white-right {
    border-right: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-lg-white-bottom {
    border-bottom: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-lg-white-left {
    border-left: 1px solid #fff !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-white {
    border: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-xl-white-top {
    border-top: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-xl-white-right {
    border-right: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-xl-white-bottom {
    border-bottom: 1px solid #fff !important;
    /* stylelint-disable-line */ }
  .border-xl-white-left {
    border-left: 1px solid #fff !important;
    /* stylelint-disable-line */ } }

.bg-gray {
  background-color: #a8b7c7 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #899db3 !important; }

.text-gray {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

a.text-gray:focus {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-layer-gray {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #a8b7c7; }

@media (min-width: 992px) {
  .col-separator-gray > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #a8b7c7; } }

.border-gray {
  border-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.text-before-gray::before {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.text-after-gray::before {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-before-gray::before {
  background-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-after-gray::before {
  background-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-gray {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-gray {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-gray {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-gray {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

.bg-gray-dark {
  background-color: #343a40 !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1d2124 !important; }

.text-gray-dark {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

a.text-gray-dark:focus {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-dark {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-dark::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #343a40; }

@media (min-width: 992px) {
  .col-separator-gray-dark > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #343a40; } }

.border-gray-dark {
  border-color: #343a40 !important;
  /* stylelint-disable-line */ }

.text-before-gray-dark::before {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.text-after-gray-dark::before {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-before-gray-dark::before {
  background-color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-after-gray-dark::before {
  background-color: #343a40 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-gray-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-gray-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-gray-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-gray-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-gray-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-gray-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-gray-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-gray-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

.bg-black {
  background-color: #111 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.text-black {
  color: #111 !important;
  /* stylelint-disable-line */ }

a.text-black:focus {
  color: #111 !important;
  /* stylelint-disable-line */ }

.bg-layer-black {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-black::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #111; }

@media (min-width: 992px) {
  .col-separator-black > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #111; } }

.border-black {
  border-color: #111 !important;
  /* stylelint-disable-line */ }

.text-before-black::before {
  color: #111 !important;
  /* stylelint-disable-line */ }

.text-after-black::before {
  color: #111 !important;
  /* stylelint-disable-line */ }

.bg-before-black::before {
  background-color: #111 !important;
  /* stylelint-disable-line */ }

.bg-after-black::before {
  background-color: #111 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-black {
    border: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-sm-black-top {
    border-top: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-sm-black-right {
    border-right: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-sm-black-bottom {
    border-bottom: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-sm-black-left {
    border-left: 1px solid #111 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-black {
    border: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-md-black-top {
    border-top: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-md-black-right {
    border-right: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-md-black-bottom {
    border-bottom: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-md-black-left {
    border-left: 1px solid #111 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-black {
    border: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-lg-black-top {
    border-top: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-lg-black-right {
    border-right: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-lg-black-bottom {
    border-bottom: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-lg-black-left {
    border-left: 1px solid #111 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-black {
    border: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-xl-black-top {
    border-top: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-xl-black-right {
    border-right: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-xl-black-bottom {
    border-bottom: 1px solid #111 !important;
    /* stylelint-disable-line */ }
  .border-xl-black-left {
    border-left: 1px solid #111 !important;
    /* stylelint-disable-line */ } }

.bg-blue-dark {
  background-color: #0a132d !important; }

a.bg-blue-dark:hover, a.bg-blue-dark:focus,
button.bg-blue-dark:hover,
button.bg-blue-dark:focus {
  background-color: #010103 !important; }

.text-blue-dark {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

a.text-blue-dark:focus {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

.bg-layer-blue-dark {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-blue-dark::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #0a132d; }

@media (min-width: 992px) {
  .col-separator-blue-dark > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #0a132d; } }

.border-blue-dark {
  border-color: #0a132d !important;
  /* stylelint-disable-line */ }

.text-before-blue-dark::before {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

.text-after-blue-dark::before {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

.bg-before-blue-dark::before {
  background-color: #0a132d !important;
  /* stylelint-disable-line */ }

.bg-after-blue-dark::before {
  background-color: #0a132d !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-blue-dark {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-dark-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-dark-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-dark-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-dark-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-blue-dark {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-blue-dark-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-blue-dark-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-blue-dark-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-blue-dark-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-blue-dark {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-dark-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-dark-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-dark-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-dark-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-blue-dark {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-dark-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-dark-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-dark-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-dark-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

.bg-blue-light {
  background-color: #a8b7c7 !important; }

a.bg-blue-light:hover, a.bg-blue-light:focus,
button.bg-blue-light:hover,
button.bg-blue-light:focus {
  background-color: #899db3 !important; }

.text-blue-light {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

a.text-blue-light:focus {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-layer-blue-light {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-blue-light::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #a8b7c7; }

@media (min-width: 992px) {
  .col-separator-blue-light > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #a8b7c7; } }

.border-blue-light {
  border-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.text-before-blue-light::before {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.text-after-blue-light::before {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-before-blue-light::before {
  background-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-after-blue-light::before {
  background-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-blue-light {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-light-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-light-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-light-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-blue-light-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-blue-light {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-light-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-light-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-light-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-blue-light-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-blue-light {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-light-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-light-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-light-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-blue-light-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-blue-light {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-light-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-light-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-light-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-blue-light-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

.bg-gray-100 {
  background-color: #f8f9fa !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important; }

.text-gray-100 {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

a.text-gray-100:focus {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-100 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-100::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #f8f9fa; }

@media (min-width: 992px) {
  .col-separator-gray-100 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #f8f9fa; } }

.border-gray-100 {
  border-color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.text-gray-before-100::before {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.text-gray-after-100::before {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.bg-gray-before-100::before {
  background-color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.bg-gray-after-100::before {
  background-color: #f8f9fa !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-100 {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-100-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-100-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-100-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-100-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-100 {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-md-100-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-md-100-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-md-100-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-md-100-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-100 {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-100-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-100-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-100-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-100-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-100 {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-100-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-100-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-100-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-100-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

.bg-gray-200 {
  background-color: #e9ecef !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important; }

.text-gray-200 {
  color: #e9ecef !important;
  /* stylelint-disable-line */ }

a.text-gray-200:focus {
  color: #e9ecef !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-200 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-200::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #e9ecef; }

@media (min-width: 992px) {
  .col-separator-gray-200 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #e9ecef; } }

.border-gray-200 {
  border-color: #e9ecef !important;
  /* stylelint-disable-line */ }

.text-gray-before-200::before {
  color: #e9ecef !important;
  /* stylelint-disable-line */ }

.text-gray-after-200::before {
  color: #e9ecef !important;
  /* stylelint-disable-line */ }

.bg-gray-before-200::before {
  background-color: #e9ecef !important;
  /* stylelint-disable-line */ }

.bg-gray-after-200::before {
  background-color: #e9ecef !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-200 {
    border: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-200-top {
    border-top: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-200-right {
    border-right: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-200-bottom {
    border-bottom: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-200-left {
    border-left: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-200 {
    border: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-md-200-top {
    border-top: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-md-200-right {
    border-right: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-md-200-bottom {
    border-bottom: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-md-200-left {
    border-left: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-200 {
    border: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-200-top {
    border-top: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-200-right {
    border-right: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-200-bottom {
    border-bottom: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-200-left {
    border-left: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-200 {
    border: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-200-top {
    border-top: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-200-right {
    border-right: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-200-bottom {
    border-bottom: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-200-left {
    border-left: 1px solid #e9ecef !important;
    /* stylelint-disable-line */ } }

.bg-gray-300 {
  background-color: #dee2e6 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c1c9d0 !important; }

.text-gray-300 {
  color: #dee2e6 !important;
  /* stylelint-disable-line */ }

a.text-gray-300:focus {
  color: #dee2e6 !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-300 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-300::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #dee2e6; }

@media (min-width: 992px) {
  .col-separator-gray-300 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #dee2e6; } }

.border-gray-300 {
  border-color: #dee2e6 !important;
  /* stylelint-disable-line */ }

.text-gray-before-300::before {
  color: #dee2e6 !important;
  /* stylelint-disable-line */ }

.text-gray-after-300::before {
  color: #dee2e6 !important;
  /* stylelint-disable-line */ }

.bg-gray-before-300::before {
  background-color: #dee2e6 !important;
  /* stylelint-disable-line */ }

.bg-gray-after-300::before {
  background-color: #dee2e6 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-300 {
    border: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-300-top {
    border-top: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-300-right {
    border-right: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-300-bottom {
    border-bottom: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-300-left {
    border-left: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-300 {
    border: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-300-top {
    border-top: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-300-right {
    border-right: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-300-bottom {
    border-bottom: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-300-left {
    border-left: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-300 {
    border: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-300-top {
    border-top: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-300-right {
    border-right: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-300-bottom {
    border-bottom: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-300-left {
    border-left: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-300 {
    border: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-300-top {
    border-top: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-300-right {
    border-right: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-300-bottom {
    border-bottom: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-300-left {
    border-left: 1px solid #dee2e6 !important;
    /* stylelint-disable-line */ } }

.bg-gray-400 {
  background-color: #ced4da !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important; }

.text-gray-400 {
  color: #ced4da !important;
  /* stylelint-disable-line */ }

a.text-gray-400:focus {
  color: #ced4da !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-400 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-400::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #ced4da; }

@media (min-width: 992px) {
  .col-separator-gray-400 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #ced4da; } }

.border-gray-400 {
  border-color: #ced4da !important;
  /* stylelint-disable-line */ }

.text-gray-before-400::before {
  color: #ced4da !important;
  /* stylelint-disable-line */ }

.text-gray-after-400::before {
  color: #ced4da !important;
  /* stylelint-disable-line */ }

.bg-gray-before-400::before {
  background-color: #ced4da !important;
  /* stylelint-disable-line */ }

.bg-gray-after-400::before {
  background-color: #ced4da !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-400 {
    border: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-400-top {
    border-top: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-400-right {
    border-right: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-400-bottom {
    border-bottom: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-400-left {
    border-left: 1px solid #ced4da !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-400 {
    border: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-md-400-top {
    border-top: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-md-400-right {
    border-right: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-md-400-bottom {
    border-bottom: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-md-400-left {
    border-left: 1px solid #ced4da !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-400 {
    border: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-400-top {
    border-top: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-400-right {
    border-right: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-400-bottom {
    border-bottom: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-400-left {
    border-left: 1px solid #ced4da !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-400 {
    border: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-400-top {
    border-top: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-400-right {
    border-right: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-400-bottom {
    border-bottom: 1px solid #ced4da !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-400-left {
    border-left: 1px solid #ced4da !important;
    /* stylelint-disable-line */ } }

.bg-gray-500 {
  background-color: #adb5bd !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919ca6 !important; }

.text-gray-500 {
  color: #adb5bd !important;
  /* stylelint-disable-line */ }

a.text-gray-500:focus {
  color: #adb5bd !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-500 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-500::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #adb5bd; }

@media (min-width: 992px) {
  .col-separator-gray-500 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #adb5bd; } }

.border-gray-500 {
  border-color: #adb5bd !important;
  /* stylelint-disable-line */ }

.text-gray-before-500::before {
  color: #adb5bd !important;
  /* stylelint-disable-line */ }

.text-gray-after-500::before {
  color: #adb5bd !important;
  /* stylelint-disable-line */ }

.bg-gray-before-500::before {
  background-color: #adb5bd !important;
  /* stylelint-disable-line */ }

.bg-gray-after-500::before {
  background-color: #adb5bd !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-500 {
    border: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-500-top {
    border-top: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-500-right {
    border-right: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-500-bottom {
    border-bottom: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-500-left {
    border-left: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-500 {
    border: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-md-500-top {
    border-top: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-md-500-right {
    border-right: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-md-500-bottom {
    border-bottom: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-md-500-left {
    border-left: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-500 {
    border: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-500-top {
    border-top: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-500-right {
    border-right: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-500-bottom {
    border-bottom: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-500-left {
    border-left: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-500 {
    border: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-500-top {
    border-top: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-500-right {
    border-right: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-500-bottom {
    border-bottom: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-500-left {
    border-left: 1px solid #adb5bd !important;
    /* stylelint-disable-line */ } }

.bg-gray-600 {
  background-color: #6c757d !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #545b62 !important; }

.text-gray-600 {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

a.text-gray-600:focus {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-600 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-600::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #6c757d; }

@media (min-width: 992px) {
  .col-separator-gray-600 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #6c757d; } }

.border-gray-600 {
  border-color: #6c757d !important;
  /* stylelint-disable-line */ }

.text-gray-before-600::before {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.text-gray-after-600::before {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.bg-gray-before-600::before {
  background-color: #6c757d !important;
  /* stylelint-disable-line */ }

.bg-gray-after-600::before {
  background-color: #6c757d !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-600 {
    border: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-600-top {
    border-top: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-600-right {
    border-right: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-600-bottom {
    border-bottom: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-600-left {
    border-left: 1px solid #6c757d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-600 {
    border: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-md-600-top {
    border-top: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-md-600-right {
    border-right: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-md-600-bottom {
    border-bottom: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-md-600-left {
    border-left: 1px solid #6c757d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-600 {
    border: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-600-top {
    border-top: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-600-right {
    border-right: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-600-bottom {
    border-bottom: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-600-left {
    border-left: 1px solid #6c757d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-600 {
    border: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-600-top {
    border-top: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-600-right {
    border-right: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-600-bottom {
    border-bottom: 1px solid #6c757d !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-600-left {
    border-left: 1px solid #6c757d !important;
    /* stylelint-disable-line */ } }

.bg-gray-700 {
  background-color: #495057 !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #32373b !important; }

.text-gray-700 {
  color: #495057 !important;
  /* stylelint-disable-line */ }

a.text-gray-700:focus {
  color: #495057 !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-700 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-700::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #495057; }

@media (min-width: 992px) {
  .col-separator-gray-700 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #495057; } }

.border-gray-700 {
  border-color: #495057 !important;
  /* stylelint-disable-line */ }

.text-gray-before-700::before {
  color: #495057 !important;
  /* stylelint-disable-line */ }

.text-gray-after-700::before {
  color: #495057 !important;
  /* stylelint-disable-line */ }

.bg-gray-before-700::before {
  background-color: #495057 !important;
  /* stylelint-disable-line */ }

.bg-gray-after-700::before {
  background-color: #495057 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-700 {
    border: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-700-top {
    border-top: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-700-right {
    border-right: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-700-bottom {
    border-bottom: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-700-left {
    border-left: 1px solid #495057 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-700 {
    border: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-700-top {
    border-top: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-700-right {
    border-right: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-700-bottom {
    border-bottom: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-700-left {
    border-left: 1px solid #495057 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-700 {
    border: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-700-top {
    border-top: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-700-right {
    border-right: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-700-bottom {
    border-bottom: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-700-left {
    border-left: 1px solid #495057 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-700 {
    border: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-700-top {
    border-top: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-700-right {
    border-right: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-700-bottom {
    border-bottom: 1px solid #495057 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-700-left {
    border-left: 1px solid #495057 !important;
    /* stylelint-disable-line */ } }

.bg-gray-800 {
  background-color: #343a40 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1d2124 !important; }

.text-gray-800 {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

a.text-gray-800:focus {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-800 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-800::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #343a40; }

@media (min-width: 992px) {
  .col-separator-gray-800 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #343a40; } }

.border-gray-800 {
  border-color: #343a40 !important;
  /* stylelint-disable-line */ }

.text-gray-before-800::before {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.text-gray-after-800::before {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-gray-before-800::before {
  background-color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-gray-after-800::before {
  background-color: #343a40 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-800 {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-800-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-800-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-800-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-800-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-800 {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-800-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-800-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-800-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-800-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-800 {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-800-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-800-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-800-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-800-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-800 {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-800-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-800-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-800-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-800-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

.bg-gray-900 {
  background-color: #212529 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important; }

.text-gray-900 {
  color: #212529 !important;
  /* stylelint-disable-line */ }

a.text-gray-900:focus {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-900 {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-900::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #212529; }

@media (min-width: 992px) {
  .col-separator-gray-900 > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #212529; } }

.border-gray-900 {
  border-color: #212529 !important;
  /* stylelint-disable-line */ }

.text-gray-before-900::before {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.text-gray-after-900::before {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.bg-gray-before-900::before {
  background-color: #212529 !important;
  /* stylelint-disable-line */ }

.bg-gray-after-900::before {
  background-color: #212529 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-900 {
    border: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-900-top {
    border-top: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-900-right {
    border-right: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-900-bottom {
    border-bottom: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-900-left {
    border-left: 1px solid #212529 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-900 {
    border: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-900-top {
    border-top: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-900-right {
    border-right: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-900-bottom {
    border-bottom: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-md-900-left {
    border-left: 1px solid #212529 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-900 {
    border: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-900-top {
    border-top: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-900-right {
    border-right: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-900-bottom {
    border-bottom: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-900-left {
    border-left: 1px solid #212529 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-900 {
    border: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-900-top {
    border-top: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-900-right {
    border-right: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-900-bottom {
    border-bottom: 1px solid #212529 !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-900-left {
    border-left: 1px solid #212529 !important;
    /* stylelint-disable-line */ } }

.bg-gray-a {
  background-color: #4a4a4a !important; }

a.bg-gray-a:hover, a.bg-gray-a:focus,
button.bg-gray-a:hover,
button.bg-gray-a:focus {
  background-color: #313030 !important; }

.text-gray-a {
  color: #4a4a4a !important;
  /* stylelint-disable-line */ }

a.text-gray-a:focus {
  color: #4a4a4a !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-a {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #4a4a4a; }

@media (min-width: 992px) {
  .col-separator-gray-a > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #4a4a4a; } }

.border-gray-a {
  border-color: #4a4a4a !important;
  /* stylelint-disable-line */ }

.text-gray-before-a::before {
  color: #4a4a4a !important;
  /* stylelint-disable-line */ }

.text-gray-after-a::before {
  color: #4a4a4a !important;
  /* stylelint-disable-line */ }

.bg-gray-before-a::before {
  background-color: #4a4a4a !important;
  /* stylelint-disable-line */ }

.bg-gray-after-a::before {
  background-color: #4a4a4a !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-a {
    border: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-a-top {
    border-top: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-a-right {
    border-right: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-a-bottom {
    border-bottom: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-a-left {
    border-left: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-a {
    border: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-a-top {
    border-top: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-a-right {
    border-right: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-a-bottom {
    border-bottom: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-a-left {
    border-left: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-a {
    border: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-a-top {
    border-top: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-a-right {
    border-right: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-a-bottom {
    border-bottom: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-a-left {
    border-left: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-a {
    border: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-a-top {
    border-top: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-a-right {
    border-right: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-a-bottom {
    border-bottom: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-a-left {
    border-left: 1px solid #4a4a4a !important;
    /* stylelint-disable-line */ } }

.bg-gray-b {
  background-color: #8a8a8a !important; }

a.bg-gray-b:hover, a.bg-gray-b:focus,
button.bg-gray-b:hover,
button.bg-gray-b:focus {
  background-color: #717070 !important; }

.text-gray-b {
  color: #8a8a8a !important;
  /* stylelint-disable-line */ }

a.text-gray-b:focus {
  color: #8a8a8a !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-b {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-b::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #8a8a8a; }

@media (min-width: 992px) {
  .col-separator-gray-b > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #8a8a8a; } }

.border-gray-b {
  border-color: #8a8a8a !important;
  /* stylelint-disable-line */ }

.text-gray-before-b::before {
  color: #8a8a8a !important;
  /* stylelint-disable-line */ }

.text-gray-after-b::before {
  color: #8a8a8a !important;
  /* stylelint-disable-line */ }

.bg-gray-before-b::before {
  background-color: #8a8a8a !important;
  /* stylelint-disable-line */ }

.bg-gray-after-b::before {
  background-color: #8a8a8a !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-b {
    border: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-b-top {
    border-top: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-b-right {
    border-right: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-b-bottom {
    border-bottom: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-b-left {
    border-left: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-b {
    border: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-b-top {
    border-top: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-b-right {
    border-right: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-b-bottom {
    border-bottom: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-md-b-left {
    border-left: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-b {
    border: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-b-top {
    border-top: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-b-right {
    border-right: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-b-bottom {
    border-bottom: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-b-left {
    border-left: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-b {
    border: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-b-top {
    border-top: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-b-right {
    border-right: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-b-bottom {
    border-bottom: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-b-left {
    border-left: 1px solid #8a8a8a !important;
    /* stylelint-disable-line */ } }

.bg-gray-c {
  background-color: #eee !important; }

a.bg-gray-c:hover, a.bg-gray-c:focus,
button.bg-gray-c:hover,
button.bg-gray-c:focus {
  background-color: #d5d4d4 !important; }

.text-gray-c {
  color: #eee !important;
  /* stylelint-disable-line */ }

a.text-gray-c:focus {
  color: #eee !important;
  /* stylelint-disable-line */ }

.bg-layer-gray-c {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-gray-c::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #eee; }

@media (min-width: 992px) {
  .col-separator-gray-c > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #eee; } }

.border-gray-c {
  border-color: #eee !important;
  /* stylelint-disable-line */ }

.text-gray-before-c::before {
  color: #eee !important;
  /* stylelint-disable-line */ }

.text-gray-after-c::before {
  color: #eee !important;
  /* stylelint-disable-line */ }

.bg-gray-before-c::before {
  background-color: #eee !important;
  /* stylelint-disable-line */ }

.bg-gray-after-c::before {
  background-color: #eee !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-gray-sm-c {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-c-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-c-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-c-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-sm-c-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-gray-md-c {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-md-c-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-md-c-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-md-c-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-md-c-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-gray-lg-c {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-c-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-c-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-c-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-lg-c-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-gray-xl-c {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-c-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-c-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-c-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-gray-xl-c-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

a.text-primary:focus {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

.bg-layer-primary {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-primary::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #006fb3; }

@media (min-width: 992px) {
  .col-separator-primary > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #006fb3; } }

.text-before-primary::before {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

.text-after-primary::before {
  color: #006fb3 !important;
  /* stylelint-disable-line */ }

.bg-before-primary::before {
  background-color: #006fb3 !important;
  /* stylelint-disable-line */ }

.bg-after-primary::before {
  background-color: #006fb3 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-primary {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-primary-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-primary-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-primary-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-sm-primary-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-primary {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-primary-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-primary-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-primary-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-md-primary-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-primary {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-primary-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-primary-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-primary-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-lg-primary-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-primary {
    border: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-primary-top {
    border-top: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-primary-right {
    border-right: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-primary-bottom {
    border-bottom: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ }
  .border-xl-primary-left {
    border-left: 1px solid #006fb3 !important;
    /* stylelint-disable-line */ } }

a.text-secondary:focus {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-layer-secondary {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-secondary::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #fe6565; }

@media (min-width: 992px) {
  .col-separator-secondary > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #fe6565; } }

.text-before-secondary::before {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.text-after-secondary::before {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-before-secondary::before {
  background-color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-after-secondary::before {
  background-color: #fe6565 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-secondary {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-secondary-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-secondary-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-secondary-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-secondary-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-secondary {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-secondary-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-secondary-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-secondary-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-secondary-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-secondary {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-secondary-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-secondary-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-secondary-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-secondary-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-secondary {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-secondary-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-secondary-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-secondary-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-secondary-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

a.text-success:focus {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

.bg-layer-success {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-success::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #2d717c; }

@media (min-width: 992px) {
  .col-separator-success > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #2d717c; } }

.text-before-success::before {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

.text-after-success::before {
  color: #2d717c !important;
  /* stylelint-disable-line */ }

.bg-before-success::before {
  background-color: #2d717c !important;
  /* stylelint-disable-line */ }

.bg-after-success::before {
  background-color: #2d717c !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-success {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-success-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-success-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-success-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-sm-success-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-success {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-success-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-success-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-success-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-md-success-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-success {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-success-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-success-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-success-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-lg-success-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-success {
    border: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-success-top {
    border-top: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-success-right {
    border-right: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-success-bottom {
    border-bottom: 1px solid #2d717c !important;
    /* stylelint-disable-line */ }
  .border-xl-success-left {
    border-left: 1px solid #2d717c !important;
    /* stylelint-disable-line */ } }

a.text-info:focus {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.bg-layer-info {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-info::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #17a2b8; }

@media (min-width: 992px) {
  .col-separator-info > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #17a2b8; } }

.text-before-info::before {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.text-after-info::before {
  color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.bg-before-info::before {
  background-color: #17a2b8 !important;
  /* stylelint-disable-line */ }

.bg-after-info::before {
  background-color: #17a2b8 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-info {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-info-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-info-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-info-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-sm-info-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-info {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-info-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-info-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-info-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-md-info-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-info {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-info-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-info-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-info-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-lg-info-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-info {
    border: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-info-top {
    border-top: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-info-right {
    border-right: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-info-bottom {
    border-bottom: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ }
  .border-xl-info-left {
    border-left: 1px solid #17a2b8 !important;
    /* stylelint-disable-line */ } }

a.text-warning:focus {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

.bg-layer-warning {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-warning::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #f2c728; }

@media (min-width: 992px) {
  .col-separator-warning > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #f2c728; } }

.text-before-warning::before {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

.text-after-warning::before {
  color: #f2c728 !important;
  /* stylelint-disable-line */ }

.bg-before-warning::before {
  background-color: #f2c728 !important;
  /* stylelint-disable-line */ }

.bg-after-warning::before {
  background-color: #f2c728 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-warning {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-warning-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-warning-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-warning-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-sm-warning-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-warning {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-warning-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-warning-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-warning-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-md-warning-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-warning {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-warning-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-warning-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-warning-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-lg-warning-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-warning {
    border: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-warning-top {
    border-top: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-warning-right {
    border-right: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-warning-bottom {
    border-bottom: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ }
  .border-xl-warning-left {
    border-left: 1px solid #f2c728 !important;
    /* stylelint-disable-line */ } }

a.text-danger:focus {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-layer-danger {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-danger::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #fe6565; }

@media (min-width: 992px) {
  .col-separator-danger > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #fe6565; } }

.text-before-danger::before {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.text-after-danger::before {
  color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-before-danger::before {
  background-color: #fe6565 !important;
  /* stylelint-disable-line */ }

.bg-after-danger::before {
  background-color: #fe6565 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-danger {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-danger-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-danger-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-danger-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-sm-danger-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-danger {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-danger-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-danger-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-danger-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-md-danger-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-danger {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-danger-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-danger-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-danger-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-lg-danger-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-danger {
    border: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-danger-top {
    border-top: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-danger-right {
    border-right: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-danger-bottom {
    border-bottom: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ }
  .border-xl-danger-left {
    border-left: 1px solid #fe6565 !important;
    /* stylelint-disable-line */ } }

a.text-light:focus {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.bg-layer-light {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-light::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #f8f9fa; }

@media (min-width: 992px) {
  .col-separator-light > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #f8f9fa; } }

.text-before-light::before {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.text-after-light::before {
  color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.bg-before-light::before {
  background-color: #f8f9fa !important;
  /* stylelint-disable-line */ }

.bg-after-light::before {
  background-color: #f8f9fa !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-light {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-sm-light-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-sm-light-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-sm-light-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-sm-light-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-light {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-md-light-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-md-light-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-md-light-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-md-light-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-light {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-lg-light-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-lg-light-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-lg-light-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-lg-light-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-light {
    border: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-xl-light-top {
    border-top: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-xl-light-right {
    border-right: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-xl-light-bottom {
    border-bottom: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ }
  .border-xl-light-left {
    border-left: 1px solid #f8f9fa !important;
    /* stylelint-disable-line */ } }

a.text-dark:focus {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-layer-dark {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-dark::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #343a40; }

@media (min-width: 992px) {
  .col-separator-dark > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #343a40; } }

.text-before-dark::before {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.text-after-dark::before {
  color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-before-dark::before {
  background-color: #343a40 !important;
  /* stylelint-disable-line */ }

.bg-after-dark::before {
  background-color: #343a40 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-sm-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-md-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-lg-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-dark {
    border: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-dark-top {
    border-top: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-dark-right {
    border-right: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-dark-bottom {
    border-bottom: 1px solid #343a40 !important;
    /* stylelint-disable-line */ }
  .border-xl-dark-left {
    border-left: 1px solid #343a40 !important;
    /* stylelint-disable-line */ } }

a.text-accent:focus {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-layer-accent {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-accent::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #a8b7c7; }

@media (min-width: 992px) {
  .col-separator-accent > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #a8b7c7; } }

.text-before-accent::before {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.text-after-accent::before {
  color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-before-accent::before {
  background-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

.bg-after-accent::before {
  background-color: #a8b7c7 !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-accent {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-accent-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-accent-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-accent-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-sm-accent-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-accent {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-accent-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-accent-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-accent-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-md-accent-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-accent {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-accent-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-accent-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-accent-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-lg-accent-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-accent {
    border: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-accent-top {
    border-top: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-accent-right {
    border-right: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-accent-bottom {
    border-bottom: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ }
  .border-xl-accent-left {
    border-left: 1px solid #a8b7c7 !important;
    /* stylelint-disable-line */ } }

a.text-neutral:focus {
  color: #eee !important;
  /* stylelint-disable-line */ }

.bg-layer-neutral {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-neutral::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #eee; }

@media (min-width: 992px) {
  .col-separator-neutral > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #eee; } }

.text-before-neutral::before {
  color: #eee !important;
  /* stylelint-disable-line */ }

.text-after-neutral::before {
  color: #eee !important;
  /* stylelint-disable-line */ }

.bg-before-neutral::before {
  background-color: #eee !important;
  /* stylelint-disable-line */ }

.bg-after-neutral::before {
  background-color: #eee !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-neutral {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-sm-neutral-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-sm-neutral-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-sm-neutral-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-sm-neutral-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-neutral {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-md-neutral-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-md-neutral-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-md-neutral-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-md-neutral-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-neutral {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-lg-neutral-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-lg-neutral-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-lg-neutral-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-lg-neutral-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-neutral {
    border: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-xl-neutral-top {
    border-top: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-xl-neutral-right {
    border-right: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-xl-neutral-bottom {
    border-bottom: 1px solid #eee !important;
    /* stylelint-disable-line */ }
  .border-xl-neutral-left {
    border-left: 1px solid #eee !important;
    /* stylelint-disable-line */ } }

a.text-tertiary:focus {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

.bg-layer-tertiary {
  position: relative;
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .bg-layer-tertiary::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
    background-color: #0a132d; }

@media (min-width: 992px) {
  .col-separator-tertiary > [class*="col-"]:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #0a132d; } }

.text-before-tertiary::before {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

.text-after-tertiary::before {
  color: #0a132d !important;
  /* stylelint-disable-line */ }

.bg-before-tertiary::before {
  background-color: #0a132d !important;
  /* stylelint-disable-line */ }

.bg-after-tertiary::before {
  background-color: #0a132d !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .border-sm-tertiary {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-tertiary-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-tertiary-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-tertiary-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-sm-tertiary-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .border-md-tertiary {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-tertiary-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-tertiary-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-tertiary-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-md-tertiary-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .border-lg-tertiary {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-tertiary-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-tertiary-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-tertiary-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-lg-tertiary-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .border-xl-tertiary {
    border: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-tertiary-top {
    border-top: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-tertiary-right {
    border-right: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-tertiary-bottom {
    border-bottom: 1px solid #0a132d !important;
    /* stylelint-disable-line */ }
  .border-xl-tertiary-left {
    border-left: 1px solid #0a132d !important;
    /* stylelint-disable-line */ } }

.close {
  font-style: normal; }

.opacity {
  opacity: 0.6; }

.container, .section-social {
  max-width: 100% !important;
  /* stylelint-disable-line */ }
  @media (min-width: 992px) {
    .container, .section-social {
      max-width: 960px !important;
      /* stylelint-disable-line */ } }
  @media (min-width: 1200px) {
    .container, .section-social {
      max-width: 1140px !important;
      /* stylelint-disable-line */ } }

input[type="text"], select, button {
  height: 48px !important;
  border-color: #000;
  border-radius: 3px; }

.btn-append-right {
  border-radius: 3px 0 0 3px !important; }

select.form-control-select {
  background: url("../../img/arrow-select.svg") no-repeat 95% 50% #fff !important;
  border: 1px solid #ccc;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.gob-primary {
  background-color: #0f69b4 !important; }

.gob-secondary {
  background-color: #e22c2c !important; }

.gob-dark {
  background-color: #001c41 !important; }

body {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.35rem; }
  @media (min-width: 768px) {
    body {
      font-size: 1rem;
      line-height: 1.375rem; } }

h1, .h1 {
  font-family: "Roboto Slab", serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.88rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

h2, .h2 {
  font-family: "Roboto Slab", serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

h3, .h3, .card-informative .card-informative-body > a {
  font-family: "Roboto Slab", serif;
  font-size: 1.23125rem;
  font-weight: 400;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    h3, .h3, .card-informative .card-informative-body > a {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

h4, .h4 {
  font-family: "Roboto Slab", serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.4625rem; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

h5, .h5 {
  font-family: "Roboto Slab", serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.1875rem; }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

h6, .h6 {
  font-family: "Roboto Slab", serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    h6, .h6 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

p {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #4a4a4a; }
  @media (min-width: 768px) {
    p {
      font-size: 1rem;
      line-height: 1.5rem; } }

q {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.1875rem;
  color: #4a4a4a; }
  q::after {
    content: ""; }
  q::before {
    content: ""; }
  @media (min-width: 768px) {
    q {
      font-size: 1rem;
      line-height: 1.1875rem; } }

a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #111;
  text-decoration: underline; }
  a:hover {
    color: #111; }

small {
  font-family: "Roboto", sans-serif;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    small {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.font-primary {
  font-family: "Roboto", sans-serif !important;
  /* stylelint-disable-line */ }

.font-accent {
  font-family: "Roboto Slab", serif !important;
  /* stylelint-disable-line */ }

.font-base {
  font-size: 0.9rem;
  line-height: 1.35rem; }
  @media (min-width: 768px) {
    .font-base {
      font-size: 1rem;
      line-height: 1.375rem; } }

.font-level-1 {
  font-size: 1.5rem;
  line-height: 2.88rem; }
  @media (min-width: 768px) {
    .font-level-1 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.font-level-2 {
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .font-level-2 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.font-level-3 {
  font-size: 1.23125rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .font-level-3 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.font-level-4 {
  font-size: 1.125rem;
  line-height: 1.4625rem; }
  @media (min-width: 768px) {
    .font-level-4 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.font-level-5 {
  font-size: 1.0625rem;
  line-height: 1.1875rem; }
  @media (min-width: 768px) {
    .font-level-5 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.font-level-6 {
  font-size: 1rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .font-level-6 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.font-level-7 {
  font-size: 0.9rem;
  line-height: 1.35rem; }
  @media (min-width: 768px) {
    .font-level-7 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.font-level-8 {
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .font-level-8 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-1-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-0 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-1 {
  height: 3.6rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-1 {
      height: 3.6rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-1 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-2 {
  height: 7.2rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-2 {
      height: 7.2rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-2 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-3 {
  height: 10.8rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-3 {
      height: 10.8rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-3 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-4 {
  height: 14.4rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-4 {
      height: 14.4rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-4 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-5 {
  height: 18rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-5 {
      height: 18rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-5 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-6 {
  height: 21.6rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-6 {
      height: 21.6rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-6 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-7 {
  height: 25.2rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-7 {
      height: 25.2rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-7 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-8 {
  height: 28.8rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-8 {
      height: 28.8rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-8 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-9 {
  height: 32.4rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-9 {
      height: 32.4rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-9 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-10 {
  height: 36rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-10 {
      height: 36rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-10 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-11 {
  height: 39.6rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-11 {
      height: 39.6rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-11 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-12 {
  height: 43.2rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-12 {
      height: 43.2rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-12 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-13 {
  height: 46.8rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-13 {
      height: 46.8rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-13 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-14 {
  height: 50.4rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-14 {
      height: 50.4rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-14 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-15 {
  height: 54rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-15 {
      height: 54rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-15 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-16 {
  height: 57.6rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-16 {
      height: 57.6rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-16 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-17 {
  height: 61.2rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-17 {
      height: 61.2rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-17 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-18 {
  height: 64.8rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-18 {
      height: 64.8rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-18 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-19 {
  height: 68.4rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-19 {
      height: 68.4rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-19 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-1-20 {
  height: 72rem;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.6rem; }
  @media (min-width: 768px) {
    .text-lines-1-20 {
      height: 72rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-1-20 {
      font-size: 2.4rem;
      line-height: 3.6rem; } }

.text-lines-2-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-0 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-1 {
  height: 2.3125rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-1 {
      height: 2.3125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-1 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-2 {
  height: 4.625rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-2 {
      height: 4.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-2 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-3 {
  height: 6.9375rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-3 {
      height: 6.9375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-3 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-4 {
  height: 9.25rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-4 {
      height: 9.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-4 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-5 {
  height: 11.5625rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-5 {
      height: 11.5625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-5 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-6 {
  height: 13.875rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-6 {
      height: 13.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-6 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-7 {
  height: 16.1875rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-7 {
      height: 16.1875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-7 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-8 {
  height: 18.5rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-8 {
      height: 18.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-8 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-9 {
  height: 20.8125rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-9 {
      height: 20.8125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-9 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-10 {
  height: 23.125rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-10 {
      height: 23.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-10 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-11 {
  height: 25.4375rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-11 {
      height: 25.4375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-11 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-12 {
  height: 27.75rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-12 {
      height: 27.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-12 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-13 {
  height: 30.0625rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-13 {
      height: 30.0625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-13 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-14 {
  height: 32.375rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-14 {
      height: 32.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-14 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-15 {
  height: 34.6875rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-15 {
      height: 34.6875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-15 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-16 {
  height: 37rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-16 {
      height: 37rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-16 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-17 {
  height: 39.3125rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-17 {
      height: 39.3125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-17 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-18 {
  height: 41.625rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-18 {
      height: 41.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-18 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-19 {
  height: 43.9375rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-19 {
      height: 43.9375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-19 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-2-20 {
  height: 46.25rem;
  overflow: hidden;
  font-size: 1.75rem;
  line-height: 2.3125rem; }
  @media (min-width: 768px) {
    .text-lines-2-20 {
      height: 46.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-2-20 {
      font-size: 1.75rem;
      line-height: 2.3125rem; } }

.text-lines-3-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-0 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-1 {
  height: 1.625rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-1 {
      height: 1.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-1 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-2 {
  height: 3.25rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-2 {
      height: 3.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-2 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-3 {
  height: 4.875rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-3 {
      height: 4.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-3 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-4 {
  height: 6.5rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-4 {
      height: 6.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-4 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-5 {
  height: 8.125rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-5 {
      height: 8.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-5 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-6 {
  height: 9.75rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-6 {
      height: 9.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-6 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-7 {
  height: 11.375rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-7 {
      height: 11.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-7 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-8 {
  height: 13rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-8 {
      height: 13rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-8 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-9 {
  height: 14.625rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-9 {
      height: 14.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-9 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-10 {
  height: 16.25rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-10 {
      height: 16.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-10 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-11 {
  height: 17.875rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-11 {
      height: 17.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-11 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-12 {
  height: 19.5rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-12 {
      height: 19.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-12 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-13 {
  height: 21.125rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-13 {
      height: 21.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-13 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-14 {
  height: 22.75rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-14 {
      height: 22.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-14 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-15 {
  height: 24.375rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-15 {
      height: 24.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-15 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-16 {
  height: 26rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-16 {
      height: 26rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-16 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-17 {
  height: 27.625rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-17 {
      height: 27.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-17 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-18 {
  height: 29.25rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-18 {
      height: 29.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-18 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-19 {
  height: 30.875rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-19 {
      height: 30.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-19 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-3-20 {
  height: 32.5rem;
  overflow: hidden;
  font-size: 1.4375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-3-20 {
      height: 32.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-3-20 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.text-lines-4-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-0 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-1 {
  height: 1.625rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-1 {
      height: 1.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-1 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-2 {
  height: 3.25rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-2 {
      height: 3.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-2 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-3 {
  height: 4.875rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-3 {
      height: 4.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-3 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-4 {
  height: 6.5rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-4 {
      height: 6.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-4 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-5 {
  height: 8.125rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-5 {
      height: 8.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-5 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-6 {
  height: 9.75rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-6 {
      height: 9.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-6 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-7 {
  height: 11.375rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-7 {
      height: 11.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-7 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-8 {
  height: 13rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-8 {
      height: 13rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-8 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-9 {
  height: 14.625rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-9 {
      height: 14.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-9 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-10 {
  height: 16.25rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-10 {
      height: 16.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-10 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-11 {
  height: 17.875rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-11 {
      height: 17.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-11 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-12 {
  height: 19.5rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-12 {
      height: 19.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-12 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-13 {
  height: 21.125rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-13 {
      height: 21.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-13 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-14 {
  height: 22.75rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-14 {
      height: 22.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-14 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-15 {
  height: 24.375rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-15 {
      height: 24.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-15 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-16 {
  height: 26rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-16 {
      height: 26rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-16 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-17 {
  height: 27.625rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-17 {
      height: 27.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-17 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-18 {
  height: 29.25rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-18 {
      height: 29.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-18 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-19 {
  height: 30.875rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-19 {
      height: 30.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-19 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-4-20 {
  height: 32.5rem;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-4-20 {
      height: 32.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-4-20 {
      font-size: 1.375rem;
      line-height: 1.625rem; } }

.text-lines-5-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-0 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-1 {
  height: 1.625rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-1 {
      height: 1.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-1 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-2 {
  height: 3.25rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-2 {
      height: 3.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-2 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-3 {
  height: 4.875rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-3 {
      height: 4.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-3 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-4 {
  height: 6.5rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-4 {
      height: 6.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-4 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-5 {
  height: 8.125rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-5 {
      height: 8.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-5 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-6 {
  height: 9.75rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-6 {
      height: 9.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-6 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-7 {
  height: 11.375rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-7 {
      height: 11.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-7 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-8 {
  height: 13rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-8 {
      height: 13rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-8 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-9 {
  height: 14.625rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-9 {
      height: 14.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-9 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-10 {
  height: 16.25rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-10 {
      height: 16.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-10 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-11 {
  height: 17.875rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-11 {
      height: 17.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-11 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-12 {
  height: 19.5rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-12 {
      height: 19.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-12 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-13 {
  height: 21.125rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-13 {
      height: 21.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-13 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-14 {
  height: 22.75rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-14 {
      height: 22.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-14 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-15 {
  height: 24.375rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-15 {
      height: 24.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-15 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-16 {
  height: 26rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-16 {
      height: 26rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-16 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-17 {
  height: 27.625rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-17 {
      height: 27.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-17 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-18 {
  height: 29.25rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-18 {
      height: 29.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-18 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-19 {
  height: 30.875rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-19 {
      height: 30.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-19 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-5-20 {
  height: 32.5rem;
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .text-lines-5-20 {
      height: 32.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-5-20 {
      font-size: 1.25rem;
      line-height: 1.625rem; } }

.text-lines-6-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-0 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-1 {
  height: 1.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-1 {
      height: 1.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-1 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-2 {
  height: 3rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-2 {
      height: 3rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-2 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-3 {
  height: 4.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-3 {
      height: 4.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-3 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-4 {
  height: 6rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-4 {
      height: 6rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-4 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-5 {
  height: 7.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-5 {
      height: 7.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-5 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-6 {
  height: 9rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-6 {
      height: 9rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-6 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-7 {
  height: 10.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-7 {
      height: 10.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-7 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-8 {
  height: 12rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-8 {
      height: 12rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-8 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-9 {
  height: 13.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-9 {
      height: 13.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-9 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-10 {
  height: 15rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-10 {
      height: 15rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-10 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-11 {
  height: 16.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-11 {
      height: 16.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-11 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-12 {
  height: 18rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-12 {
      height: 18rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-12 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-13 {
  height: 19.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-13 {
      height: 19.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-13 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-14 {
  height: 21rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-14 {
      height: 21rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-14 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-15 {
  height: 22.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-15 {
      height: 22.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-15 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-16 {
  height: 24rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-16 {
      height: 24rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-16 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-17 {
  height: 25.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-17 {
      height: 25.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-17 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-18 {
  height: 27rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-18 {
      height: 27rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-18 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-19 {
  height: 28.5rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-19 {
      height: 28.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-19 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-6-20 {
  height: 30rem;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-6-20 {
      height: 30rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-6-20 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.text-lines-7-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-0 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-1 {
  height: 1.375rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-1 {
      height: 1.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-1 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-2 {
  height: 2.75rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-2 {
      height: 2.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-2 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-3 {
  height: 4.125rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-3 {
      height: 4.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-3 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-4 {
  height: 5.5rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-4 {
      height: 5.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-4 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-5 {
  height: 6.875rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-5 {
      height: 6.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-5 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-6 {
  height: 8.25rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-6 {
      height: 8.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-6 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-7 {
  height: 9.625rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-7 {
      height: 9.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-7 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-8 {
  height: 11rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-8 {
      height: 11rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-8 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-9 {
  height: 12.375rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-9 {
      height: 12.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-9 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-10 {
  height: 13.75rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-10 {
      height: 13.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-10 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-11 {
  height: 15.125rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-11 {
      height: 15.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-11 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-12 {
  height: 16.5rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-12 {
      height: 16.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-12 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-13 {
  height: 17.875rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-13 {
      height: 17.875rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-13 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-14 {
  height: 19.25rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-14 {
      height: 19.25rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-14 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-15 {
  height: 20.625rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-15 {
      height: 20.625rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-15 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-16 {
  height: 22rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-16 {
      height: 22rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-16 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-17 {
  height: 23.375rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-17 {
      height: 23.375rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-17 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-18 {
  height: 24.75rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-18 {
      height: 24.75rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-18 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-19 {
  height: 26.125rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-19 {
      height: 26.125rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-19 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-7-20 {
  height: 27.5rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem; }
  @media (min-width: 768px) {
    .text-lines-7-20 {
      height: 27.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-7-20 {
      font-size: 1rem;
      line-height: 1.375rem; } }

.text-lines-8-0 {
  height: 0rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-0 {
      height: 0rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-0 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-1 {
  height: 1.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-1 {
      height: 1.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-1 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-2 {
  height: 3rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-2 {
      height: 3rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-2 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-3 {
  height: 4.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-3 {
      height: 4.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-3 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-4 {
  height: 6rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-4 {
      height: 6rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-4 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-5 {
  height: 7.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-5 {
      height: 7.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-5 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-6 {
  height: 9rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-6 {
      height: 9rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-6 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-7 {
  height: 10.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-7 {
      height: 10.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-7 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-8 {
  height: 12rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-8 {
      height: 12rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-8 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-9 {
  height: 13.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-9 {
      height: 13.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-9 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-10 {
  height: 15rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-10 {
      height: 15rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-10 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-11 {
  height: 16.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-11 {
      height: 16.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-11 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-12 {
  height: 18rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-12 {
      height: 18rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-12 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-13 {
  height: 19.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-13 {
      height: 19.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-13 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-14 {
  height: 21rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-14 {
      height: 21rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-14 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-15 {
  height: 22.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-15 {
      height: 22.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-15 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-16 {
  height: 24rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-16 {
      height: 24rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-16 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-17 {
  height: 25.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-17 {
      height: 25.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-17 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-18 {
  height: 27rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-18 {
      height: 27rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-18 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-19 {
  height: 28.5rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-19 {
      height: 28.5rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-19 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

.text-lines-8-20 {
  height: 30rem;
  overflow: hidden;
  font-size: 0.6875rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .text-lines-8-20 {
      height: 30rem;
      overflow: hidden; } }
  @media (min-width: 768px) {
    .text-lines-8-20 {
      font-size: 0.6875rem;
      line-height: 1.5rem; } }

@font-face {
  font-family: icongob-cl;
  font-style: 400;
  font-weight: 400;
  src: url("../fonts/fontello.eot?kyr2k2");
  src: url("../fonts/fontello.eot?kyr2k2#iefix") format("embedded-opentype"), url("../fonts/fontello.ttf?kyr2k2") format("truetype"), url("../fonts/fontello.woff2?kyr2k2") format("woff2"), url("../fonts/fontello.woff?kyr2k2") format("woff"), url("../fonts/fontello.svg?kyr2k2#icongob-cl") format("svg"); }

.cl.cl-2x {
  font-size: 2rem; }

.cl.cl-3x {
  font-size: 3rem; }

.cl.cl-4x {
  font-size: 4rem; }

.cl.cl-5x {
  font-size: 5rem; }

.cl.cl-6x {
  font-size: 6rem; }

.cl.cl-7x {
  font-size: 7rem; }

.cl.cl-8x {
  font-size: 8rem; }

.cl.cl-9x {
  font-size: 9rem; }

.cl.cl-10x {
  font-size: 10rem; }

.cl-search {
  color: #111; }
  .cl-search::before {
    color: inherit;
    content: ""; }

.cl-agenda {
  color: #111; }
  .cl-agenda::before {
    color: inherit;
    content: ""; }

.cl-agendarhora {
  color: #111; }
  .cl-agendarhora::before {
    color: inherit;
    content: ""; }

.cl-alerta {
  color: #111; }
  .cl-alerta::before {
    color: inherit;
    content: ""; }

.cl-ayuda {
  color: #111; }
  .cl-ayuda::before {
    color: inherit;
    content: ""; }

.cl-beneficios {
  color: #111; }
  .cl-beneficios::before {
    color: inherit;
    content: ""; }

.cl-boot {
  color: #111; }
  .cl-boot::before {
    color: inherit;
    content: ""; }

.cl-callcenter {
  color: #111; }
  .cl-callcenter::before {
    color: inherit;
    content: ""; }

.cl-cargar {
  color: #111; }
  .cl-cargar::before {
    color: inherit;
    content: ""; }

.cl-cerrar {
  color: #111; }
  .cl-cerrar::before {
    color: inherit;
    content: ""; }

.cl-certificado_1 {
  color: #111; }
  .cl-certificado_1::before {
    color: inherit;
    content: ""; }

.cl-claveunica {
  color: #111; }
  .cl-claveunica::before {
    color: inherit;
    content: ""; }

.cl-contraste {
  color: #111; }
  .cl-contraste::before {
    color: inherit;
    content: ""; }

.cl-descargar {
  color: #111; }
  .cl-descargar::before {
    color: inherit;
    content: ""; }

.cl-editar {
  color: #111; }
  .cl-editar::before {
    color: inherit;
    content: ""; }

.cl-facebook {
  color: #111; }
  .cl-facebook::before {
    color: inherit;
    content: ""; }

.cl-filtro {
  color: #111; }
  .cl-filtro::before {
    color: inherit;
    content: ""; }

.cl-hablar {
  color: #111; }
  .cl-hablar::before {
    color: inherit;
    content: ""; }

.cl-img {
  color: #111; }
  .cl-img::before {
    color: inherit;
    content: ""; }

.cl-info {
  color: #111; }
  .cl-info::before {
    color: inherit;
    content: ""; }

.cl-like {
  color: #111; }
  .cl-like::before {
    color: inherit;
    content: ""; }

.cl-link {
  color: #111; }
  .cl-link::before {
    color: inherit;
    content: ""; }

.cl-login {
  color: #111; }
  .cl-login::before {
    color: inherit;
    content: ""; }

.cl-lupa {
  color: #111; }
  .cl-lupa::before {
    color: inherit;
    content: ""; }

.cl-mas {
  color: #111; }
  .cl-mas::before {
    color: inherit;
    content: ""; }

.cl-menos {
  color: #111; }
  .cl-menos::before {
    color: inherit;
    content: ""; }

.cl-menu {
  color: #111; }
  .cl-menu::before {
    color: inherit;
    content: ""; }

.cl-mess {
  color: #111; }
  .cl-mess::before {
    color: inherit;
    content: ""; }

.cl-no-like {
  color: #111; }
  .cl-no-like::before {
    color: inherit;
    content: ""; }

.cl-notificacion {
  color: #111; }
  .cl-notificacion::before {
    color: inherit;
    content: ""; }

.cl-pause {
  color: #111; }
  .cl-pause::before {
    color: inherit;
    content: ""; }

.cl-play {
  color: #111; }
  .cl-play::before {
    color: inherit;
    content: ""; }

.cl-print {
  color: #111; }
  .cl-print::before {
    color: inherit;
    content: ""; }

.cl-sound {
  color: #111; }
  .cl-sound::before {
    color: inherit;
    content: ""; }

.cl-sucursales {
  color: #111; }
  .cl-sucursales::before {
    color: inherit;
    content: ""; }

.cl-telefono {
  color: #111; }
  .cl-telefono::before {
    color: inherit;
    content: ""; }

.cl-trash {
  color: #111; }
  .cl-trash::before {
    color: inherit;
    content: ""; }

.cl-twitter {
  color: #111; }
  .cl-twitter::before {
    color: inherit;
    content: ""; }

.cl-ver {
  color: #111; }
  .cl-ver::before {
    color: inherit;
    content: ""; }

.cl-youtube {
  color: #111; }
  .cl-youtube::before {
    color: inherit;
    content: ""; }

.cl-instagram {
  color: #111; }
  .cl-instagram::before {
    color: inherit;
    content: ""; }

.cl-mail-alt {
  color: #111; }
  .cl-mail-alt::before {
    color: inherit;
    content: ""; }

.cl-close-line {
  color: #a8b7c7; }
  .cl-close-line::before {
    color: inherit;
    content: ""; }

.cl-logout {
  color: #a8b7c7; }
  .cl-logout::before {
    color: inherit;
    content: ""; }

.cl-download {
  color: #111; }
  .cl-download::before {
    color: inherit;
    content: ""; }

.cl-breadcrumb-arrow {
  color: #a8b7c7; }
  .cl-breadcrumb-arrow::before {
    color: inherit;
    content: ""; }

.cl-arrow-line {
  color: #006fb3; }
  .cl-arrow-line::before {
    color: inherit;
    content: ""; }

.cl-close {
  color: #a8b7c7; }
  .cl-close::before {
    color: inherit;
    content: ""; }

.cl-decrease-text {
  color: #a8b7c7; }
  .cl-decrease-text::before {
    color: inherit;
    content: ""; }

.cl-increase-text {
  color: #a8b7c7; }
  .cl-increase-text::before {
    color: inherit;
    content: ""; }

.cl-contrast {
  color: #a8b7c7; }
  .cl-contrast::before {
    color: inherit;
    content: ""; }

.cl-messenger {
  color: #a8b7c7; }
  .cl-messenger::before {
    color: inherit;
    content: ""; }

.cl-arrow {
  color: #a8b7c7; }
  .cl-arrow::before {
    color: inherit;
    content: ""; }

.cl-telephone {
  color: #006fb3; }
  .cl-telephone::before {
    color: inherit;
    content: ""; }

.cl-grant {
  color: #006fb3; }
  .cl-grant::before {
    color: inherit;
    content: ""; }

.cl-bonuses {
  color: #006fb3; }
  .cl-bonuses::before {
    color: inherit;
    content: ""; }

.cl-burger {
  color: #111; }
  .cl-burger::before {
    color: inherit;
    content: ""; }

.cl-certificates {
  color: #006fb3; }
  .cl-certificates::before {
    color: inherit;
    content: ""; }

.cl-key-unique {
  color: #006fb3; }
  .cl-key-unique::before {
    color: inherit;
    content: ""; }

.cl-meet-authorities {
  color: #006fb3; }
  .cl-meet-authorities::before {
    color: inherit;
    content: ""; }

.cl-filter {
  color: #006fb3; }
  .cl-filter::before {
    color: inherit;
    content: ""; }

.cl-procedures {
  color: #006fb3; }
  .cl-procedures::before {
    color: inherit;
    content: ""; }

.cl-login-fill {
  color: #006fb3; }
  .cl-login-fill::before {
    color: inherit;
    content: ""; }

.cl-question {
  color: #a8b7c7; }
  .cl-question::before {
    color: inherit;
    content: ""; }

.cl-doctor {
  color: #006fb3; }
  .cl-doctor::before {
    color: inherit;
    content: ""; }

.cl-woman {
  color: #006fb3; }
  .cl-woman::before {
    color: inherit;
    content: ""; }

.cl-health {
  color: #006fb3; }
  .cl-health::before {
    color: inherit;
    content: ""; }

.cl-modules {
  color: #006fb3; }
  .cl-modules::before {
    color: inherit;
    content: ""; }

.cl-navigation {
  color: #006fb3; }
  .cl-navigation::before {
    color: inherit;
    content: ""; }

.cl-recent-news {
  color: #006fb3; }
  .cl-recent-news::before {
    color: inherit;
    content: ""; }

.cl-mobile-offices {
  color: #006fb3; }
  .cl-mobile-offices::before {
    color: inherit;
    content: ""; }

.cl-pensions {
  color: #006fb3; }
  .cl-pensions::before {
    color: inherit;
    content: ""; }

.cl-social-networks {
  color: #006fb3; }
  .cl-social-networks::before {
    color: inherit;
    content: ""; }

.cl-subsidy-of-cesantia {
  color: #006fb3; }
  .cl-subsidy-of-cesantia::before {
    color: inherit;
    content: ""; }

.cl-subsidies {
  color: #006fb3; }
  .cl-subsidies::before {
    color: inherit;
    content: ""; }

.cl-accessibility {
  color: #a8b7c7; }
  .cl-accessibility::before {
    color: inherit;
    content: ""; }

.cl-branches {
  color: #006fb3; }
  .cl-branches::before {
    color: inherit;
    content: ""; }

.cl-arrow-right {
  color: #a8b7c7; }
  .cl-arrow-right::before {
    color: inherit;
    content: ""; }

.cl-add-circle-line {
  color: #a8b7c7; }
  .cl-add-circle-line::before {
    color: inherit;
    content: ""; }

.cl-add-circle-fill {
  color: #a8b7c7; }
  .cl-add-circle-fill::before {
    color: inherit;
    content: ""; }

.cl-m-arrow-right {
  color: #111; }
  .cl-m-arrow-right::before {
    color: inherit;
    content: ""; }

.cl-m-arrow-down {
  color: #111; }
  .cl-m-arrow-down::before {
    color: inherit;
    content: ""; }

.cl-m-arrow-up {
  color: #111; }
  .cl-m-arrow-up::before {
    color: inherit;
    content: ""; }

.cl-img-preview .path1::before {
  color: #eee;
  content: "\e906"; }

.cl-img-preview .path2::before {
  margin-left: -1em;
  color: #8a8a8a;
  content: "\e907"; }

.line {
  position: relative;
  display: inline-block;
  min-width: 100%;
  margin-bottom: 30px;
  text-align: center; }
  .line::after {
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    height: 5px;
    content: "";
    background: linear-gradient(to right, #006fb3 0%, #006fb3 50%, #fe6565 50%, #fe6565 100%); }
  @media (min-width: 768px) {
    .line {
      min-width: 600px; } }

.btn {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400; }
  .btn.btn-primary, .btn.btn-outline-primary {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-primary:not(.btn-sm):not(.btn-lg), .btn.btn-outline-primary:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-secondary, .btn.btn-outline-secondary {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-secondary:not(.btn-sm):not(.btn-lg), .btn.btn-outline-secondary:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-success, .btn.btn-outline-success {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-success:not(.btn-sm):not(.btn-lg), .btn.btn-outline-success:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-info, .btn.btn-outline-info {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-info:not(.btn-sm):not(.btn-lg), .btn.btn-outline-info:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-warning, .btn.btn-outline-warning {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-warning:not(.btn-sm):not(.btn-lg), .btn.btn-outline-warning:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-danger, .btn.btn-outline-danger {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-danger:not(.btn-sm):not(.btn-lg), .btn.btn-outline-danger:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-light, .btn.btn-outline-light {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-light:not(.btn-sm):not(.btn-lg), .btn.btn-outline-light:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-dark, .btn.btn-outline-dark {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-dark:not(.btn-sm):not(.btn-lg), .btn.btn-outline-dark:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-accent, .btn.btn-outline-accent {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-accent:not(.btn-sm):not(.btn-lg), .btn.btn-outline-accent:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-neutral, .btn.btn-outline-neutral {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-neutral:not(.btn-sm):not(.btn-lg), .btn.btn-outline-neutral:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-tertiary, .btn.btn-outline-tertiary {
    text-decoration: underline;
    word-break: break-word;
    white-space: normal; }
    .btn.btn-tertiary:not(.btn-sm):not(.btn-lg), .btn.btn-outline-tertiary:not(.btn-sm):not(.btn-lg) {
      min-height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5rem; }
  .btn.btn-default-size {
    display: block; }
    @media (min-width: 992px) {
      .btn.btn-default-size {
        display: inline-block;
        width: unset;
        min-width: 360px; } }
  .btn.btn-card-xl {
    min-height: 48px !important;
    padding: 10px 30px !important;
    border-radius: 3px; }
  .btn.btn-card-xs {
    min-height: 48px !important;
    padding: 10px 15px !important;
    border-radius: 3px; }
  .btn.btn-gray-c {
    padding: 7px 30px;
    margin: 0 20px 20px 0;
    text-decoration: underline;
    background-color: #d8d8d8; }
  .btn.btn-maps {
    background-color: #eee;
    border: 1px solid #8a8a8a;
    border-radius: 3px; }
  .btn i {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    vertical-align: top; }

.banner {
  display: block;
  overflow: hidden;
  text-decoration: none;
  border-radius: 3px; }
  .banner:hover {
    text-decoration: none; }
  .banner a {
    text-decoration: none; }
  .banner .img_frj {
    position: absolute;
    top: 0;
    width: 100px; }
  .banner .banner_content {
    margin-top: 20px;
    margin-bottom: 8px; }
    .banner .banner_content .img_mrg {
      font-size: 37px; }
    .banner .banner_content .font_ligth {
      font-family: "gobCl_light"; }
    .banner .banner_content .font_bold {
      font-family: "gobCL_Bold"; }
    .banner .banner_content .d_row span {
      font-size: 16px;
      line-height: 8px; }
    .banner .banner_content .d_row h3 {
      font-size: 20px;
      line-height: 12px; }
    .banner .banner_content .d_col span {
      display: inline-block;
      margin-right: 6px;
      font-size: 26px;
      line-height: 34px; }
    .banner .banner_content .d_col h3 {
      display: inline-block;
      font-size: 28px;
      line-height: 34px; }
  .banner.banner-grey span, .banner.banner-grey h3 {
    color: #424040; }
  .banner.banner-blue span, .banner.banner-blue h3 {
    color: #006fb3; }
  .banner.border-banner-shadow {
    box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.8); }
  .banner.border-banner-blue {
    border: 1px solid #006fb3; }
  .banner.border-banner-grey {
    border: 1px solid #aaa; }
  .banner .cl-grey {
    color: #424040; }

a.link-unstyled, a.link-icon, a.social {
  text-decoration: none; }

a.link-icon {
  margin-left: 0.5rem;
  font-size: 1.375rem; }

.card-featured {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #a8b7c7; }
  .card-featured::before {
    position: absolute;
    right: 6px;
    bottom: 6px;
    font-size: 11px; }
  .card-featured:hover {
    color: #006fb3;
    text-decoration: none;
    border: 1px solid #006fb3; }
    .card-featured:hover::before {
      color: #006fb3; }
  .card-featured .card-featured-image {
    height: 99px; }
    @media (min-width: 992px) {
      .card-featured .card-featured-image {
        height: 145px; } }
  .card-featured .card-featured-body {
    padding: 13px; }
    .card-featured .card-featured-body > h4 {
      margin: 0 0 13px;
      overflow: hidden;
      font-weight: bold;
      line-height: 29px; }
    .card-featured .card-featured-body > p {
      margin: 0;
      overflow: hidden; }
    .card-featured .card-featured-body > span {
      display: block;
      margin-top: 20px;
      color: #0f69b4;
      text-decoration: underline; }

.card-news {
  margin-bottom: 1.5rem;
  border: 1px solid #a8b7c7; }
  .card-news .card-news-image {
    height: 99px; }
    @media (min-width: 992px) {
      .card-news .card-news-image {
        height: 145px; } }
  .card-news .card-news-image-list {
    height: 200px; }
    .card-news .card-news-image-list h4 {
      margin: 10% 8% 5%;
      font-weight: bold; }
    .card-news .card-news-image-list a {
      margin: 0 8%; }
  .card-news .card-news-image-width {
    width: 140px;
    margin: 30px auto; }
  .card-news .card-news-footer {
    min-height: 70px; }
  .card-news .card-news-type {
    display: inline-block;
    padding: 3px 10px;
    margin: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-decoration: underline;
    background-color: #0f69b4; }
  .card-news:hover {
    color: #006fb3;
    border: 1px solid #006fb3; }
    .card-news:hover a {
      color: #006fb3; }
  .card-news.with-lead .card-news-body > a {
    height: auto; }
  .card-news.with-lead .card-news-body h4,
  .card-news.with-lead .card-news-body .card-news-title {
    display: block;
    margin: 0 0 13px;
    overflow: hidden; }
  .card-news.with-lead .card-news-body p {
    display: block;
    margin: 0 0 13px;
    overflow: hidden; }
  .card-news .card-news-body {
    padding: 13px; }
    .card-news .card-news-body > span {
      display: block;
      margin: 0 0 13px;
      overflow: hidden;
      opacity: 0.6; }
      @media (min-width: 768px) {
        .card-news .card-news-body > span {
          height: 1.5rem; } }
    .card-news .card-news-body > a {
      display: block;
      margin: 0;
      overflow: hidden; }
    .card-news .card-news-body h4 {
      font-weight: bold;
      line-height: 33px; }

.card-news-post {
  color: #4a4a4a; }
  .card-news-post h2 {
    font-size: 38px;
    font-weight: bold;
    line-height: 50px; }
  .card-news-post .card-bajada {
    font-size: 28px;
    font-weight: 300;
    line-height: 37px; }
  .card-news-post .card-text-gal-imgs a {
    margin: 0 10px 20px 10px; }
  .card-news-post .card-text-gal-imgs img:hover {
    color: #006fb3;
    border: 1px solid #006fb3; }
    .card-news-post .card-text-gal-imgs img:hover a {
      color: #006fb3; }
  .card-news-post .card-new-franja {
    position: relative;
    padding: 20px 0;
    margin: 20px 0;
    border-top: 1px solid #b4b3b3;
    border-bottom: 1px solid #b4b3b3; }
    .card-news-post .card-new-franja span {
      font-size: 14px;
      line-height: 16px; }
    .card-news-post .card-new-franja i {
      position: absolute;
      top: 45%;
      right: 15px;
      font-size: 25px; }
    .card-news-post .card-new-franja a.link-icon div {
      margin-left: 10px; }
  .card-news-post .card-text-dest {
    margin: 40px 0;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 300;
    line-height: 37px; }
  .card-news-post h4 {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    line-height: 37px; }
  .card-news-post h5 {
    font-size: 22px;
    font-weight: bold;
    line-height: 29px; }

.card-data-list {
  margin-right: 0;
  margin-left: 0; }
  .card-data-list .card-data {
    display: flex;
    align-items: center;
    height: 5.875rem;
    padding: 16px;
    border: 1px solid #a8b7c7; }
    .card-data-list .card-data:hover {
      color: #fff;
      background-color: #006fb3 !important;
      /* stylelint-disable-line */ }
      .card-data-list .card-data:hover p {
        color: #fff; }
    .card-data-list .card-data:not(:last-child) {
      border-bottom: none; }
    @media (min-width: 768px) {
      .card-data-list .card-data {
        display: block;
        height: 11.925rem; } }
    @media (min-width: 992px) {
      .card-data-list .card-data:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid #a8b7c7; } }
    .card-data-list .card-data:first-child {
      background-color: #f5f6f7; }
    .card-data-list .card-data > .card-data-title {
      display: flex;
      align-items: center; }
      @media (min-width: 768px) {
        .card-data-list .card-data > .card-data-title {
          display: block; } }
      .card-data-list .card-data > .card-data-title > .card-data-quantity {
        width: 3.75rem;
        height: 1.625rem;
        padding-right: 10px;
        overflow: hidden;
        font-family: "Roboto Slab", serif;
        font-size: 1.8125rem;
        line-height: 1.625rem;
        text-align: right; }
        @media (min-width: 768px) {
          .card-data-list .card-data > .card-data-title > .card-data-quantity {
            width: auto;
            text-align: left; } }
      .card-data-list .card-data > .card-data-title > h5 {
        margin-bottom: 0;
        overflow: hidden;
        font-size: 1.25rem; }
        @media (min-width: 768px) {
          .card-data-list .card-data > .card-data-title > h5 {
            height: 3.25rem;
            margin-bottom: 0.5rem; } }
    .card-data-list .card-data > p {
      display: none;
      height: 4.05rem;
      overflow: hidden;
      font-size: 0.9rem;
      line-height: 1.35rem; }
      @media (min-width: 768px) {
        .card-data-list .card-data > p {
          display: block; } }

.card-data-tramite .card-data .card-data-title {
  font-family: "Roboto Slab";
  color: #000; }
  .card-data-tramite .card-data .card-data-title .card-data-quantity {
    margin-bottom: 10px;
    font-size: 38px;
    font-weight: bold; }
    .card-data-tramite .card-data .card-data-title .card-data-quantity.text-xl {
      margin: 57px 10px;
      font-size: 130px; }
  .card-data-tramite .card-data .card-data-title h5 {
    font-size: 23px;
    font-weight: 300;
    line-height: 37px; }
    .card-data-tramite .card-data .card-data-title h5.text-xl {
      font-size: 28px; }

.card-links .card-links-image {
  float: left;
  width: 50px; }
  .card-links .card-links-image .cl {
    font-size: 32px; }

.card-links .card-links-body {
  padding-left: 50px; }
  .card-links .card-links-body h4 {
    margin-bottom: 1rem; }
  .card-links .card-links-body ul.list-unstyled li {
    height: 2.7rem;
    margin-bottom: 1rem;
    overflow: hidden; }
    @media (min-width: 768px) {
      .card-links .card-links-body ul.list-unstyled li {
        height: 2.75rem; } }
    .card-links .card-links-body ul.list-unstyled li a {
      color: #4a4a4a; }
  .card-links .card-links-body ul.list-inline li a, .card-links .card-links-body ul.list-inline li::after {
    color: #4a4a4a;
    opacity: 0.6; }
  .card-links .card-links-body p {
    color: #4a4a4a;
    opacity: 0.6; }

.card-informative {
  margin-bottom: 1.5rem;
  border: 1px solid #a8b7c7; }
  .card-informative .card-informative-image {
    height: 99px; }
    @media (min-width: 992px) {
      .card-informative .card-informative-image {
        height: 200px; } }
  .card-informative .card-informative-body {
    height: 6.7625rem;
    padding: 13px; }
    @media (min-width: 768px) {
      .card-informative .card-informative-body {
        height: 6.8125rem; } }
    .card-informative .card-informative-body > a {
      display: block;
      height: 1.625rem;
      margin: 0 0 13px;
      overflow: hidden;
      font-weight: 300;
      color: #4a4a4a; }
      @media (min-width: 768px) {
        .card-informative .card-informative-body > a {
          height: 1.625rem; } }
    .card-informative .card-informative-body > p {
      height: 2.7rem;
      margin: 0;
      overflow: hidden;
      opacity: 0.6; }
      @media (min-width: 768px) {
        .card-informative .card-informative-body > p {
          height: 2.75rem; } }

.card-featured-news .card-featured-news-body small {
  margin-bottom: 1.5rem; }

.card-featured-news .card-featured-news-body p,
.card-featured-news .card-featured-news-body small {
  opacity: 0.6; }

.card-featured-news .card-featured-news-body a {
  color: #4a4a4a; }

section.section.contain-cover,
.contain-cover {
  position: relative; }
  section.section.contain-cover.contain-cover-background,
  section.section.contain-cover .contain-cover-background,
  .contain-cover.contain-cover-background,
  .contain-cover .contain-cover-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  section.section.contain-cover .contain-cover-background,
  .contain-cover .contain-cover-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; }
  section.section.contain-cover.contain-cover-opacity,
  section.section.contain-cover .contain-cover-opacity,
  .contain-cover.contain-cover-opacity,
  .contain-cover .contain-cover-opacity {
    background: rgba(17, 17, 17, 0.4) !important;
    /* stylelint-disable-line */
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 100%) !important;
    /* stylelint-disable-line */ }
    section.section.contain-cover.contain-cover-opacity.hover-disabled, section.section.contain-cover.contain-cover-opacity:hover,
    section.section.contain-cover .contain-cover-opacity.hover-disabled,
    section.section.contain-cover .contain-cover-opacity:hover,
    .contain-cover.contain-cover-opacity.hover-disabled,
    .contain-cover.contain-cover-opacity:hover,
    .contain-cover .contain-cover-opacity.hover-disabled,
    .contain-cover .contain-cover-opacity:hover {
      background: rgba(17, 17, 17, 0.6) !important;
      /* stylelint-disable-line */
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.6) 100%) !important;
      /* stylelint-disable-line */ }
  section.section.contain-cover.contain-cover-video,
  .contain-cover.contain-cover-video {
    background: transparent; }
    section.section.contain-cover.contain-cover-video .video-container,
    section.section.contain-cover.contain-cover-video .contain-cover-opacity,
    .contain-cover.contain-cover-video .video-container,
    .contain-cover.contain-cover-video .contain-cover-opacity {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden; }
    section.section.contain-cover.contain-cover-video .video-container,
    .contain-cover.contain-cover-video .video-container {
      z-index: -3; }
    section.section.contain-cover.contain-cover-video video,
    .contain-cover.contain-cover-video video {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: -3;
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
      transform: translate(-50%, -50%); }
    section.section.contain-cover.contain-cover-video .contain-cover-opacity,
    .contain-cover.contain-cover-video .contain-cover-opacity {
      z-index: -1; }

.pseudo-background {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.form-search .input-group-append .btn-append-search, .form-search .search .search-action .btn-append-search, .search .form-search .search-action .btn-append-search {
  min-height: 20px !important;
  padding: 0 15px !important;
  text-decoration: none;
  border-radius: 0 3px 3px 0; }
  .form-search .input-group-append .btn-append-search i.cl, .form-search .search .search-action .btn-append-search i.cl, .search .form-search .search-action .btn-append-search i.cl {
    color: #fff; }

.search {
  width: 100%; }
  @media (min-width: 992px) {
    .search {
      width: 555px; } }
  .search:not(.with-label) {
    width: 100%; }
    @media (min-width: 992px) {
      .search:not(.with-label) {
        width: 555px; } }
    .search:not(.with-label) .form-control {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      font-weight: normal;
      border-right: none;
      border-radius: 3px; }
  .search.with-label .search-content {
    flex-wrap: nowrap;
    justify-content: stretch; }
    .search.with-label .search-content .form-control {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      font-weight: normal;
      border-right: none;
      border-radius: 3px; }
  .search .search-action .btn {
    padding: .2rem .7rem;
    background-color: #006fb3;
    border-color: #006fb3;
    border-left: none;
    border-radius: 0 3px 3px 0 !important;
    transition: none; }
    .search .search-action .btn:hover {
      color: #006fb3;
      background-color: #0f69b4;
      border-color: #0f69b4; }
    .search .search-action .btn:focus {
      box-shadow: none; }
    .search .search-action .btn i {
      font-size: 1.25rem;
      color: #fff; }
  .search.dom-search .dom-search-behavior-cancel {
    padding-right: 0; }

.search-not-found {
  display: none; }
  .search-not-found.not-found {
    display: block; }

.title-lg {
  font-size: 38px;
  line-height: 50px; }

.bajada-lg {
  font-size: 32px;
  line-height: 43px; }

.title-md {
  font-size: 35px;
  line-height: 46px; }

.profile p {
  line-height: 1.5rem; }

.profile-charge {
  font-family: "Roboto Slab", serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.8125rem;
  letter-spacing: 0.0675rem; }

.profile-body section.section .section-header.with-profile-charge {
  border-bottom: none; }
  .profile-body section.section .section-header.with-profile-charge .profile-charge {
    padding-top: 0.5rem;
    border-top: 1px solid theme-color("accent"); }
  .profile-body section.section .section-header.with-profile-charge .section-icons {
    bottom: 5.125rem; }

.profile-group {
  display: contents; }
  @media (min-width: 768px) {
    .profile-group {
      display: block; } }

.profile .profile-header {
  width: 100%;
  padding: 0 15px; }
  @supports (display: contents) {
    .profile .profile-header {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .profile .profile-header {
      flex: 0 0 25%;
      max-width: 25%; } }

@media (min-width: 992px) {
  .profile .profile-body {
    flex: 0 0 75%;
    max-width: 75%; } }

.profile .profile-body section.section:first-child .section-header {
  padding: 3rem 15px 0; }
  @supports (display: contents) {
    .profile .profile-body section.section:first-child .section-header {
      padding: 0 15px 0;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .profile .profile-body section.section:first-child .section-header {
      padding: 0 15px 0;
      flex: 0 0 100%;
      max-width: 100%; } }

.profile .profile-body section.section:first-child .section-body {
  margin-top: 1.5rem; }
  @media (min-width: 992px) {
    .profile .profile-body section.section:first-child .section-body {
      margin-top: 0; } }

.profile .profile-body,
.profile .profile-body > section.section {
  display: contents; }
  @media (min-width: 992px) {
    .profile .profile-body,
    .profile .profile-body > section.section {
      display: block; } }

.profile-page {
  align-content: flex-start; }
  .profile-page p {
    line-height: 1.5rem; }
  .profile-page .profile-group {
    order: 1; }
    @media (min-width: 768px) {
      .profile-page .profile-group {
        flex-direction: column;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%; } }
  .profile-page .profile-header {
    display: block;
    order: 1;
    width: 100%;
    padding: 0 15px; }
    .profile-page .profile-header section.section {
      margin-top: 3rem; }
      @media (min-width: 768px) {
        .profile-page .profile-header section.section {
          margin-top: 1.5rem; } }
  .profile-page .profile-footer {
    display: block;
    order: 3;
    width: 100%;
    padding: 0 15px; }
    .profile-page .profile-footer section.section {
      margin-top: 3rem; }
      @media (min-width: 768px) {
        .profile-page .profile-footer section.section {
          margin-top: 1.5rem; } }
  .profile-page .profile-body {
    order: 2; }
    .profile-page .profile-body section.section {
      margin-top: 3rem; }
    @media (min-width: 768px) {
      .profile-page .profile-body section.section:first-child {
        margin-top: 0; } }

.profile-section p {
  line-height: 1.5rem; }

@media (min-width: 992px) {
  .profile-section .profile-body section.section:first-child .section-header,
  .profile-section .profile-footer section.section:first-child .section-header {
    margin-top: 0; } }

.profile-section .profile-header {
  width: 100%;
  padding: 0 15px; }
  .profile-section .profile-header section.section {
    margin-top: 1.5rem; }
  @supports (display: contents) {
    .profile-section .profile-header {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .profile-section .profile-header {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; } }

.profile-section .profile-footer section.section {
  margin-top: 1.5rem; }
  @media (min-width: 992px) {
    .profile-section .profile-footer section.section {
      margin-top: 0; } }

@media (min-width: 992px) {
  .profile-section .profile-body {
    flex: 0 0 50%;
    max-width: 50%; } }

.profile-section .profile-body section.section:first-child .section-header {
  padding: 0 15px; }
  @supports (display: contents) {
    .profile-section .profile-body section.section:first-child .section-header {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .profile-section .profile-body section.section:first-child .section-header {
      flex: 0 0 100%;
      max-width: 100%; } }
  .profile-section .profile-body section.section:first-child .section-header .section-icons {
    position: initial; }
    @media (min-width: 992px) {
      .profile-section .profile-body section.section:first-child .section-header .section-icons {
        position: absolute;
        right: 15px; } }
    .profile-section .profile-body section.section:first-child .section-header .section-icons .link-icon {
      margin-right: 1rem;
      margin-left: 0; }
      @media (min-width: 992px) {
        .profile-section .profile-body section.section:first-child .section-header .section-icons .link-icon {
          margin-right: 0;
          margin-left: 1rem; } }

.profile-section .profile-body section.section:first-child .section-body {
  margin-top: 1.5rem; }
  @media (min-width: 992px) {
    .profile-section .profile-body section.section:first-child .section-body {
      margin-top: 0; } }

.profile-section .profile-body,
.profile-section .profile-body > section.section {
  display: contents; }
  @media (min-width: 992px) {
    .profile-section .profile-body,
    .profile-section .profile-body > section.section {
      display: block; } }

.toolbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  height: 38px; }
  @media (min-width: 576px) {
    .toolbar {
      width: 232px; } }
  @media (min-width: 768px) {
    .toolbar {
      width: 200px; } }
  .toolbar .toolbar-btn {
    display: none;
    width: 38px;
    height: 38px;
    padding: 9.23px;
    text-decoration: none;
    vertical-align: top;
    border: 1.77px solid #4a4a4a; }
    .toolbar .toolbar-btn:hover {
      border-color: #006fb3; }
      .toolbar .toolbar-btn:hover .cl::before {
        color: #006fb3; }
    .toolbar .toolbar-btn .toolbar-btn-icon-content {
      display: inline-block;
      height: 34.46px;
      padding: 9.23px; }
    @media (min-width: 768px) {
      .toolbar .toolbar-btn {
        display: inline-block; } }
    .toolbar .toolbar-btn .cl {
      display: block;
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      text-align: center; }
      .toolbar .toolbar-btn .cl::before {
        color: #4a4a4a; }
    .toolbar .toolbar-btn.toolbar-toggler {
      display: inline-block; }
      .toolbar .toolbar-btn.toolbar-toggler .toolbar-behavior-on {
        display: block; }
      .toolbar .toolbar-btn.toolbar-toggler .toolbar-behavior-off {
        display: none;
        font-size: 16px;
        line-height: 16px;
        color: #a8b7c7;
        text-align: center; }
      @media (min-width: 768px) {
        .toolbar .toolbar-btn.toolbar-toggler {
          display: none; } }
    .toolbar .toolbar-btn.toolbar-behavior-decrease {
      padding: 9.23px; }
      .toolbar .toolbar-btn.toolbar-behavior-decrease .cl {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px; }
    .toolbar .toolbar-btn.toolbar-behavior-increase {
      padding: 9.23px; }
      .toolbar .toolbar-btn.toolbar-behavior-increase .cl {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px; }
    .toolbar .toolbar-btn.toolbar-behavior-read {
      width: 38px;
      padding: 9.23px; }
      .toolbar .toolbar-btn.toolbar-behavior-read .toolbar-btn-icon-content {
        padding: 9.23px; }
      .toolbar .toolbar-btn.toolbar-behavior-read .cl {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px; }
  .toolbar .toolbar-btn-group {
    display: inline-block;
    height: 38px; }
    .toolbar .toolbar-btn-group .toolbar-btn:not(:first-child) {
      margin-left: 4px; }
  .toolbar.active .toolbar-btn {
    display: inline-block; }
    @media (min-width: 768px) {
      .toolbar.active .toolbar-btn.toolbar-toggler {
        display: none; } }
    .toolbar.active .toolbar-btn.toolbar-toggler .toolbar-behavior-on {
      display: none; }
    .toolbar.active .toolbar-btn.toolbar-toggler .toolbar-behavior-off {
      display: block; }
  .toolbar.light .toolbar-btn {
    border-color: #fff; }
    .toolbar.light .toolbar-btn .cl::before {
      color: #fff; }
    .toolbar.light .toolbar-btn:hover {
      border-color: #006fb3; }
      .toolbar.light .toolbar-btn:hover .cl::before {
        color: #006fb3; }

.social {
  display: block;
  padding: 10px 0;
  overflow: hidden; }
  @media (min-width: 992px) {
    .social:hover .social-icon .cl-twitter::before {
      color: #00aced; } }
  @media (min-width: 992px) {
    .social:hover .social-icon .cl-youtube::before {
      color: #f00; } }
  @media (min-width: 992px) {
    .social:hover .social-icon .cl-instagram::before {
      color: #bc2a8d; } }
  @media (min-width: 992px) {
    .social:hover .social-icon .cl-facebook::before {
      color: #3b5998; } }
  .social .social-icon {
    display: block;
    font-size: 2rem;
    line-height: 16px;
    text-align: center; }
    @media (min-width: 992px) {
      .social .social-icon {
        float: left;
        margin-right: 10px;
        text-align: left; } }
    .social .social-icon .cl-twitter::before {
      color: #00aced; }
      @media (min-width: 992px) {
        .social .social-icon .cl-twitter::before {
          color: #a8b7c7; } }
    .social .social-icon .cl-youtube::before {
      color: #f00; }
      @media (min-width: 992px) {
        .social .social-icon .cl-youtube::before {
          color: #a8b7c7; } }
    .social .social-icon .cl-instagram::before {
      color: #bc2a8d; }
      @media (min-width: 992px) {
        .social .social-icon .cl-instagram::before {
          color: #a8b7c7; } }
    .social .social-icon .cl-facebook::before {
      color: #3b5998; }
      @media (min-width: 992px) {
        .social .social-icon .cl-facebook::before {
          color: #a8b7c7; } }
  .social .social-name {
    display: none;
    padding-left: 2rem;
    font-size: 16px;
    color: #4a4a4a; }
    @media (min-width: 992px) {
      .social .social-name {
        display: block; } }
  .social .social-url {
    display: none;
    padding-left: 2rem;
    font-size: 12px;
    line-height: 16px;
    color: #4a4a4a; }
    @media (min-width: 992px) {
      .social .social-url {
        display: block; } }
  .social.social-sm .social-name,
  .social.social-sm .social-url {
    display: none; }
  .social.social-sm .social-icon {
    float: none;
    margin: 0 auto; }

.breadcrumb {
  min-height: 34px;
  padding-bottom: 0;
  padding-left: 0; }
  .breadcrumb .breadcrumb-item {
    display: inline-block; }
    .breadcrumb .breadcrumb-item:not(:first-child)::before {
      margin: 0 10px; }

nav[aria-label="breadcrumb"] ~ section > .container > .section-header.with-toolbar, nav[aria-label="breadcrumb"] ~ section > .section-social > .section-header.with-toolbar {
  padding-top: 0; }

nav.navbar {
  align-items: initial;
  min-height: 135px; }
  @media (max-width: 768px) {
    nav.navbar {
      min-height: 60px; } }
  nav.navbar .text-md {
    display: none; }
    @media (max-width: 768px) {
      nav.navbar .text-md {
        display: inline-block;
        margin-right: 10px;
        font-size: 16px;
        text-decoration: underline; } }
  nav.navbar .logo {
    display: block; }
    nav.navbar .logo .md-logo {
      display: none; }
    nav.navbar .logo .lg-logo {
      display: block; }
    nav.navbar .logo .lg-logo-text .franja-azul {
      display: block;
      width: 70px;
      height: 15px;
      background-color: #0167b7; }
    nav.navbar .logo .lg-logo-text .franja-roja {
      display: block;
      width: 87px;
      height: 15px;
      background-color: #ee3a41; }
    nav.navbar .logo .logo-title {
      max-width: 183px;
      margin-top: 20px;
      font-family: gobCl;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      white-space: initial; }
    @media (max-width: 768px) {
      nav.navbar .logo .md-logo {
        display: block; }
      nav.navbar .logo .lg-logo {
        display: none; }
      nav.navbar .logo .lg-logo-text .franja-azul {
        width: 54px;
        height: 14px; }
      nav.navbar .logo .lg-logo-text .franja-roja {
        width: 67px;
        height: 14px; }
      nav.navbar .logo .logo-title {
        margin-top: 10px;
        font-size: 16px;
        line-height: 19px; } }
  nav.navbar.bg_light {
    background-color: #fff; }
    @media (max-width: 768px) {
      nav.navbar.bg_light {
        background-color: #eee; } }
  nav.navbar.bg_dark {
    background-color: rgba(0, 28, 65, 0.86); }
    nav.navbar.bg_dark .logo-title {
      color: #fff; }
    nav.navbar.bg_dark .input-navbar {
      border-color: #fff !important; }
    nav.navbar.bg_dark .navbar-primary .nav-link {
      color: #fff; }
      nav.navbar.bg_dark .navbar-primary .nav-link:hover {
        color: #000; }
    @media (max-width: 768px) {
      nav.navbar.bg_dark {
        background-color: #eee; }
        nav.navbar.bg_dark .logo-title {
          color: #111; }
        nav.navbar.bg_dark .navbar-primary .nav-link {
          color: #000; }
          nav.navbar.bg_dark .navbar-primary .nav-link:hover {
            color: #000; } }
  nav.navbar .navbar-collapse .cl-search {
    height: 38px !important;
    color: #fff;
    background-color: #0f69b4;
    border-radius: 0 3px 3px 0; }
  nav.navbar .navbar-collapse .input-navbar {
    border-color: #000;
    border-right: 0;
    border-radius: 3px 0 0 3px; }
  nav.navbar .navbar-collapse .navbar-primary > li a:hover {
    background-color: #eee; }
  @media (max-width: 768px) {
    nav.navbar .navbar-collapse .navbar-primary .border-md-0 {
      border: 0 !important; }
    nav.navbar .navbar-collapse .navbar-primary .dropdown-menu {
      background-color: #eee; }
      nav.navbar .navbar-collapse .navbar-primary .dropdown-menu a {
        padding-left: 40px; }
    nav.navbar .navbar-collapse .navbar-primary > li a:hover {
      background-color: #d5d5d5; }
    nav.navbar .navbar-collapse .navbar-primary .input-navbar {
      width: 85%; } }
  @media (max-width: 768px) {
    nav.navbar .navbar-collapse .navbar-top {
      border-bottom: 1px solid #999; }
      nav.navbar .navbar-collapse .navbar-top > li a:hover {
        background-color: #d5d5d5; } }
  @media (max-width: 768px) {
    nav.navbar .navbar-collapse {
      margin-bottom: 50px; } }
  @media (min-width: 992px) {
    nav.navbar .navbar-primary {
      position: relative;
      top: 30px; }
    nav.navbar .navbar-top {
      position: absolute;
      top: 0; }
      nav.navbar .navbar-top li:last-child a {
        text-decoration: none; }
      nav.navbar .navbar-top .btn-navbar {
        padding: 8px 15px;
        margin-left: 10px;
        font-family: Roboto, sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #001c41;
        border: 1px solid transparent;
        border-radius: 0;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }
        nav.navbar .navbar-top .btn-navbar:hover {
          color: #fff; }
        nav.navbar .navbar-top .btn-navbar .cl-claveunica {
          margin-right: 5px;
          font-size: 20px;
          color: #fff; } }

nav.navbar.navbar-fr {
  min-height: 54px;
  padding: 0;
  box-shadow: 0 2px 0 0 #e3e5ef; }
  nav.navbar.navbar-fr .container, nav.navbar.navbar-fr .section-social {
    position: relative;
    padding: 0 15px; }
    nav.navbar.navbar-fr .container a.navbar-brand img, nav.navbar.navbar-fr .section-social a.navbar-brand img {
      position: absolute;
      top: 0;
      width: 115px; }
  @media (min-width: 992px) {
    nav.navbar.navbar-fr {
      min-height: 69px; } }
  nav.navbar.navbar-fr .navbar-brand {
    min-height: 54px;
    text-decoration: none; }
    @media (min-width: 992px) {
      nav.navbar.navbar-fr .navbar-brand {
        min-height: 69px; } }
  nav.navbar.navbar-fr .navbar-toggler {
    min-height: 54px;
    padding: 0.25rem 12px;
    font-size: 20px;
    border: none; }
    @media (min-width: 992px) {
      nav.navbar.navbar-fr .navbar-toggler {
        min-height: 69px; } }
    nav.navbar.navbar-fr .navbar-toggler:focus {
      outline: none; }
  nav.navbar.navbar-fr.navbar-expand-xs .navbar-nav {
    height: 69px; }
    nav.navbar.navbar-fr.navbar-expand-xs .navbar-nav .nav-separator,
    nav.navbar.navbar-fr.navbar-expand-xs .navbar-nav .nav-behavior {
      display: block; }
    nav.navbar.navbar-fr.navbar-expand-xs .navbar-nav .nav-item .nav-link {
      height: auto;
      padding: 8px 14.4px;
      line-height: 28px;
      height: 69px;
      padding: 22.4px 14.4px; }
  @media (min-width: 576px) {
    nav.navbar.navbar-fr.navbar-expand-sm .navbar-nav {
      height: 69px; } }
  @media (min-width: 576px) {
    nav.navbar.navbar-fr.navbar-expand-sm .navbar-nav .nav-separator,
    nav.navbar.navbar-fr.navbar-expand-sm .navbar-nav .nav-behavior {
      display: block; } }
  nav.navbar.navbar-fr.navbar-expand-sm .navbar-nav .nav-item .nav-link {
    height: auto;
    padding: 8px 14.4px;
    line-height: 28px; }
    @media (min-width: 576px) {
      nav.navbar.navbar-fr.navbar-expand-sm .navbar-nav .nav-item .nav-link {
        height: 69px;
        padding: 22.4px 14.4px; } }
  @media (min-width: 768px) {
    nav.navbar.navbar-fr.navbar-expand-md .navbar-nav {
      height: 69px; } }
  @media (min-width: 768px) {
    nav.navbar.navbar-fr.navbar-expand-md .navbar-nav .nav-separator,
    nav.navbar.navbar-fr.navbar-expand-md .navbar-nav .nav-behavior {
      display: block; } }
  nav.navbar.navbar-fr.navbar-expand-md .navbar-nav .nav-item .nav-link {
    height: auto;
    padding: 8px 14.4px;
    line-height: 28px; }
    @media (min-width: 768px) {
      nav.navbar.navbar-fr.navbar-expand-md .navbar-nav .nav-item .nav-link {
        height: 69px;
        padding: 22.4px 14.4px; } }
  @media (min-width: 992px) {
    nav.navbar.navbar-fr.navbar-expand-lg .navbar-nav {
      height: 69px; } }
  @media (min-width: 992px) {
    nav.navbar.navbar-fr.navbar-expand-lg .navbar-nav .nav-separator,
    nav.navbar.navbar-fr.navbar-expand-lg .navbar-nav .nav-behavior {
      display: block; } }
  nav.navbar.navbar-fr.navbar-expand-lg .navbar-nav .nav-item .nav-link {
    height: auto;
    padding: 8px 14.4px;
    line-height: 28px; }
    @media (min-width: 992px) {
      nav.navbar.navbar-fr.navbar-expand-lg .navbar-nav .nav-item .nav-link {
        height: 69px;
        padding: 22.4px 14.4px; } }
  @media (min-width: 1200px) {
    nav.navbar.navbar-fr.navbar-expand-xl .navbar-nav {
      height: 69px; } }
  @media (min-width: 1200px) {
    nav.navbar.navbar-fr.navbar-expand-xl .navbar-nav .nav-separator,
    nav.navbar.navbar-fr.navbar-expand-xl .navbar-nav .nav-behavior {
      display: block; } }
  nav.navbar.navbar-fr.navbar-expand-xl .navbar-nav .nav-item .nav-link {
    height: auto;
    padding: 8px 14.4px;
    line-height: 28px; }
    @media (min-width: 1200px) {
      nav.navbar.navbar-fr.navbar-expand-xl .navbar-nav .nav-item .nav-link {
        height: 69px;
        padding: 22.4px 14.4px; } }
  nav.navbar.navbar-fr .navbar-nav .nav-separator {
    display: none;
    width: 1px;
    height: 1.5625rem;
    margin: 22.4px 0;
    background-color: #0a132d; }
  nav.navbar.navbar-fr .navbar-nav .nav-behavior {
    display: none;
    height: 69px;
    padding: 22.4px 14.4px; }
    nav.navbar.navbar-fr .navbar-nav .nav-behavior .nav-link {
      width: 1.5625rem;
      height: 1.5625rem;
      padding: 0;
      font-size: 0.6875rem;
      line-height: 1.5625rem;
      color: #a8b7c7;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      border: 1px solid #a8b7c7; }
      nav.navbar.navbar-fr .navbar-nav .nav-behavior .nav-link:active, nav.navbar.navbar-fr .navbar-nav .nav-behavior .nav-link.active {
        background-color: #fff;
        border-color: #006fb3; }
      nav.navbar.navbar-fr .navbar-nav .nav-behavior .nav-link:hover {
        color: #006fb3;
        border-color: #006fb3; }
        nav.navbar.navbar-fr .navbar-nav .nav-behavior .nav-link:hover .cl::before {
          color: #006fb3; }
  nav.navbar.navbar-fr .navbar-nav .nav-item .nav-link {
    padding: 8px 14.4px;
    font-size: 1rem;
    line-height: 28px; }
    @media (min-width: 992px) {
      nav.navbar.navbar-fr .navbar-nav .nav-item .nav-link {
        height: 69px;
        padding: 22.4px 14.4px; } }
    nav.navbar.navbar-fr .navbar-nav .nav-item .nav-link:hover {
      text-decoration: underline; }
  nav.navbar.navbar-fr .navbar-nav .nav-item:not(.dropdown) .nav-link:hover {
    color: #006fb3; }
  nav.navbar.navbar-fr .navbar-nav .nav-item.dropdown .dropdown-menu {
    margin: 0;
    border: none; }
    nav.navbar.navbar-fr .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item {
      text-decoration: underline; }
  nav.navbar.navbar-fr .navbar-nav .nav-item.dropdown.show .dropdown-toggle {
    color: #fff;
    background-color: #006fb3; }
  nav.navbar.navbar-fr .navbar-nav .nav-item.dropdown.show .dropdown-menu.show {
    background-color: #006fb3; }
    nav.navbar.navbar-fr .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item {
      padding: 0.25rem 0.9rem;
      color: #fff; }
      nav.navbar.navbar-fr .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item:hover {
        color: #fff;
        background-color: #0a132d; }

main {
  margin-top: 54px; }
  @media (min-width: 992px) {
    main {
      margin-top: 69px; } }

main {
  padding-bottom: 1.5rem; }

section.section .section-header {
  position: relative; }
  section.section .section-header .section-icons {
    position: absolute;
    right: 0;
    bottom: 1rem; }
    section.section .section-header .section-icons .link-icon {
      margin-left: 1rem; }

section.section .section-action {
  padding-top: 1.5rem;
  text-align: center; }

section .section-header.with-toolbar {
  padding: 2.125rem 0 1rem; }
  section .section-header.with-toolbar::after {
    display: block;
    clear: both;
    content: ""; }
  section .section-header.with-toolbar .toolbar {
    float: right; }
    @media (min-width: 768px) {
      section .section-header.with-toolbar .toolbar {
        float: none; } }
    @media (min-width: 992px) {
      section .section-header.with-toolbar .toolbar {
        float: right; } }

section.section-jumbotron > .container > .section-body:not(.unstyled), section.section-jumbotron > .section-social > .section-body:not(.unstyled) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center; }
  section.section-jumbotron > .container > .section-body:not(.unstyled) > *:not(.line), section.section-jumbotron > .section-social > .section-body:not(.unstyled) > *:not(.line) {
    max-width: 100%; }

section.section-jumbotron.section-jumbotron-lg h1 {
  font-size: 1.5rem;
  line-height: 3.6rem; }
  @media (min-width: 992px) {
    section.section-jumbotron.section-jumbotron-lg h1 {
      font-size: 3rem;
      line-height: 3.6rem; } }

section.section-jumbotron.section-jumbotron-lg p {
  font-size: 0.9rem;
  line-height: 1.0625rem; }
  @media (min-width: 992px) {
    section.section-jumbotron.section-jumbotron-lg p {
      font-size: 1.25rem;
      line-height: 1.5rem; } }

.section-social {
  position: relative;
  padding-top: 1.5rem; }
  .section-social::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: "";
    border-top: 1px solid #a8b7c7; }
    @media (min-width: 992px) {
      .section-social::before {
        right: 15px;
        left: 15px; } }

section.contingency {
  z-index: 1010;
  display: none;
  flex-direction: column;
  min-height: calc(100vh - 54px);
  background-color: #fff; }
  section.contingency .contingency-body {
    height: calc(100vh - 114px);
    overflow-x: hidden;
    overflow-y: auto; }
    @media (min-width: 992px) {
      section.contingency .contingency-body {
        height: calc(100vh - 129px); } }
    section.contingency .contingency-body > section.section-jumbotron .section-body .line::after {
      height: 2.5px;
      background: #111; }
    section.contingency .contingency-body > section.section .section-header h2 {
      color: #4a4a4a; }
  section.contingency .contingency-footer {
    height: 60px; }
    section.contingency .contingency-footer .container, section.contingency .contingency-footer .section-social {
      position: relative;
      height: 60px; }
      section.contingency .contingency-footer .container .footer-logo, section.contingency .contingency-footer .section-social .footer-logo {
        position: absolute;
        bottom: 0; }
  section.contingency .contingency-event {
    margin: 3rem 0 0; }
    section.contingency .contingency-event .contingency-event-date {
      float: left;
      width: 70px; }
      section.contingency .contingency-event .contingency-event-date .contingency-event-time {
        font-size: 14px;
        opacity: 0.6; }
      section.contingency .contingency-event .contingency-event-date .contingency-event-day {
        font-size: 10px;
        opacity: 0.6; }
    section.contingency .contingency-event .contingency-event-body {
      display: block;
      padding-left: 70px; }

.contingency-active:not(.contingency-closed) {
  padding-right: 15px;
  overflow: hidden; }
  .contingency-active:not(.contingency-closed) section.contingency {
    position: fixed;
    top: 54px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex; }
    @media (min-width: 992px) {
      .contingency-active:not(.contingency-closed) section.contingency {
        top: 69px; } }

section.onboarding {
  z-index: 1090;
  display: none;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; }
  section.onboarding .onboarding-body {
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto; }
    @media (min-width: 992px) {
      section.onboarding .onboarding-body {
        height: calc(100vh - 60px); } }
  section.onboarding .onboarding-footer {
    height: 60px; }
    section.onboarding .onboarding-footer .container, section.onboarding .onboarding-footer .section-social {
      position: relative;
      height: 60px; }
      section.onboarding .onboarding-footer .container .footer-logo, section.onboarding .onboarding-footer .section-social .footer-logo {
        position: absolute;
        bottom: 0; }

.onboarding-active:not(.onboarding-closed) {
  padding-right: 15px;
  overflow: hidden; }
  .onboarding-active:not(.onboarding-closed) section.onboarding {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex; }

section.redirecting {
  z-index: 1100;
  display: none;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; }
  section.redirecting .redirecting-body {
    min-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto; }
    @media (min-width: 992px) {
      section.redirecting .redirecting-body {
        min-height: calc(100vh - 60px); } }
  section.redirecting .redirecting-footer {
    height: 60px; }
    section.redirecting .redirecting-footer .container, section.redirecting .redirecting-footer .section-social {
      position: relative;
      height: 60px; }
      section.redirecting .redirecting-footer .container .footer-logo, section.redirecting .redirecting-footer .section-social .footer-logo {
        position: absolute;
        bottom: 0; }
  section.redirecting.show {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex; }

.arrow-loading .cl {
  font-size: 1.125rem; }
  .arrow-loading .cl:nth-child(1) {
    opacity: .1; }
  .arrow-loading .cl:nth-child(2) {
    opacity: .5; }
  .arrow-loading .cl:nth-child(3) {
    opacity: .75; }

.section-nav {
  padding-left: 0; }
  .section-nav ul {
    padding-left: 1rem; }
    .section-nav ul ul {
      display: none; }

.toc-entry {
  display: block; }
  .toc-entry a {
    display: block;
    padding: 0.125rem 1.5rem; }
    .toc-entry a:hover {
      color: #006fb3;
      text-decoration: none; }

.bd-sidebar {
  order: 0; }
  @media (min-width: 768px) {
    @supports (position: sticky) {
      .bd-sidebar {
        position: sticky;
        top: 69px;
        z-index: 1000;
        height: calc(100vh - 69px); } } }
  @media (min-width: 768px) {
    .bd-sidebar {
      flex: 0 1 320px; } }

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 768px) {
    .bd-links {
      display: block !important;
      /* stylelint-disable-line */ }
      @supports (position: sticky) {
        .bd-links {
          max-height: calc(100vh - 4rem);
          overflow-y: auto; } } }

.bd-search-docs-toggle {
  line-height: 1;
  color: #212529; }

.bd-sidenav {
  display: none; }

.bd-toc-link {
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none; }
  .bd-toc-link:hover {
    color: #006fb3;
    background-color: #fff; }

.bd-toc-item {
  padding: 10px 0;
  border-top: 1px solid #eee; }
  .bd-toc-item:first-child {
    margin-top: 0; }
  .bd-toc-item:last-child {
    border-bottom: 1px solid #eee; }
  .bd-toc-item.active {
    background-color: #fff; }
    .bd-toc-item.active > .bd-toc-link {
      color: #006fb3; }
      .bd-toc-item.active > .bd-toc-link:hover {
        background-color: transparent; }
    .bd-toc-item.active > .bd-sidenav {
      display: block; }

.bd-sidebar .nav > li > a {
  display: block;
  padding: 0.25rem 1.5rem;
  font-size: 0.9rem;
  color: rgba(17, 17, 17, 0.6); }

.bd-sidebar .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent; }

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  background-color: transparent; }

footer {
  position: relative;
  display: block;
  width: 100%;
  padding: 40px 0 50px;
  overflow: hidden;
  background-color: #0a132d; }
  footer ol {
    list-style: none; }
  footer .font-sm-footer {
    font-size: 14px; }
  footer .footer-icon {
    display: block;
    width: 30px;
    height: 30px; }
    footer .footer-icon.footer-icon-cc {
      background: url("../img/icon-cc.svg"); }
    footer .footer-icon.footer-icon-person {
      background: url("../img/icon-person.svg"); }
  footer .borderlgleft {
    border-left: 1px solid #fff; }
  footer .bordersmbottom {
    border-bottom: 0; }
  footer .bordersmleft {
    border-left: 1px solid #fff; }
  @media (max-width: 768px) {
    footer .borderlgleft {
      border-left: 0 !important; }
    footer .bordersmbottom {
      border-bottom: 1px solid #fff; }
    footer .md-pt {
      padding-top: 30px; } }
  @media (max-width: 480px) {
    footer .bordersmleft {
      border-left: 0 !important; } }
  @media (min-width: 768px) {
    footer {
      height: auto;
      padding: 40px 0 50px; } }
  @media (min-width: 992px) {
    footer {
      padding: 40px 0 50px; } }
  @media (min-width: 768px) {
    footer > .container > .row, footer > .section-social > .row, footer > .container > .card-data-list, footer > .section-social > .card-data-list, footer > .container > .card-featured-news, footer > .section-social > .card-featured-news, footer > .container > .profile, footer > .section-social > .profile, footer > .container > .profile-page, footer > .section-social > .profile-page, footer > .container > .profile-section, footer > .section-social > .profile-section,
    footer > .row,
    footer > .card-data-list,
    footer > .card-featured-news,
    footer > .profile,
    footer > .profile-page,
    footer > .profile-section {
      display: flex; } }
  footer.footer-expand-xs {
    height: auto;
    padding: 0;
    padding: 40px 0 50px; }
    footer.footer-expand-xs > .container > .row, footer.footer-expand-xs > .section-social > .row, footer.footer-expand-xs > .container > .card-data-list, footer.footer-expand-xs > .section-social > .card-data-list, footer.footer-expand-xs > .container > .card-featured-news, footer.footer-expand-xs > .section-social > .card-featured-news, footer.footer-expand-xs > .container > .profile, footer.footer-expand-xs > .section-social > .profile, footer.footer-expand-xs > .container > .profile-page, footer.footer-expand-xs > .section-social > .profile-page, footer.footer-expand-xs > .container > .profile-section, footer.footer-expand-xs > .section-social > .profile-section,
    footer.footer-expand-xs > .row,
    footer.footer-expand-xs > .card-data-list,
    footer.footer-expand-xs > .card-featured-news,
    footer.footer-expand-xs > .profile,
    footer.footer-expand-xs > .profile-page,
    footer.footer-expand-xs > .profile-section {
      display: none;
      display: flex; }
    footer.footer-expand-xs .footer-logo .footer-default-logo {
      display: none;
      display: block; }
    footer.footer-expand-xs .footer-logo .footer-mobile-logo {
      display: block;
      display: none; }
  footer.footer-expand-sm {
    height: auto;
    padding: 0; }
    @media (min-width: 576px) {
      footer.footer-expand-sm {
        padding: 40px 0 50px; } }
    footer.footer-expand-sm > .container > .row, footer.footer-expand-sm > .section-social > .row, footer.footer-expand-sm > .container > .card-data-list, footer.footer-expand-sm > .section-social > .card-data-list, footer.footer-expand-sm > .container > .card-featured-news, footer.footer-expand-sm > .section-social > .card-featured-news, footer.footer-expand-sm > .container > .profile, footer.footer-expand-sm > .section-social > .profile, footer.footer-expand-sm > .container > .profile-page, footer.footer-expand-sm > .section-social > .profile-page, footer.footer-expand-sm > .container > .profile-section, footer.footer-expand-sm > .section-social > .profile-section,
    footer.footer-expand-sm > .row,
    footer.footer-expand-sm > .card-data-list,
    footer.footer-expand-sm > .card-featured-news,
    footer.footer-expand-sm > .profile,
    footer.footer-expand-sm > .profile-page,
    footer.footer-expand-sm > .profile-section {
      display: none; }
      @media (min-width: 576px) {
        footer.footer-expand-sm > .container > .row, footer.footer-expand-sm > .section-social > .row, footer.footer-expand-sm > .container > .card-data-list, footer.footer-expand-sm > .section-social > .card-data-list, footer.footer-expand-sm > .container > .card-featured-news, footer.footer-expand-sm > .section-social > .card-featured-news, footer.footer-expand-sm > .container > .profile, footer.footer-expand-sm > .section-social > .profile, footer.footer-expand-sm > .container > .profile-page, footer.footer-expand-sm > .section-social > .profile-page, footer.footer-expand-sm > .container > .profile-section, footer.footer-expand-sm > .section-social > .profile-section,
        footer.footer-expand-sm > .row,
        footer.footer-expand-sm > .card-data-list,
        footer.footer-expand-sm > .card-featured-news,
        footer.footer-expand-sm > .profile,
        footer.footer-expand-sm > .profile-page,
        footer.footer-expand-sm > .profile-section {
          display: flex; } }
    footer.footer-expand-sm .footer-logo .footer-default-logo {
      display: none; }
      @media (min-width: 576px) {
        footer.footer-expand-sm .footer-logo .footer-default-logo {
          display: block; } }
    footer.footer-expand-sm .footer-logo .footer-mobile-logo {
      display: block; }
      @media (min-width: 576px) {
        footer.footer-expand-sm .footer-logo .footer-mobile-logo {
          display: none; } }
  footer.footer-expand-md {
    height: auto;
    padding: 0; }
    @media (min-width: 768px) {
      footer.footer-expand-md {
        padding: 40px 0 50px; } }
    footer.footer-expand-md > .container > .row, footer.footer-expand-md > .section-social > .row, footer.footer-expand-md > .container > .card-data-list, footer.footer-expand-md > .section-social > .card-data-list, footer.footer-expand-md > .container > .card-featured-news, footer.footer-expand-md > .section-social > .card-featured-news, footer.footer-expand-md > .container > .profile, footer.footer-expand-md > .section-social > .profile, footer.footer-expand-md > .container > .profile-page, footer.footer-expand-md > .section-social > .profile-page, footer.footer-expand-md > .container > .profile-section, footer.footer-expand-md > .section-social > .profile-section,
    footer.footer-expand-md > .row,
    footer.footer-expand-md > .card-data-list,
    footer.footer-expand-md > .card-featured-news,
    footer.footer-expand-md > .profile,
    footer.footer-expand-md > .profile-page,
    footer.footer-expand-md > .profile-section {
      display: none; }
      @media (min-width: 768px) {
        footer.footer-expand-md > .container > .row, footer.footer-expand-md > .section-social > .row, footer.footer-expand-md > .container > .card-data-list, footer.footer-expand-md > .section-social > .card-data-list, footer.footer-expand-md > .container > .card-featured-news, footer.footer-expand-md > .section-social > .card-featured-news, footer.footer-expand-md > .container > .profile, footer.footer-expand-md > .section-social > .profile, footer.footer-expand-md > .container > .profile-page, footer.footer-expand-md > .section-social > .profile-page, footer.footer-expand-md > .container > .profile-section, footer.footer-expand-md > .section-social > .profile-section,
        footer.footer-expand-md > .row,
        footer.footer-expand-md > .card-data-list,
        footer.footer-expand-md > .card-featured-news,
        footer.footer-expand-md > .profile,
        footer.footer-expand-md > .profile-page,
        footer.footer-expand-md > .profile-section {
          display: flex; } }
    footer.footer-expand-md .footer-logo .footer-default-logo {
      display: none; }
      @media (min-width: 768px) {
        footer.footer-expand-md .footer-logo .footer-default-logo {
          display: block; } }
    footer.footer-expand-md .footer-logo .footer-mobile-logo {
      display: block; }
      @media (min-width: 768px) {
        footer.footer-expand-md .footer-logo .footer-mobile-logo {
          display: none; } }
  footer.footer-expand-lg {
    height: auto;
    padding: 0; }
    @media (min-width: 992px) {
      footer.footer-expand-lg {
        padding: 40px 0 50px; } }
    footer.footer-expand-lg > .container > .row, footer.footer-expand-lg > .section-social > .row, footer.footer-expand-lg > .container > .card-data-list, footer.footer-expand-lg > .section-social > .card-data-list, footer.footer-expand-lg > .container > .card-featured-news, footer.footer-expand-lg > .section-social > .card-featured-news, footer.footer-expand-lg > .container > .profile, footer.footer-expand-lg > .section-social > .profile, footer.footer-expand-lg > .container > .profile-page, footer.footer-expand-lg > .section-social > .profile-page, footer.footer-expand-lg > .container > .profile-section, footer.footer-expand-lg > .section-social > .profile-section,
    footer.footer-expand-lg > .row,
    footer.footer-expand-lg > .card-data-list,
    footer.footer-expand-lg > .card-featured-news,
    footer.footer-expand-lg > .profile,
    footer.footer-expand-lg > .profile-page,
    footer.footer-expand-lg > .profile-section {
      display: none; }
      @media (min-width: 992px) {
        footer.footer-expand-lg > .container > .row, footer.footer-expand-lg > .section-social > .row, footer.footer-expand-lg > .container > .card-data-list, footer.footer-expand-lg > .section-social > .card-data-list, footer.footer-expand-lg > .container > .card-featured-news, footer.footer-expand-lg > .section-social > .card-featured-news, footer.footer-expand-lg > .container > .profile, footer.footer-expand-lg > .section-social > .profile, footer.footer-expand-lg > .container > .profile-page, footer.footer-expand-lg > .section-social > .profile-page, footer.footer-expand-lg > .container > .profile-section, footer.footer-expand-lg > .section-social > .profile-section,
        footer.footer-expand-lg > .row,
        footer.footer-expand-lg > .card-data-list,
        footer.footer-expand-lg > .card-featured-news,
        footer.footer-expand-lg > .profile,
        footer.footer-expand-lg > .profile-page,
        footer.footer-expand-lg > .profile-section {
          display: flex; } }
    footer.footer-expand-lg .footer-logo .footer-default-logo {
      display: none; }
      @media (min-width: 992px) {
        footer.footer-expand-lg .footer-logo .footer-default-logo {
          display: block; } }
    footer.footer-expand-lg .footer-logo .footer-mobile-logo {
      display: block; }
      @media (min-width: 992px) {
        footer.footer-expand-lg .footer-logo .footer-mobile-logo {
          display: none; } }
  footer.footer-expand-xl {
    height: auto;
    padding: 0; }
    @media (min-width: 1200px) {
      footer.footer-expand-xl {
        padding: 40px 0 50px; } }
    footer.footer-expand-xl > .container > .row, footer.footer-expand-xl > .section-social > .row, footer.footer-expand-xl > .container > .card-data-list, footer.footer-expand-xl > .section-social > .card-data-list, footer.footer-expand-xl > .container > .card-featured-news, footer.footer-expand-xl > .section-social > .card-featured-news, footer.footer-expand-xl > .container > .profile, footer.footer-expand-xl > .section-social > .profile, footer.footer-expand-xl > .container > .profile-page, footer.footer-expand-xl > .section-social > .profile-page, footer.footer-expand-xl > .container > .profile-section, footer.footer-expand-xl > .section-social > .profile-section,
    footer.footer-expand-xl > .row,
    footer.footer-expand-xl > .card-data-list,
    footer.footer-expand-xl > .card-featured-news,
    footer.footer-expand-xl > .profile,
    footer.footer-expand-xl > .profile-page,
    footer.footer-expand-xl > .profile-section {
      display: none; }
      @media (min-width: 1200px) {
        footer.footer-expand-xl > .container > .row, footer.footer-expand-xl > .section-social > .row, footer.footer-expand-xl > .container > .card-data-list, footer.footer-expand-xl > .section-social > .card-data-list, footer.footer-expand-xl > .container > .card-featured-news, footer.footer-expand-xl > .section-social > .card-featured-news, footer.footer-expand-xl > .container > .profile, footer.footer-expand-xl > .section-social > .profile, footer.footer-expand-xl > .container > .profile-page, footer.footer-expand-xl > .section-social > .profile-page, footer.footer-expand-xl > .container > .profile-section, footer.footer-expand-xl > .section-social > .profile-section,
        footer.footer-expand-xl > .row,
        footer.footer-expand-xl > .card-data-list,
        footer.footer-expand-xl > .card-featured-news,
        footer.footer-expand-xl > .profile,
        footer.footer-expand-xl > .profile-page,
        footer.footer-expand-xl > .profile-section {
          display: flex; } }
    footer.footer-expand-xl .footer-logo .footer-default-logo {
      display: none; }
      @media (min-width: 1200px) {
        footer.footer-expand-xl .footer-logo .footer-default-logo {
          display: block; } }
    footer.footer-expand-xl .footer-logo .footer-mobile-logo {
      display: block; }
      @media (min-width: 1200px) {
        footer.footer-expand-xl .footer-logo .footer-mobile-logo {
          display: none; } }
  footer .footer-logo {
    position: absolute;
    bottom: 0; }
    footer .footer-logo .footer-default-logo {
      display: none; }
      @media (min-width: 768px) {
        footer .footer-logo .footer-default-logo {
          display: block; } }
    footer .footer-logo .footer-mobile-logo {
      display: block; }
      @media (min-width: 768px) {
        footer .footer-logo .footer-mobile-logo {
          display: none; } }
  footer .footer-social {
    padding-left: 0;
    text-align: center; }
    @media (min-width: 992px) {
      footer .footer-social {
        display: flex;
        justify-content: space-between;
        width: 100%; } }
    footer .footer-social li {
      display: inline-block;
      margin-bottom: 10px; }
      footer .footer-social li:not(:first-child) {
        margin-left: 10px; }
      footer .footer-social li a {
        font-size: 32px;
        color: #eee;
        text-align: center; }
  footer .footer-links a {
    display: block;
    padding: 10px 40px 10px 0;
    color: #fff;
    width: 100%;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media (min-width: 768px) {
      footer .footer-links a {
        padding: 10px 0; } }

.footer-logo .footer-default-logo {
  display: none;
  width: 236px; }
  @media (min-width: 768px) {
    .footer-logo .footer-default-logo {
      display: block; } }

.footer-logo .footer-mobile-logo {
  display: block; }
  @media (min-width: 768px) {
    .footer-logo .footer-mobile-logo {
      display: none; } }

body.footer-links-0 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-0 main {
      min-height: calc(100vh - 314px); } }
  @media (min-width: 1200px) {
    body.footer-links-0 main {
      min-height: calc(100vh - 292px); } }

body.footer-links-0 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-0 footer.footer-expand-xs > .container > .row, body.footer-links-0 footer.footer-expand-xs > .section-social > .row, body.footer-links-0 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-0 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-xs > .container > .profile, body.footer-links-0 footer.footer-expand-xs > .section-social > .profile, body.footer-links-0 footer.footer-expand-xs > .container > .profile-page, body.footer-links-0 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-xs > .container > .profile-section, body.footer-links-0 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-0 footer.footer-expand-xs > .row,
  body.footer-links-0 footer.footer-expand-xs > .card-data-list,
  body.footer-links-0 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-0 footer.footer-expand-xs > .profile,
  body.footer-links-0 footer.footer-expand-xs > .profile-page,
  body.footer-links-0 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-0 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-0 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-0 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-0 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-0 footer.footer-expand-sm > .container > .row, body.footer-links-0 footer.footer-expand-sm > .section-social > .row, body.footer-links-0 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-0 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-sm > .container > .profile, body.footer-links-0 footer.footer-expand-sm > .section-social > .profile, body.footer-links-0 footer.footer-expand-sm > .container > .profile-page, body.footer-links-0 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-sm > .container > .profile-section, body.footer-links-0 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-0 footer.footer-expand-sm > .row,
  body.footer-links-0 footer.footer-expand-sm > .card-data-list,
  body.footer-links-0 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-0 footer.footer-expand-sm > .profile,
  body.footer-links-0 footer.footer-expand-sm > .profile-page,
  body.footer-links-0 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-0 footer.footer-expand-sm > .container > .row, body.footer-links-0 footer.footer-expand-sm > .section-social > .row, body.footer-links-0 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-0 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-sm > .container > .profile, body.footer-links-0 footer.footer-expand-sm > .section-social > .profile, body.footer-links-0 footer.footer-expand-sm > .container > .profile-page, body.footer-links-0 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-sm > .container > .profile-section, body.footer-links-0 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-0 footer.footer-expand-sm > .row,
      body.footer-links-0 footer.footer-expand-sm > .card-data-list,
      body.footer-links-0 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-0 footer.footer-expand-sm > .profile,
      body.footer-links-0 footer.footer-expand-sm > .profile-page,
      body.footer-links-0 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-0 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-0 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-0 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-0 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-0 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-0 footer.footer-expand-md {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-0 footer.footer-expand-md > .container > .row, body.footer-links-0 footer.footer-expand-md > .section-social > .row, body.footer-links-0 footer.footer-expand-md > .container > .card-data-list, body.footer-links-0 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-md > .container > .profile, body.footer-links-0 footer.footer-expand-md > .section-social > .profile, body.footer-links-0 footer.footer-expand-md > .container > .profile-page, body.footer-links-0 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-md > .container > .profile-section, body.footer-links-0 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-0 footer.footer-expand-md > .row,
  body.footer-links-0 footer.footer-expand-md > .card-data-list,
  body.footer-links-0 footer.footer-expand-md > .card-featured-news,
  body.footer-links-0 footer.footer-expand-md > .profile,
  body.footer-links-0 footer.footer-expand-md > .profile-page,
  body.footer-links-0 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-0 footer.footer-expand-md > .container > .row, body.footer-links-0 footer.footer-expand-md > .section-social > .row, body.footer-links-0 footer.footer-expand-md > .container > .card-data-list, body.footer-links-0 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-md > .container > .profile, body.footer-links-0 footer.footer-expand-md > .section-social > .profile, body.footer-links-0 footer.footer-expand-md > .container > .profile-page, body.footer-links-0 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-md > .container > .profile-section, body.footer-links-0 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-0 footer.footer-expand-md > .row,
      body.footer-links-0 footer.footer-expand-md > .card-data-list,
      body.footer-links-0 footer.footer-expand-md > .card-featured-news,
      body.footer-links-0 footer.footer-expand-md > .profile,
      body.footer-links-0 footer.footer-expand-md > .profile-page,
      body.footer-links-0 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-0 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-0 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-0 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-0 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-0 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-0 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-0 footer.footer-expand-lg > .container > .row, body.footer-links-0 footer.footer-expand-lg > .section-social > .row, body.footer-links-0 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-0 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-lg > .container > .profile, body.footer-links-0 footer.footer-expand-lg > .section-social > .profile, body.footer-links-0 footer.footer-expand-lg > .container > .profile-page, body.footer-links-0 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-lg > .container > .profile-section, body.footer-links-0 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-0 footer.footer-expand-lg > .row,
  body.footer-links-0 footer.footer-expand-lg > .card-data-list,
  body.footer-links-0 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-0 footer.footer-expand-lg > .profile,
  body.footer-links-0 footer.footer-expand-lg > .profile-page,
  body.footer-links-0 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-0 footer.footer-expand-lg > .container > .row, body.footer-links-0 footer.footer-expand-lg > .section-social > .row, body.footer-links-0 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-0 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-lg > .container > .profile, body.footer-links-0 footer.footer-expand-lg > .section-social > .profile, body.footer-links-0 footer.footer-expand-lg > .container > .profile-page, body.footer-links-0 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-lg > .container > .profile-section, body.footer-links-0 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-0 footer.footer-expand-lg > .row,
      body.footer-links-0 footer.footer-expand-lg > .card-data-list,
      body.footer-links-0 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-0 footer.footer-expand-lg > .profile,
      body.footer-links-0 footer.footer-expand-lg > .profile-page,
      body.footer-links-0 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-0 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-0 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-0 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-0 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-0 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-0 footer.footer-expand-xl {
      height: 223px;
      padding: 40px 0 50px; } }
  body.footer-links-0 footer.footer-expand-xl > .container > .row, body.footer-links-0 footer.footer-expand-xl > .section-social > .row, body.footer-links-0 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-0 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-xl > .container > .profile, body.footer-links-0 footer.footer-expand-xl > .section-social > .profile, body.footer-links-0 footer.footer-expand-xl > .container > .profile-page, body.footer-links-0 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-xl > .container > .profile-section, body.footer-links-0 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-0 footer.footer-expand-xl > .row,
  body.footer-links-0 footer.footer-expand-xl > .card-data-list,
  body.footer-links-0 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-0 footer.footer-expand-xl > .profile,
  body.footer-links-0 footer.footer-expand-xl > .profile-page,
  body.footer-links-0 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-0 footer.footer-expand-xl > .container > .row, body.footer-links-0 footer.footer-expand-xl > .section-social > .row, body.footer-links-0 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-0 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-0 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-0 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-0 footer.footer-expand-xl > .container > .profile, body.footer-links-0 footer.footer-expand-xl > .section-social > .profile, body.footer-links-0 footer.footer-expand-xl > .container > .profile-page, body.footer-links-0 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-0 footer.footer-expand-xl > .container > .profile-section, body.footer-links-0 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-0 footer.footer-expand-xl > .row,
      body.footer-links-0 footer.footer-expand-xl > .card-data-list,
      body.footer-links-0 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-0 footer.footer-expand-xl > .profile,
      body.footer-links-0 footer.footer-expand-xl > .profile-page,
      body.footer-links-0 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-0 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-0 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-0 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-0 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-0 footer .footer-links {
  height: 0px; }
  @media (min-width: 768px) {
    body.footer-links-0 footer .footer-links {
      height: 0px; } }

body.footer-links-1 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-1 main {
      min-height: calc(100vh - 358px); } }
  @media (min-width: 1200px) {
    body.footer-links-1 main {
      min-height: calc(100vh - 292px); } }

body.footer-links-1 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-1 footer.footer-expand-xs > .container > .row, body.footer-links-1 footer.footer-expand-xs > .section-social > .row, body.footer-links-1 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-1 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-xs > .container > .profile, body.footer-links-1 footer.footer-expand-xs > .section-social > .profile, body.footer-links-1 footer.footer-expand-xs > .container > .profile-page, body.footer-links-1 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-xs > .container > .profile-section, body.footer-links-1 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-1 footer.footer-expand-xs > .row,
  body.footer-links-1 footer.footer-expand-xs > .card-data-list,
  body.footer-links-1 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-1 footer.footer-expand-xs > .profile,
  body.footer-links-1 footer.footer-expand-xs > .profile-page,
  body.footer-links-1 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-1 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-1 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-1 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-1 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-1 footer.footer-expand-sm > .container > .row, body.footer-links-1 footer.footer-expand-sm > .section-social > .row, body.footer-links-1 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-1 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-sm > .container > .profile, body.footer-links-1 footer.footer-expand-sm > .section-social > .profile, body.footer-links-1 footer.footer-expand-sm > .container > .profile-page, body.footer-links-1 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-sm > .container > .profile-section, body.footer-links-1 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-1 footer.footer-expand-sm > .row,
  body.footer-links-1 footer.footer-expand-sm > .card-data-list,
  body.footer-links-1 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-1 footer.footer-expand-sm > .profile,
  body.footer-links-1 footer.footer-expand-sm > .profile-page,
  body.footer-links-1 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-1 footer.footer-expand-sm > .container > .row, body.footer-links-1 footer.footer-expand-sm > .section-social > .row, body.footer-links-1 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-1 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-sm > .container > .profile, body.footer-links-1 footer.footer-expand-sm > .section-social > .profile, body.footer-links-1 footer.footer-expand-sm > .container > .profile-page, body.footer-links-1 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-sm > .container > .profile-section, body.footer-links-1 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-1 footer.footer-expand-sm > .row,
      body.footer-links-1 footer.footer-expand-sm > .card-data-list,
      body.footer-links-1 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-1 footer.footer-expand-sm > .profile,
      body.footer-links-1 footer.footer-expand-sm > .profile-page,
      body.footer-links-1 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-1 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-1 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-1 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-1 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-1 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-1 footer.footer-expand-md {
      height: 289px;
      padding: 40px 0 50px; } }
  body.footer-links-1 footer.footer-expand-md > .container > .row, body.footer-links-1 footer.footer-expand-md > .section-social > .row, body.footer-links-1 footer.footer-expand-md > .container > .card-data-list, body.footer-links-1 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-md > .container > .profile, body.footer-links-1 footer.footer-expand-md > .section-social > .profile, body.footer-links-1 footer.footer-expand-md > .container > .profile-page, body.footer-links-1 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-md > .container > .profile-section, body.footer-links-1 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-1 footer.footer-expand-md > .row,
  body.footer-links-1 footer.footer-expand-md > .card-data-list,
  body.footer-links-1 footer.footer-expand-md > .card-featured-news,
  body.footer-links-1 footer.footer-expand-md > .profile,
  body.footer-links-1 footer.footer-expand-md > .profile-page,
  body.footer-links-1 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-1 footer.footer-expand-md > .container > .row, body.footer-links-1 footer.footer-expand-md > .section-social > .row, body.footer-links-1 footer.footer-expand-md > .container > .card-data-list, body.footer-links-1 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-md > .container > .profile, body.footer-links-1 footer.footer-expand-md > .section-social > .profile, body.footer-links-1 footer.footer-expand-md > .container > .profile-page, body.footer-links-1 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-md > .container > .profile-section, body.footer-links-1 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-1 footer.footer-expand-md > .row,
      body.footer-links-1 footer.footer-expand-md > .card-data-list,
      body.footer-links-1 footer.footer-expand-md > .card-featured-news,
      body.footer-links-1 footer.footer-expand-md > .profile,
      body.footer-links-1 footer.footer-expand-md > .profile-page,
      body.footer-links-1 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-1 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-1 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-1 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-1 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-1 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-1 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-1 footer.footer-expand-lg > .container > .row, body.footer-links-1 footer.footer-expand-lg > .section-social > .row, body.footer-links-1 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-1 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-lg > .container > .profile, body.footer-links-1 footer.footer-expand-lg > .section-social > .profile, body.footer-links-1 footer.footer-expand-lg > .container > .profile-page, body.footer-links-1 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-lg > .container > .profile-section, body.footer-links-1 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-1 footer.footer-expand-lg > .row,
  body.footer-links-1 footer.footer-expand-lg > .card-data-list,
  body.footer-links-1 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-1 footer.footer-expand-lg > .profile,
  body.footer-links-1 footer.footer-expand-lg > .profile-page,
  body.footer-links-1 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-1 footer.footer-expand-lg > .container > .row, body.footer-links-1 footer.footer-expand-lg > .section-social > .row, body.footer-links-1 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-1 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-lg > .container > .profile, body.footer-links-1 footer.footer-expand-lg > .section-social > .profile, body.footer-links-1 footer.footer-expand-lg > .container > .profile-page, body.footer-links-1 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-lg > .container > .profile-section, body.footer-links-1 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-1 footer.footer-expand-lg > .row,
      body.footer-links-1 footer.footer-expand-lg > .card-data-list,
      body.footer-links-1 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-1 footer.footer-expand-lg > .profile,
      body.footer-links-1 footer.footer-expand-lg > .profile-page,
      body.footer-links-1 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-1 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-1 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-1 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-1 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-1 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-1 footer.footer-expand-xl {
      height: 223px;
      padding: 40px 0 50px; } }
  body.footer-links-1 footer.footer-expand-xl > .container > .row, body.footer-links-1 footer.footer-expand-xl > .section-social > .row, body.footer-links-1 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-1 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-xl > .container > .profile, body.footer-links-1 footer.footer-expand-xl > .section-social > .profile, body.footer-links-1 footer.footer-expand-xl > .container > .profile-page, body.footer-links-1 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-xl > .container > .profile-section, body.footer-links-1 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-1 footer.footer-expand-xl > .row,
  body.footer-links-1 footer.footer-expand-xl > .card-data-list,
  body.footer-links-1 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-1 footer.footer-expand-xl > .profile,
  body.footer-links-1 footer.footer-expand-xl > .profile-page,
  body.footer-links-1 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-1 footer.footer-expand-xl > .container > .row, body.footer-links-1 footer.footer-expand-xl > .section-social > .row, body.footer-links-1 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-1 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-1 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-1 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-1 footer.footer-expand-xl > .container > .profile, body.footer-links-1 footer.footer-expand-xl > .section-social > .profile, body.footer-links-1 footer.footer-expand-xl > .container > .profile-page, body.footer-links-1 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-1 footer.footer-expand-xl > .container > .profile-section, body.footer-links-1 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-1 footer.footer-expand-xl > .row,
      body.footer-links-1 footer.footer-expand-xl > .card-data-list,
      body.footer-links-1 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-1 footer.footer-expand-xl > .profile,
      body.footer-links-1 footer.footer-expand-xl > .profile-page,
      body.footer-links-1 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-1 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-1 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-1 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-1 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-1 footer .footer-links {
  height: 44px; }
  @media (min-width: 768px) {
    body.footer-links-1 footer .footer-links {
      height: 44px; } }

body.footer-links-2 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-2 main {
      min-height: calc(100vh - 358px); } }
  @media (min-width: 1200px) {
    body.footer-links-2 main {
      min-height: calc(100vh - 292px); } }

body.footer-links-2 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-2 footer.footer-expand-xs > .container > .row, body.footer-links-2 footer.footer-expand-xs > .section-social > .row, body.footer-links-2 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-2 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-xs > .container > .profile, body.footer-links-2 footer.footer-expand-xs > .section-social > .profile, body.footer-links-2 footer.footer-expand-xs > .container > .profile-page, body.footer-links-2 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-xs > .container > .profile-section, body.footer-links-2 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-2 footer.footer-expand-xs > .row,
  body.footer-links-2 footer.footer-expand-xs > .card-data-list,
  body.footer-links-2 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-2 footer.footer-expand-xs > .profile,
  body.footer-links-2 footer.footer-expand-xs > .profile-page,
  body.footer-links-2 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-2 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-2 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-2 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-2 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-2 footer.footer-expand-sm > .container > .row, body.footer-links-2 footer.footer-expand-sm > .section-social > .row, body.footer-links-2 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-2 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-sm > .container > .profile, body.footer-links-2 footer.footer-expand-sm > .section-social > .profile, body.footer-links-2 footer.footer-expand-sm > .container > .profile-page, body.footer-links-2 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-sm > .container > .profile-section, body.footer-links-2 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-2 footer.footer-expand-sm > .row,
  body.footer-links-2 footer.footer-expand-sm > .card-data-list,
  body.footer-links-2 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-2 footer.footer-expand-sm > .profile,
  body.footer-links-2 footer.footer-expand-sm > .profile-page,
  body.footer-links-2 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-2 footer.footer-expand-sm > .container > .row, body.footer-links-2 footer.footer-expand-sm > .section-social > .row, body.footer-links-2 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-2 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-sm > .container > .profile, body.footer-links-2 footer.footer-expand-sm > .section-social > .profile, body.footer-links-2 footer.footer-expand-sm > .container > .profile-page, body.footer-links-2 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-sm > .container > .profile-section, body.footer-links-2 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-2 footer.footer-expand-sm > .row,
      body.footer-links-2 footer.footer-expand-sm > .card-data-list,
      body.footer-links-2 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-2 footer.footer-expand-sm > .profile,
      body.footer-links-2 footer.footer-expand-sm > .profile-page,
      body.footer-links-2 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-2 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-2 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-2 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-2 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-2 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-2 footer.footer-expand-md {
      height: 289px;
      padding: 40px 0 50px; } }
  body.footer-links-2 footer.footer-expand-md > .container > .row, body.footer-links-2 footer.footer-expand-md > .section-social > .row, body.footer-links-2 footer.footer-expand-md > .container > .card-data-list, body.footer-links-2 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-md > .container > .profile, body.footer-links-2 footer.footer-expand-md > .section-social > .profile, body.footer-links-2 footer.footer-expand-md > .container > .profile-page, body.footer-links-2 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-md > .container > .profile-section, body.footer-links-2 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-2 footer.footer-expand-md > .row,
  body.footer-links-2 footer.footer-expand-md > .card-data-list,
  body.footer-links-2 footer.footer-expand-md > .card-featured-news,
  body.footer-links-2 footer.footer-expand-md > .profile,
  body.footer-links-2 footer.footer-expand-md > .profile-page,
  body.footer-links-2 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-2 footer.footer-expand-md > .container > .row, body.footer-links-2 footer.footer-expand-md > .section-social > .row, body.footer-links-2 footer.footer-expand-md > .container > .card-data-list, body.footer-links-2 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-md > .container > .profile, body.footer-links-2 footer.footer-expand-md > .section-social > .profile, body.footer-links-2 footer.footer-expand-md > .container > .profile-page, body.footer-links-2 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-md > .container > .profile-section, body.footer-links-2 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-2 footer.footer-expand-md > .row,
      body.footer-links-2 footer.footer-expand-md > .card-data-list,
      body.footer-links-2 footer.footer-expand-md > .card-featured-news,
      body.footer-links-2 footer.footer-expand-md > .profile,
      body.footer-links-2 footer.footer-expand-md > .profile-page,
      body.footer-links-2 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-2 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-2 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-2 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-2 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-2 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-2 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-2 footer.footer-expand-lg > .container > .row, body.footer-links-2 footer.footer-expand-lg > .section-social > .row, body.footer-links-2 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-2 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-lg > .container > .profile, body.footer-links-2 footer.footer-expand-lg > .section-social > .profile, body.footer-links-2 footer.footer-expand-lg > .container > .profile-page, body.footer-links-2 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-lg > .container > .profile-section, body.footer-links-2 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-2 footer.footer-expand-lg > .row,
  body.footer-links-2 footer.footer-expand-lg > .card-data-list,
  body.footer-links-2 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-2 footer.footer-expand-lg > .profile,
  body.footer-links-2 footer.footer-expand-lg > .profile-page,
  body.footer-links-2 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-2 footer.footer-expand-lg > .container > .row, body.footer-links-2 footer.footer-expand-lg > .section-social > .row, body.footer-links-2 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-2 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-lg > .container > .profile, body.footer-links-2 footer.footer-expand-lg > .section-social > .profile, body.footer-links-2 footer.footer-expand-lg > .container > .profile-page, body.footer-links-2 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-lg > .container > .profile-section, body.footer-links-2 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-2 footer.footer-expand-lg > .row,
      body.footer-links-2 footer.footer-expand-lg > .card-data-list,
      body.footer-links-2 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-2 footer.footer-expand-lg > .profile,
      body.footer-links-2 footer.footer-expand-lg > .profile-page,
      body.footer-links-2 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-2 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-2 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-2 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-2 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-2 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-2 footer.footer-expand-xl {
      height: 223px;
      padding: 40px 0 50px; } }
  body.footer-links-2 footer.footer-expand-xl > .container > .row, body.footer-links-2 footer.footer-expand-xl > .section-social > .row, body.footer-links-2 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-2 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-xl > .container > .profile, body.footer-links-2 footer.footer-expand-xl > .section-social > .profile, body.footer-links-2 footer.footer-expand-xl > .container > .profile-page, body.footer-links-2 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-xl > .container > .profile-section, body.footer-links-2 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-2 footer.footer-expand-xl > .row,
  body.footer-links-2 footer.footer-expand-xl > .card-data-list,
  body.footer-links-2 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-2 footer.footer-expand-xl > .profile,
  body.footer-links-2 footer.footer-expand-xl > .profile-page,
  body.footer-links-2 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-2 footer.footer-expand-xl > .container > .row, body.footer-links-2 footer.footer-expand-xl > .section-social > .row, body.footer-links-2 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-2 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-2 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-2 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-2 footer.footer-expand-xl > .container > .profile, body.footer-links-2 footer.footer-expand-xl > .section-social > .profile, body.footer-links-2 footer.footer-expand-xl > .container > .profile-page, body.footer-links-2 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-2 footer.footer-expand-xl > .container > .profile-section, body.footer-links-2 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-2 footer.footer-expand-xl > .row,
      body.footer-links-2 footer.footer-expand-xl > .card-data-list,
      body.footer-links-2 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-2 footer.footer-expand-xl > .profile,
      body.footer-links-2 footer.footer-expand-xl > .profile-page,
      body.footer-links-2 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-2 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-2 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-2 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-2 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-2 footer .footer-links {
  height: 88px; }
  @media (min-width: 768px) {
    body.footer-links-2 footer .footer-links {
      height: 44px; } }

body.footer-links-3 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-3 main {
      min-height: calc(100vh - 402px); } }
  @media (min-width: 1200px) {
    body.footer-links-3 main {
      min-height: calc(100vh - 292px); } }

body.footer-links-3 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-3 footer.footer-expand-xs > .container > .row, body.footer-links-3 footer.footer-expand-xs > .section-social > .row, body.footer-links-3 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-3 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-xs > .container > .profile, body.footer-links-3 footer.footer-expand-xs > .section-social > .profile, body.footer-links-3 footer.footer-expand-xs > .container > .profile-page, body.footer-links-3 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-xs > .container > .profile-section, body.footer-links-3 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-3 footer.footer-expand-xs > .row,
  body.footer-links-3 footer.footer-expand-xs > .card-data-list,
  body.footer-links-3 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-3 footer.footer-expand-xs > .profile,
  body.footer-links-3 footer.footer-expand-xs > .profile-page,
  body.footer-links-3 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-3 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-3 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-3 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-3 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-3 footer.footer-expand-sm > .container > .row, body.footer-links-3 footer.footer-expand-sm > .section-social > .row, body.footer-links-3 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-3 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-sm > .container > .profile, body.footer-links-3 footer.footer-expand-sm > .section-social > .profile, body.footer-links-3 footer.footer-expand-sm > .container > .profile-page, body.footer-links-3 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-sm > .container > .profile-section, body.footer-links-3 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-3 footer.footer-expand-sm > .row,
  body.footer-links-3 footer.footer-expand-sm > .card-data-list,
  body.footer-links-3 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-3 footer.footer-expand-sm > .profile,
  body.footer-links-3 footer.footer-expand-sm > .profile-page,
  body.footer-links-3 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-3 footer.footer-expand-sm > .container > .row, body.footer-links-3 footer.footer-expand-sm > .section-social > .row, body.footer-links-3 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-3 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-sm > .container > .profile, body.footer-links-3 footer.footer-expand-sm > .section-social > .profile, body.footer-links-3 footer.footer-expand-sm > .container > .profile-page, body.footer-links-3 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-sm > .container > .profile-section, body.footer-links-3 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-3 footer.footer-expand-sm > .row,
      body.footer-links-3 footer.footer-expand-sm > .card-data-list,
      body.footer-links-3 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-3 footer.footer-expand-sm > .profile,
      body.footer-links-3 footer.footer-expand-sm > .profile-page,
      body.footer-links-3 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-3 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-3 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-3 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-3 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-3 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-3 footer.footer-expand-md {
      height: 333px;
      padding: 40px 0 50px; } }
  body.footer-links-3 footer.footer-expand-md > .container > .row, body.footer-links-3 footer.footer-expand-md > .section-social > .row, body.footer-links-3 footer.footer-expand-md > .container > .card-data-list, body.footer-links-3 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-md > .container > .profile, body.footer-links-3 footer.footer-expand-md > .section-social > .profile, body.footer-links-3 footer.footer-expand-md > .container > .profile-page, body.footer-links-3 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-md > .container > .profile-section, body.footer-links-3 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-3 footer.footer-expand-md > .row,
  body.footer-links-3 footer.footer-expand-md > .card-data-list,
  body.footer-links-3 footer.footer-expand-md > .card-featured-news,
  body.footer-links-3 footer.footer-expand-md > .profile,
  body.footer-links-3 footer.footer-expand-md > .profile-page,
  body.footer-links-3 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-3 footer.footer-expand-md > .container > .row, body.footer-links-3 footer.footer-expand-md > .section-social > .row, body.footer-links-3 footer.footer-expand-md > .container > .card-data-list, body.footer-links-3 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-md > .container > .profile, body.footer-links-3 footer.footer-expand-md > .section-social > .profile, body.footer-links-3 footer.footer-expand-md > .container > .profile-page, body.footer-links-3 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-md > .container > .profile-section, body.footer-links-3 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-3 footer.footer-expand-md > .row,
      body.footer-links-3 footer.footer-expand-md > .card-data-list,
      body.footer-links-3 footer.footer-expand-md > .card-featured-news,
      body.footer-links-3 footer.footer-expand-md > .profile,
      body.footer-links-3 footer.footer-expand-md > .profile-page,
      body.footer-links-3 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-3 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-3 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-3 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-3 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-3 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-3 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-3 footer.footer-expand-lg > .container > .row, body.footer-links-3 footer.footer-expand-lg > .section-social > .row, body.footer-links-3 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-3 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-lg > .container > .profile, body.footer-links-3 footer.footer-expand-lg > .section-social > .profile, body.footer-links-3 footer.footer-expand-lg > .container > .profile-page, body.footer-links-3 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-lg > .container > .profile-section, body.footer-links-3 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-3 footer.footer-expand-lg > .row,
  body.footer-links-3 footer.footer-expand-lg > .card-data-list,
  body.footer-links-3 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-3 footer.footer-expand-lg > .profile,
  body.footer-links-3 footer.footer-expand-lg > .profile-page,
  body.footer-links-3 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-3 footer.footer-expand-lg > .container > .row, body.footer-links-3 footer.footer-expand-lg > .section-social > .row, body.footer-links-3 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-3 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-lg > .container > .profile, body.footer-links-3 footer.footer-expand-lg > .section-social > .profile, body.footer-links-3 footer.footer-expand-lg > .container > .profile-page, body.footer-links-3 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-lg > .container > .profile-section, body.footer-links-3 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-3 footer.footer-expand-lg > .row,
      body.footer-links-3 footer.footer-expand-lg > .card-data-list,
      body.footer-links-3 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-3 footer.footer-expand-lg > .profile,
      body.footer-links-3 footer.footer-expand-lg > .profile-page,
      body.footer-links-3 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-3 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-3 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-3 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-3 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-3 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-3 footer.footer-expand-xl {
      height: 223px;
      padding: 40px 0 50px; } }
  body.footer-links-3 footer.footer-expand-xl > .container > .row, body.footer-links-3 footer.footer-expand-xl > .section-social > .row, body.footer-links-3 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-3 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-xl > .container > .profile, body.footer-links-3 footer.footer-expand-xl > .section-social > .profile, body.footer-links-3 footer.footer-expand-xl > .container > .profile-page, body.footer-links-3 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-xl > .container > .profile-section, body.footer-links-3 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-3 footer.footer-expand-xl > .row,
  body.footer-links-3 footer.footer-expand-xl > .card-data-list,
  body.footer-links-3 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-3 footer.footer-expand-xl > .profile,
  body.footer-links-3 footer.footer-expand-xl > .profile-page,
  body.footer-links-3 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-3 footer.footer-expand-xl > .container > .row, body.footer-links-3 footer.footer-expand-xl > .section-social > .row, body.footer-links-3 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-3 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-3 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-3 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-3 footer.footer-expand-xl > .container > .profile, body.footer-links-3 footer.footer-expand-xl > .section-social > .profile, body.footer-links-3 footer.footer-expand-xl > .container > .profile-page, body.footer-links-3 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-3 footer.footer-expand-xl > .container > .profile-section, body.footer-links-3 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-3 footer.footer-expand-xl > .row,
      body.footer-links-3 footer.footer-expand-xl > .card-data-list,
      body.footer-links-3 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-3 footer.footer-expand-xl > .profile,
      body.footer-links-3 footer.footer-expand-xl > .profile-page,
      body.footer-links-3 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-3 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-3 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-3 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-3 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-3 footer .footer-links {
  height: 132px; }
  @media (min-width: 768px) {
    body.footer-links-3 footer .footer-links {
      height: 88px; } }

body.footer-links-4 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-4 main {
      min-height: calc(100vh - 402px); } }
  @media (min-width: 1200px) {
    body.footer-links-4 main {
      min-height: calc(100vh - 292px); } }

body.footer-links-4 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-4 footer.footer-expand-xs > .container > .row, body.footer-links-4 footer.footer-expand-xs > .section-social > .row, body.footer-links-4 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-4 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-xs > .container > .profile, body.footer-links-4 footer.footer-expand-xs > .section-social > .profile, body.footer-links-4 footer.footer-expand-xs > .container > .profile-page, body.footer-links-4 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-xs > .container > .profile-section, body.footer-links-4 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-4 footer.footer-expand-xs > .row,
  body.footer-links-4 footer.footer-expand-xs > .card-data-list,
  body.footer-links-4 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-4 footer.footer-expand-xs > .profile,
  body.footer-links-4 footer.footer-expand-xs > .profile-page,
  body.footer-links-4 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-4 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-4 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-4 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-4 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-4 footer.footer-expand-sm > .container > .row, body.footer-links-4 footer.footer-expand-sm > .section-social > .row, body.footer-links-4 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-4 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-sm > .container > .profile, body.footer-links-4 footer.footer-expand-sm > .section-social > .profile, body.footer-links-4 footer.footer-expand-sm > .container > .profile-page, body.footer-links-4 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-sm > .container > .profile-section, body.footer-links-4 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-4 footer.footer-expand-sm > .row,
  body.footer-links-4 footer.footer-expand-sm > .card-data-list,
  body.footer-links-4 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-4 footer.footer-expand-sm > .profile,
  body.footer-links-4 footer.footer-expand-sm > .profile-page,
  body.footer-links-4 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-4 footer.footer-expand-sm > .container > .row, body.footer-links-4 footer.footer-expand-sm > .section-social > .row, body.footer-links-4 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-4 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-sm > .container > .profile, body.footer-links-4 footer.footer-expand-sm > .section-social > .profile, body.footer-links-4 footer.footer-expand-sm > .container > .profile-page, body.footer-links-4 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-sm > .container > .profile-section, body.footer-links-4 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-4 footer.footer-expand-sm > .row,
      body.footer-links-4 footer.footer-expand-sm > .card-data-list,
      body.footer-links-4 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-4 footer.footer-expand-sm > .profile,
      body.footer-links-4 footer.footer-expand-sm > .profile-page,
      body.footer-links-4 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-4 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-4 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-4 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-4 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-4 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-4 footer.footer-expand-md {
      height: 333px;
      padding: 40px 0 50px; } }
  body.footer-links-4 footer.footer-expand-md > .container > .row, body.footer-links-4 footer.footer-expand-md > .section-social > .row, body.footer-links-4 footer.footer-expand-md > .container > .card-data-list, body.footer-links-4 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-md > .container > .profile, body.footer-links-4 footer.footer-expand-md > .section-social > .profile, body.footer-links-4 footer.footer-expand-md > .container > .profile-page, body.footer-links-4 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-md > .container > .profile-section, body.footer-links-4 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-4 footer.footer-expand-md > .row,
  body.footer-links-4 footer.footer-expand-md > .card-data-list,
  body.footer-links-4 footer.footer-expand-md > .card-featured-news,
  body.footer-links-4 footer.footer-expand-md > .profile,
  body.footer-links-4 footer.footer-expand-md > .profile-page,
  body.footer-links-4 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-4 footer.footer-expand-md > .container > .row, body.footer-links-4 footer.footer-expand-md > .section-social > .row, body.footer-links-4 footer.footer-expand-md > .container > .card-data-list, body.footer-links-4 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-md > .container > .profile, body.footer-links-4 footer.footer-expand-md > .section-social > .profile, body.footer-links-4 footer.footer-expand-md > .container > .profile-page, body.footer-links-4 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-md > .container > .profile-section, body.footer-links-4 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-4 footer.footer-expand-md > .row,
      body.footer-links-4 footer.footer-expand-md > .card-data-list,
      body.footer-links-4 footer.footer-expand-md > .card-featured-news,
      body.footer-links-4 footer.footer-expand-md > .profile,
      body.footer-links-4 footer.footer-expand-md > .profile-page,
      body.footer-links-4 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-4 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-4 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-4 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-4 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-4 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-4 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-4 footer.footer-expand-lg > .container > .row, body.footer-links-4 footer.footer-expand-lg > .section-social > .row, body.footer-links-4 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-4 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-lg > .container > .profile, body.footer-links-4 footer.footer-expand-lg > .section-social > .profile, body.footer-links-4 footer.footer-expand-lg > .container > .profile-page, body.footer-links-4 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-lg > .container > .profile-section, body.footer-links-4 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-4 footer.footer-expand-lg > .row,
  body.footer-links-4 footer.footer-expand-lg > .card-data-list,
  body.footer-links-4 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-4 footer.footer-expand-lg > .profile,
  body.footer-links-4 footer.footer-expand-lg > .profile-page,
  body.footer-links-4 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-4 footer.footer-expand-lg > .container > .row, body.footer-links-4 footer.footer-expand-lg > .section-social > .row, body.footer-links-4 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-4 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-lg > .container > .profile, body.footer-links-4 footer.footer-expand-lg > .section-social > .profile, body.footer-links-4 footer.footer-expand-lg > .container > .profile-page, body.footer-links-4 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-lg > .container > .profile-section, body.footer-links-4 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-4 footer.footer-expand-lg > .row,
      body.footer-links-4 footer.footer-expand-lg > .card-data-list,
      body.footer-links-4 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-4 footer.footer-expand-lg > .profile,
      body.footer-links-4 footer.footer-expand-lg > .profile-page,
      body.footer-links-4 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-4 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-4 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-4 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-4 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-4 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-4 footer.footer-expand-xl {
      height: 223px;
      padding: 40px 0 50px; } }
  body.footer-links-4 footer.footer-expand-xl > .container > .row, body.footer-links-4 footer.footer-expand-xl > .section-social > .row, body.footer-links-4 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-4 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-xl > .container > .profile, body.footer-links-4 footer.footer-expand-xl > .section-social > .profile, body.footer-links-4 footer.footer-expand-xl > .container > .profile-page, body.footer-links-4 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-xl > .container > .profile-section, body.footer-links-4 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-4 footer.footer-expand-xl > .row,
  body.footer-links-4 footer.footer-expand-xl > .card-data-list,
  body.footer-links-4 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-4 footer.footer-expand-xl > .profile,
  body.footer-links-4 footer.footer-expand-xl > .profile-page,
  body.footer-links-4 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-4 footer.footer-expand-xl > .container > .row, body.footer-links-4 footer.footer-expand-xl > .section-social > .row, body.footer-links-4 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-4 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-4 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-4 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-4 footer.footer-expand-xl > .container > .profile, body.footer-links-4 footer.footer-expand-xl > .section-social > .profile, body.footer-links-4 footer.footer-expand-xl > .container > .profile-page, body.footer-links-4 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-4 footer.footer-expand-xl > .container > .profile-section, body.footer-links-4 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-4 footer.footer-expand-xl > .row,
      body.footer-links-4 footer.footer-expand-xl > .card-data-list,
      body.footer-links-4 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-4 footer.footer-expand-xl > .profile,
      body.footer-links-4 footer.footer-expand-xl > .profile-page,
      body.footer-links-4 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-4 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-4 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-4 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-4 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-4 footer .footer-links {
  height: 176px; }
  @media (min-width: 768px) {
    body.footer-links-4 footer .footer-links {
      height: 88px; } }

body.footer-links-5 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-5 main {
      min-height: calc(100vh - 446px); } }
  @media (min-width: 1200px) {
    body.footer-links-5 main {
      min-height: calc(100vh - 301px); } }

body.footer-links-5 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-5 footer.footer-expand-xs > .container > .row, body.footer-links-5 footer.footer-expand-xs > .section-social > .row, body.footer-links-5 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-5 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-xs > .container > .profile, body.footer-links-5 footer.footer-expand-xs > .section-social > .profile, body.footer-links-5 footer.footer-expand-xs > .container > .profile-page, body.footer-links-5 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-xs > .container > .profile-section, body.footer-links-5 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-5 footer.footer-expand-xs > .row,
  body.footer-links-5 footer.footer-expand-xs > .card-data-list,
  body.footer-links-5 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-5 footer.footer-expand-xs > .profile,
  body.footer-links-5 footer.footer-expand-xs > .profile-page,
  body.footer-links-5 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-5 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-5 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-5 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-5 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-5 footer.footer-expand-sm > .container > .row, body.footer-links-5 footer.footer-expand-sm > .section-social > .row, body.footer-links-5 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-5 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-sm > .container > .profile, body.footer-links-5 footer.footer-expand-sm > .section-social > .profile, body.footer-links-5 footer.footer-expand-sm > .container > .profile-page, body.footer-links-5 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-sm > .container > .profile-section, body.footer-links-5 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-5 footer.footer-expand-sm > .row,
  body.footer-links-5 footer.footer-expand-sm > .card-data-list,
  body.footer-links-5 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-5 footer.footer-expand-sm > .profile,
  body.footer-links-5 footer.footer-expand-sm > .profile-page,
  body.footer-links-5 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-5 footer.footer-expand-sm > .container > .row, body.footer-links-5 footer.footer-expand-sm > .section-social > .row, body.footer-links-5 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-5 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-sm > .container > .profile, body.footer-links-5 footer.footer-expand-sm > .section-social > .profile, body.footer-links-5 footer.footer-expand-sm > .container > .profile-page, body.footer-links-5 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-sm > .container > .profile-section, body.footer-links-5 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-5 footer.footer-expand-sm > .row,
      body.footer-links-5 footer.footer-expand-sm > .card-data-list,
      body.footer-links-5 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-5 footer.footer-expand-sm > .profile,
      body.footer-links-5 footer.footer-expand-sm > .profile-page,
      body.footer-links-5 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-5 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-5 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-5 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-5 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-5 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-5 footer.footer-expand-md {
      height: 377px;
      padding: 40px 0 50px; } }
  body.footer-links-5 footer.footer-expand-md > .container > .row, body.footer-links-5 footer.footer-expand-md > .section-social > .row, body.footer-links-5 footer.footer-expand-md > .container > .card-data-list, body.footer-links-5 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-md > .container > .profile, body.footer-links-5 footer.footer-expand-md > .section-social > .profile, body.footer-links-5 footer.footer-expand-md > .container > .profile-page, body.footer-links-5 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-md > .container > .profile-section, body.footer-links-5 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-5 footer.footer-expand-md > .row,
  body.footer-links-5 footer.footer-expand-md > .card-data-list,
  body.footer-links-5 footer.footer-expand-md > .card-featured-news,
  body.footer-links-5 footer.footer-expand-md > .profile,
  body.footer-links-5 footer.footer-expand-md > .profile-page,
  body.footer-links-5 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-5 footer.footer-expand-md > .container > .row, body.footer-links-5 footer.footer-expand-md > .section-social > .row, body.footer-links-5 footer.footer-expand-md > .container > .card-data-list, body.footer-links-5 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-md > .container > .profile, body.footer-links-5 footer.footer-expand-md > .section-social > .profile, body.footer-links-5 footer.footer-expand-md > .container > .profile-page, body.footer-links-5 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-md > .container > .profile-section, body.footer-links-5 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-5 footer.footer-expand-md > .row,
      body.footer-links-5 footer.footer-expand-md > .card-data-list,
      body.footer-links-5 footer.footer-expand-md > .card-featured-news,
      body.footer-links-5 footer.footer-expand-md > .profile,
      body.footer-links-5 footer.footer-expand-md > .profile-page,
      body.footer-links-5 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-5 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-5 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-5 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-5 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-5 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-5 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-5 footer.footer-expand-lg > .container > .row, body.footer-links-5 footer.footer-expand-lg > .section-social > .row, body.footer-links-5 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-5 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-lg > .container > .profile, body.footer-links-5 footer.footer-expand-lg > .section-social > .profile, body.footer-links-5 footer.footer-expand-lg > .container > .profile-page, body.footer-links-5 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-lg > .container > .profile-section, body.footer-links-5 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-5 footer.footer-expand-lg > .row,
  body.footer-links-5 footer.footer-expand-lg > .card-data-list,
  body.footer-links-5 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-5 footer.footer-expand-lg > .profile,
  body.footer-links-5 footer.footer-expand-lg > .profile-page,
  body.footer-links-5 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-5 footer.footer-expand-lg > .container > .row, body.footer-links-5 footer.footer-expand-lg > .section-social > .row, body.footer-links-5 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-5 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-lg > .container > .profile, body.footer-links-5 footer.footer-expand-lg > .section-social > .profile, body.footer-links-5 footer.footer-expand-lg > .container > .profile-page, body.footer-links-5 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-lg > .container > .profile-section, body.footer-links-5 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-5 footer.footer-expand-lg > .row,
      body.footer-links-5 footer.footer-expand-lg > .card-data-list,
      body.footer-links-5 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-5 footer.footer-expand-lg > .profile,
      body.footer-links-5 footer.footer-expand-lg > .profile-page,
      body.footer-links-5 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-5 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-5 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-5 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-5 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-5 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-5 footer.footer-expand-xl {
      height: 232px;
      padding: 40px 0 50px; } }
  body.footer-links-5 footer.footer-expand-xl > .container > .row, body.footer-links-5 footer.footer-expand-xl > .section-social > .row, body.footer-links-5 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-5 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-xl > .container > .profile, body.footer-links-5 footer.footer-expand-xl > .section-social > .profile, body.footer-links-5 footer.footer-expand-xl > .container > .profile-page, body.footer-links-5 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-xl > .container > .profile-section, body.footer-links-5 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-5 footer.footer-expand-xl > .row,
  body.footer-links-5 footer.footer-expand-xl > .card-data-list,
  body.footer-links-5 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-5 footer.footer-expand-xl > .profile,
  body.footer-links-5 footer.footer-expand-xl > .profile-page,
  body.footer-links-5 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-5 footer.footer-expand-xl > .container > .row, body.footer-links-5 footer.footer-expand-xl > .section-social > .row, body.footer-links-5 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-5 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-5 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-5 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-5 footer.footer-expand-xl > .container > .profile, body.footer-links-5 footer.footer-expand-xl > .section-social > .profile, body.footer-links-5 footer.footer-expand-xl > .container > .profile-page, body.footer-links-5 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-5 footer.footer-expand-xl > .container > .profile-section, body.footer-links-5 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-5 footer.footer-expand-xl > .row,
      body.footer-links-5 footer.footer-expand-xl > .card-data-list,
      body.footer-links-5 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-5 footer.footer-expand-xl > .profile,
      body.footer-links-5 footer.footer-expand-xl > .profile-page,
      body.footer-links-5 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-5 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-5 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-5 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-5 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-5 footer .footer-links {
  height: 220px; }
  @media (min-width: 768px) {
    body.footer-links-5 footer .footer-links {
      height: 132px; } }

body.footer-links-6 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-6 main {
      min-height: calc(100vh - 446px); } }
  @media (min-width: 1200px) {
    body.footer-links-6 main {
      min-height: calc(100vh - 301px); } }

body.footer-links-6 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-6 footer.footer-expand-xs > .container > .row, body.footer-links-6 footer.footer-expand-xs > .section-social > .row, body.footer-links-6 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-6 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-xs > .container > .profile, body.footer-links-6 footer.footer-expand-xs > .section-social > .profile, body.footer-links-6 footer.footer-expand-xs > .container > .profile-page, body.footer-links-6 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-xs > .container > .profile-section, body.footer-links-6 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-6 footer.footer-expand-xs > .row,
  body.footer-links-6 footer.footer-expand-xs > .card-data-list,
  body.footer-links-6 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-6 footer.footer-expand-xs > .profile,
  body.footer-links-6 footer.footer-expand-xs > .profile-page,
  body.footer-links-6 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-6 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-6 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-6 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-6 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-6 footer.footer-expand-sm > .container > .row, body.footer-links-6 footer.footer-expand-sm > .section-social > .row, body.footer-links-6 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-6 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-sm > .container > .profile, body.footer-links-6 footer.footer-expand-sm > .section-social > .profile, body.footer-links-6 footer.footer-expand-sm > .container > .profile-page, body.footer-links-6 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-sm > .container > .profile-section, body.footer-links-6 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-6 footer.footer-expand-sm > .row,
  body.footer-links-6 footer.footer-expand-sm > .card-data-list,
  body.footer-links-6 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-6 footer.footer-expand-sm > .profile,
  body.footer-links-6 footer.footer-expand-sm > .profile-page,
  body.footer-links-6 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-6 footer.footer-expand-sm > .container > .row, body.footer-links-6 footer.footer-expand-sm > .section-social > .row, body.footer-links-6 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-6 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-sm > .container > .profile, body.footer-links-6 footer.footer-expand-sm > .section-social > .profile, body.footer-links-6 footer.footer-expand-sm > .container > .profile-page, body.footer-links-6 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-sm > .container > .profile-section, body.footer-links-6 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-6 footer.footer-expand-sm > .row,
      body.footer-links-6 footer.footer-expand-sm > .card-data-list,
      body.footer-links-6 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-6 footer.footer-expand-sm > .profile,
      body.footer-links-6 footer.footer-expand-sm > .profile-page,
      body.footer-links-6 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-6 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-6 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-6 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-6 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-6 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-6 footer.footer-expand-md {
      height: 377px;
      padding: 40px 0 50px; } }
  body.footer-links-6 footer.footer-expand-md > .container > .row, body.footer-links-6 footer.footer-expand-md > .section-social > .row, body.footer-links-6 footer.footer-expand-md > .container > .card-data-list, body.footer-links-6 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-md > .container > .profile, body.footer-links-6 footer.footer-expand-md > .section-social > .profile, body.footer-links-6 footer.footer-expand-md > .container > .profile-page, body.footer-links-6 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-md > .container > .profile-section, body.footer-links-6 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-6 footer.footer-expand-md > .row,
  body.footer-links-6 footer.footer-expand-md > .card-data-list,
  body.footer-links-6 footer.footer-expand-md > .card-featured-news,
  body.footer-links-6 footer.footer-expand-md > .profile,
  body.footer-links-6 footer.footer-expand-md > .profile-page,
  body.footer-links-6 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-6 footer.footer-expand-md > .container > .row, body.footer-links-6 footer.footer-expand-md > .section-social > .row, body.footer-links-6 footer.footer-expand-md > .container > .card-data-list, body.footer-links-6 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-md > .container > .profile, body.footer-links-6 footer.footer-expand-md > .section-social > .profile, body.footer-links-6 footer.footer-expand-md > .container > .profile-page, body.footer-links-6 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-md > .container > .profile-section, body.footer-links-6 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-6 footer.footer-expand-md > .row,
      body.footer-links-6 footer.footer-expand-md > .card-data-list,
      body.footer-links-6 footer.footer-expand-md > .card-featured-news,
      body.footer-links-6 footer.footer-expand-md > .profile,
      body.footer-links-6 footer.footer-expand-md > .profile-page,
      body.footer-links-6 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-6 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-6 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-6 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-6 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-6 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-6 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-6 footer.footer-expand-lg > .container > .row, body.footer-links-6 footer.footer-expand-lg > .section-social > .row, body.footer-links-6 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-6 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-lg > .container > .profile, body.footer-links-6 footer.footer-expand-lg > .section-social > .profile, body.footer-links-6 footer.footer-expand-lg > .container > .profile-page, body.footer-links-6 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-lg > .container > .profile-section, body.footer-links-6 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-6 footer.footer-expand-lg > .row,
  body.footer-links-6 footer.footer-expand-lg > .card-data-list,
  body.footer-links-6 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-6 footer.footer-expand-lg > .profile,
  body.footer-links-6 footer.footer-expand-lg > .profile-page,
  body.footer-links-6 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-6 footer.footer-expand-lg > .container > .row, body.footer-links-6 footer.footer-expand-lg > .section-social > .row, body.footer-links-6 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-6 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-lg > .container > .profile, body.footer-links-6 footer.footer-expand-lg > .section-social > .profile, body.footer-links-6 footer.footer-expand-lg > .container > .profile-page, body.footer-links-6 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-lg > .container > .profile-section, body.footer-links-6 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-6 footer.footer-expand-lg > .row,
      body.footer-links-6 footer.footer-expand-lg > .card-data-list,
      body.footer-links-6 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-6 footer.footer-expand-lg > .profile,
      body.footer-links-6 footer.footer-expand-lg > .profile-page,
      body.footer-links-6 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-6 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-6 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-6 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-6 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-6 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-6 footer.footer-expand-xl {
      height: 232px;
      padding: 40px 0 50px; } }
  body.footer-links-6 footer.footer-expand-xl > .container > .row, body.footer-links-6 footer.footer-expand-xl > .section-social > .row, body.footer-links-6 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-6 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-xl > .container > .profile, body.footer-links-6 footer.footer-expand-xl > .section-social > .profile, body.footer-links-6 footer.footer-expand-xl > .container > .profile-page, body.footer-links-6 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-xl > .container > .profile-section, body.footer-links-6 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-6 footer.footer-expand-xl > .row,
  body.footer-links-6 footer.footer-expand-xl > .card-data-list,
  body.footer-links-6 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-6 footer.footer-expand-xl > .profile,
  body.footer-links-6 footer.footer-expand-xl > .profile-page,
  body.footer-links-6 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-6 footer.footer-expand-xl > .container > .row, body.footer-links-6 footer.footer-expand-xl > .section-social > .row, body.footer-links-6 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-6 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-6 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-6 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-6 footer.footer-expand-xl > .container > .profile, body.footer-links-6 footer.footer-expand-xl > .section-social > .profile, body.footer-links-6 footer.footer-expand-xl > .container > .profile-page, body.footer-links-6 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-6 footer.footer-expand-xl > .container > .profile-section, body.footer-links-6 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-6 footer.footer-expand-xl > .row,
      body.footer-links-6 footer.footer-expand-xl > .card-data-list,
      body.footer-links-6 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-6 footer.footer-expand-xl > .profile,
      body.footer-links-6 footer.footer-expand-xl > .profile-page,
      body.footer-links-6 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-6 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-6 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-6 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-6 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-6 footer .footer-links {
  height: 264px; }
  @media (min-width: 768px) {
    body.footer-links-6 footer .footer-links {
      height: 132px; } }

body.footer-links-7 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-7 main {
      min-height: calc(100vh - 490px); } }
  @media (min-width: 1200px) {
    body.footer-links-7 main {
      min-height: calc(100vh - 345px); } }

body.footer-links-7 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-7 footer.footer-expand-xs > .container > .row, body.footer-links-7 footer.footer-expand-xs > .section-social > .row, body.footer-links-7 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-7 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-xs > .container > .profile, body.footer-links-7 footer.footer-expand-xs > .section-social > .profile, body.footer-links-7 footer.footer-expand-xs > .container > .profile-page, body.footer-links-7 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-xs > .container > .profile-section, body.footer-links-7 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-7 footer.footer-expand-xs > .row,
  body.footer-links-7 footer.footer-expand-xs > .card-data-list,
  body.footer-links-7 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-7 footer.footer-expand-xs > .profile,
  body.footer-links-7 footer.footer-expand-xs > .profile-page,
  body.footer-links-7 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-7 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-7 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-7 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-7 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-7 footer.footer-expand-sm > .container > .row, body.footer-links-7 footer.footer-expand-sm > .section-social > .row, body.footer-links-7 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-7 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-sm > .container > .profile, body.footer-links-7 footer.footer-expand-sm > .section-social > .profile, body.footer-links-7 footer.footer-expand-sm > .container > .profile-page, body.footer-links-7 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-sm > .container > .profile-section, body.footer-links-7 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-7 footer.footer-expand-sm > .row,
  body.footer-links-7 footer.footer-expand-sm > .card-data-list,
  body.footer-links-7 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-7 footer.footer-expand-sm > .profile,
  body.footer-links-7 footer.footer-expand-sm > .profile-page,
  body.footer-links-7 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-7 footer.footer-expand-sm > .container > .row, body.footer-links-7 footer.footer-expand-sm > .section-social > .row, body.footer-links-7 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-7 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-sm > .container > .profile, body.footer-links-7 footer.footer-expand-sm > .section-social > .profile, body.footer-links-7 footer.footer-expand-sm > .container > .profile-page, body.footer-links-7 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-sm > .container > .profile-section, body.footer-links-7 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-7 footer.footer-expand-sm > .row,
      body.footer-links-7 footer.footer-expand-sm > .card-data-list,
      body.footer-links-7 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-7 footer.footer-expand-sm > .profile,
      body.footer-links-7 footer.footer-expand-sm > .profile-page,
      body.footer-links-7 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-7 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-7 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-7 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-7 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-7 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-7 footer.footer-expand-md {
      height: 421px;
      padding: 40px 0 50px; } }
  body.footer-links-7 footer.footer-expand-md > .container > .row, body.footer-links-7 footer.footer-expand-md > .section-social > .row, body.footer-links-7 footer.footer-expand-md > .container > .card-data-list, body.footer-links-7 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-md > .container > .profile, body.footer-links-7 footer.footer-expand-md > .section-social > .profile, body.footer-links-7 footer.footer-expand-md > .container > .profile-page, body.footer-links-7 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-md > .container > .profile-section, body.footer-links-7 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-7 footer.footer-expand-md > .row,
  body.footer-links-7 footer.footer-expand-md > .card-data-list,
  body.footer-links-7 footer.footer-expand-md > .card-featured-news,
  body.footer-links-7 footer.footer-expand-md > .profile,
  body.footer-links-7 footer.footer-expand-md > .profile-page,
  body.footer-links-7 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-7 footer.footer-expand-md > .container > .row, body.footer-links-7 footer.footer-expand-md > .section-social > .row, body.footer-links-7 footer.footer-expand-md > .container > .card-data-list, body.footer-links-7 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-md > .container > .profile, body.footer-links-7 footer.footer-expand-md > .section-social > .profile, body.footer-links-7 footer.footer-expand-md > .container > .profile-page, body.footer-links-7 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-md > .container > .profile-section, body.footer-links-7 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-7 footer.footer-expand-md > .row,
      body.footer-links-7 footer.footer-expand-md > .card-data-list,
      body.footer-links-7 footer.footer-expand-md > .card-featured-news,
      body.footer-links-7 footer.footer-expand-md > .profile,
      body.footer-links-7 footer.footer-expand-md > .profile-page,
      body.footer-links-7 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-7 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-7 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-7 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-7 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-7 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-7 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-7 footer.footer-expand-lg > .container > .row, body.footer-links-7 footer.footer-expand-lg > .section-social > .row, body.footer-links-7 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-7 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-lg > .container > .profile, body.footer-links-7 footer.footer-expand-lg > .section-social > .profile, body.footer-links-7 footer.footer-expand-lg > .container > .profile-page, body.footer-links-7 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-lg > .container > .profile-section, body.footer-links-7 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-7 footer.footer-expand-lg > .row,
  body.footer-links-7 footer.footer-expand-lg > .card-data-list,
  body.footer-links-7 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-7 footer.footer-expand-lg > .profile,
  body.footer-links-7 footer.footer-expand-lg > .profile-page,
  body.footer-links-7 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-7 footer.footer-expand-lg > .container > .row, body.footer-links-7 footer.footer-expand-lg > .section-social > .row, body.footer-links-7 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-7 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-lg > .container > .profile, body.footer-links-7 footer.footer-expand-lg > .section-social > .profile, body.footer-links-7 footer.footer-expand-lg > .container > .profile-page, body.footer-links-7 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-lg > .container > .profile-section, body.footer-links-7 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-7 footer.footer-expand-lg > .row,
      body.footer-links-7 footer.footer-expand-lg > .card-data-list,
      body.footer-links-7 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-7 footer.footer-expand-lg > .profile,
      body.footer-links-7 footer.footer-expand-lg > .profile-page,
      body.footer-links-7 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-7 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-7 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-7 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-7 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-7 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-7 footer.footer-expand-xl {
      height: 276px;
      padding: 40px 0 50px; } }
  body.footer-links-7 footer.footer-expand-xl > .container > .row, body.footer-links-7 footer.footer-expand-xl > .section-social > .row, body.footer-links-7 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-7 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-xl > .container > .profile, body.footer-links-7 footer.footer-expand-xl > .section-social > .profile, body.footer-links-7 footer.footer-expand-xl > .container > .profile-page, body.footer-links-7 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-xl > .container > .profile-section, body.footer-links-7 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-7 footer.footer-expand-xl > .row,
  body.footer-links-7 footer.footer-expand-xl > .card-data-list,
  body.footer-links-7 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-7 footer.footer-expand-xl > .profile,
  body.footer-links-7 footer.footer-expand-xl > .profile-page,
  body.footer-links-7 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-7 footer.footer-expand-xl > .container > .row, body.footer-links-7 footer.footer-expand-xl > .section-social > .row, body.footer-links-7 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-7 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-7 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-7 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-7 footer.footer-expand-xl > .container > .profile, body.footer-links-7 footer.footer-expand-xl > .section-social > .profile, body.footer-links-7 footer.footer-expand-xl > .container > .profile-page, body.footer-links-7 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-7 footer.footer-expand-xl > .container > .profile-section, body.footer-links-7 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-7 footer.footer-expand-xl > .row,
      body.footer-links-7 footer.footer-expand-xl > .card-data-list,
      body.footer-links-7 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-7 footer.footer-expand-xl > .profile,
      body.footer-links-7 footer.footer-expand-xl > .profile-page,
      body.footer-links-7 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-7 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-7 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-7 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-7 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-7 footer .footer-links {
  height: 308px; }
  @media (min-width: 768px) {
    body.footer-links-7 footer .footer-links {
      height: 176px; } }

body.footer-links-8 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-8 main {
      min-height: calc(100vh - 490px); } }
  @media (min-width: 1200px) {
    body.footer-links-8 main {
      min-height: calc(100vh - 345px); } }

body.footer-links-8 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-8 footer.footer-expand-xs > .container > .row, body.footer-links-8 footer.footer-expand-xs > .section-social > .row, body.footer-links-8 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-8 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-xs > .container > .profile, body.footer-links-8 footer.footer-expand-xs > .section-social > .profile, body.footer-links-8 footer.footer-expand-xs > .container > .profile-page, body.footer-links-8 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-xs > .container > .profile-section, body.footer-links-8 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-8 footer.footer-expand-xs > .row,
  body.footer-links-8 footer.footer-expand-xs > .card-data-list,
  body.footer-links-8 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-8 footer.footer-expand-xs > .profile,
  body.footer-links-8 footer.footer-expand-xs > .profile-page,
  body.footer-links-8 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-8 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-8 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-8 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-8 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-8 footer.footer-expand-sm > .container > .row, body.footer-links-8 footer.footer-expand-sm > .section-social > .row, body.footer-links-8 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-8 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-sm > .container > .profile, body.footer-links-8 footer.footer-expand-sm > .section-social > .profile, body.footer-links-8 footer.footer-expand-sm > .container > .profile-page, body.footer-links-8 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-sm > .container > .profile-section, body.footer-links-8 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-8 footer.footer-expand-sm > .row,
  body.footer-links-8 footer.footer-expand-sm > .card-data-list,
  body.footer-links-8 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-8 footer.footer-expand-sm > .profile,
  body.footer-links-8 footer.footer-expand-sm > .profile-page,
  body.footer-links-8 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-8 footer.footer-expand-sm > .container > .row, body.footer-links-8 footer.footer-expand-sm > .section-social > .row, body.footer-links-8 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-8 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-sm > .container > .profile, body.footer-links-8 footer.footer-expand-sm > .section-social > .profile, body.footer-links-8 footer.footer-expand-sm > .container > .profile-page, body.footer-links-8 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-sm > .container > .profile-section, body.footer-links-8 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-8 footer.footer-expand-sm > .row,
      body.footer-links-8 footer.footer-expand-sm > .card-data-list,
      body.footer-links-8 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-8 footer.footer-expand-sm > .profile,
      body.footer-links-8 footer.footer-expand-sm > .profile-page,
      body.footer-links-8 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-8 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-8 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-8 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-8 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-8 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-8 footer.footer-expand-md {
      height: 421px;
      padding: 40px 0 50px; } }
  body.footer-links-8 footer.footer-expand-md > .container > .row, body.footer-links-8 footer.footer-expand-md > .section-social > .row, body.footer-links-8 footer.footer-expand-md > .container > .card-data-list, body.footer-links-8 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-md > .container > .profile, body.footer-links-8 footer.footer-expand-md > .section-social > .profile, body.footer-links-8 footer.footer-expand-md > .container > .profile-page, body.footer-links-8 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-md > .container > .profile-section, body.footer-links-8 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-8 footer.footer-expand-md > .row,
  body.footer-links-8 footer.footer-expand-md > .card-data-list,
  body.footer-links-8 footer.footer-expand-md > .card-featured-news,
  body.footer-links-8 footer.footer-expand-md > .profile,
  body.footer-links-8 footer.footer-expand-md > .profile-page,
  body.footer-links-8 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-8 footer.footer-expand-md > .container > .row, body.footer-links-8 footer.footer-expand-md > .section-social > .row, body.footer-links-8 footer.footer-expand-md > .container > .card-data-list, body.footer-links-8 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-md > .container > .profile, body.footer-links-8 footer.footer-expand-md > .section-social > .profile, body.footer-links-8 footer.footer-expand-md > .container > .profile-page, body.footer-links-8 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-md > .container > .profile-section, body.footer-links-8 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-8 footer.footer-expand-md > .row,
      body.footer-links-8 footer.footer-expand-md > .card-data-list,
      body.footer-links-8 footer.footer-expand-md > .card-featured-news,
      body.footer-links-8 footer.footer-expand-md > .profile,
      body.footer-links-8 footer.footer-expand-md > .profile-page,
      body.footer-links-8 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-8 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-8 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-8 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-8 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-8 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-8 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-8 footer.footer-expand-lg > .container > .row, body.footer-links-8 footer.footer-expand-lg > .section-social > .row, body.footer-links-8 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-8 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-lg > .container > .profile, body.footer-links-8 footer.footer-expand-lg > .section-social > .profile, body.footer-links-8 footer.footer-expand-lg > .container > .profile-page, body.footer-links-8 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-lg > .container > .profile-section, body.footer-links-8 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-8 footer.footer-expand-lg > .row,
  body.footer-links-8 footer.footer-expand-lg > .card-data-list,
  body.footer-links-8 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-8 footer.footer-expand-lg > .profile,
  body.footer-links-8 footer.footer-expand-lg > .profile-page,
  body.footer-links-8 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-8 footer.footer-expand-lg > .container > .row, body.footer-links-8 footer.footer-expand-lg > .section-social > .row, body.footer-links-8 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-8 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-lg > .container > .profile, body.footer-links-8 footer.footer-expand-lg > .section-social > .profile, body.footer-links-8 footer.footer-expand-lg > .container > .profile-page, body.footer-links-8 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-lg > .container > .profile-section, body.footer-links-8 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-8 footer.footer-expand-lg > .row,
      body.footer-links-8 footer.footer-expand-lg > .card-data-list,
      body.footer-links-8 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-8 footer.footer-expand-lg > .profile,
      body.footer-links-8 footer.footer-expand-lg > .profile-page,
      body.footer-links-8 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-8 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-8 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-8 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-8 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-8 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-8 footer.footer-expand-xl {
      height: 276px;
      padding: 40px 0 50px; } }
  body.footer-links-8 footer.footer-expand-xl > .container > .row, body.footer-links-8 footer.footer-expand-xl > .section-social > .row, body.footer-links-8 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-8 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-xl > .container > .profile, body.footer-links-8 footer.footer-expand-xl > .section-social > .profile, body.footer-links-8 footer.footer-expand-xl > .container > .profile-page, body.footer-links-8 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-xl > .container > .profile-section, body.footer-links-8 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-8 footer.footer-expand-xl > .row,
  body.footer-links-8 footer.footer-expand-xl > .card-data-list,
  body.footer-links-8 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-8 footer.footer-expand-xl > .profile,
  body.footer-links-8 footer.footer-expand-xl > .profile-page,
  body.footer-links-8 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-8 footer.footer-expand-xl > .container > .row, body.footer-links-8 footer.footer-expand-xl > .section-social > .row, body.footer-links-8 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-8 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-8 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-8 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-8 footer.footer-expand-xl > .container > .profile, body.footer-links-8 footer.footer-expand-xl > .section-social > .profile, body.footer-links-8 footer.footer-expand-xl > .container > .profile-page, body.footer-links-8 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-8 footer.footer-expand-xl > .container > .profile-section, body.footer-links-8 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-8 footer.footer-expand-xl > .row,
      body.footer-links-8 footer.footer-expand-xl > .card-data-list,
      body.footer-links-8 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-8 footer.footer-expand-xl > .profile,
      body.footer-links-8 footer.footer-expand-xl > .profile-page,
      body.footer-links-8 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-8 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-8 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-8 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-8 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-8 footer .footer-links {
  height: 352px; }
  @media (min-width: 768px) {
    body.footer-links-8 footer .footer-links {
      height: 176px; } }

body.footer-links-9 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-9 main {
      min-height: calc(100vh - 534px); } }
  @media (min-width: 1200px) {
    body.footer-links-9 main {
      min-height: calc(100vh - 389px); } }

body.footer-links-9 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-9 footer.footer-expand-xs > .container > .row, body.footer-links-9 footer.footer-expand-xs > .section-social > .row, body.footer-links-9 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-9 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-xs > .container > .profile, body.footer-links-9 footer.footer-expand-xs > .section-social > .profile, body.footer-links-9 footer.footer-expand-xs > .container > .profile-page, body.footer-links-9 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-xs > .container > .profile-section, body.footer-links-9 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-9 footer.footer-expand-xs > .row,
  body.footer-links-9 footer.footer-expand-xs > .card-data-list,
  body.footer-links-9 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-9 footer.footer-expand-xs > .profile,
  body.footer-links-9 footer.footer-expand-xs > .profile-page,
  body.footer-links-9 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-9 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-9 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-9 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-9 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-9 footer.footer-expand-sm > .container > .row, body.footer-links-9 footer.footer-expand-sm > .section-social > .row, body.footer-links-9 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-9 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-sm > .container > .profile, body.footer-links-9 footer.footer-expand-sm > .section-social > .profile, body.footer-links-9 footer.footer-expand-sm > .container > .profile-page, body.footer-links-9 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-sm > .container > .profile-section, body.footer-links-9 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-9 footer.footer-expand-sm > .row,
  body.footer-links-9 footer.footer-expand-sm > .card-data-list,
  body.footer-links-9 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-9 footer.footer-expand-sm > .profile,
  body.footer-links-9 footer.footer-expand-sm > .profile-page,
  body.footer-links-9 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-9 footer.footer-expand-sm > .container > .row, body.footer-links-9 footer.footer-expand-sm > .section-social > .row, body.footer-links-9 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-9 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-sm > .container > .profile, body.footer-links-9 footer.footer-expand-sm > .section-social > .profile, body.footer-links-9 footer.footer-expand-sm > .container > .profile-page, body.footer-links-9 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-sm > .container > .profile-section, body.footer-links-9 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-9 footer.footer-expand-sm > .row,
      body.footer-links-9 footer.footer-expand-sm > .card-data-list,
      body.footer-links-9 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-9 footer.footer-expand-sm > .profile,
      body.footer-links-9 footer.footer-expand-sm > .profile-page,
      body.footer-links-9 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-9 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-9 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-9 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-9 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-9 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-9 footer.footer-expand-md {
      height: 465px;
      padding: 40px 0 50px; } }
  body.footer-links-9 footer.footer-expand-md > .container > .row, body.footer-links-9 footer.footer-expand-md > .section-social > .row, body.footer-links-9 footer.footer-expand-md > .container > .card-data-list, body.footer-links-9 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-md > .container > .profile, body.footer-links-9 footer.footer-expand-md > .section-social > .profile, body.footer-links-9 footer.footer-expand-md > .container > .profile-page, body.footer-links-9 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-md > .container > .profile-section, body.footer-links-9 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-9 footer.footer-expand-md > .row,
  body.footer-links-9 footer.footer-expand-md > .card-data-list,
  body.footer-links-9 footer.footer-expand-md > .card-featured-news,
  body.footer-links-9 footer.footer-expand-md > .profile,
  body.footer-links-9 footer.footer-expand-md > .profile-page,
  body.footer-links-9 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-9 footer.footer-expand-md > .container > .row, body.footer-links-9 footer.footer-expand-md > .section-social > .row, body.footer-links-9 footer.footer-expand-md > .container > .card-data-list, body.footer-links-9 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-md > .container > .profile, body.footer-links-9 footer.footer-expand-md > .section-social > .profile, body.footer-links-9 footer.footer-expand-md > .container > .profile-page, body.footer-links-9 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-md > .container > .profile-section, body.footer-links-9 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-9 footer.footer-expand-md > .row,
      body.footer-links-9 footer.footer-expand-md > .card-data-list,
      body.footer-links-9 footer.footer-expand-md > .card-featured-news,
      body.footer-links-9 footer.footer-expand-md > .profile,
      body.footer-links-9 footer.footer-expand-md > .profile-page,
      body.footer-links-9 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-9 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-9 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-9 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-9 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-9 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-9 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-9 footer.footer-expand-lg > .container > .row, body.footer-links-9 footer.footer-expand-lg > .section-social > .row, body.footer-links-9 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-9 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-lg > .container > .profile, body.footer-links-9 footer.footer-expand-lg > .section-social > .profile, body.footer-links-9 footer.footer-expand-lg > .container > .profile-page, body.footer-links-9 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-lg > .container > .profile-section, body.footer-links-9 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-9 footer.footer-expand-lg > .row,
  body.footer-links-9 footer.footer-expand-lg > .card-data-list,
  body.footer-links-9 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-9 footer.footer-expand-lg > .profile,
  body.footer-links-9 footer.footer-expand-lg > .profile-page,
  body.footer-links-9 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-9 footer.footer-expand-lg > .container > .row, body.footer-links-9 footer.footer-expand-lg > .section-social > .row, body.footer-links-9 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-9 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-lg > .container > .profile, body.footer-links-9 footer.footer-expand-lg > .section-social > .profile, body.footer-links-9 footer.footer-expand-lg > .container > .profile-page, body.footer-links-9 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-lg > .container > .profile-section, body.footer-links-9 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-9 footer.footer-expand-lg > .row,
      body.footer-links-9 footer.footer-expand-lg > .card-data-list,
      body.footer-links-9 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-9 footer.footer-expand-lg > .profile,
      body.footer-links-9 footer.footer-expand-lg > .profile-page,
      body.footer-links-9 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-9 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-9 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-9 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-9 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-9 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-9 footer.footer-expand-xl {
      height: 320px;
      padding: 40px 0 50px; } }
  body.footer-links-9 footer.footer-expand-xl > .container > .row, body.footer-links-9 footer.footer-expand-xl > .section-social > .row, body.footer-links-9 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-9 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-xl > .container > .profile, body.footer-links-9 footer.footer-expand-xl > .section-social > .profile, body.footer-links-9 footer.footer-expand-xl > .container > .profile-page, body.footer-links-9 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-xl > .container > .profile-section, body.footer-links-9 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-9 footer.footer-expand-xl > .row,
  body.footer-links-9 footer.footer-expand-xl > .card-data-list,
  body.footer-links-9 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-9 footer.footer-expand-xl > .profile,
  body.footer-links-9 footer.footer-expand-xl > .profile-page,
  body.footer-links-9 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-9 footer.footer-expand-xl > .container > .row, body.footer-links-9 footer.footer-expand-xl > .section-social > .row, body.footer-links-9 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-9 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-9 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-9 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-9 footer.footer-expand-xl > .container > .profile, body.footer-links-9 footer.footer-expand-xl > .section-social > .profile, body.footer-links-9 footer.footer-expand-xl > .container > .profile-page, body.footer-links-9 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-9 footer.footer-expand-xl > .container > .profile-section, body.footer-links-9 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-9 footer.footer-expand-xl > .row,
      body.footer-links-9 footer.footer-expand-xl > .card-data-list,
      body.footer-links-9 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-9 footer.footer-expand-xl > .profile,
      body.footer-links-9 footer.footer-expand-xl > .profile-page,
      body.footer-links-9 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-9 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-9 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-9 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-9 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-9 footer .footer-links {
  height: 396px; }
  @media (min-width: 768px) {
    body.footer-links-9 footer .footer-links {
      height: 220px; } }

body.footer-links-10 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-10 main {
      min-height: calc(100vh - 534px); } }
  @media (min-width: 1200px) {
    body.footer-links-10 main {
      min-height: calc(100vh - 389px); } }

body.footer-links-10 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-10 footer.footer-expand-xs > .container > .row, body.footer-links-10 footer.footer-expand-xs > .section-social > .row, body.footer-links-10 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-10 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-xs > .container > .profile, body.footer-links-10 footer.footer-expand-xs > .section-social > .profile, body.footer-links-10 footer.footer-expand-xs > .container > .profile-page, body.footer-links-10 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-xs > .container > .profile-section, body.footer-links-10 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-10 footer.footer-expand-xs > .row,
  body.footer-links-10 footer.footer-expand-xs > .card-data-list,
  body.footer-links-10 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-10 footer.footer-expand-xs > .profile,
  body.footer-links-10 footer.footer-expand-xs > .profile-page,
  body.footer-links-10 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-10 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-10 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-10 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-10 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-10 footer.footer-expand-sm > .container > .row, body.footer-links-10 footer.footer-expand-sm > .section-social > .row, body.footer-links-10 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-10 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-sm > .container > .profile, body.footer-links-10 footer.footer-expand-sm > .section-social > .profile, body.footer-links-10 footer.footer-expand-sm > .container > .profile-page, body.footer-links-10 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-sm > .container > .profile-section, body.footer-links-10 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-10 footer.footer-expand-sm > .row,
  body.footer-links-10 footer.footer-expand-sm > .card-data-list,
  body.footer-links-10 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-10 footer.footer-expand-sm > .profile,
  body.footer-links-10 footer.footer-expand-sm > .profile-page,
  body.footer-links-10 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-10 footer.footer-expand-sm > .container > .row, body.footer-links-10 footer.footer-expand-sm > .section-social > .row, body.footer-links-10 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-10 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-sm > .container > .profile, body.footer-links-10 footer.footer-expand-sm > .section-social > .profile, body.footer-links-10 footer.footer-expand-sm > .container > .profile-page, body.footer-links-10 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-sm > .container > .profile-section, body.footer-links-10 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-10 footer.footer-expand-sm > .row,
      body.footer-links-10 footer.footer-expand-sm > .card-data-list,
      body.footer-links-10 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-10 footer.footer-expand-sm > .profile,
      body.footer-links-10 footer.footer-expand-sm > .profile-page,
      body.footer-links-10 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-10 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-10 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-10 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-10 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-10 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-10 footer.footer-expand-md {
      height: 465px;
      padding: 40px 0 50px; } }
  body.footer-links-10 footer.footer-expand-md > .container > .row, body.footer-links-10 footer.footer-expand-md > .section-social > .row, body.footer-links-10 footer.footer-expand-md > .container > .card-data-list, body.footer-links-10 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-md > .container > .profile, body.footer-links-10 footer.footer-expand-md > .section-social > .profile, body.footer-links-10 footer.footer-expand-md > .container > .profile-page, body.footer-links-10 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-md > .container > .profile-section, body.footer-links-10 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-10 footer.footer-expand-md > .row,
  body.footer-links-10 footer.footer-expand-md > .card-data-list,
  body.footer-links-10 footer.footer-expand-md > .card-featured-news,
  body.footer-links-10 footer.footer-expand-md > .profile,
  body.footer-links-10 footer.footer-expand-md > .profile-page,
  body.footer-links-10 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-10 footer.footer-expand-md > .container > .row, body.footer-links-10 footer.footer-expand-md > .section-social > .row, body.footer-links-10 footer.footer-expand-md > .container > .card-data-list, body.footer-links-10 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-md > .container > .profile, body.footer-links-10 footer.footer-expand-md > .section-social > .profile, body.footer-links-10 footer.footer-expand-md > .container > .profile-page, body.footer-links-10 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-md > .container > .profile-section, body.footer-links-10 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-10 footer.footer-expand-md > .row,
      body.footer-links-10 footer.footer-expand-md > .card-data-list,
      body.footer-links-10 footer.footer-expand-md > .card-featured-news,
      body.footer-links-10 footer.footer-expand-md > .profile,
      body.footer-links-10 footer.footer-expand-md > .profile-page,
      body.footer-links-10 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-10 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-10 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-10 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-10 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-10 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-10 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-10 footer.footer-expand-lg > .container > .row, body.footer-links-10 footer.footer-expand-lg > .section-social > .row, body.footer-links-10 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-10 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-lg > .container > .profile, body.footer-links-10 footer.footer-expand-lg > .section-social > .profile, body.footer-links-10 footer.footer-expand-lg > .container > .profile-page, body.footer-links-10 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-lg > .container > .profile-section, body.footer-links-10 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-10 footer.footer-expand-lg > .row,
  body.footer-links-10 footer.footer-expand-lg > .card-data-list,
  body.footer-links-10 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-10 footer.footer-expand-lg > .profile,
  body.footer-links-10 footer.footer-expand-lg > .profile-page,
  body.footer-links-10 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-10 footer.footer-expand-lg > .container > .row, body.footer-links-10 footer.footer-expand-lg > .section-social > .row, body.footer-links-10 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-10 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-lg > .container > .profile, body.footer-links-10 footer.footer-expand-lg > .section-social > .profile, body.footer-links-10 footer.footer-expand-lg > .container > .profile-page, body.footer-links-10 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-lg > .container > .profile-section, body.footer-links-10 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-10 footer.footer-expand-lg > .row,
      body.footer-links-10 footer.footer-expand-lg > .card-data-list,
      body.footer-links-10 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-10 footer.footer-expand-lg > .profile,
      body.footer-links-10 footer.footer-expand-lg > .profile-page,
      body.footer-links-10 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-10 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-10 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-10 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-10 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-10 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-10 footer.footer-expand-xl {
      height: 320px;
      padding: 40px 0 50px; } }
  body.footer-links-10 footer.footer-expand-xl > .container > .row, body.footer-links-10 footer.footer-expand-xl > .section-social > .row, body.footer-links-10 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-10 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-xl > .container > .profile, body.footer-links-10 footer.footer-expand-xl > .section-social > .profile, body.footer-links-10 footer.footer-expand-xl > .container > .profile-page, body.footer-links-10 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-xl > .container > .profile-section, body.footer-links-10 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-10 footer.footer-expand-xl > .row,
  body.footer-links-10 footer.footer-expand-xl > .card-data-list,
  body.footer-links-10 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-10 footer.footer-expand-xl > .profile,
  body.footer-links-10 footer.footer-expand-xl > .profile-page,
  body.footer-links-10 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-10 footer.footer-expand-xl > .container > .row, body.footer-links-10 footer.footer-expand-xl > .section-social > .row, body.footer-links-10 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-10 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-10 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-10 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-10 footer.footer-expand-xl > .container > .profile, body.footer-links-10 footer.footer-expand-xl > .section-social > .profile, body.footer-links-10 footer.footer-expand-xl > .container > .profile-page, body.footer-links-10 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-10 footer.footer-expand-xl > .container > .profile-section, body.footer-links-10 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-10 footer.footer-expand-xl > .row,
      body.footer-links-10 footer.footer-expand-xl > .card-data-list,
      body.footer-links-10 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-10 footer.footer-expand-xl > .profile,
      body.footer-links-10 footer.footer-expand-xl > .profile-page,
      body.footer-links-10 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-10 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-10 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-10 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-10 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-10 footer .footer-links {
  height: 440px; }
  @media (min-width: 768px) {
    body.footer-links-10 footer .footer-links {
      height: 220px; } }

body.footer-links-11 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-11 main {
      min-height: calc(100vh - 578px); } }
  @media (min-width: 1200px) {
    body.footer-links-11 main {
      min-height: calc(100vh - 433px); } }

body.footer-links-11 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-11 footer.footer-expand-xs > .container > .row, body.footer-links-11 footer.footer-expand-xs > .section-social > .row, body.footer-links-11 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-11 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-xs > .container > .profile, body.footer-links-11 footer.footer-expand-xs > .section-social > .profile, body.footer-links-11 footer.footer-expand-xs > .container > .profile-page, body.footer-links-11 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-xs > .container > .profile-section, body.footer-links-11 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-11 footer.footer-expand-xs > .row,
  body.footer-links-11 footer.footer-expand-xs > .card-data-list,
  body.footer-links-11 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-11 footer.footer-expand-xs > .profile,
  body.footer-links-11 footer.footer-expand-xs > .profile-page,
  body.footer-links-11 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-11 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-11 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-11 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-11 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-11 footer.footer-expand-sm > .container > .row, body.footer-links-11 footer.footer-expand-sm > .section-social > .row, body.footer-links-11 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-11 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-sm > .container > .profile, body.footer-links-11 footer.footer-expand-sm > .section-social > .profile, body.footer-links-11 footer.footer-expand-sm > .container > .profile-page, body.footer-links-11 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-sm > .container > .profile-section, body.footer-links-11 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-11 footer.footer-expand-sm > .row,
  body.footer-links-11 footer.footer-expand-sm > .card-data-list,
  body.footer-links-11 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-11 footer.footer-expand-sm > .profile,
  body.footer-links-11 footer.footer-expand-sm > .profile-page,
  body.footer-links-11 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-11 footer.footer-expand-sm > .container > .row, body.footer-links-11 footer.footer-expand-sm > .section-social > .row, body.footer-links-11 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-11 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-sm > .container > .profile, body.footer-links-11 footer.footer-expand-sm > .section-social > .profile, body.footer-links-11 footer.footer-expand-sm > .container > .profile-page, body.footer-links-11 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-sm > .container > .profile-section, body.footer-links-11 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-11 footer.footer-expand-sm > .row,
      body.footer-links-11 footer.footer-expand-sm > .card-data-list,
      body.footer-links-11 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-11 footer.footer-expand-sm > .profile,
      body.footer-links-11 footer.footer-expand-sm > .profile-page,
      body.footer-links-11 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-11 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-11 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-11 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-11 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-11 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-11 footer.footer-expand-md {
      height: 509px;
      padding: 40px 0 50px; } }
  body.footer-links-11 footer.footer-expand-md > .container > .row, body.footer-links-11 footer.footer-expand-md > .section-social > .row, body.footer-links-11 footer.footer-expand-md > .container > .card-data-list, body.footer-links-11 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-md > .container > .profile, body.footer-links-11 footer.footer-expand-md > .section-social > .profile, body.footer-links-11 footer.footer-expand-md > .container > .profile-page, body.footer-links-11 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-md > .container > .profile-section, body.footer-links-11 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-11 footer.footer-expand-md > .row,
  body.footer-links-11 footer.footer-expand-md > .card-data-list,
  body.footer-links-11 footer.footer-expand-md > .card-featured-news,
  body.footer-links-11 footer.footer-expand-md > .profile,
  body.footer-links-11 footer.footer-expand-md > .profile-page,
  body.footer-links-11 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-11 footer.footer-expand-md > .container > .row, body.footer-links-11 footer.footer-expand-md > .section-social > .row, body.footer-links-11 footer.footer-expand-md > .container > .card-data-list, body.footer-links-11 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-md > .container > .profile, body.footer-links-11 footer.footer-expand-md > .section-social > .profile, body.footer-links-11 footer.footer-expand-md > .container > .profile-page, body.footer-links-11 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-md > .container > .profile-section, body.footer-links-11 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-11 footer.footer-expand-md > .row,
      body.footer-links-11 footer.footer-expand-md > .card-data-list,
      body.footer-links-11 footer.footer-expand-md > .card-featured-news,
      body.footer-links-11 footer.footer-expand-md > .profile,
      body.footer-links-11 footer.footer-expand-md > .profile-page,
      body.footer-links-11 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-11 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-11 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-11 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-11 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-11 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-11 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-11 footer.footer-expand-lg > .container > .row, body.footer-links-11 footer.footer-expand-lg > .section-social > .row, body.footer-links-11 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-11 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-lg > .container > .profile, body.footer-links-11 footer.footer-expand-lg > .section-social > .profile, body.footer-links-11 footer.footer-expand-lg > .container > .profile-page, body.footer-links-11 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-lg > .container > .profile-section, body.footer-links-11 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-11 footer.footer-expand-lg > .row,
  body.footer-links-11 footer.footer-expand-lg > .card-data-list,
  body.footer-links-11 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-11 footer.footer-expand-lg > .profile,
  body.footer-links-11 footer.footer-expand-lg > .profile-page,
  body.footer-links-11 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-11 footer.footer-expand-lg > .container > .row, body.footer-links-11 footer.footer-expand-lg > .section-social > .row, body.footer-links-11 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-11 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-lg > .container > .profile, body.footer-links-11 footer.footer-expand-lg > .section-social > .profile, body.footer-links-11 footer.footer-expand-lg > .container > .profile-page, body.footer-links-11 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-lg > .container > .profile-section, body.footer-links-11 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-11 footer.footer-expand-lg > .row,
      body.footer-links-11 footer.footer-expand-lg > .card-data-list,
      body.footer-links-11 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-11 footer.footer-expand-lg > .profile,
      body.footer-links-11 footer.footer-expand-lg > .profile-page,
      body.footer-links-11 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-11 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-11 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-11 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-11 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-11 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-11 footer.footer-expand-xl {
      height: 364px;
      padding: 40px 0 50px; } }
  body.footer-links-11 footer.footer-expand-xl > .container > .row, body.footer-links-11 footer.footer-expand-xl > .section-social > .row, body.footer-links-11 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-11 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-xl > .container > .profile, body.footer-links-11 footer.footer-expand-xl > .section-social > .profile, body.footer-links-11 footer.footer-expand-xl > .container > .profile-page, body.footer-links-11 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-xl > .container > .profile-section, body.footer-links-11 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-11 footer.footer-expand-xl > .row,
  body.footer-links-11 footer.footer-expand-xl > .card-data-list,
  body.footer-links-11 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-11 footer.footer-expand-xl > .profile,
  body.footer-links-11 footer.footer-expand-xl > .profile-page,
  body.footer-links-11 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-11 footer.footer-expand-xl > .container > .row, body.footer-links-11 footer.footer-expand-xl > .section-social > .row, body.footer-links-11 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-11 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-11 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-11 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-11 footer.footer-expand-xl > .container > .profile, body.footer-links-11 footer.footer-expand-xl > .section-social > .profile, body.footer-links-11 footer.footer-expand-xl > .container > .profile-page, body.footer-links-11 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-11 footer.footer-expand-xl > .container > .profile-section, body.footer-links-11 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-11 footer.footer-expand-xl > .row,
      body.footer-links-11 footer.footer-expand-xl > .card-data-list,
      body.footer-links-11 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-11 footer.footer-expand-xl > .profile,
      body.footer-links-11 footer.footer-expand-xl > .profile-page,
      body.footer-links-11 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-11 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-11 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-11 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-11 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-11 footer .footer-links {
  height: 484px; }
  @media (min-width: 768px) {
    body.footer-links-11 footer .footer-links {
      height: 264px; } }

body.footer-links-12 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-12 main {
      min-height: calc(100vh - 578px); } }
  @media (min-width: 1200px) {
    body.footer-links-12 main {
      min-height: calc(100vh - 433px); } }

body.footer-links-12 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-12 footer.footer-expand-xs > .container > .row, body.footer-links-12 footer.footer-expand-xs > .section-social > .row, body.footer-links-12 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-12 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-xs > .container > .profile, body.footer-links-12 footer.footer-expand-xs > .section-social > .profile, body.footer-links-12 footer.footer-expand-xs > .container > .profile-page, body.footer-links-12 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-xs > .container > .profile-section, body.footer-links-12 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-12 footer.footer-expand-xs > .row,
  body.footer-links-12 footer.footer-expand-xs > .card-data-list,
  body.footer-links-12 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-12 footer.footer-expand-xs > .profile,
  body.footer-links-12 footer.footer-expand-xs > .profile-page,
  body.footer-links-12 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-12 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-12 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-12 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-12 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-12 footer.footer-expand-sm > .container > .row, body.footer-links-12 footer.footer-expand-sm > .section-social > .row, body.footer-links-12 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-12 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-sm > .container > .profile, body.footer-links-12 footer.footer-expand-sm > .section-social > .profile, body.footer-links-12 footer.footer-expand-sm > .container > .profile-page, body.footer-links-12 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-sm > .container > .profile-section, body.footer-links-12 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-12 footer.footer-expand-sm > .row,
  body.footer-links-12 footer.footer-expand-sm > .card-data-list,
  body.footer-links-12 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-12 footer.footer-expand-sm > .profile,
  body.footer-links-12 footer.footer-expand-sm > .profile-page,
  body.footer-links-12 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-12 footer.footer-expand-sm > .container > .row, body.footer-links-12 footer.footer-expand-sm > .section-social > .row, body.footer-links-12 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-12 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-sm > .container > .profile, body.footer-links-12 footer.footer-expand-sm > .section-social > .profile, body.footer-links-12 footer.footer-expand-sm > .container > .profile-page, body.footer-links-12 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-sm > .container > .profile-section, body.footer-links-12 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-12 footer.footer-expand-sm > .row,
      body.footer-links-12 footer.footer-expand-sm > .card-data-list,
      body.footer-links-12 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-12 footer.footer-expand-sm > .profile,
      body.footer-links-12 footer.footer-expand-sm > .profile-page,
      body.footer-links-12 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-12 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-12 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-12 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-12 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-12 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-12 footer.footer-expand-md {
      height: 509px;
      padding: 40px 0 50px; } }
  body.footer-links-12 footer.footer-expand-md > .container > .row, body.footer-links-12 footer.footer-expand-md > .section-social > .row, body.footer-links-12 footer.footer-expand-md > .container > .card-data-list, body.footer-links-12 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-md > .container > .profile, body.footer-links-12 footer.footer-expand-md > .section-social > .profile, body.footer-links-12 footer.footer-expand-md > .container > .profile-page, body.footer-links-12 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-md > .container > .profile-section, body.footer-links-12 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-12 footer.footer-expand-md > .row,
  body.footer-links-12 footer.footer-expand-md > .card-data-list,
  body.footer-links-12 footer.footer-expand-md > .card-featured-news,
  body.footer-links-12 footer.footer-expand-md > .profile,
  body.footer-links-12 footer.footer-expand-md > .profile-page,
  body.footer-links-12 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-12 footer.footer-expand-md > .container > .row, body.footer-links-12 footer.footer-expand-md > .section-social > .row, body.footer-links-12 footer.footer-expand-md > .container > .card-data-list, body.footer-links-12 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-md > .container > .profile, body.footer-links-12 footer.footer-expand-md > .section-social > .profile, body.footer-links-12 footer.footer-expand-md > .container > .profile-page, body.footer-links-12 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-md > .container > .profile-section, body.footer-links-12 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-12 footer.footer-expand-md > .row,
      body.footer-links-12 footer.footer-expand-md > .card-data-list,
      body.footer-links-12 footer.footer-expand-md > .card-featured-news,
      body.footer-links-12 footer.footer-expand-md > .profile,
      body.footer-links-12 footer.footer-expand-md > .profile-page,
      body.footer-links-12 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-12 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-12 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-12 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-12 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-12 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-12 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-12 footer.footer-expand-lg > .container > .row, body.footer-links-12 footer.footer-expand-lg > .section-social > .row, body.footer-links-12 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-12 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-lg > .container > .profile, body.footer-links-12 footer.footer-expand-lg > .section-social > .profile, body.footer-links-12 footer.footer-expand-lg > .container > .profile-page, body.footer-links-12 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-lg > .container > .profile-section, body.footer-links-12 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-12 footer.footer-expand-lg > .row,
  body.footer-links-12 footer.footer-expand-lg > .card-data-list,
  body.footer-links-12 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-12 footer.footer-expand-lg > .profile,
  body.footer-links-12 footer.footer-expand-lg > .profile-page,
  body.footer-links-12 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-12 footer.footer-expand-lg > .container > .row, body.footer-links-12 footer.footer-expand-lg > .section-social > .row, body.footer-links-12 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-12 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-lg > .container > .profile, body.footer-links-12 footer.footer-expand-lg > .section-social > .profile, body.footer-links-12 footer.footer-expand-lg > .container > .profile-page, body.footer-links-12 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-lg > .container > .profile-section, body.footer-links-12 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-12 footer.footer-expand-lg > .row,
      body.footer-links-12 footer.footer-expand-lg > .card-data-list,
      body.footer-links-12 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-12 footer.footer-expand-lg > .profile,
      body.footer-links-12 footer.footer-expand-lg > .profile-page,
      body.footer-links-12 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-12 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-12 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-12 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-12 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-12 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-12 footer.footer-expand-xl {
      height: 364px;
      padding: 40px 0 50px; } }
  body.footer-links-12 footer.footer-expand-xl > .container > .row, body.footer-links-12 footer.footer-expand-xl > .section-social > .row, body.footer-links-12 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-12 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-xl > .container > .profile, body.footer-links-12 footer.footer-expand-xl > .section-social > .profile, body.footer-links-12 footer.footer-expand-xl > .container > .profile-page, body.footer-links-12 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-xl > .container > .profile-section, body.footer-links-12 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-12 footer.footer-expand-xl > .row,
  body.footer-links-12 footer.footer-expand-xl > .card-data-list,
  body.footer-links-12 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-12 footer.footer-expand-xl > .profile,
  body.footer-links-12 footer.footer-expand-xl > .profile-page,
  body.footer-links-12 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-12 footer.footer-expand-xl > .container > .row, body.footer-links-12 footer.footer-expand-xl > .section-social > .row, body.footer-links-12 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-12 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-12 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-12 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-12 footer.footer-expand-xl > .container > .profile, body.footer-links-12 footer.footer-expand-xl > .section-social > .profile, body.footer-links-12 footer.footer-expand-xl > .container > .profile-page, body.footer-links-12 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-12 footer.footer-expand-xl > .container > .profile-section, body.footer-links-12 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-12 footer.footer-expand-xl > .row,
      body.footer-links-12 footer.footer-expand-xl > .card-data-list,
      body.footer-links-12 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-12 footer.footer-expand-xl > .profile,
      body.footer-links-12 footer.footer-expand-xl > .profile-page,
      body.footer-links-12 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-12 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-12 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-12 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-12 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-12 footer .footer-links {
  height: 528px; }
  @media (min-width: 768px) {
    body.footer-links-12 footer .footer-links {
      height: 264px; } }

body.footer-links-13 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-13 main {
      min-height: calc(100vh - 622px); } }
  @media (min-width: 1200px) {
    body.footer-links-13 main {
      min-height: calc(100vh - 477px); } }

body.footer-links-13 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-13 footer.footer-expand-xs > .container > .row, body.footer-links-13 footer.footer-expand-xs > .section-social > .row, body.footer-links-13 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-13 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-xs > .container > .profile, body.footer-links-13 footer.footer-expand-xs > .section-social > .profile, body.footer-links-13 footer.footer-expand-xs > .container > .profile-page, body.footer-links-13 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-xs > .container > .profile-section, body.footer-links-13 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-13 footer.footer-expand-xs > .row,
  body.footer-links-13 footer.footer-expand-xs > .card-data-list,
  body.footer-links-13 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-13 footer.footer-expand-xs > .profile,
  body.footer-links-13 footer.footer-expand-xs > .profile-page,
  body.footer-links-13 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-13 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-13 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-13 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-13 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-13 footer.footer-expand-sm > .container > .row, body.footer-links-13 footer.footer-expand-sm > .section-social > .row, body.footer-links-13 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-13 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-sm > .container > .profile, body.footer-links-13 footer.footer-expand-sm > .section-social > .profile, body.footer-links-13 footer.footer-expand-sm > .container > .profile-page, body.footer-links-13 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-sm > .container > .profile-section, body.footer-links-13 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-13 footer.footer-expand-sm > .row,
  body.footer-links-13 footer.footer-expand-sm > .card-data-list,
  body.footer-links-13 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-13 footer.footer-expand-sm > .profile,
  body.footer-links-13 footer.footer-expand-sm > .profile-page,
  body.footer-links-13 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-13 footer.footer-expand-sm > .container > .row, body.footer-links-13 footer.footer-expand-sm > .section-social > .row, body.footer-links-13 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-13 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-sm > .container > .profile, body.footer-links-13 footer.footer-expand-sm > .section-social > .profile, body.footer-links-13 footer.footer-expand-sm > .container > .profile-page, body.footer-links-13 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-sm > .container > .profile-section, body.footer-links-13 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-13 footer.footer-expand-sm > .row,
      body.footer-links-13 footer.footer-expand-sm > .card-data-list,
      body.footer-links-13 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-13 footer.footer-expand-sm > .profile,
      body.footer-links-13 footer.footer-expand-sm > .profile-page,
      body.footer-links-13 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-13 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-13 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-13 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-13 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-13 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-13 footer.footer-expand-md {
      height: 553px;
      padding: 40px 0 50px; } }
  body.footer-links-13 footer.footer-expand-md > .container > .row, body.footer-links-13 footer.footer-expand-md > .section-social > .row, body.footer-links-13 footer.footer-expand-md > .container > .card-data-list, body.footer-links-13 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-md > .container > .profile, body.footer-links-13 footer.footer-expand-md > .section-social > .profile, body.footer-links-13 footer.footer-expand-md > .container > .profile-page, body.footer-links-13 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-md > .container > .profile-section, body.footer-links-13 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-13 footer.footer-expand-md > .row,
  body.footer-links-13 footer.footer-expand-md > .card-data-list,
  body.footer-links-13 footer.footer-expand-md > .card-featured-news,
  body.footer-links-13 footer.footer-expand-md > .profile,
  body.footer-links-13 footer.footer-expand-md > .profile-page,
  body.footer-links-13 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-13 footer.footer-expand-md > .container > .row, body.footer-links-13 footer.footer-expand-md > .section-social > .row, body.footer-links-13 footer.footer-expand-md > .container > .card-data-list, body.footer-links-13 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-md > .container > .profile, body.footer-links-13 footer.footer-expand-md > .section-social > .profile, body.footer-links-13 footer.footer-expand-md > .container > .profile-page, body.footer-links-13 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-md > .container > .profile-section, body.footer-links-13 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-13 footer.footer-expand-md > .row,
      body.footer-links-13 footer.footer-expand-md > .card-data-list,
      body.footer-links-13 footer.footer-expand-md > .card-featured-news,
      body.footer-links-13 footer.footer-expand-md > .profile,
      body.footer-links-13 footer.footer-expand-md > .profile-page,
      body.footer-links-13 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-13 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-13 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-13 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-13 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-13 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-13 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-13 footer.footer-expand-lg > .container > .row, body.footer-links-13 footer.footer-expand-lg > .section-social > .row, body.footer-links-13 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-13 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-lg > .container > .profile, body.footer-links-13 footer.footer-expand-lg > .section-social > .profile, body.footer-links-13 footer.footer-expand-lg > .container > .profile-page, body.footer-links-13 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-lg > .container > .profile-section, body.footer-links-13 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-13 footer.footer-expand-lg > .row,
  body.footer-links-13 footer.footer-expand-lg > .card-data-list,
  body.footer-links-13 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-13 footer.footer-expand-lg > .profile,
  body.footer-links-13 footer.footer-expand-lg > .profile-page,
  body.footer-links-13 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-13 footer.footer-expand-lg > .container > .row, body.footer-links-13 footer.footer-expand-lg > .section-social > .row, body.footer-links-13 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-13 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-lg > .container > .profile, body.footer-links-13 footer.footer-expand-lg > .section-social > .profile, body.footer-links-13 footer.footer-expand-lg > .container > .profile-page, body.footer-links-13 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-lg > .container > .profile-section, body.footer-links-13 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-13 footer.footer-expand-lg > .row,
      body.footer-links-13 footer.footer-expand-lg > .card-data-list,
      body.footer-links-13 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-13 footer.footer-expand-lg > .profile,
      body.footer-links-13 footer.footer-expand-lg > .profile-page,
      body.footer-links-13 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-13 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-13 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-13 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-13 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-13 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-13 footer.footer-expand-xl {
      height: 408px;
      padding: 40px 0 50px; } }
  body.footer-links-13 footer.footer-expand-xl > .container > .row, body.footer-links-13 footer.footer-expand-xl > .section-social > .row, body.footer-links-13 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-13 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-xl > .container > .profile, body.footer-links-13 footer.footer-expand-xl > .section-social > .profile, body.footer-links-13 footer.footer-expand-xl > .container > .profile-page, body.footer-links-13 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-xl > .container > .profile-section, body.footer-links-13 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-13 footer.footer-expand-xl > .row,
  body.footer-links-13 footer.footer-expand-xl > .card-data-list,
  body.footer-links-13 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-13 footer.footer-expand-xl > .profile,
  body.footer-links-13 footer.footer-expand-xl > .profile-page,
  body.footer-links-13 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-13 footer.footer-expand-xl > .container > .row, body.footer-links-13 footer.footer-expand-xl > .section-social > .row, body.footer-links-13 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-13 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-13 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-13 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-13 footer.footer-expand-xl > .container > .profile, body.footer-links-13 footer.footer-expand-xl > .section-social > .profile, body.footer-links-13 footer.footer-expand-xl > .container > .profile-page, body.footer-links-13 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-13 footer.footer-expand-xl > .container > .profile-section, body.footer-links-13 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-13 footer.footer-expand-xl > .row,
      body.footer-links-13 footer.footer-expand-xl > .card-data-list,
      body.footer-links-13 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-13 footer.footer-expand-xl > .profile,
      body.footer-links-13 footer.footer-expand-xl > .profile-page,
      body.footer-links-13 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-13 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-13 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-13 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-13 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-13 footer .footer-links {
  height: 572px; }
  @media (min-width: 768px) {
    body.footer-links-13 footer .footer-links {
      height: 308px; } }

body.footer-links-14 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-14 main {
      min-height: calc(100vh - 622px); } }
  @media (min-width: 1200px) {
    body.footer-links-14 main {
      min-height: calc(100vh - 477px); } }

body.footer-links-14 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-14 footer.footer-expand-xs > .container > .row, body.footer-links-14 footer.footer-expand-xs > .section-social > .row, body.footer-links-14 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-14 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-xs > .container > .profile, body.footer-links-14 footer.footer-expand-xs > .section-social > .profile, body.footer-links-14 footer.footer-expand-xs > .container > .profile-page, body.footer-links-14 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-xs > .container > .profile-section, body.footer-links-14 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-14 footer.footer-expand-xs > .row,
  body.footer-links-14 footer.footer-expand-xs > .card-data-list,
  body.footer-links-14 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-14 footer.footer-expand-xs > .profile,
  body.footer-links-14 footer.footer-expand-xs > .profile-page,
  body.footer-links-14 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-14 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-14 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-14 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-14 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-14 footer.footer-expand-sm > .container > .row, body.footer-links-14 footer.footer-expand-sm > .section-social > .row, body.footer-links-14 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-14 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-sm > .container > .profile, body.footer-links-14 footer.footer-expand-sm > .section-social > .profile, body.footer-links-14 footer.footer-expand-sm > .container > .profile-page, body.footer-links-14 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-sm > .container > .profile-section, body.footer-links-14 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-14 footer.footer-expand-sm > .row,
  body.footer-links-14 footer.footer-expand-sm > .card-data-list,
  body.footer-links-14 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-14 footer.footer-expand-sm > .profile,
  body.footer-links-14 footer.footer-expand-sm > .profile-page,
  body.footer-links-14 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-14 footer.footer-expand-sm > .container > .row, body.footer-links-14 footer.footer-expand-sm > .section-social > .row, body.footer-links-14 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-14 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-sm > .container > .profile, body.footer-links-14 footer.footer-expand-sm > .section-social > .profile, body.footer-links-14 footer.footer-expand-sm > .container > .profile-page, body.footer-links-14 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-sm > .container > .profile-section, body.footer-links-14 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-14 footer.footer-expand-sm > .row,
      body.footer-links-14 footer.footer-expand-sm > .card-data-list,
      body.footer-links-14 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-14 footer.footer-expand-sm > .profile,
      body.footer-links-14 footer.footer-expand-sm > .profile-page,
      body.footer-links-14 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-14 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-14 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-14 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-14 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-14 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-14 footer.footer-expand-md {
      height: 553px;
      padding: 40px 0 50px; } }
  body.footer-links-14 footer.footer-expand-md > .container > .row, body.footer-links-14 footer.footer-expand-md > .section-social > .row, body.footer-links-14 footer.footer-expand-md > .container > .card-data-list, body.footer-links-14 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-md > .container > .profile, body.footer-links-14 footer.footer-expand-md > .section-social > .profile, body.footer-links-14 footer.footer-expand-md > .container > .profile-page, body.footer-links-14 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-md > .container > .profile-section, body.footer-links-14 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-14 footer.footer-expand-md > .row,
  body.footer-links-14 footer.footer-expand-md > .card-data-list,
  body.footer-links-14 footer.footer-expand-md > .card-featured-news,
  body.footer-links-14 footer.footer-expand-md > .profile,
  body.footer-links-14 footer.footer-expand-md > .profile-page,
  body.footer-links-14 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-14 footer.footer-expand-md > .container > .row, body.footer-links-14 footer.footer-expand-md > .section-social > .row, body.footer-links-14 footer.footer-expand-md > .container > .card-data-list, body.footer-links-14 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-md > .container > .profile, body.footer-links-14 footer.footer-expand-md > .section-social > .profile, body.footer-links-14 footer.footer-expand-md > .container > .profile-page, body.footer-links-14 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-md > .container > .profile-section, body.footer-links-14 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-14 footer.footer-expand-md > .row,
      body.footer-links-14 footer.footer-expand-md > .card-data-list,
      body.footer-links-14 footer.footer-expand-md > .card-featured-news,
      body.footer-links-14 footer.footer-expand-md > .profile,
      body.footer-links-14 footer.footer-expand-md > .profile-page,
      body.footer-links-14 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-14 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-14 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-14 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-14 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-14 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-14 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-14 footer.footer-expand-lg > .container > .row, body.footer-links-14 footer.footer-expand-lg > .section-social > .row, body.footer-links-14 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-14 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-lg > .container > .profile, body.footer-links-14 footer.footer-expand-lg > .section-social > .profile, body.footer-links-14 footer.footer-expand-lg > .container > .profile-page, body.footer-links-14 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-lg > .container > .profile-section, body.footer-links-14 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-14 footer.footer-expand-lg > .row,
  body.footer-links-14 footer.footer-expand-lg > .card-data-list,
  body.footer-links-14 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-14 footer.footer-expand-lg > .profile,
  body.footer-links-14 footer.footer-expand-lg > .profile-page,
  body.footer-links-14 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-14 footer.footer-expand-lg > .container > .row, body.footer-links-14 footer.footer-expand-lg > .section-social > .row, body.footer-links-14 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-14 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-lg > .container > .profile, body.footer-links-14 footer.footer-expand-lg > .section-social > .profile, body.footer-links-14 footer.footer-expand-lg > .container > .profile-page, body.footer-links-14 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-lg > .container > .profile-section, body.footer-links-14 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-14 footer.footer-expand-lg > .row,
      body.footer-links-14 footer.footer-expand-lg > .card-data-list,
      body.footer-links-14 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-14 footer.footer-expand-lg > .profile,
      body.footer-links-14 footer.footer-expand-lg > .profile-page,
      body.footer-links-14 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-14 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-14 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-14 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-14 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-14 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-14 footer.footer-expand-xl {
      height: 408px;
      padding: 40px 0 50px; } }
  body.footer-links-14 footer.footer-expand-xl > .container > .row, body.footer-links-14 footer.footer-expand-xl > .section-social > .row, body.footer-links-14 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-14 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-xl > .container > .profile, body.footer-links-14 footer.footer-expand-xl > .section-social > .profile, body.footer-links-14 footer.footer-expand-xl > .container > .profile-page, body.footer-links-14 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-xl > .container > .profile-section, body.footer-links-14 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-14 footer.footer-expand-xl > .row,
  body.footer-links-14 footer.footer-expand-xl > .card-data-list,
  body.footer-links-14 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-14 footer.footer-expand-xl > .profile,
  body.footer-links-14 footer.footer-expand-xl > .profile-page,
  body.footer-links-14 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-14 footer.footer-expand-xl > .container > .row, body.footer-links-14 footer.footer-expand-xl > .section-social > .row, body.footer-links-14 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-14 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-14 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-14 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-14 footer.footer-expand-xl > .container > .profile, body.footer-links-14 footer.footer-expand-xl > .section-social > .profile, body.footer-links-14 footer.footer-expand-xl > .container > .profile-page, body.footer-links-14 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-14 footer.footer-expand-xl > .container > .profile-section, body.footer-links-14 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-14 footer.footer-expand-xl > .row,
      body.footer-links-14 footer.footer-expand-xl > .card-data-list,
      body.footer-links-14 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-14 footer.footer-expand-xl > .profile,
      body.footer-links-14 footer.footer-expand-xl > .profile-page,
      body.footer-links-14 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-14 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-14 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-14 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-14 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-14 footer .footer-links {
  height: 616px; }
  @media (min-width: 768px) {
    body.footer-links-14 footer .footer-links {
      height: 308px; } }

body.footer-links-15 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-15 main {
      min-height: calc(100vh - 666px); } }
  @media (min-width: 1200px) {
    body.footer-links-15 main {
      min-height: calc(100vh - 521px); } }

body.footer-links-15 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-15 footer.footer-expand-xs > .container > .row, body.footer-links-15 footer.footer-expand-xs > .section-social > .row, body.footer-links-15 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-15 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-xs > .container > .profile, body.footer-links-15 footer.footer-expand-xs > .section-social > .profile, body.footer-links-15 footer.footer-expand-xs > .container > .profile-page, body.footer-links-15 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-xs > .container > .profile-section, body.footer-links-15 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-15 footer.footer-expand-xs > .row,
  body.footer-links-15 footer.footer-expand-xs > .card-data-list,
  body.footer-links-15 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-15 footer.footer-expand-xs > .profile,
  body.footer-links-15 footer.footer-expand-xs > .profile-page,
  body.footer-links-15 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-15 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-15 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-15 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-15 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-15 footer.footer-expand-sm > .container > .row, body.footer-links-15 footer.footer-expand-sm > .section-social > .row, body.footer-links-15 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-15 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-sm > .container > .profile, body.footer-links-15 footer.footer-expand-sm > .section-social > .profile, body.footer-links-15 footer.footer-expand-sm > .container > .profile-page, body.footer-links-15 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-sm > .container > .profile-section, body.footer-links-15 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-15 footer.footer-expand-sm > .row,
  body.footer-links-15 footer.footer-expand-sm > .card-data-list,
  body.footer-links-15 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-15 footer.footer-expand-sm > .profile,
  body.footer-links-15 footer.footer-expand-sm > .profile-page,
  body.footer-links-15 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-15 footer.footer-expand-sm > .container > .row, body.footer-links-15 footer.footer-expand-sm > .section-social > .row, body.footer-links-15 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-15 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-sm > .container > .profile, body.footer-links-15 footer.footer-expand-sm > .section-social > .profile, body.footer-links-15 footer.footer-expand-sm > .container > .profile-page, body.footer-links-15 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-sm > .container > .profile-section, body.footer-links-15 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-15 footer.footer-expand-sm > .row,
      body.footer-links-15 footer.footer-expand-sm > .card-data-list,
      body.footer-links-15 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-15 footer.footer-expand-sm > .profile,
      body.footer-links-15 footer.footer-expand-sm > .profile-page,
      body.footer-links-15 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-15 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-15 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-15 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-15 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-15 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-15 footer.footer-expand-md {
      height: 597px;
      padding: 40px 0 50px; } }
  body.footer-links-15 footer.footer-expand-md > .container > .row, body.footer-links-15 footer.footer-expand-md > .section-social > .row, body.footer-links-15 footer.footer-expand-md > .container > .card-data-list, body.footer-links-15 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-md > .container > .profile, body.footer-links-15 footer.footer-expand-md > .section-social > .profile, body.footer-links-15 footer.footer-expand-md > .container > .profile-page, body.footer-links-15 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-md > .container > .profile-section, body.footer-links-15 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-15 footer.footer-expand-md > .row,
  body.footer-links-15 footer.footer-expand-md > .card-data-list,
  body.footer-links-15 footer.footer-expand-md > .card-featured-news,
  body.footer-links-15 footer.footer-expand-md > .profile,
  body.footer-links-15 footer.footer-expand-md > .profile-page,
  body.footer-links-15 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-15 footer.footer-expand-md > .container > .row, body.footer-links-15 footer.footer-expand-md > .section-social > .row, body.footer-links-15 footer.footer-expand-md > .container > .card-data-list, body.footer-links-15 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-md > .container > .profile, body.footer-links-15 footer.footer-expand-md > .section-social > .profile, body.footer-links-15 footer.footer-expand-md > .container > .profile-page, body.footer-links-15 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-md > .container > .profile-section, body.footer-links-15 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-15 footer.footer-expand-md > .row,
      body.footer-links-15 footer.footer-expand-md > .card-data-list,
      body.footer-links-15 footer.footer-expand-md > .card-featured-news,
      body.footer-links-15 footer.footer-expand-md > .profile,
      body.footer-links-15 footer.footer-expand-md > .profile-page,
      body.footer-links-15 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-15 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-15 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-15 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-15 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-15 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-15 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-15 footer.footer-expand-lg > .container > .row, body.footer-links-15 footer.footer-expand-lg > .section-social > .row, body.footer-links-15 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-15 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-lg > .container > .profile, body.footer-links-15 footer.footer-expand-lg > .section-social > .profile, body.footer-links-15 footer.footer-expand-lg > .container > .profile-page, body.footer-links-15 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-lg > .container > .profile-section, body.footer-links-15 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-15 footer.footer-expand-lg > .row,
  body.footer-links-15 footer.footer-expand-lg > .card-data-list,
  body.footer-links-15 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-15 footer.footer-expand-lg > .profile,
  body.footer-links-15 footer.footer-expand-lg > .profile-page,
  body.footer-links-15 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-15 footer.footer-expand-lg > .container > .row, body.footer-links-15 footer.footer-expand-lg > .section-social > .row, body.footer-links-15 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-15 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-lg > .container > .profile, body.footer-links-15 footer.footer-expand-lg > .section-social > .profile, body.footer-links-15 footer.footer-expand-lg > .container > .profile-page, body.footer-links-15 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-lg > .container > .profile-section, body.footer-links-15 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-15 footer.footer-expand-lg > .row,
      body.footer-links-15 footer.footer-expand-lg > .card-data-list,
      body.footer-links-15 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-15 footer.footer-expand-lg > .profile,
      body.footer-links-15 footer.footer-expand-lg > .profile-page,
      body.footer-links-15 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-15 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-15 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-15 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-15 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-15 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-15 footer.footer-expand-xl {
      height: 452px;
      padding: 40px 0 50px; } }
  body.footer-links-15 footer.footer-expand-xl > .container > .row, body.footer-links-15 footer.footer-expand-xl > .section-social > .row, body.footer-links-15 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-15 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-xl > .container > .profile, body.footer-links-15 footer.footer-expand-xl > .section-social > .profile, body.footer-links-15 footer.footer-expand-xl > .container > .profile-page, body.footer-links-15 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-xl > .container > .profile-section, body.footer-links-15 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-15 footer.footer-expand-xl > .row,
  body.footer-links-15 footer.footer-expand-xl > .card-data-list,
  body.footer-links-15 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-15 footer.footer-expand-xl > .profile,
  body.footer-links-15 footer.footer-expand-xl > .profile-page,
  body.footer-links-15 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-15 footer.footer-expand-xl > .container > .row, body.footer-links-15 footer.footer-expand-xl > .section-social > .row, body.footer-links-15 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-15 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-15 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-15 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-15 footer.footer-expand-xl > .container > .profile, body.footer-links-15 footer.footer-expand-xl > .section-social > .profile, body.footer-links-15 footer.footer-expand-xl > .container > .profile-page, body.footer-links-15 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-15 footer.footer-expand-xl > .container > .profile-section, body.footer-links-15 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-15 footer.footer-expand-xl > .row,
      body.footer-links-15 footer.footer-expand-xl > .card-data-list,
      body.footer-links-15 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-15 footer.footer-expand-xl > .profile,
      body.footer-links-15 footer.footer-expand-xl > .profile-page,
      body.footer-links-15 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-15 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-15 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-15 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-15 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-15 footer .footer-links {
  height: 660px; }
  @media (min-width: 768px) {
    body.footer-links-15 footer .footer-links {
      height: 352px; } }

body.footer-links-16 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-16 main {
      min-height: calc(100vh - 666px); } }
  @media (min-width: 1200px) {
    body.footer-links-16 main {
      min-height: calc(100vh - 521px); } }

body.footer-links-16 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-16 footer.footer-expand-xs > .container > .row, body.footer-links-16 footer.footer-expand-xs > .section-social > .row, body.footer-links-16 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-16 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-xs > .container > .profile, body.footer-links-16 footer.footer-expand-xs > .section-social > .profile, body.footer-links-16 footer.footer-expand-xs > .container > .profile-page, body.footer-links-16 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-xs > .container > .profile-section, body.footer-links-16 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-16 footer.footer-expand-xs > .row,
  body.footer-links-16 footer.footer-expand-xs > .card-data-list,
  body.footer-links-16 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-16 footer.footer-expand-xs > .profile,
  body.footer-links-16 footer.footer-expand-xs > .profile-page,
  body.footer-links-16 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-16 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-16 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-16 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-16 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-16 footer.footer-expand-sm > .container > .row, body.footer-links-16 footer.footer-expand-sm > .section-social > .row, body.footer-links-16 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-16 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-sm > .container > .profile, body.footer-links-16 footer.footer-expand-sm > .section-social > .profile, body.footer-links-16 footer.footer-expand-sm > .container > .profile-page, body.footer-links-16 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-sm > .container > .profile-section, body.footer-links-16 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-16 footer.footer-expand-sm > .row,
  body.footer-links-16 footer.footer-expand-sm > .card-data-list,
  body.footer-links-16 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-16 footer.footer-expand-sm > .profile,
  body.footer-links-16 footer.footer-expand-sm > .profile-page,
  body.footer-links-16 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-16 footer.footer-expand-sm > .container > .row, body.footer-links-16 footer.footer-expand-sm > .section-social > .row, body.footer-links-16 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-16 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-sm > .container > .profile, body.footer-links-16 footer.footer-expand-sm > .section-social > .profile, body.footer-links-16 footer.footer-expand-sm > .container > .profile-page, body.footer-links-16 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-sm > .container > .profile-section, body.footer-links-16 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-16 footer.footer-expand-sm > .row,
      body.footer-links-16 footer.footer-expand-sm > .card-data-list,
      body.footer-links-16 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-16 footer.footer-expand-sm > .profile,
      body.footer-links-16 footer.footer-expand-sm > .profile-page,
      body.footer-links-16 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-16 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-16 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-16 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-16 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-16 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-16 footer.footer-expand-md {
      height: 597px;
      padding: 40px 0 50px; } }
  body.footer-links-16 footer.footer-expand-md > .container > .row, body.footer-links-16 footer.footer-expand-md > .section-social > .row, body.footer-links-16 footer.footer-expand-md > .container > .card-data-list, body.footer-links-16 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-md > .container > .profile, body.footer-links-16 footer.footer-expand-md > .section-social > .profile, body.footer-links-16 footer.footer-expand-md > .container > .profile-page, body.footer-links-16 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-md > .container > .profile-section, body.footer-links-16 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-16 footer.footer-expand-md > .row,
  body.footer-links-16 footer.footer-expand-md > .card-data-list,
  body.footer-links-16 footer.footer-expand-md > .card-featured-news,
  body.footer-links-16 footer.footer-expand-md > .profile,
  body.footer-links-16 footer.footer-expand-md > .profile-page,
  body.footer-links-16 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-16 footer.footer-expand-md > .container > .row, body.footer-links-16 footer.footer-expand-md > .section-social > .row, body.footer-links-16 footer.footer-expand-md > .container > .card-data-list, body.footer-links-16 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-md > .container > .profile, body.footer-links-16 footer.footer-expand-md > .section-social > .profile, body.footer-links-16 footer.footer-expand-md > .container > .profile-page, body.footer-links-16 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-md > .container > .profile-section, body.footer-links-16 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-16 footer.footer-expand-md > .row,
      body.footer-links-16 footer.footer-expand-md > .card-data-list,
      body.footer-links-16 footer.footer-expand-md > .card-featured-news,
      body.footer-links-16 footer.footer-expand-md > .profile,
      body.footer-links-16 footer.footer-expand-md > .profile-page,
      body.footer-links-16 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-16 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-16 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-16 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-16 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-16 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-16 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-16 footer.footer-expand-lg > .container > .row, body.footer-links-16 footer.footer-expand-lg > .section-social > .row, body.footer-links-16 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-16 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-lg > .container > .profile, body.footer-links-16 footer.footer-expand-lg > .section-social > .profile, body.footer-links-16 footer.footer-expand-lg > .container > .profile-page, body.footer-links-16 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-lg > .container > .profile-section, body.footer-links-16 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-16 footer.footer-expand-lg > .row,
  body.footer-links-16 footer.footer-expand-lg > .card-data-list,
  body.footer-links-16 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-16 footer.footer-expand-lg > .profile,
  body.footer-links-16 footer.footer-expand-lg > .profile-page,
  body.footer-links-16 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-16 footer.footer-expand-lg > .container > .row, body.footer-links-16 footer.footer-expand-lg > .section-social > .row, body.footer-links-16 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-16 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-lg > .container > .profile, body.footer-links-16 footer.footer-expand-lg > .section-social > .profile, body.footer-links-16 footer.footer-expand-lg > .container > .profile-page, body.footer-links-16 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-lg > .container > .profile-section, body.footer-links-16 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-16 footer.footer-expand-lg > .row,
      body.footer-links-16 footer.footer-expand-lg > .card-data-list,
      body.footer-links-16 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-16 footer.footer-expand-lg > .profile,
      body.footer-links-16 footer.footer-expand-lg > .profile-page,
      body.footer-links-16 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-16 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-16 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-16 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-16 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-16 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-16 footer.footer-expand-xl {
      height: 452px;
      padding: 40px 0 50px; } }
  body.footer-links-16 footer.footer-expand-xl > .container > .row, body.footer-links-16 footer.footer-expand-xl > .section-social > .row, body.footer-links-16 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-16 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-xl > .container > .profile, body.footer-links-16 footer.footer-expand-xl > .section-social > .profile, body.footer-links-16 footer.footer-expand-xl > .container > .profile-page, body.footer-links-16 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-xl > .container > .profile-section, body.footer-links-16 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-16 footer.footer-expand-xl > .row,
  body.footer-links-16 footer.footer-expand-xl > .card-data-list,
  body.footer-links-16 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-16 footer.footer-expand-xl > .profile,
  body.footer-links-16 footer.footer-expand-xl > .profile-page,
  body.footer-links-16 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-16 footer.footer-expand-xl > .container > .row, body.footer-links-16 footer.footer-expand-xl > .section-social > .row, body.footer-links-16 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-16 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-16 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-16 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-16 footer.footer-expand-xl > .container > .profile, body.footer-links-16 footer.footer-expand-xl > .section-social > .profile, body.footer-links-16 footer.footer-expand-xl > .container > .profile-page, body.footer-links-16 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-16 footer.footer-expand-xl > .container > .profile-section, body.footer-links-16 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-16 footer.footer-expand-xl > .row,
      body.footer-links-16 footer.footer-expand-xl > .card-data-list,
      body.footer-links-16 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-16 footer.footer-expand-xl > .profile,
      body.footer-links-16 footer.footer-expand-xl > .profile-page,
      body.footer-links-16 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-16 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-16 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-16 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-16 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-16 footer .footer-links {
  height: 704px; }
  @media (min-width: 768px) {
    body.footer-links-16 footer .footer-links {
      height: 352px; } }

body.footer-links-17 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-17 main {
      min-height: calc(100vh - 710px); } }
  @media (min-width: 1200px) {
    body.footer-links-17 main {
      min-height: calc(100vh - 565px); } }

body.footer-links-17 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-17 footer.footer-expand-xs > .container > .row, body.footer-links-17 footer.footer-expand-xs > .section-social > .row, body.footer-links-17 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-17 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-xs > .container > .profile, body.footer-links-17 footer.footer-expand-xs > .section-social > .profile, body.footer-links-17 footer.footer-expand-xs > .container > .profile-page, body.footer-links-17 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-xs > .container > .profile-section, body.footer-links-17 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-17 footer.footer-expand-xs > .row,
  body.footer-links-17 footer.footer-expand-xs > .card-data-list,
  body.footer-links-17 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-17 footer.footer-expand-xs > .profile,
  body.footer-links-17 footer.footer-expand-xs > .profile-page,
  body.footer-links-17 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-17 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-17 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-17 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-17 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-17 footer.footer-expand-sm > .container > .row, body.footer-links-17 footer.footer-expand-sm > .section-social > .row, body.footer-links-17 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-17 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-sm > .container > .profile, body.footer-links-17 footer.footer-expand-sm > .section-social > .profile, body.footer-links-17 footer.footer-expand-sm > .container > .profile-page, body.footer-links-17 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-sm > .container > .profile-section, body.footer-links-17 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-17 footer.footer-expand-sm > .row,
  body.footer-links-17 footer.footer-expand-sm > .card-data-list,
  body.footer-links-17 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-17 footer.footer-expand-sm > .profile,
  body.footer-links-17 footer.footer-expand-sm > .profile-page,
  body.footer-links-17 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-17 footer.footer-expand-sm > .container > .row, body.footer-links-17 footer.footer-expand-sm > .section-social > .row, body.footer-links-17 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-17 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-sm > .container > .profile, body.footer-links-17 footer.footer-expand-sm > .section-social > .profile, body.footer-links-17 footer.footer-expand-sm > .container > .profile-page, body.footer-links-17 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-sm > .container > .profile-section, body.footer-links-17 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-17 footer.footer-expand-sm > .row,
      body.footer-links-17 footer.footer-expand-sm > .card-data-list,
      body.footer-links-17 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-17 footer.footer-expand-sm > .profile,
      body.footer-links-17 footer.footer-expand-sm > .profile-page,
      body.footer-links-17 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-17 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-17 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-17 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-17 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-17 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-17 footer.footer-expand-md {
      height: 641px;
      padding: 40px 0 50px; } }
  body.footer-links-17 footer.footer-expand-md > .container > .row, body.footer-links-17 footer.footer-expand-md > .section-social > .row, body.footer-links-17 footer.footer-expand-md > .container > .card-data-list, body.footer-links-17 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-md > .container > .profile, body.footer-links-17 footer.footer-expand-md > .section-social > .profile, body.footer-links-17 footer.footer-expand-md > .container > .profile-page, body.footer-links-17 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-md > .container > .profile-section, body.footer-links-17 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-17 footer.footer-expand-md > .row,
  body.footer-links-17 footer.footer-expand-md > .card-data-list,
  body.footer-links-17 footer.footer-expand-md > .card-featured-news,
  body.footer-links-17 footer.footer-expand-md > .profile,
  body.footer-links-17 footer.footer-expand-md > .profile-page,
  body.footer-links-17 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-17 footer.footer-expand-md > .container > .row, body.footer-links-17 footer.footer-expand-md > .section-social > .row, body.footer-links-17 footer.footer-expand-md > .container > .card-data-list, body.footer-links-17 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-md > .container > .profile, body.footer-links-17 footer.footer-expand-md > .section-social > .profile, body.footer-links-17 footer.footer-expand-md > .container > .profile-page, body.footer-links-17 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-md > .container > .profile-section, body.footer-links-17 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-17 footer.footer-expand-md > .row,
      body.footer-links-17 footer.footer-expand-md > .card-data-list,
      body.footer-links-17 footer.footer-expand-md > .card-featured-news,
      body.footer-links-17 footer.footer-expand-md > .profile,
      body.footer-links-17 footer.footer-expand-md > .profile-page,
      body.footer-links-17 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-17 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-17 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-17 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-17 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-17 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-17 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-17 footer.footer-expand-lg > .container > .row, body.footer-links-17 footer.footer-expand-lg > .section-social > .row, body.footer-links-17 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-17 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-lg > .container > .profile, body.footer-links-17 footer.footer-expand-lg > .section-social > .profile, body.footer-links-17 footer.footer-expand-lg > .container > .profile-page, body.footer-links-17 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-lg > .container > .profile-section, body.footer-links-17 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-17 footer.footer-expand-lg > .row,
  body.footer-links-17 footer.footer-expand-lg > .card-data-list,
  body.footer-links-17 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-17 footer.footer-expand-lg > .profile,
  body.footer-links-17 footer.footer-expand-lg > .profile-page,
  body.footer-links-17 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-17 footer.footer-expand-lg > .container > .row, body.footer-links-17 footer.footer-expand-lg > .section-social > .row, body.footer-links-17 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-17 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-lg > .container > .profile, body.footer-links-17 footer.footer-expand-lg > .section-social > .profile, body.footer-links-17 footer.footer-expand-lg > .container > .profile-page, body.footer-links-17 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-lg > .container > .profile-section, body.footer-links-17 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-17 footer.footer-expand-lg > .row,
      body.footer-links-17 footer.footer-expand-lg > .card-data-list,
      body.footer-links-17 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-17 footer.footer-expand-lg > .profile,
      body.footer-links-17 footer.footer-expand-lg > .profile-page,
      body.footer-links-17 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-17 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-17 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-17 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-17 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-17 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-17 footer.footer-expand-xl {
      height: 496px;
      padding: 40px 0 50px; } }
  body.footer-links-17 footer.footer-expand-xl > .container > .row, body.footer-links-17 footer.footer-expand-xl > .section-social > .row, body.footer-links-17 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-17 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-xl > .container > .profile, body.footer-links-17 footer.footer-expand-xl > .section-social > .profile, body.footer-links-17 footer.footer-expand-xl > .container > .profile-page, body.footer-links-17 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-xl > .container > .profile-section, body.footer-links-17 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-17 footer.footer-expand-xl > .row,
  body.footer-links-17 footer.footer-expand-xl > .card-data-list,
  body.footer-links-17 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-17 footer.footer-expand-xl > .profile,
  body.footer-links-17 footer.footer-expand-xl > .profile-page,
  body.footer-links-17 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-17 footer.footer-expand-xl > .container > .row, body.footer-links-17 footer.footer-expand-xl > .section-social > .row, body.footer-links-17 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-17 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-17 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-17 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-17 footer.footer-expand-xl > .container > .profile, body.footer-links-17 footer.footer-expand-xl > .section-social > .profile, body.footer-links-17 footer.footer-expand-xl > .container > .profile-page, body.footer-links-17 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-17 footer.footer-expand-xl > .container > .profile-section, body.footer-links-17 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-17 footer.footer-expand-xl > .row,
      body.footer-links-17 footer.footer-expand-xl > .card-data-list,
      body.footer-links-17 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-17 footer.footer-expand-xl > .profile,
      body.footer-links-17 footer.footer-expand-xl > .profile-page,
      body.footer-links-17 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-17 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-17 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-17 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-17 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-17 footer .footer-links {
  height: 748px; }
  @media (min-width: 768px) {
    body.footer-links-17 footer .footer-links {
      height: 396px; } }

body.footer-links-18 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-18 main {
      min-height: calc(100vh - 710px); } }
  @media (min-width: 1200px) {
    body.footer-links-18 main {
      min-height: calc(100vh - 565px); } }

body.footer-links-18 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-18 footer.footer-expand-xs > .container > .row, body.footer-links-18 footer.footer-expand-xs > .section-social > .row, body.footer-links-18 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-18 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-xs > .container > .profile, body.footer-links-18 footer.footer-expand-xs > .section-social > .profile, body.footer-links-18 footer.footer-expand-xs > .container > .profile-page, body.footer-links-18 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-xs > .container > .profile-section, body.footer-links-18 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-18 footer.footer-expand-xs > .row,
  body.footer-links-18 footer.footer-expand-xs > .card-data-list,
  body.footer-links-18 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-18 footer.footer-expand-xs > .profile,
  body.footer-links-18 footer.footer-expand-xs > .profile-page,
  body.footer-links-18 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-18 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-18 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-18 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-18 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-18 footer.footer-expand-sm > .container > .row, body.footer-links-18 footer.footer-expand-sm > .section-social > .row, body.footer-links-18 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-18 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-sm > .container > .profile, body.footer-links-18 footer.footer-expand-sm > .section-social > .profile, body.footer-links-18 footer.footer-expand-sm > .container > .profile-page, body.footer-links-18 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-sm > .container > .profile-section, body.footer-links-18 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-18 footer.footer-expand-sm > .row,
  body.footer-links-18 footer.footer-expand-sm > .card-data-list,
  body.footer-links-18 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-18 footer.footer-expand-sm > .profile,
  body.footer-links-18 footer.footer-expand-sm > .profile-page,
  body.footer-links-18 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-18 footer.footer-expand-sm > .container > .row, body.footer-links-18 footer.footer-expand-sm > .section-social > .row, body.footer-links-18 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-18 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-sm > .container > .profile, body.footer-links-18 footer.footer-expand-sm > .section-social > .profile, body.footer-links-18 footer.footer-expand-sm > .container > .profile-page, body.footer-links-18 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-sm > .container > .profile-section, body.footer-links-18 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-18 footer.footer-expand-sm > .row,
      body.footer-links-18 footer.footer-expand-sm > .card-data-list,
      body.footer-links-18 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-18 footer.footer-expand-sm > .profile,
      body.footer-links-18 footer.footer-expand-sm > .profile-page,
      body.footer-links-18 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-18 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-18 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-18 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-18 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-18 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-18 footer.footer-expand-md {
      height: 641px;
      padding: 40px 0 50px; } }
  body.footer-links-18 footer.footer-expand-md > .container > .row, body.footer-links-18 footer.footer-expand-md > .section-social > .row, body.footer-links-18 footer.footer-expand-md > .container > .card-data-list, body.footer-links-18 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-md > .container > .profile, body.footer-links-18 footer.footer-expand-md > .section-social > .profile, body.footer-links-18 footer.footer-expand-md > .container > .profile-page, body.footer-links-18 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-md > .container > .profile-section, body.footer-links-18 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-18 footer.footer-expand-md > .row,
  body.footer-links-18 footer.footer-expand-md > .card-data-list,
  body.footer-links-18 footer.footer-expand-md > .card-featured-news,
  body.footer-links-18 footer.footer-expand-md > .profile,
  body.footer-links-18 footer.footer-expand-md > .profile-page,
  body.footer-links-18 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-18 footer.footer-expand-md > .container > .row, body.footer-links-18 footer.footer-expand-md > .section-social > .row, body.footer-links-18 footer.footer-expand-md > .container > .card-data-list, body.footer-links-18 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-md > .container > .profile, body.footer-links-18 footer.footer-expand-md > .section-social > .profile, body.footer-links-18 footer.footer-expand-md > .container > .profile-page, body.footer-links-18 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-md > .container > .profile-section, body.footer-links-18 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-18 footer.footer-expand-md > .row,
      body.footer-links-18 footer.footer-expand-md > .card-data-list,
      body.footer-links-18 footer.footer-expand-md > .card-featured-news,
      body.footer-links-18 footer.footer-expand-md > .profile,
      body.footer-links-18 footer.footer-expand-md > .profile-page,
      body.footer-links-18 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-18 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-18 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-18 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-18 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-18 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-18 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-18 footer.footer-expand-lg > .container > .row, body.footer-links-18 footer.footer-expand-lg > .section-social > .row, body.footer-links-18 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-18 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-lg > .container > .profile, body.footer-links-18 footer.footer-expand-lg > .section-social > .profile, body.footer-links-18 footer.footer-expand-lg > .container > .profile-page, body.footer-links-18 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-lg > .container > .profile-section, body.footer-links-18 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-18 footer.footer-expand-lg > .row,
  body.footer-links-18 footer.footer-expand-lg > .card-data-list,
  body.footer-links-18 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-18 footer.footer-expand-lg > .profile,
  body.footer-links-18 footer.footer-expand-lg > .profile-page,
  body.footer-links-18 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-18 footer.footer-expand-lg > .container > .row, body.footer-links-18 footer.footer-expand-lg > .section-social > .row, body.footer-links-18 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-18 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-lg > .container > .profile, body.footer-links-18 footer.footer-expand-lg > .section-social > .profile, body.footer-links-18 footer.footer-expand-lg > .container > .profile-page, body.footer-links-18 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-lg > .container > .profile-section, body.footer-links-18 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-18 footer.footer-expand-lg > .row,
      body.footer-links-18 footer.footer-expand-lg > .card-data-list,
      body.footer-links-18 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-18 footer.footer-expand-lg > .profile,
      body.footer-links-18 footer.footer-expand-lg > .profile-page,
      body.footer-links-18 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-18 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-18 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-18 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-18 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-18 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-18 footer.footer-expand-xl {
      height: 496px;
      padding: 40px 0 50px; } }
  body.footer-links-18 footer.footer-expand-xl > .container > .row, body.footer-links-18 footer.footer-expand-xl > .section-social > .row, body.footer-links-18 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-18 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-xl > .container > .profile, body.footer-links-18 footer.footer-expand-xl > .section-social > .profile, body.footer-links-18 footer.footer-expand-xl > .container > .profile-page, body.footer-links-18 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-xl > .container > .profile-section, body.footer-links-18 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-18 footer.footer-expand-xl > .row,
  body.footer-links-18 footer.footer-expand-xl > .card-data-list,
  body.footer-links-18 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-18 footer.footer-expand-xl > .profile,
  body.footer-links-18 footer.footer-expand-xl > .profile-page,
  body.footer-links-18 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-18 footer.footer-expand-xl > .container > .row, body.footer-links-18 footer.footer-expand-xl > .section-social > .row, body.footer-links-18 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-18 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-18 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-18 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-18 footer.footer-expand-xl > .container > .profile, body.footer-links-18 footer.footer-expand-xl > .section-social > .profile, body.footer-links-18 footer.footer-expand-xl > .container > .profile-page, body.footer-links-18 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-18 footer.footer-expand-xl > .container > .profile-section, body.footer-links-18 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-18 footer.footer-expand-xl > .row,
      body.footer-links-18 footer.footer-expand-xl > .card-data-list,
      body.footer-links-18 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-18 footer.footer-expand-xl > .profile,
      body.footer-links-18 footer.footer-expand-xl > .profile-page,
      body.footer-links-18 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-18 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-18 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-18 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-18 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-18 footer .footer-links {
  height: 792px; }
  @media (min-width: 768px) {
    body.footer-links-18 footer .footer-links {
      height: 396px; } }

body.footer-links-19 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-19 main {
      min-height: calc(100vh - 754px); } }
  @media (min-width: 1200px) {
    body.footer-links-19 main {
      min-height: calc(100vh - 609px); } }

body.footer-links-19 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-19 footer.footer-expand-xs > .container > .row, body.footer-links-19 footer.footer-expand-xs > .section-social > .row, body.footer-links-19 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-19 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-xs > .container > .profile, body.footer-links-19 footer.footer-expand-xs > .section-social > .profile, body.footer-links-19 footer.footer-expand-xs > .container > .profile-page, body.footer-links-19 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-xs > .container > .profile-section, body.footer-links-19 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-19 footer.footer-expand-xs > .row,
  body.footer-links-19 footer.footer-expand-xs > .card-data-list,
  body.footer-links-19 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-19 footer.footer-expand-xs > .profile,
  body.footer-links-19 footer.footer-expand-xs > .profile-page,
  body.footer-links-19 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-19 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-19 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-19 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-19 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-19 footer.footer-expand-sm > .container > .row, body.footer-links-19 footer.footer-expand-sm > .section-social > .row, body.footer-links-19 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-19 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-sm > .container > .profile, body.footer-links-19 footer.footer-expand-sm > .section-social > .profile, body.footer-links-19 footer.footer-expand-sm > .container > .profile-page, body.footer-links-19 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-sm > .container > .profile-section, body.footer-links-19 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-19 footer.footer-expand-sm > .row,
  body.footer-links-19 footer.footer-expand-sm > .card-data-list,
  body.footer-links-19 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-19 footer.footer-expand-sm > .profile,
  body.footer-links-19 footer.footer-expand-sm > .profile-page,
  body.footer-links-19 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-19 footer.footer-expand-sm > .container > .row, body.footer-links-19 footer.footer-expand-sm > .section-social > .row, body.footer-links-19 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-19 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-sm > .container > .profile, body.footer-links-19 footer.footer-expand-sm > .section-social > .profile, body.footer-links-19 footer.footer-expand-sm > .container > .profile-page, body.footer-links-19 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-sm > .container > .profile-section, body.footer-links-19 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-19 footer.footer-expand-sm > .row,
      body.footer-links-19 footer.footer-expand-sm > .card-data-list,
      body.footer-links-19 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-19 footer.footer-expand-sm > .profile,
      body.footer-links-19 footer.footer-expand-sm > .profile-page,
      body.footer-links-19 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-19 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-19 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-19 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-19 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-19 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-19 footer.footer-expand-md {
      height: 685px;
      padding: 40px 0 50px; } }
  body.footer-links-19 footer.footer-expand-md > .container > .row, body.footer-links-19 footer.footer-expand-md > .section-social > .row, body.footer-links-19 footer.footer-expand-md > .container > .card-data-list, body.footer-links-19 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-md > .container > .profile, body.footer-links-19 footer.footer-expand-md > .section-social > .profile, body.footer-links-19 footer.footer-expand-md > .container > .profile-page, body.footer-links-19 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-md > .container > .profile-section, body.footer-links-19 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-19 footer.footer-expand-md > .row,
  body.footer-links-19 footer.footer-expand-md > .card-data-list,
  body.footer-links-19 footer.footer-expand-md > .card-featured-news,
  body.footer-links-19 footer.footer-expand-md > .profile,
  body.footer-links-19 footer.footer-expand-md > .profile-page,
  body.footer-links-19 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-19 footer.footer-expand-md > .container > .row, body.footer-links-19 footer.footer-expand-md > .section-social > .row, body.footer-links-19 footer.footer-expand-md > .container > .card-data-list, body.footer-links-19 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-md > .container > .profile, body.footer-links-19 footer.footer-expand-md > .section-social > .profile, body.footer-links-19 footer.footer-expand-md > .container > .profile-page, body.footer-links-19 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-md > .container > .profile-section, body.footer-links-19 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-19 footer.footer-expand-md > .row,
      body.footer-links-19 footer.footer-expand-md > .card-data-list,
      body.footer-links-19 footer.footer-expand-md > .card-featured-news,
      body.footer-links-19 footer.footer-expand-md > .profile,
      body.footer-links-19 footer.footer-expand-md > .profile-page,
      body.footer-links-19 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-19 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-19 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-19 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-19 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-19 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-19 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-19 footer.footer-expand-lg > .container > .row, body.footer-links-19 footer.footer-expand-lg > .section-social > .row, body.footer-links-19 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-19 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-lg > .container > .profile, body.footer-links-19 footer.footer-expand-lg > .section-social > .profile, body.footer-links-19 footer.footer-expand-lg > .container > .profile-page, body.footer-links-19 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-lg > .container > .profile-section, body.footer-links-19 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-19 footer.footer-expand-lg > .row,
  body.footer-links-19 footer.footer-expand-lg > .card-data-list,
  body.footer-links-19 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-19 footer.footer-expand-lg > .profile,
  body.footer-links-19 footer.footer-expand-lg > .profile-page,
  body.footer-links-19 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-19 footer.footer-expand-lg > .container > .row, body.footer-links-19 footer.footer-expand-lg > .section-social > .row, body.footer-links-19 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-19 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-lg > .container > .profile, body.footer-links-19 footer.footer-expand-lg > .section-social > .profile, body.footer-links-19 footer.footer-expand-lg > .container > .profile-page, body.footer-links-19 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-lg > .container > .profile-section, body.footer-links-19 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-19 footer.footer-expand-lg > .row,
      body.footer-links-19 footer.footer-expand-lg > .card-data-list,
      body.footer-links-19 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-19 footer.footer-expand-lg > .profile,
      body.footer-links-19 footer.footer-expand-lg > .profile-page,
      body.footer-links-19 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-19 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-19 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-19 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-19 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-19 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-19 footer.footer-expand-xl {
      height: 540px;
      padding: 40px 0 50px; } }
  body.footer-links-19 footer.footer-expand-xl > .container > .row, body.footer-links-19 footer.footer-expand-xl > .section-social > .row, body.footer-links-19 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-19 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-xl > .container > .profile, body.footer-links-19 footer.footer-expand-xl > .section-social > .profile, body.footer-links-19 footer.footer-expand-xl > .container > .profile-page, body.footer-links-19 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-xl > .container > .profile-section, body.footer-links-19 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-19 footer.footer-expand-xl > .row,
  body.footer-links-19 footer.footer-expand-xl > .card-data-list,
  body.footer-links-19 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-19 footer.footer-expand-xl > .profile,
  body.footer-links-19 footer.footer-expand-xl > .profile-page,
  body.footer-links-19 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-19 footer.footer-expand-xl > .container > .row, body.footer-links-19 footer.footer-expand-xl > .section-social > .row, body.footer-links-19 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-19 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-19 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-19 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-19 footer.footer-expand-xl > .container > .profile, body.footer-links-19 footer.footer-expand-xl > .section-social > .profile, body.footer-links-19 footer.footer-expand-xl > .container > .profile-page, body.footer-links-19 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-19 footer.footer-expand-xl > .container > .profile-section, body.footer-links-19 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-19 footer.footer-expand-xl > .row,
      body.footer-links-19 footer.footer-expand-xl > .card-data-list,
      body.footer-links-19 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-19 footer.footer-expand-xl > .profile,
      body.footer-links-19 footer.footer-expand-xl > .profile-page,
      body.footer-links-19 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-19 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-19 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-19 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-19 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-19 footer .footer-links {
  height: 836px; }
  @media (min-width: 768px) {
    body.footer-links-19 footer .footer-links {
      height: 440px; } }

body.footer-links-20 main {
  min-height: calc(100vh - 118px); }
  @media (min-width: 768px) {
    body.footer-links-20 main {
      min-height: calc(100vh - 754px); } }
  @media (min-width: 1200px) {
    body.footer-links-20 main {
      min-height: calc(100vh - 609px); } }

body.footer-links-20 footer.footer-expand-xs {
  height: 64px;
  padding: 0;
  height: 64px;
  padding: 40px 0 50px; }
  body.footer-links-20 footer.footer-expand-xs > .container > .row, body.footer-links-20 footer.footer-expand-xs > .section-social > .row, body.footer-links-20 footer.footer-expand-xs > .container > .card-data-list, body.footer-links-20 footer.footer-expand-xs > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-xs > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-xs > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-xs > .container > .profile, body.footer-links-20 footer.footer-expand-xs > .section-social > .profile, body.footer-links-20 footer.footer-expand-xs > .container > .profile-page, body.footer-links-20 footer.footer-expand-xs > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-xs > .container > .profile-section, body.footer-links-20 footer.footer-expand-xs > .section-social > .profile-section,
  body.footer-links-20 footer.footer-expand-xs > .row,
  body.footer-links-20 footer.footer-expand-xs > .card-data-list,
  body.footer-links-20 footer.footer-expand-xs > .card-featured-news,
  body.footer-links-20 footer.footer-expand-xs > .profile,
  body.footer-links-20 footer.footer-expand-xs > .profile-page,
  body.footer-links-20 footer.footer-expand-xs > .profile-section {
    display: none;
    display: flex; }
  body.footer-links-20 footer.footer-expand-xs .footer-logo .footer-default-logo {
    display: none;
    display: block; }
  body.footer-links-20 footer.footer-expand-xs .footer-logo .footer-mobile-logo {
    display: block;
    display: none; }

body.footer-links-20 footer.footer-expand-sm {
  height: 64px;
  padding: 0; }
  @media (min-width: 576px) {
    body.footer-links-20 footer.footer-expand-sm {
      padding: 40px 0 50px; } }
  body.footer-links-20 footer.footer-expand-sm > .container > .row, body.footer-links-20 footer.footer-expand-sm > .section-social > .row, body.footer-links-20 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-20 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-sm > .container > .profile, body.footer-links-20 footer.footer-expand-sm > .section-social > .profile, body.footer-links-20 footer.footer-expand-sm > .container > .profile-page, body.footer-links-20 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-sm > .container > .profile-section, body.footer-links-20 footer.footer-expand-sm > .section-social > .profile-section,
  body.footer-links-20 footer.footer-expand-sm > .row,
  body.footer-links-20 footer.footer-expand-sm > .card-data-list,
  body.footer-links-20 footer.footer-expand-sm > .card-featured-news,
  body.footer-links-20 footer.footer-expand-sm > .profile,
  body.footer-links-20 footer.footer-expand-sm > .profile-page,
  body.footer-links-20 footer.footer-expand-sm > .profile-section {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-20 footer.footer-expand-sm > .container > .row, body.footer-links-20 footer.footer-expand-sm > .section-social > .row, body.footer-links-20 footer.footer-expand-sm > .container > .card-data-list, body.footer-links-20 footer.footer-expand-sm > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-sm > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-sm > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-sm > .container > .profile, body.footer-links-20 footer.footer-expand-sm > .section-social > .profile, body.footer-links-20 footer.footer-expand-sm > .container > .profile-page, body.footer-links-20 footer.footer-expand-sm > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-sm > .container > .profile-section, body.footer-links-20 footer.footer-expand-sm > .section-social > .profile-section,
      body.footer-links-20 footer.footer-expand-sm > .row,
      body.footer-links-20 footer.footer-expand-sm > .card-data-list,
      body.footer-links-20 footer.footer-expand-sm > .card-featured-news,
      body.footer-links-20 footer.footer-expand-sm > .profile,
      body.footer-links-20 footer.footer-expand-sm > .profile-page,
      body.footer-links-20 footer.footer-expand-sm > .profile-section {
        display: flex; } }
  body.footer-links-20 footer.footer-expand-sm .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 576px) {
      body.footer-links-20 footer.footer-expand-sm .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-20 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 576px) {
      body.footer-links-20 footer.footer-expand-sm .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-20 footer.footer-expand-md {
  height: 64px;
  padding: 0; }
  @media (min-width: 768px) {
    body.footer-links-20 footer.footer-expand-md {
      height: 685px;
      padding: 40px 0 50px; } }
  body.footer-links-20 footer.footer-expand-md > .container > .row, body.footer-links-20 footer.footer-expand-md > .section-social > .row, body.footer-links-20 footer.footer-expand-md > .container > .card-data-list, body.footer-links-20 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-md > .container > .profile, body.footer-links-20 footer.footer-expand-md > .section-social > .profile, body.footer-links-20 footer.footer-expand-md > .container > .profile-page, body.footer-links-20 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-md > .container > .profile-section, body.footer-links-20 footer.footer-expand-md > .section-social > .profile-section,
  body.footer-links-20 footer.footer-expand-md > .row,
  body.footer-links-20 footer.footer-expand-md > .card-data-list,
  body.footer-links-20 footer.footer-expand-md > .card-featured-news,
  body.footer-links-20 footer.footer-expand-md > .profile,
  body.footer-links-20 footer.footer-expand-md > .profile-page,
  body.footer-links-20 footer.footer-expand-md > .profile-section {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-20 footer.footer-expand-md > .container > .row, body.footer-links-20 footer.footer-expand-md > .section-social > .row, body.footer-links-20 footer.footer-expand-md > .container > .card-data-list, body.footer-links-20 footer.footer-expand-md > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-md > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-md > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-md > .container > .profile, body.footer-links-20 footer.footer-expand-md > .section-social > .profile, body.footer-links-20 footer.footer-expand-md > .container > .profile-page, body.footer-links-20 footer.footer-expand-md > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-md > .container > .profile-section, body.footer-links-20 footer.footer-expand-md > .section-social > .profile-section,
      body.footer-links-20 footer.footer-expand-md > .row,
      body.footer-links-20 footer.footer-expand-md > .card-data-list,
      body.footer-links-20 footer.footer-expand-md > .card-featured-news,
      body.footer-links-20 footer.footer-expand-md > .profile,
      body.footer-links-20 footer.footer-expand-md > .profile-page,
      body.footer-links-20 footer.footer-expand-md > .profile-section {
        display: flex; } }
  body.footer-links-20 footer.footer-expand-md .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 768px) {
      body.footer-links-20 footer.footer-expand-md .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-20 footer.footer-expand-md .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 768px) {
      body.footer-links-20 footer.footer-expand-md .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-20 footer.footer-expand-lg {
  height: 64px;
  padding: 0; }
  @media (min-width: 992px) {
    body.footer-links-20 footer.footer-expand-lg {
      height: 245px;
      padding: 40px 0 50px; } }
  body.footer-links-20 footer.footer-expand-lg > .container > .row, body.footer-links-20 footer.footer-expand-lg > .section-social > .row, body.footer-links-20 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-20 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-lg > .container > .profile, body.footer-links-20 footer.footer-expand-lg > .section-social > .profile, body.footer-links-20 footer.footer-expand-lg > .container > .profile-page, body.footer-links-20 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-lg > .container > .profile-section, body.footer-links-20 footer.footer-expand-lg > .section-social > .profile-section,
  body.footer-links-20 footer.footer-expand-lg > .row,
  body.footer-links-20 footer.footer-expand-lg > .card-data-list,
  body.footer-links-20 footer.footer-expand-lg > .card-featured-news,
  body.footer-links-20 footer.footer-expand-lg > .profile,
  body.footer-links-20 footer.footer-expand-lg > .profile-page,
  body.footer-links-20 footer.footer-expand-lg > .profile-section {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-20 footer.footer-expand-lg > .container > .row, body.footer-links-20 footer.footer-expand-lg > .section-social > .row, body.footer-links-20 footer.footer-expand-lg > .container > .card-data-list, body.footer-links-20 footer.footer-expand-lg > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-lg > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-lg > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-lg > .container > .profile, body.footer-links-20 footer.footer-expand-lg > .section-social > .profile, body.footer-links-20 footer.footer-expand-lg > .container > .profile-page, body.footer-links-20 footer.footer-expand-lg > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-lg > .container > .profile-section, body.footer-links-20 footer.footer-expand-lg > .section-social > .profile-section,
      body.footer-links-20 footer.footer-expand-lg > .row,
      body.footer-links-20 footer.footer-expand-lg > .card-data-list,
      body.footer-links-20 footer.footer-expand-lg > .card-featured-news,
      body.footer-links-20 footer.footer-expand-lg > .profile,
      body.footer-links-20 footer.footer-expand-lg > .profile-page,
      body.footer-links-20 footer.footer-expand-lg > .profile-section {
        display: flex; } }
  body.footer-links-20 footer.footer-expand-lg .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 992px) {
      body.footer-links-20 footer.footer-expand-lg .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-20 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 992px) {
      body.footer-links-20 footer.footer-expand-lg .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-20 footer.footer-expand-xl {
  height: 64px;
  padding: 0; }
  @media (min-width: 1200px) {
    body.footer-links-20 footer.footer-expand-xl {
      height: 540px;
      padding: 40px 0 50px; } }
  body.footer-links-20 footer.footer-expand-xl > .container > .row, body.footer-links-20 footer.footer-expand-xl > .section-social > .row, body.footer-links-20 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-20 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-xl > .container > .profile, body.footer-links-20 footer.footer-expand-xl > .section-social > .profile, body.footer-links-20 footer.footer-expand-xl > .container > .profile-page, body.footer-links-20 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-xl > .container > .profile-section, body.footer-links-20 footer.footer-expand-xl > .section-social > .profile-section,
  body.footer-links-20 footer.footer-expand-xl > .row,
  body.footer-links-20 footer.footer-expand-xl > .card-data-list,
  body.footer-links-20 footer.footer-expand-xl > .card-featured-news,
  body.footer-links-20 footer.footer-expand-xl > .profile,
  body.footer-links-20 footer.footer-expand-xl > .profile-page,
  body.footer-links-20 footer.footer-expand-xl > .profile-section {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-20 footer.footer-expand-xl > .container > .row, body.footer-links-20 footer.footer-expand-xl > .section-social > .row, body.footer-links-20 footer.footer-expand-xl > .container > .card-data-list, body.footer-links-20 footer.footer-expand-xl > .section-social > .card-data-list, body.footer-links-20 footer.footer-expand-xl > .container > .card-featured-news, body.footer-links-20 footer.footer-expand-xl > .section-social > .card-featured-news, body.footer-links-20 footer.footer-expand-xl > .container > .profile, body.footer-links-20 footer.footer-expand-xl > .section-social > .profile, body.footer-links-20 footer.footer-expand-xl > .container > .profile-page, body.footer-links-20 footer.footer-expand-xl > .section-social > .profile-page, body.footer-links-20 footer.footer-expand-xl > .container > .profile-section, body.footer-links-20 footer.footer-expand-xl > .section-social > .profile-section,
      body.footer-links-20 footer.footer-expand-xl > .row,
      body.footer-links-20 footer.footer-expand-xl > .card-data-list,
      body.footer-links-20 footer.footer-expand-xl > .card-featured-news,
      body.footer-links-20 footer.footer-expand-xl > .profile,
      body.footer-links-20 footer.footer-expand-xl > .profile-page,
      body.footer-links-20 footer.footer-expand-xl > .profile-section {
        display: flex; } }
  body.footer-links-20 footer.footer-expand-xl .footer-logo .footer-default-logo {
    display: none; }
    @media (min-width: 1200px) {
      body.footer-links-20 footer.footer-expand-xl .footer-logo .footer-default-logo {
        display: block; } }
  body.footer-links-20 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
    display: block; }
    @media (min-width: 1200px) {
      body.footer-links-20 footer.footer-expand-xl .footer-logo .footer-mobile-logo {
        display: none; } }

body.footer-links-20 footer .footer-links {
  height: 880px; }
  @media (min-width: 768px) {
    body.footer-links-20 footer .footer-links {
      height: 440px; } }

.page-news .line {
  color: #111;
  text-align: left; }

.page-news small {
  opacity: 0.6; }

.page-news h1,
.page-news .h1 {
  font-size: 1.25rem;
  line-height: 1.625rem; }
  @media (min-width: 768px) {
    .page-news h1,
    .page-news .h1 {
      font-size: 1.4375rem;
      line-height: 1.625rem; } }

.page-news h4,
.page-news .h4 {
  font-size: 0.9375rem;
  line-height: 1.5rem;
  opacity: 0.6; }
  @media (min-width: 768px) {
    .page-news h4,
    .page-news .h4 {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.georeferens-search h2 {
  font-size: 28px; }

.georeferens-search h3 {
  font-size: 28px; }

.georeferens-search span {
  font-size: 18px; }

.georeferens-search .searchw {
  background-color: #f0eaea; }

/* other vendors */
html.cms-ready nav.navbar {
  top: 0; }

@media (min-width: 768px) {
  @supports (position: sticky) {
    html.cms-ready .bd-sidebar {
      top: 69px;
      height: calc(100vh - 69px); } } }

html.cms-ready.cms-toolbar-expanded nav.navbar {
  top: 45.99px; }

@media (min-width: 768px) {
  @supports (position: sticky) {
    html.cms-ready.cms-toolbar-expanded .bd-sidebar {
      top: 114.99px;
      height: calc(100vh - 114.99px); } } }

.select2.select2-container .select2-selection {
  height: calc(2.25rem + 2px);
  border-color: #ced4da;
  border-radius: 0; }
  .select2.select2-container .select2-selection .select2-selection__rendered,
  .select2.select2-container .select2-selection .select2-selection__arrow {
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5; }

@media (min-width: 576px) {
  .toolbar.with-read-speaker {
    width: 332px; } }

@media (min-width: 768px) {
  .toolbar.with-read-speaker {
    width: 300px; } }

.toolbar.with-read-speaker .toolbar-btn.toolbar-behavior-read {
  width: 160px;
  padding: 0; }

.toolbar.with-read-speaker.active .toolbar-player.toolbar-btn.toolbar-behavior-read {
  display: flex; }

.toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read {
  display: none; }
  @media (min-width: 768px) {
    .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read {
      display: flex; } }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read > a {
    text-decoration: none; }
    .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read > a .rsbtn_left .rsbtn_text {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #4a4a4a; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read:hover > a .rsbtn_text {
    color: #006fb3; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded > a .rsbtn_left .rsbtn_text span {
    display: none; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart {
    display: inline-block;
    flex: 1;
    height: 34.46px; }
    .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box {
      display: flex;
      justify-content: space-between;
      height: 34.46px; }
      .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_pause .toolbar-btn-icon-content {
        padding: 9.23px 6.9225px; }
      .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_closer .toolbar-btn-icon-content,
      .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_dl .toolbar-btn-icon-content {
        padding: 9.23px 6.9225px; }
      .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_progress_container {
        position: relative;
        flex: 1;
        margin: 13px 2px;
        border: 1px solid #a8b7c7; }
        .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_progress_container .rsbtn_progress_handle.rsimg {
          position: absolute;
          top: -5px;
          left: -3px;
          width: 6px;
          height: 16px;
          background-color: #a8b7c7;
          background-image: none; }
        .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_progress_container .rsbtn_progress_played {
          position: absolute;
          height: 100%;
          background-color: #a8b7c7;
          background-image: none; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded.rspaused .rsbtn_pause .toolbar-btn-icon-content .cl.cl-pause {
    display: none; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded.rspaused .rsbtn_pause .toolbar-btn-icon-content .cl.cl-play {
    display: block; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded.rsplaying .rsbtn_pause .toolbar-btn-icon-content .cl.cl-pause {
    display: block; }
  .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded.rsplaying .rsbtn_pause .toolbar-btn-icon-content .cl.cl-play {
    display: none; }

.a11y-font-0 .a11y-font-0-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.a11y-font-0 .a11y-font-0-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.a11y-font-0 .a11y-font-0-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.a11y-font-0 .a11y-font-0-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.a11y-font-0 .a11y-font-0-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.a11y-font-0 .a11y-font-0-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.a11y-font-0 .a11y-font-0-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.a11y-font-0 .a11y-font-0-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.a11y-font-0 .a11y-font-0-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.a11y-font-0 .a11y-font-0-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.a11y-font-0 .a11y-font-0-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.a11y-font-0 .a11y-font-0-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.a11y-font-0 .a11y-font-0-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.a11y-font-0 .a11y-font-0-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.a11y-font-0 .a11y-font-0-offset-1 {
  margin-left: 8.3333333333%; }

.a11y-font-0 .a11y-font-0-offset-2 {
  margin-left: 16.6666666667%; }

.a11y-font-0 .a11y-font-0-offset-3 {
  margin-left: 25%; }

.a11y-font-0 .a11y-font-0-offset-4 {
  margin-left: 33.3333333333%; }

.a11y-font-0 .a11y-font-0-offset-5 {
  margin-left: 41.6666666667%; }

.a11y-font-0 .a11y-font-0-offset-6 {
  margin-left: 50%; }

.a11y-font-0 .a11y-font-0-offset-7 {
  margin-left: 58.3333333333%; }

.a11y-font-0 .a11y-font-0-offset-8 {
  margin-left: 66.6666666667%; }

.a11y-font-0 .a11y-font-0-offset-9 {
  margin-left: 75%; }

.a11y-font-0 .a11y-font-0-offset-10 {
  margin-left: 83.3333333333%; }

.a11y-font-0 .a11y-font-0-offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .a11y-font-0 .a11y-font-0-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-0 .a11y-font-0-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-0 .a11y-font-0-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-0 .a11y-font-0-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-0 .a11y-font-0-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-offset-sm-0 {
    margin-left: 0; }
  .a11y-font-0 .a11y-font-0-offset-sm-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-sm-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-sm-3 {
    margin-left: 25%; }
  .a11y-font-0 .a11y-font-0-offset-sm-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-sm-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-sm-6 {
    margin-left: 50%; }
  .a11y-font-0 .a11y-font-0-offset-sm-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-sm-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-sm-9 {
    margin-left: 75%; }
  .a11y-font-0 .a11y-font-0-offset-sm-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .a11y-font-0 .a11y-font-0-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-0 .a11y-font-0-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-0 .a11y-font-0-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-0 .a11y-font-0-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-0 .a11y-font-0-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-offset-md-0 {
    margin-left: 0; }
  .a11y-font-0 .a11y-font-0-offset-md-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-md-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-md-3 {
    margin-left: 25%; }
  .a11y-font-0 .a11y-font-0-offset-md-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-md-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-md-6 {
    margin-left: 50%; }
  .a11y-font-0 .a11y-font-0-offset-md-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-md-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-md-9 {
    margin-left: 75%; }
  .a11y-font-0 .a11y-font-0-offset-md-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .a11y-font-0 .a11y-font-0-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-0 .a11y-font-0-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-0 .a11y-font-0-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-0 .a11y-font-0-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-0 .a11y-font-0-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-offset-lg-0 {
    margin-left: 0; }
  .a11y-font-0 .a11y-font-0-offset-lg-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-lg-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-lg-3 {
    margin-left: 25%; }
  .a11y-font-0 .a11y-font-0-offset-lg-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-lg-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-lg-6 {
    margin-left: 50%; }
  .a11y-font-0 .a11y-font-0-offset-lg-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-lg-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-lg-9 {
    margin-left: 75%; }
  .a11y-font-0 .a11y-font-0-offset-lg-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .a11y-font-0 .a11y-font-0-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-0 .a11y-font-0-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-0 .a11y-font-0-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-0 .a11y-font-0-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-0 .a11y-font-0-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-0 .a11y-font-0-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-0 .a11y-font-0-offset-xl-0 {
    margin-left: 0; }
  .a11y-font-0 .a11y-font-0-offset-xl-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-xl-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-xl-3 {
    margin-left: 25%; }
  .a11y-font-0 .a11y-font-0-offset-xl-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-xl-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-xl-6 {
    margin-left: 50%; }
  .a11y-font-0 .a11y-font-0-offset-xl-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-xl-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-0 .a11y-font-0-offset-xl-9 {
    margin-left: 75%; }
  .a11y-font-0 .a11y-font-0-offset-xl-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-0 .a11y-font-0-offset-xl-11 {
    margin-left: 91.6666666667%; } }

.a11y-font-0 .a11y-font-0-d-none {
  display: none !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-inline {
  display: inline !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-inline-block {
  display: inline-block !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-block {
  display: block !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-table {
  display: table !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-table-row {
  display: table-row !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-table-cell {
  display: table-cell !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-flex {
  display: flex !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-d-inline-flex {
  display: inline-flex !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.a11y-font-0 .a11y-font-0-text-justify {
  text-align: justify !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-nowrap {
  white-space: nowrap !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-left {
  text-align: left !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-right {
  text-align: right !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-center {
  text-align: center !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-0 .a11y-font-0-text-sm-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-sm-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-sm-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-0 .a11y-font-0-text-md-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-md-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-md-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-0 .a11y-font-0-text-lg-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-lg-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-lg-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-0 .a11y-font-0-text-xl-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-xl-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-text-xl-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

.a11y-font-0 .a11y-font-0-text-lowercase {
  text-transform: lowercase !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-uppercase {
  text-transform: uppercase !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-capitalize {
  text-transform: capitalize !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-font-weight-light {
  font-weight: 300 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-font-weight-normal {
  font-weight: 400 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-font-weight-bold {
  font-weight: 700 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-font-italic {
  font-style: italic !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-white {
  color: #fff !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-body {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-muted {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-0 {
  margin: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-0,
.a11y-font-0 .a11y-font-0-my-0 {
  margin-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-0,
.a11y-font-0 .a11y-font-0-mx-0 {
  margin-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-0,
.a11y-font-0 .a11y-font-0-my-0 {
  margin-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-0,
.a11y-font-0 .a11y-font-0-mx-0 {
  margin-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-1 {
  margin: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-1,
.a11y-font-0 .a11y-font-0-my-1 {
  margin-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-1,
.a11y-font-0 .a11y-font-0-mx-1 {
  margin-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-1,
.a11y-font-0 .a11y-font-0-my-1 {
  margin-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-1,
.a11y-font-0 .a11y-font-0-mx-1 {
  margin-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-2 {
  margin: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-2,
.a11y-font-0 .a11y-font-0-my-2 {
  margin-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-2,
.a11y-font-0 .a11y-font-0-mx-2 {
  margin-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-2,
.a11y-font-0 .a11y-font-0-my-2 {
  margin-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-2,
.a11y-font-0 .a11y-font-0-mx-2 {
  margin-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-3 {
  margin: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-3,
.a11y-font-0 .a11y-font-0-my-3 {
  margin-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-3,
.a11y-font-0 .a11y-font-0-mx-3 {
  margin-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-3,
.a11y-font-0 .a11y-font-0-my-3 {
  margin-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-3,
.a11y-font-0 .a11y-font-0-mx-3 {
  margin-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-4 {
  margin: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-4,
.a11y-font-0 .a11y-font-0-my-4 {
  margin-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-4,
.a11y-font-0 .a11y-font-0-mx-4 {
  margin-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-4,
.a11y-font-0 .a11y-font-0-my-4 {
  margin-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-4,
.a11y-font-0 .a11y-font-0-mx-4 {
  margin-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-5 {
  margin: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-5,
.a11y-font-0 .a11y-font-0-my-5 {
  margin-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-5,
.a11y-font-0 .a11y-font-0-mx-5 {
  margin-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-5,
.a11y-font-0 .a11y-font-0-my-5 {
  margin-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-5,
.a11y-font-0 .a11y-font-0-mx-5 {
  margin-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-p-0 {
  padding: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pt-0,
.a11y-font-0 .a11y-font-0-py-0 {
  padding-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pr-0,
.a11y-font-0 .a11y-font-0-px-0 {
  padding-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pb-0,
.a11y-font-0 .a11y-font-0-py-0 {
  padding-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pl-0,
.a11y-font-0 .a11y-font-0-px-0 {
  padding-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-p-1 {
  padding: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pt-1,
.a11y-font-0 .a11y-font-0-py-1 {
  padding-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pr-1,
.a11y-font-0 .a11y-font-0-px-1 {
  padding-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pb-1,
.a11y-font-0 .a11y-font-0-py-1 {
  padding-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pl-1,
.a11y-font-0 .a11y-font-0-px-1 {
  padding-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-p-2 {
  padding: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pt-2,
.a11y-font-0 .a11y-font-0-py-2 {
  padding-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pr-2,
.a11y-font-0 .a11y-font-0-px-2 {
  padding-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pb-2,
.a11y-font-0 .a11y-font-0-py-2 {
  padding-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pl-2,
.a11y-font-0 .a11y-font-0-px-2 {
  padding-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-p-3 {
  padding: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pt-3,
.a11y-font-0 .a11y-font-0-py-3 {
  padding-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pr-3,
.a11y-font-0 .a11y-font-0-px-3 {
  padding-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pb-3,
.a11y-font-0 .a11y-font-0-py-3 {
  padding-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pl-3,
.a11y-font-0 .a11y-font-0-px-3 {
  padding-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-p-4 {
  padding: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pt-4,
.a11y-font-0 .a11y-font-0-py-4 {
  padding-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pr-4,
.a11y-font-0 .a11y-font-0-px-4 {
  padding-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pb-4,
.a11y-font-0 .a11y-font-0-py-4 {
  padding-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pl-4,
.a11y-font-0 .a11y-font-0-px-4 {
  padding-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-p-5 {
  padding: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pt-5,
.a11y-font-0 .a11y-font-0-py-5 {
  padding-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pr-5,
.a11y-font-0 .a11y-font-0-px-5 {
  padding-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pb-5,
.a11y-font-0 .a11y-font-0-py-5 {
  padding-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-pl-5,
.a11y-font-0 .a11y-font-0-px-5 {
  padding-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-m-auto {
  margin: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mt-auto,
.a11y-font-0 .a11y-font-0-my-auto {
  margin-top: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mr-auto,
.a11y-font-0 .a11y-font-0-mx-auto {
  margin-right: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-mb-auto,
.a11y-font-0 .a11y-font-0-my-auto {
  margin-bottom: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-0 .a11y-font-0-ml-auto,
.a11y-font-0 .a11y-font-0-mx-auto {
  margin-left: auto !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-0 .a11y-font-0-col-sm-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-0,
  .a11y-font-0 .a11y-font-0-my-sm-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-0,
  .a11y-font-0 .a11y-font-0-mx-sm-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-0,
  .a11y-font-0 .a11y-font-0-my-sm-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-0,
  .a11y-font-0 .a11y-font-0-mx-sm-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-1,
  .a11y-font-0 .a11y-font-0-my-sm-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-1,
  .a11y-font-0 .a11y-font-0-mx-sm-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-1,
  .a11y-font-0 .a11y-font-0-my-sm-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-1,
  .a11y-font-0 .a11y-font-0-mx-sm-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-2,
  .a11y-font-0 .a11y-font-0-my-sm-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-2,
  .a11y-font-0 .a11y-font-0-mx-sm-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-2,
  .a11y-font-0 .a11y-font-0-my-sm-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-2,
  .a11y-font-0 .a11y-font-0-mx-sm-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-3,
  .a11y-font-0 .a11y-font-0-my-sm-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-3,
  .a11y-font-0 .a11y-font-0-mx-sm-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-3,
  .a11y-font-0 .a11y-font-0-my-sm-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-3,
  .a11y-font-0 .a11y-font-0-mx-sm-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-4,
  .a11y-font-0 .a11y-font-0-my-sm-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-4,
  .a11y-font-0 .a11y-font-0-mx-sm-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-4,
  .a11y-font-0 .a11y-font-0-my-sm-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-4,
  .a11y-font-0 .a11y-font-0-mx-sm-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-5,
  .a11y-font-0 .a11y-font-0-my-sm-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-5,
  .a11y-font-0 .a11y-font-0-mx-sm-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-5,
  .a11y-font-0 .a11y-font-0-my-sm-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-5,
  .a11y-font-0 .a11y-font-0-mx-sm-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-sm-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-sm-0,
  .a11y-font-0 .a11y-font-0-py-sm-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-sm-0,
  .a11y-font-0 .a11y-font-0-px-sm-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-sm-0,
  .a11y-font-0 .a11y-font-0-py-sm-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-sm-0,
  .a11y-font-0 .a11y-font-0-px-sm-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-sm-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-sm-1,
  .a11y-font-0 .a11y-font-0-py-sm-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-sm-1,
  .a11y-font-0 .a11y-font-0-px-sm-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-sm-1,
  .a11y-font-0 .a11y-font-0-py-sm-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-sm-1,
  .a11y-font-0 .a11y-font-0-px-sm-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-sm-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-sm-2,
  .a11y-font-0 .a11y-font-0-py-sm-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-sm-2,
  .a11y-font-0 .a11y-font-0-px-sm-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-sm-2,
  .a11y-font-0 .a11y-font-0-py-sm-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-sm-2,
  .a11y-font-0 .a11y-font-0-px-sm-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-sm-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-sm-3,
  .a11y-font-0 .a11y-font-0-py-sm-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-sm-3,
  .a11y-font-0 .a11y-font-0-px-sm-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-sm-3,
  .a11y-font-0 .a11y-font-0-py-sm-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-sm-3,
  .a11y-font-0 .a11y-font-0-px-sm-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-sm-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-sm-4,
  .a11y-font-0 .a11y-font-0-py-sm-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-sm-4,
  .a11y-font-0 .a11y-font-0-px-sm-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-sm-4,
  .a11y-font-0 .a11y-font-0-py-sm-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-sm-4,
  .a11y-font-0 .a11y-font-0-px-sm-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-sm-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-sm-5,
  .a11y-font-0 .a11y-font-0-py-sm-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-sm-5,
  .a11y-font-0 .a11y-font-0-px-sm-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-sm-5,
  .a11y-font-0 .a11y-font-0-py-sm-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-sm-5,
  .a11y-font-0 .a11y-font-0-px-sm-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-sm-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-sm-auto,
  .a11y-font-0 .a11y-font-0-my-sm-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-sm-auto,
  .a11y-font-0 .a11y-font-0-mx-sm-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-sm-auto,
  .a11y-font-0 .a11y-font-0-my-sm-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-sm-auto,
  .a11y-font-0 .a11y-font-0-mx-sm-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-0 .a11y-font-0-col-md-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-0,
  .a11y-font-0 .a11y-font-0-my-md-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-0,
  .a11y-font-0 .a11y-font-0-mx-md-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-0,
  .a11y-font-0 .a11y-font-0-my-md-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-0,
  .a11y-font-0 .a11y-font-0-mx-md-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-1,
  .a11y-font-0 .a11y-font-0-my-md-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-1,
  .a11y-font-0 .a11y-font-0-mx-md-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-1,
  .a11y-font-0 .a11y-font-0-my-md-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-1,
  .a11y-font-0 .a11y-font-0-mx-md-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-2,
  .a11y-font-0 .a11y-font-0-my-md-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-2,
  .a11y-font-0 .a11y-font-0-mx-md-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-2,
  .a11y-font-0 .a11y-font-0-my-md-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-2,
  .a11y-font-0 .a11y-font-0-mx-md-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-3,
  .a11y-font-0 .a11y-font-0-my-md-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-3,
  .a11y-font-0 .a11y-font-0-mx-md-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-3,
  .a11y-font-0 .a11y-font-0-my-md-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-3,
  .a11y-font-0 .a11y-font-0-mx-md-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-4,
  .a11y-font-0 .a11y-font-0-my-md-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-4,
  .a11y-font-0 .a11y-font-0-mx-md-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-4,
  .a11y-font-0 .a11y-font-0-my-md-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-4,
  .a11y-font-0 .a11y-font-0-mx-md-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-5,
  .a11y-font-0 .a11y-font-0-my-md-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-5,
  .a11y-font-0 .a11y-font-0-mx-md-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-5,
  .a11y-font-0 .a11y-font-0-my-md-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-5,
  .a11y-font-0 .a11y-font-0-mx-md-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-md-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-md-0,
  .a11y-font-0 .a11y-font-0-py-md-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-md-0,
  .a11y-font-0 .a11y-font-0-px-md-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-md-0,
  .a11y-font-0 .a11y-font-0-py-md-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-md-0,
  .a11y-font-0 .a11y-font-0-px-md-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-md-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-md-1,
  .a11y-font-0 .a11y-font-0-py-md-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-md-1,
  .a11y-font-0 .a11y-font-0-px-md-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-md-1,
  .a11y-font-0 .a11y-font-0-py-md-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-md-1,
  .a11y-font-0 .a11y-font-0-px-md-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-md-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-md-2,
  .a11y-font-0 .a11y-font-0-py-md-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-md-2,
  .a11y-font-0 .a11y-font-0-px-md-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-md-2,
  .a11y-font-0 .a11y-font-0-py-md-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-md-2,
  .a11y-font-0 .a11y-font-0-px-md-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-md-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-md-3,
  .a11y-font-0 .a11y-font-0-py-md-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-md-3,
  .a11y-font-0 .a11y-font-0-px-md-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-md-3,
  .a11y-font-0 .a11y-font-0-py-md-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-md-3,
  .a11y-font-0 .a11y-font-0-px-md-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-md-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-md-4,
  .a11y-font-0 .a11y-font-0-py-md-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-md-4,
  .a11y-font-0 .a11y-font-0-px-md-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-md-4,
  .a11y-font-0 .a11y-font-0-py-md-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-md-4,
  .a11y-font-0 .a11y-font-0-px-md-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-md-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-md-5,
  .a11y-font-0 .a11y-font-0-py-md-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-md-5,
  .a11y-font-0 .a11y-font-0-px-md-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-md-5,
  .a11y-font-0 .a11y-font-0-py-md-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-md-5,
  .a11y-font-0 .a11y-font-0-px-md-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-md-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-md-auto,
  .a11y-font-0 .a11y-font-0-my-md-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-md-auto,
  .a11y-font-0 .a11y-font-0-mx-md-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-md-auto,
  .a11y-font-0 .a11y-font-0-my-md-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-md-auto,
  .a11y-font-0 .a11y-font-0-mx-md-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-0 .a11y-font-0-col-lg-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-0,
  .a11y-font-0 .a11y-font-0-my-lg-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-0,
  .a11y-font-0 .a11y-font-0-mx-lg-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-0,
  .a11y-font-0 .a11y-font-0-my-lg-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-0,
  .a11y-font-0 .a11y-font-0-mx-lg-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-1,
  .a11y-font-0 .a11y-font-0-my-lg-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-1,
  .a11y-font-0 .a11y-font-0-mx-lg-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-1,
  .a11y-font-0 .a11y-font-0-my-lg-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-1,
  .a11y-font-0 .a11y-font-0-mx-lg-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-2,
  .a11y-font-0 .a11y-font-0-my-lg-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-2,
  .a11y-font-0 .a11y-font-0-mx-lg-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-2,
  .a11y-font-0 .a11y-font-0-my-lg-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-2,
  .a11y-font-0 .a11y-font-0-mx-lg-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-3,
  .a11y-font-0 .a11y-font-0-my-lg-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-3,
  .a11y-font-0 .a11y-font-0-mx-lg-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-3,
  .a11y-font-0 .a11y-font-0-my-lg-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-3,
  .a11y-font-0 .a11y-font-0-mx-lg-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-4,
  .a11y-font-0 .a11y-font-0-my-lg-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-4,
  .a11y-font-0 .a11y-font-0-mx-lg-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-4,
  .a11y-font-0 .a11y-font-0-my-lg-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-4,
  .a11y-font-0 .a11y-font-0-mx-lg-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-5,
  .a11y-font-0 .a11y-font-0-my-lg-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-5,
  .a11y-font-0 .a11y-font-0-mx-lg-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-5,
  .a11y-font-0 .a11y-font-0-my-lg-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-5,
  .a11y-font-0 .a11y-font-0-mx-lg-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-lg-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-lg-0,
  .a11y-font-0 .a11y-font-0-py-lg-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-lg-0,
  .a11y-font-0 .a11y-font-0-px-lg-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-lg-0,
  .a11y-font-0 .a11y-font-0-py-lg-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-lg-0,
  .a11y-font-0 .a11y-font-0-px-lg-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-lg-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-lg-1,
  .a11y-font-0 .a11y-font-0-py-lg-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-lg-1,
  .a11y-font-0 .a11y-font-0-px-lg-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-lg-1,
  .a11y-font-0 .a11y-font-0-py-lg-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-lg-1,
  .a11y-font-0 .a11y-font-0-px-lg-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-lg-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-lg-2,
  .a11y-font-0 .a11y-font-0-py-lg-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-lg-2,
  .a11y-font-0 .a11y-font-0-px-lg-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-lg-2,
  .a11y-font-0 .a11y-font-0-py-lg-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-lg-2,
  .a11y-font-0 .a11y-font-0-px-lg-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-lg-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-lg-3,
  .a11y-font-0 .a11y-font-0-py-lg-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-lg-3,
  .a11y-font-0 .a11y-font-0-px-lg-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-lg-3,
  .a11y-font-0 .a11y-font-0-py-lg-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-lg-3,
  .a11y-font-0 .a11y-font-0-px-lg-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-lg-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-lg-4,
  .a11y-font-0 .a11y-font-0-py-lg-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-lg-4,
  .a11y-font-0 .a11y-font-0-px-lg-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-lg-4,
  .a11y-font-0 .a11y-font-0-py-lg-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-lg-4,
  .a11y-font-0 .a11y-font-0-px-lg-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-lg-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-lg-5,
  .a11y-font-0 .a11y-font-0-py-lg-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-lg-5,
  .a11y-font-0 .a11y-font-0-px-lg-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-lg-5,
  .a11y-font-0 .a11y-font-0-py-lg-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-lg-5,
  .a11y-font-0 .a11y-font-0-px-lg-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-lg-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-lg-auto,
  .a11y-font-0 .a11y-font-0-my-lg-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-lg-auto,
  .a11y-font-0 .a11y-font-0-mx-lg-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-lg-auto,
  .a11y-font-0 .a11y-font-0-my-lg-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-lg-auto,
  .a11y-font-0 .a11y-font-0-mx-lg-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-0 .a11y-font-0-col-xl-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-0,
  .a11y-font-0 .a11y-font-0-my-xl-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-0,
  .a11y-font-0 .a11y-font-0-mx-xl-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-0,
  .a11y-font-0 .a11y-font-0-my-xl-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-0,
  .a11y-font-0 .a11y-font-0-mx-xl-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-1,
  .a11y-font-0 .a11y-font-0-my-xl-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-1,
  .a11y-font-0 .a11y-font-0-mx-xl-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-1,
  .a11y-font-0 .a11y-font-0-my-xl-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-1,
  .a11y-font-0 .a11y-font-0-mx-xl-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-2,
  .a11y-font-0 .a11y-font-0-my-xl-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-2,
  .a11y-font-0 .a11y-font-0-mx-xl-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-2,
  .a11y-font-0 .a11y-font-0-my-xl-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-2,
  .a11y-font-0 .a11y-font-0-mx-xl-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-3,
  .a11y-font-0 .a11y-font-0-my-xl-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-3,
  .a11y-font-0 .a11y-font-0-mx-xl-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-3,
  .a11y-font-0 .a11y-font-0-my-xl-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-3,
  .a11y-font-0 .a11y-font-0-mx-xl-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-4,
  .a11y-font-0 .a11y-font-0-my-xl-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-4,
  .a11y-font-0 .a11y-font-0-mx-xl-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-4,
  .a11y-font-0 .a11y-font-0-my-xl-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-4,
  .a11y-font-0 .a11y-font-0-mx-xl-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-5,
  .a11y-font-0 .a11y-font-0-my-xl-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-5,
  .a11y-font-0 .a11y-font-0-mx-xl-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-5,
  .a11y-font-0 .a11y-font-0-my-xl-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-5,
  .a11y-font-0 .a11y-font-0-mx-xl-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-xl-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-xl-0,
  .a11y-font-0 .a11y-font-0-py-xl-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-xl-0,
  .a11y-font-0 .a11y-font-0-px-xl-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-xl-0,
  .a11y-font-0 .a11y-font-0-py-xl-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-xl-0,
  .a11y-font-0 .a11y-font-0-px-xl-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-xl-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-xl-1,
  .a11y-font-0 .a11y-font-0-py-xl-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-xl-1,
  .a11y-font-0 .a11y-font-0-px-xl-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-xl-1,
  .a11y-font-0 .a11y-font-0-py-xl-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-xl-1,
  .a11y-font-0 .a11y-font-0-px-xl-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-xl-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-xl-2,
  .a11y-font-0 .a11y-font-0-py-xl-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-xl-2,
  .a11y-font-0 .a11y-font-0-px-xl-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-xl-2,
  .a11y-font-0 .a11y-font-0-py-xl-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-xl-2,
  .a11y-font-0 .a11y-font-0-px-xl-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-xl-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-xl-3,
  .a11y-font-0 .a11y-font-0-py-xl-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-xl-3,
  .a11y-font-0 .a11y-font-0-px-xl-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-xl-3,
  .a11y-font-0 .a11y-font-0-py-xl-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-xl-3,
  .a11y-font-0 .a11y-font-0-px-xl-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-xl-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-xl-4,
  .a11y-font-0 .a11y-font-0-py-xl-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-xl-4,
  .a11y-font-0 .a11y-font-0-px-xl-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-xl-4,
  .a11y-font-0 .a11y-font-0-py-xl-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-xl-4,
  .a11y-font-0 .a11y-font-0-px-xl-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-p-xl-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pt-xl-5,
  .a11y-font-0 .a11y-font-0-py-xl-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pr-xl-5,
  .a11y-font-0 .a11y-font-0-px-xl-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pb-xl-5,
  .a11y-font-0 .a11y-font-0-py-xl-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-pl-xl-5,
  .a11y-font-0 .a11y-font-0-px-xl-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-m-xl-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mt-xl-auto,
  .a11y-font-0 .a11y-font-0-my-xl-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mr-xl-auto,
  .a11y-font-0 .a11y-font-0-mx-xl-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-mb-xl-auto,
  .a11y-font-0 .a11y-font-0-my-xl-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-0 .a11y-font-0-ml-xl-auto,
  .a11y-font-0 .a11y-font-0-mx-xl-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

.a11y-font-1 .a11y-font-1-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.a11y-font-1 .a11y-font-1-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.a11y-font-1 .a11y-font-1-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.a11y-font-1 .a11y-font-1-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.a11y-font-1 .a11y-font-1-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.a11y-font-1 .a11y-font-1-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.a11y-font-1 .a11y-font-1-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.a11y-font-1 .a11y-font-1-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.a11y-font-1 .a11y-font-1-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.a11y-font-1 .a11y-font-1-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.a11y-font-1 .a11y-font-1-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.a11y-font-1 .a11y-font-1-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.a11y-font-1 .a11y-font-1-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.a11y-font-1 .a11y-font-1-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.a11y-font-1 .a11y-font-1-offset-1 {
  margin-left: 8.3333333333%; }

.a11y-font-1 .a11y-font-1-offset-2 {
  margin-left: 16.6666666667%; }

.a11y-font-1 .a11y-font-1-offset-3 {
  margin-left: 25%; }

.a11y-font-1 .a11y-font-1-offset-4 {
  margin-left: 33.3333333333%; }

.a11y-font-1 .a11y-font-1-offset-5 {
  margin-left: 41.6666666667%; }

.a11y-font-1 .a11y-font-1-offset-6 {
  margin-left: 50%; }

.a11y-font-1 .a11y-font-1-offset-7 {
  margin-left: 58.3333333333%; }

.a11y-font-1 .a11y-font-1-offset-8 {
  margin-left: 66.6666666667%; }

.a11y-font-1 .a11y-font-1-offset-9 {
  margin-left: 75%; }

.a11y-font-1 .a11y-font-1-offset-10 {
  margin-left: 83.3333333333%; }

.a11y-font-1 .a11y-font-1-offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .a11y-font-1 .a11y-font-1-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-font-1-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-font-1-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-font-1-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-font-1-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-offset-sm-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-font-1-offset-sm-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-sm-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-sm-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-font-1-offset-sm-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-sm-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-sm-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-font-1-offset-sm-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-sm-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-sm-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-font-1-offset-sm-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .a11y-font-1 .a11y-font-1-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-font-1-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-font-1-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-font-1-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-font-1-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-offset-md-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-font-1-offset-md-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-md-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-md-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-font-1-offset-md-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-md-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-md-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-font-1-offset-md-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-md-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-md-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-font-1-offset-md-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .a11y-font-1 .a11y-font-1-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-font-1-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-font-1-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-font-1-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-font-1-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-offset-lg-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-font-1-offset-lg-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-lg-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-lg-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-font-1-offset-lg-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-lg-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-lg-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-font-1-offset-lg-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-lg-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-lg-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-font-1-offset-lg-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .a11y-font-1 .a11y-font-1-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-font-1-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-font-1-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-font-1-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-font-1-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-font-1-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-font-1-offset-xl-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-font-1-offset-xl-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-xl-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-xl-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-font-1-offset-xl-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-xl-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-xl-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-font-1-offset-xl-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-xl-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-font-1-offset-xl-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-font-1-offset-xl-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-font-1-offset-xl-11 {
    margin-left: 91.6666666667%; } }

.a11y-font-1 .a11y-font-1-d-none {
  display: none !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-inline {
  display: inline !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-inline-block {
  display: inline-block !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-block {
  display: block !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-table {
  display: table !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-table-row {
  display: table-row !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-table-cell {
  display: table-cell !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-flex {
  display: flex !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-d-inline-flex {
  display: inline-flex !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.a11y-font-1 .a11y-font-1-text-justify {
  text-align: justify !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-nowrap {
  white-space: nowrap !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-left {
  text-align: left !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-right {
  text-align: right !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-center {
  text-align: center !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-1 .a11y-font-1-text-sm-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-sm-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-sm-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-1 .a11y-font-1-text-md-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-md-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-md-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-1 .a11y-font-1-text-lg-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-lg-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-lg-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-1 .a11y-font-1-text-xl-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-xl-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-text-xl-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

.a11y-font-1 .a11y-font-1-text-lowercase {
  text-transform: lowercase !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-uppercase {
  text-transform: uppercase !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-capitalize {
  text-transform: capitalize !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-font-weight-light {
  font-weight: 300 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-font-weight-normal {
  font-weight: 400 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-font-weight-bold {
  font-weight: 700 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-font-italic {
  font-style: italic !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-white {
  color: #fff !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-body {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-muted {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-0 {
  margin: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-0,
.a11y-font-1 .a11y-font-1-my-0 {
  margin-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-0,
.a11y-font-1 .a11y-font-1-mx-0 {
  margin-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-0,
.a11y-font-1 .a11y-font-1-my-0 {
  margin-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-0,
.a11y-font-1 .a11y-font-1-mx-0 {
  margin-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-1 {
  margin: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-1,
.a11y-font-1 .a11y-font-1-my-1 {
  margin-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-1,
.a11y-font-1 .a11y-font-1-mx-1 {
  margin-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-1,
.a11y-font-1 .a11y-font-1-my-1 {
  margin-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-1,
.a11y-font-1 .a11y-font-1-mx-1 {
  margin-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-2 {
  margin: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-2,
.a11y-font-1 .a11y-font-1-my-2 {
  margin-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-2,
.a11y-font-1 .a11y-font-1-mx-2 {
  margin-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-2,
.a11y-font-1 .a11y-font-1-my-2 {
  margin-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-2,
.a11y-font-1 .a11y-font-1-mx-2 {
  margin-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-3 {
  margin: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-3,
.a11y-font-1 .a11y-font-1-my-3 {
  margin-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-3,
.a11y-font-1 .a11y-font-1-mx-3 {
  margin-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-3,
.a11y-font-1 .a11y-font-1-my-3 {
  margin-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-3,
.a11y-font-1 .a11y-font-1-mx-3 {
  margin-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-4 {
  margin: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-4,
.a11y-font-1 .a11y-font-1-my-4 {
  margin-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-4,
.a11y-font-1 .a11y-font-1-mx-4 {
  margin-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-4,
.a11y-font-1 .a11y-font-1-my-4 {
  margin-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-4,
.a11y-font-1 .a11y-font-1-mx-4 {
  margin-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-5 {
  margin: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-5,
.a11y-font-1 .a11y-font-1-my-5 {
  margin-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-5,
.a11y-font-1 .a11y-font-1-mx-5 {
  margin-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-5,
.a11y-font-1 .a11y-font-1-my-5 {
  margin-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-5,
.a11y-font-1 .a11y-font-1-mx-5 {
  margin-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-p-0 {
  padding: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pt-0,
.a11y-font-1 .a11y-font-1-py-0 {
  padding-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pr-0,
.a11y-font-1 .a11y-font-1-px-0 {
  padding-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pb-0,
.a11y-font-1 .a11y-font-1-py-0 {
  padding-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pl-0,
.a11y-font-1 .a11y-font-1-px-0 {
  padding-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-p-1 {
  padding: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pt-1,
.a11y-font-1 .a11y-font-1-py-1 {
  padding-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pr-1,
.a11y-font-1 .a11y-font-1-px-1 {
  padding-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pb-1,
.a11y-font-1 .a11y-font-1-py-1 {
  padding-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pl-1,
.a11y-font-1 .a11y-font-1-px-1 {
  padding-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-p-2 {
  padding: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pt-2,
.a11y-font-1 .a11y-font-1-py-2 {
  padding-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pr-2,
.a11y-font-1 .a11y-font-1-px-2 {
  padding-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pb-2,
.a11y-font-1 .a11y-font-1-py-2 {
  padding-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pl-2,
.a11y-font-1 .a11y-font-1-px-2 {
  padding-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-p-3 {
  padding: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pt-3,
.a11y-font-1 .a11y-font-1-py-3 {
  padding-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pr-3,
.a11y-font-1 .a11y-font-1-px-3 {
  padding-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pb-3,
.a11y-font-1 .a11y-font-1-py-3 {
  padding-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pl-3,
.a11y-font-1 .a11y-font-1-px-3 {
  padding-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-p-4 {
  padding: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pt-4,
.a11y-font-1 .a11y-font-1-py-4 {
  padding-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pr-4,
.a11y-font-1 .a11y-font-1-px-4 {
  padding-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pb-4,
.a11y-font-1 .a11y-font-1-py-4 {
  padding-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pl-4,
.a11y-font-1 .a11y-font-1-px-4 {
  padding-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-p-5 {
  padding: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pt-5,
.a11y-font-1 .a11y-font-1-py-5 {
  padding-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pr-5,
.a11y-font-1 .a11y-font-1-px-5 {
  padding-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pb-5,
.a11y-font-1 .a11y-font-1-py-5 {
  padding-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-pl-5,
.a11y-font-1 .a11y-font-1-px-5 {
  padding-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-m-auto {
  margin: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mt-auto,
.a11y-font-1 .a11y-font-1-my-auto {
  margin-top: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mr-auto,
.a11y-font-1 .a11y-font-1-mx-auto {
  margin-right: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-mb-auto,
.a11y-font-1 .a11y-font-1-my-auto {
  margin-bottom: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-font-1-ml-auto,
.a11y-font-1 .a11y-font-1-mx-auto {
  margin-left: auto !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-1 .a11y-font-1-col-sm-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-0,
  .a11y-font-1 .a11y-font-1-my-sm-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-0,
  .a11y-font-1 .a11y-font-1-mx-sm-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-0,
  .a11y-font-1 .a11y-font-1-my-sm-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-0,
  .a11y-font-1 .a11y-font-1-mx-sm-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-1,
  .a11y-font-1 .a11y-font-1-my-sm-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-1,
  .a11y-font-1 .a11y-font-1-mx-sm-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-1,
  .a11y-font-1 .a11y-font-1-my-sm-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-1,
  .a11y-font-1 .a11y-font-1-mx-sm-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-2,
  .a11y-font-1 .a11y-font-1-my-sm-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-2,
  .a11y-font-1 .a11y-font-1-mx-sm-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-2,
  .a11y-font-1 .a11y-font-1-my-sm-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-2,
  .a11y-font-1 .a11y-font-1-mx-sm-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-3,
  .a11y-font-1 .a11y-font-1-my-sm-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-3,
  .a11y-font-1 .a11y-font-1-mx-sm-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-3,
  .a11y-font-1 .a11y-font-1-my-sm-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-3,
  .a11y-font-1 .a11y-font-1-mx-sm-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-4,
  .a11y-font-1 .a11y-font-1-my-sm-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-4,
  .a11y-font-1 .a11y-font-1-mx-sm-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-4,
  .a11y-font-1 .a11y-font-1-my-sm-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-4,
  .a11y-font-1 .a11y-font-1-mx-sm-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-5,
  .a11y-font-1 .a11y-font-1-my-sm-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-5,
  .a11y-font-1 .a11y-font-1-mx-sm-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-5,
  .a11y-font-1 .a11y-font-1-my-sm-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-5,
  .a11y-font-1 .a11y-font-1-mx-sm-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-sm-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-sm-0,
  .a11y-font-1 .a11y-font-1-py-sm-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-sm-0,
  .a11y-font-1 .a11y-font-1-px-sm-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-sm-0,
  .a11y-font-1 .a11y-font-1-py-sm-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-sm-0,
  .a11y-font-1 .a11y-font-1-px-sm-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-sm-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-sm-1,
  .a11y-font-1 .a11y-font-1-py-sm-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-sm-1,
  .a11y-font-1 .a11y-font-1-px-sm-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-sm-1,
  .a11y-font-1 .a11y-font-1-py-sm-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-sm-1,
  .a11y-font-1 .a11y-font-1-px-sm-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-sm-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-sm-2,
  .a11y-font-1 .a11y-font-1-py-sm-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-sm-2,
  .a11y-font-1 .a11y-font-1-px-sm-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-sm-2,
  .a11y-font-1 .a11y-font-1-py-sm-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-sm-2,
  .a11y-font-1 .a11y-font-1-px-sm-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-sm-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-sm-3,
  .a11y-font-1 .a11y-font-1-py-sm-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-sm-3,
  .a11y-font-1 .a11y-font-1-px-sm-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-sm-3,
  .a11y-font-1 .a11y-font-1-py-sm-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-sm-3,
  .a11y-font-1 .a11y-font-1-px-sm-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-sm-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-sm-4,
  .a11y-font-1 .a11y-font-1-py-sm-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-sm-4,
  .a11y-font-1 .a11y-font-1-px-sm-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-sm-4,
  .a11y-font-1 .a11y-font-1-py-sm-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-sm-4,
  .a11y-font-1 .a11y-font-1-px-sm-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-sm-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-sm-5,
  .a11y-font-1 .a11y-font-1-py-sm-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-sm-5,
  .a11y-font-1 .a11y-font-1-px-sm-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-sm-5,
  .a11y-font-1 .a11y-font-1-py-sm-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-sm-5,
  .a11y-font-1 .a11y-font-1-px-sm-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-sm-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-sm-auto,
  .a11y-font-1 .a11y-font-1-my-sm-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-sm-auto,
  .a11y-font-1 .a11y-font-1-mx-sm-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-sm-auto,
  .a11y-font-1 .a11y-font-1-my-sm-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-sm-auto,
  .a11y-font-1 .a11y-font-1-mx-sm-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-1 .a11y-font-1-col-md-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-0,
  .a11y-font-1 .a11y-font-1-my-md-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-0,
  .a11y-font-1 .a11y-font-1-mx-md-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-0,
  .a11y-font-1 .a11y-font-1-my-md-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-0,
  .a11y-font-1 .a11y-font-1-mx-md-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-1,
  .a11y-font-1 .a11y-font-1-my-md-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-1,
  .a11y-font-1 .a11y-font-1-mx-md-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-1,
  .a11y-font-1 .a11y-font-1-my-md-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-1,
  .a11y-font-1 .a11y-font-1-mx-md-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-2,
  .a11y-font-1 .a11y-font-1-my-md-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-2,
  .a11y-font-1 .a11y-font-1-mx-md-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-2,
  .a11y-font-1 .a11y-font-1-my-md-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-2,
  .a11y-font-1 .a11y-font-1-mx-md-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-3,
  .a11y-font-1 .a11y-font-1-my-md-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-3,
  .a11y-font-1 .a11y-font-1-mx-md-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-3,
  .a11y-font-1 .a11y-font-1-my-md-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-3,
  .a11y-font-1 .a11y-font-1-mx-md-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-4,
  .a11y-font-1 .a11y-font-1-my-md-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-4,
  .a11y-font-1 .a11y-font-1-mx-md-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-4,
  .a11y-font-1 .a11y-font-1-my-md-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-4,
  .a11y-font-1 .a11y-font-1-mx-md-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-5,
  .a11y-font-1 .a11y-font-1-my-md-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-5,
  .a11y-font-1 .a11y-font-1-mx-md-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-5,
  .a11y-font-1 .a11y-font-1-my-md-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-5,
  .a11y-font-1 .a11y-font-1-mx-md-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-md-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-md-0,
  .a11y-font-1 .a11y-font-1-py-md-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-md-0,
  .a11y-font-1 .a11y-font-1-px-md-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-md-0,
  .a11y-font-1 .a11y-font-1-py-md-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-md-0,
  .a11y-font-1 .a11y-font-1-px-md-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-md-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-md-1,
  .a11y-font-1 .a11y-font-1-py-md-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-md-1,
  .a11y-font-1 .a11y-font-1-px-md-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-md-1,
  .a11y-font-1 .a11y-font-1-py-md-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-md-1,
  .a11y-font-1 .a11y-font-1-px-md-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-md-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-md-2,
  .a11y-font-1 .a11y-font-1-py-md-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-md-2,
  .a11y-font-1 .a11y-font-1-px-md-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-md-2,
  .a11y-font-1 .a11y-font-1-py-md-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-md-2,
  .a11y-font-1 .a11y-font-1-px-md-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-md-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-md-3,
  .a11y-font-1 .a11y-font-1-py-md-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-md-3,
  .a11y-font-1 .a11y-font-1-px-md-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-md-3,
  .a11y-font-1 .a11y-font-1-py-md-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-md-3,
  .a11y-font-1 .a11y-font-1-px-md-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-md-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-md-4,
  .a11y-font-1 .a11y-font-1-py-md-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-md-4,
  .a11y-font-1 .a11y-font-1-px-md-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-md-4,
  .a11y-font-1 .a11y-font-1-py-md-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-md-4,
  .a11y-font-1 .a11y-font-1-px-md-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-md-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-md-5,
  .a11y-font-1 .a11y-font-1-py-md-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-md-5,
  .a11y-font-1 .a11y-font-1-px-md-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-md-5,
  .a11y-font-1 .a11y-font-1-py-md-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-md-5,
  .a11y-font-1 .a11y-font-1-px-md-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-md-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-md-auto,
  .a11y-font-1 .a11y-font-1-my-md-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-md-auto,
  .a11y-font-1 .a11y-font-1-mx-md-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-md-auto,
  .a11y-font-1 .a11y-font-1-my-md-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-md-auto,
  .a11y-font-1 .a11y-font-1-mx-md-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-1 .a11y-font-1-col-lg-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-0,
  .a11y-font-1 .a11y-font-1-my-lg-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-0,
  .a11y-font-1 .a11y-font-1-mx-lg-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-0,
  .a11y-font-1 .a11y-font-1-my-lg-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-0,
  .a11y-font-1 .a11y-font-1-mx-lg-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-1,
  .a11y-font-1 .a11y-font-1-my-lg-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-1,
  .a11y-font-1 .a11y-font-1-mx-lg-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-1,
  .a11y-font-1 .a11y-font-1-my-lg-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-1,
  .a11y-font-1 .a11y-font-1-mx-lg-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-2,
  .a11y-font-1 .a11y-font-1-my-lg-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-2,
  .a11y-font-1 .a11y-font-1-mx-lg-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-2,
  .a11y-font-1 .a11y-font-1-my-lg-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-2,
  .a11y-font-1 .a11y-font-1-mx-lg-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-3,
  .a11y-font-1 .a11y-font-1-my-lg-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-3,
  .a11y-font-1 .a11y-font-1-mx-lg-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-3,
  .a11y-font-1 .a11y-font-1-my-lg-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-3,
  .a11y-font-1 .a11y-font-1-mx-lg-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-4,
  .a11y-font-1 .a11y-font-1-my-lg-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-4,
  .a11y-font-1 .a11y-font-1-mx-lg-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-4,
  .a11y-font-1 .a11y-font-1-my-lg-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-4,
  .a11y-font-1 .a11y-font-1-mx-lg-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-5,
  .a11y-font-1 .a11y-font-1-my-lg-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-5,
  .a11y-font-1 .a11y-font-1-mx-lg-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-5,
  .a11y-font-1 .a11y-font-1-my-lg-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-5,
  .a11y-font-1 .a11y-font-1-mx-lg-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-lg-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-lg-0,
  .a11y-font-1 .a11y-font-1-py-lg-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-lg-0,
  .a11y-font-1 .a11y-font-1-px-lg-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-lg-0,
  .a11y-font-1 .a11y-font-1-py-lg-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-lg-0,
  .a11y-font-1 .a11y-font-1-px-lg-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-lg-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-lg-1,
  .a11y-font-1 .a11y-font-1-py-lg-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-lg-1,
  .a11y-font-1 .a11y-font-1-px-lg-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-lg-1,
  .a11y-font-1 .a11y-font-1-py-lg-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-lg-1,
  .a11y-font-1 .a11y-font-1-px-lg-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-lg-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-lg-2,
  .a11y-font-1 .a11y-font-1-py-lg-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-lg-2,
  .a11y-font-1 .a11y-font-1-px-lg-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-lg-2,
  .a11y-font-1 .a11y-font-1-py-lg-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-lg-2,
  .a11y-font-1 .a11y-font-1-px-lg-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-lg-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-lg-3,
  .a11y-font-1 .a11y-font-1-py-lg-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-lg-3,
  .a11y-font-1 .a11y-font-1-px-lg-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-lg-3,
  .a11y-font-1 .a11y-font-1-py-lg-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-lg-3,
  .a11y-font-1 .a11y-font-1-px-lg-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-lg-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-lg-4,
  .a11y-font-1 .a11y-font-1-py-lg-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-lg-4,
  .a11y-font-1 .a11y-font-1-px-lg-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-lg-4,
  .a11y-font-1 .a11y-font-1-py-lg-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-lg-4,
  .a11y-font-1 .a11y-font-1-px-lg-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-lg-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-lg-5,
  .a11y-font-1 .a11y-font-1-py-lg-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-lg-5,
  .a11y-font-1 .a11y-font-1-px-lg-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-lg-5,
  .a11y-font-1 .a11y-font-1-py-lg-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-lg-5,
  .a11y-font-1 .a11y-font-1-px-lg-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-lg-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-lg-auto,
  .a11y-font-1 .a11y-font-1-my-lg-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-lg-auto,
  .a11y-font-1 .a11y-font-1-mx-lg-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-lg-auto,
  .a11y-font-1 .a11y-font-1-my-lg-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-lg-auto,
  .a11y-font-1 .a11y-font-1-mx-lg-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-1 .a11y-font-1-col-xl-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-0,
  .a11y-font-1 .a11y-font-1-my-xl-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-0,
  .a11y-font-1 .a11y-font-1-mx-xl-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-0,
  .a11y-font-1 .a11y-font-1-my-xl-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-0,
  .a11y-font-1 .a11y-font-1-mx-xl-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-1,
  .a11y-font-1 .a11y-font-1-my-xl-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-1,
  .a11y-font-1 .a11y-font-1-mx-xl-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-1,
  .a11y-font-1 .a11y-font-1-my-xl-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-1,
  .a11y-font-1 .a11y-font-1-mx-xl-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-2,
  .a11y-font-1 .a11y-font-1-my-xl-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-2,
  .a11y-font-1 .a11y-font-1-mx-xl-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-2,
  .a11y-font-1 .a11y-font-1-my-xl-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-2,
  .a11y-font-1 .a11y-font-1-mx-xl-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-3,
  .a11y-font-1 .a11y-font-1-my-xl-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-3,
  .a11y-font-1 .a11y-font-1-mx-xl-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-3,
  .a11y-font-1 .a11y-font-1-my-xl-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-3,
  .a11y-font-1 .a11y-font-1-mx-xl-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-4,
  .a11y-font-1 .a11y-font-1-my-xl-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-4,
  .a11y-font-1 .a11y-font-1-mx-xl-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-4,
  .a11y-font-1 .a11y-font-1-my-xl-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-4,
  .a11y-font-1 .a11y-font-1-mx-xl-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-5,
  .a11y-font-1 .a11y-font-1-my-xl-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-5,
  .a11y-font-1 .a11y-font-1-mx-xl-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-5,
  .a11y-font-1 .a11y-font-1-my-xl-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-5,
  .a11y-font-1 .a11y-font-1-mx-xl-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-xl-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-xl-0,
  .a11y-font-1 .a11y-font-1-py-xl-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-xl-0,
  .a11y-font-1 .a11y-font-1-px-xl-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-xl-0,
  .a11y-font-1 .a11y-font-1-py-xl-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-xl-0,
  .a11y-font-1 .a11y-font-1-px-xl-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-xl-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-xl-1,
  .a11y-font-1 .a11y-font-1-py-xl-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-xl-1,
  .a11y-font-1 .a11y-font-1-px-xl-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-xl-1,
  .a11y-font-1 .a11y-font-1-py-xl-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-xl-1,
  .a11y-font-1 .a11y-font-1-px-xl-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-xl-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-xl-2,
  .a11y-font-1 .a11y-font-1-py-xl-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-xl-2,
  .a11y-font-1 .a11y-font-1-px-xl-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-xl-2,
  .a11y-font-1 .a11y-font-1-py-xl-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-xl-2,
  .a11y-font-1 .a11y-font-1-px-xl-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-xl-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-xl-3,
  .a11y-font-1 .a11y-font-1-py-xl-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-xl-3,
  .a11y-font-1 .a11y-font-1-px-xl-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-xl-3,
  .a11y-font-1 .a11y-font-1-py-xl-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-xl-3,
  .a11y-font-1 .a11y-font-1-px-xl-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-xl-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-xl-4,
  .a11y-font-1 .a11y-font-1-py-xl-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-xl-4,
  .a11y-font-1 .a11y-font-1-px-xl-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-xl-4,
  .a11y-font-1 .a11y-font-1-py-xl-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-xl-4,
  .a11y-font-1 .a11y-font-1-px-xl-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-p-xl-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pt-xl-5,
  .a11y-font-1 .a11y-font-1-py-xl-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pr-xl-5,
  .a11y-font-1 .a11y-font-1-px-xl-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pb-xl-5,
  .a11y-font-1 .a11y-font-1-py-xl-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-pl-xl-5,
  .a11y-font-1 .a11y-font-1-px-xl-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-m-xl-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mt-xl-auto,
  .a11y-font-1 .a11y-font-1-my-xl-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mr-xl-auto,
  .a11y-font-1 .a11y-font-1-mx-xl-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-mb-xl-auto,
  .a11y-font-1 .a11y-font-1-my-xl-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-font-1-ml-xl-auto,
  .a11y-font-1 .a11y-font-1-mx-xl-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

.a11y-font-2 .a11y-font-2-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.a11y-font-2 .a11y-font-2-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.a11y-font-2 .a11y-font-2-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.a11y-font-2 .a11y-font-2-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.a11y-font-2 .a11y-font-2-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.a11y-font-2 .a11y-font-2-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.a11y-font-2 .a11y-font-2-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.a11y-font-2 .a11y-font-2-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.a11y-font-2 .a11y-font-2-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.a11y-font-2 .a11y-font-2-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.a11y-font-2 .a11y-font-2-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.a11y-font-2 .a11y-font-2-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.a11y-font-2 .a11y-font-2-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.a11y-font-2 .a11y-font-2-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.a11y-font-2 .a11y-font-2-offset-1 {
  margin-left: 8.3333333333%; }

.a11y-font-2 .a11y-font-2-offset-2 {
  margin-left: 16.6666666667%; }

.a11y-font-2 .a11y-font-2-offset-3 {
  margin-left: 25%; }

.a11y-font-2 .a11y-font-2-offset-4 {
  margin-left: 33.3333333333%; }

.a11y-font-2 .a11y-font-2-offset-5 {
  margin-left: 41.6666666667%; }

.a11y-font-2 .a11y-font-2-offset-6 {
  margin-left: 50%; }

.a11y-font-2 .a11y-font-2-offset-7 {
  margin-left: 58.3333333333%; }

.a11y-font-2 .a11y-font-2-offset-8 {
  margin-left: 66.6666666667%; }

.a11y-font-2 .a11y-font-2-offset-9 {
  margin-left: 75%; }

.a11y-font-2 .a11y-font-2-offset-10 {
  margin-left: 83.3333333333%; }

.a11y-font-2 .a11y-font-2-offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .a11y-font-2 .a11y-font-2-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-2 .a11y-font-2-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-2 .a11y-font-2-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-2 .a11y-font-2-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-2 .a11y-font-2-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-offset-sm-0 {
    margin-left: 0; }
  .a11y-font-2 .a11y-font-2-offset-sm-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-sm-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-sm-3 {
    margin-left: 25%; }
  .a11y-font-2 .a11y-font-2-offset-sm-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-sm-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-sm-6 {
    margin-left: 50%; }
  .a11y-font-2 .a11y-font-2-offset-sm-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-sm-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-sm-9 {
    margin-left: 75%; }
  .a11y-font-2 .a11y-font-2-offset-sm-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .a11y-font-2 .a11y-font-2-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-2 .a11y-font-2-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-2 .a11y-font-2-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-2 .a11y-font-2-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-2 .a11y-font-2-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-offset-md-0 {
    margin-left: 0; }
  .a11y-font-2 .a11y-font-2-offset-md-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-md-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-md-3 {
    margin-left: 25%; }
  .a11y-font-2 .a11y-font-2-offset-md-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-md-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-md-6 {
    margin-left: 50%; }
  .a11y-font-2 .a11y-font-2-offset-md-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-md-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-md-9 {
    margin-left: 75%; }
  .a11y-font-2 .a11y-font-2-offset-md-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .a11y-font-2 .a11y-font-2-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-2 .a11y-font-2-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-2 .a11y-font-2-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-2 .a11y-font-2-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-2 .a11y-font-2-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-offset-lg-0 {
    margin-left: 0; }
  .a11y-font-2 .a11y-font-2-offset-lg-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-lg-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-lg-3 {
    margin-left: 25%; }
  .a11y-font-2 .a11y-font-2-offset-lg-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-lg-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-lg-6 {
    margin-left: 50%; }
  .a11y-font-2 .a11y-font-2-offset-lg-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-lg-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-lg-9 {
    margin-left: 75%; }
  .a11y-font-2 .a11y-font-2-offset-lg-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .a11y-font-2 .a11y-font-2-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-2 .a11y-font-2-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-2 .a11y-font-2-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-2 .a11y-font-2-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-2 .a11y-font-2-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-2 .a11y-font-2-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-2 .a11y-font-2-offset-xl-0 {
    margin-left: 0; }
  .a11y-font-2 .a11y-font-2-offset-xl-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-xl-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-xl-3 {
    margin-left: 25%; }
  .a11y-font-2 .a11y-font-2-offset-xl-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-xl-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-xl-6 {
    margin-left: 50%; }
  .a11y-font-2 .a11y-font-2-offset-xl-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-xl-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-2 .a11y-font-2-offset-xl-9 {
    margin-left: 75%; }
  .a11y-font-2 .a11y-font-2-offset-xl-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-2 .a11y-font-2-offset-xl-11 {
    margin-left: 91.6666666667%; } }

.a11y-font-2 .a11y-font-2-d-none {
  display: none !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-inline {
  display: inline !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-inline-block {
  display: inline-block !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-block {
  display: block !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-table {
  display: table !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-table-row {
  display: table-row !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-table-cell {
  display: table-cell !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-flex {
  display: flex !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-d-inline-flex {
  display: inline-flex !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.a11y-font-2 .a11y-font-2-text-justify {
  text-align: justify !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-nowrap {
  white-space: nowrap !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-left {
  text-align: left !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-right {
  text-align: right !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-center {
  text-align: center !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-2 .a11y-font-2-text-sm-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-sm-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-sm-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-2 .a11y-font-2-text-md-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-md-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-md-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-2 .a11y-font-2-text-lg-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-lg-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-lg-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-2 .a11y-font-2-text-xl-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-xl-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-text-xl-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

.a11y-font-2 .a11y-font-2-text-lowercase {
  text-transform: lowercase !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-uppercase {
  text-transform: uppercase !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-capitalize {
  text-transform: capitalize !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-font-weight-light {
  font-weight: 300 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-font-weight-normal {
  font-weight: 400 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-font-weight-bold {
  font-weight: 700 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-font-italic {
  font-style: italic !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-white {
  color: #fff !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-body {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-muted {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-0 {
  margin: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-0,
.a11y-font-2 .a11y-font-2-my-0 {
  margin-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-0,
.a11y-font-2 .a11y-font-2-mx-0 {
  margin-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-0,
.a11y-font-2 .a11y-font-2-my-0 {
  margin-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-0,
.a11y-font-2 .a11y-font-2-mx-0 {
  margin-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-1 {
  margin: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-1,
.a11y-font-2 .a11y-font-2-my-1 {
  margin-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-1,
.a11y-font-2 .a11y-font-2-mx-1 {
  margin-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-1,
.a11y-font-2 .a11y-font-2-my-1 {
  margin-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-1,
.a11y-font-2 .a11y-font-2-mx-1 {
  margin-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-2 {
  margin: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-2,
.a11y-font-2 .a11y-font-2-my-2 {
  margin-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-2,
.a11y-font-2 .a11y-font-2-mx-2 {
  margin-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-2,
.a11y-font-2 .a11y-font-2-my-2 {
  margin-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-2,
.a11y-font-2 .a11y-font-2-mx-2 {
  margin-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-3 {
  margin: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-3,
.a11y-font-2 .a11y-font-2-my-3 {
  margin-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-3,
.a11y-font-2 .a11y-font-2-mx-3 {
  margin-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-3,
.a11y-font-2 .a11y-font-2-my-3 {
  margin-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-3,
.a11y-font-2 .a11y-font-2-mx-3 {
  margin-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-4 {
  margin: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-4,
.a11y-font-2 .a11y-font-2-my-4 {
  margin-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-4,
.a11y-font-2 .a11y-font-2-mx-4 {
  margin-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-4,
.a11y-font-2 .a11y-font-2-my-4 {
  margin-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-4,
.a11y-font-2 .a11y-font-2-mx-4 {
  margin-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-5 {
  margin: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-5,
.a11y-font-2 .a11y-font-2-my-5 {
  margin-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-5,
.a11y-font-2 .a11y-font-2-mx-5 {
  margin-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-5,
.a11y-font-2 .a11y-font-2-my-5 {
  margin-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-5,
.a11y-font-2 .a11y-font-2-mx-5 {
  margin-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-p-0 {
  padding: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pt-0,
.a11y-font-2 .a11y-font-2-py-0 {
  padding-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pr-0,
.a11y-font-2 .a11y-font-2-px-0 {
  padding-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pb-0,
.a11y-font-2 .a11y-font-2-py-0 {
  padding-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pl-0,
.a11y-font-2 .a11y-font-2-px-0 {
  padding-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-p-1 {
  padding: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pt-1,
.a11y-font-2 .a11y-font-2-py-1 {
  padding-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pr-1,
.a11y-font-2 .a11y-font-2-px-1 {
  padding-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pb-1,
.a11y-font-2 .a11y-font-2-py-1 {
  padding-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pl-1,
.a11y-font-2 .a11y-font-2-px-1 {
  padding-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-p-2 {
  padding: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pt-2,
.a11y-font-2 .a11y-font-2-py-2 {
  padding-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pr-2,
.a11y-font-2 .a11y-font-2-px-2 {
  padding-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pb-2,
.a11y-font-2 .a11y-font-2-py-2 {
  padding-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pl-2,
.a11y-font-2 .a11y-font-2-px-2 {
  padding-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-p-3 {
  padding: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pt-3,
.a11y-font-2 .a11y-font-2-py-3 {
  padding-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pr-3,
.a11y-font-2 .a11y-font-2-px-3 {
  padding-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pb-3,
.a11y-font-2 .a11y-font-2-py-3 {
  padding-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pl-3,
.a11y-font-2 .a11y-font-2-px-3 {
  padding-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-p-4 {
  padding: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pt-4,
.a11y-font-2 .a11y-font-2-py-4 {
  padding-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pr-4,
.a11y-font-2 .a11y-font-2-px-4 {
  padding-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pb-4,
.a11y-font-2 .a11y-font-2-py-4 {
  padding-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pl-4,
.a11y-font-2 .a11y-font-2-px-4 {
  padding-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-p-5 {
  padding: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pt-5,
.a11y-font-2 .a11y-font-2-py-5 {
  padding-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pr-5,
.a11y-font-2 .a11y-font-2-px-5 {
  padding-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pb-5,
.a11y-font-2 .a11y-font-2-py-5 {
  padding-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-pl-5,
.a11y-font-2 .a11y-font-2-px-5 {
  padding-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-m-auto {
  margin: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mt-auto,
.a11y-font-2 .a11y-font-2-my-auto {
  margin-top: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mr-auto,
.a11y-font-2 .a11y-font-2-mx-auto {
  margin-right: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-mb-auto,
.a11y-font-2 .a11y-font-2-my-auto {
  margin-bottom: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-2 .a11y-font-2-ml-auto,
.a11y-font-2 .a11y-font-2-mx-auto {
  margin-left: auto !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-2 .a11y-font-2-col-sm-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-0,
  .a11y-font-2 .a11y-font-2-my-sm-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-0,
  .a11y-font-2 .a11y-font-2-mx-sm-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-0,
  .a11y-font-2 .a11y-font-2-my-sm-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-0,
  .a11y-font-2 .a11y-font-2-mx-sm-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-1,
  .a11y-font-2 .a11y-font-2-my-sm-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-1,
  .a11y-font-2 .a11y-font-2-mx-sm-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-1,
  .a11y-font-2 .a11y-font-2-my-sm-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-1,
  .a11y-font-2 .a11y-font-2-mx-sm-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-2,
  .a11y-font-2 .a11y-font-2-my-sm-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-2,
  .a11y-font-2 .a11y-font-2-mx-sm-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-2,
  .a11y-font-2 .a11y-font-2-my-sm-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-2,
  .a11y-font-2 .a11y-font-2-mx-sm-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-3,
  .a11y-font-2 .a11y-font-2-my-sm-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-3,
  .a11y-font-2 .a11y-font-2-mx-sm-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-3,
  .a11y-font-2 .a11y-font-2-my-sm-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-3,
  .a11y-font-2 .a11y-font-2-mx-sm-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-4,
  .a11y-font-2 .a11y-font-2-my-sm-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-4,
  .a11y-font-2 .a11y-font-2-mx-sm-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-4,
  .a11y-font-2 .a11y-font-2-my-sm-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-4,
  .a11y-font-2 .a11y-font-2-mx-sm-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-5,
  .a11y-font-2 .a11y-font-2-my-sm-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-5,
  .a11y-font-2 .a11y-font-2-mx-sm-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-5,
  .a11y-font-2 .a11y-font-2-my-sm-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-5,
  .a11y-font-2 .a11y-font-2-mx-sm-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-sm-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-sm-0,
  .a11y-font-2 .a11y-font-2-py-sm-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-sm-0,
  .a11y-font-2 .a11y-font-2-px-sm-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-sm-0,
  .a11y-font-2 .a11y-font-2-py-sm-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-sm-0,
  .a11y-font-2 .a11y-font-2-px-sm-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-sm-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-sm-1,
  .a11y-font-2 .a11y-font-2-py-sm-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-sm-1,
  .a11y-font-2 .a11y-font-2-px-sm-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-sm-1,
  .a11y-font-2 .a11y-font-2-py-sm-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-sm-1,
  .a11y-font-2 .a11y-font-2-px-sm-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-sm-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-sm-2,
  .a11y-font-2 .a11y-font-2-py-sm-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-sm-2,
  .a11y-font-2 .a11y-font-2-px-sm-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-sm-2,
  .a11y-font-2 .a11y-font-2-py-sm-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-sm-2,
  .a11y-font-2 .a11y-font-2-px-sm-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-sm-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-sm-3,
  .a11y-font-2 .a11y-font-2-py-sm-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-sm-3,
  .a11y-font-2 .a11y-font-2-px-sm-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-sm-3,
  .a11y-font-2 .a11y-font-2-py-sm-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-sm-3,
  .a11y-font-2 .a11y-font-2-px-sm-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-sm-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-sm-4,
  .a11y-font-2 .a11y-font-2-py-sm-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-sm-4,
  .a11y-font-2 .a11y-font-2-px-sm-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-sm-4,
  .a11y-font-2 .a11y-font-2-py-sm-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-sm-4,
  .a11y-font-2 .a11y-font-2-px-sm-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-sm-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-sm-5,
  .a11y-font-2 .a11y-font-2-py-sm-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-sm-5,
  .a11y-font-2 .a11y-font-2-px-sm-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-sm-5,
  .a11y-font-2 .a11y-font-2-py-sm-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-sm-5,
  .a11y-font-2 .a11y-font-2-px-sm-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-sm-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-sm-auto,
  .a11y-font-2 .a11y-font-2-my-sm-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-sm-auto,
  .a11y-font-2 .a11y-font-2-mx-sm-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-sm-auto,
  .a11y-font-2 .a11y-font-2-my-sm-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-sm-auto,
  .a11y-font-2 .a11y-font-2-mx-sm-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-2 .a11y-font-2-col-md-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-0,
  .a11y-font-2 .a11y-font-2-my-md-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-0,
  .a11y-font-2 .a11y-font-2-mx-md-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-0,
  .a11y-font-2 .a11y-font-2-my-md-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-0,
  .a11y-font-2 .a11y-font-2-mx-md-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-1,
  .a11y-font-2 .a11y-font-2-my-md-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-1,
  .a11y-font-2 .a11y-font-2-mx-md-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-1,
  .a11y-font-2 .a11y-font-2-my-md-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-1,
  .a11y-font-2 .a11y-font-2-mx-md-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-2,
  .a11y-font-2 .a11y-font-2-my-md-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-2,
  .a11y-font-2 .a11y-font-2-mx-md-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-2,
  .a11y-font-2 .a11y-font-2-my-md-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-2,
  .a11y-font-2 .a11y-font-2-mx-md-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-3,
  .a11y-font-2 .a11y-font-2-my-md-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-3,
  .a11y-font-2 .a11y-font-2-mx-md-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-3,
  .a11y-font-2 .a11y-font-2-my-md-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-3,
  .a11y-font-2 .a11y-font-2-mx-md-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-4,
  .a11y-font-2 .a11y-font-2-my-md-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-4,
  .a11y-font-2 .a11y-font-2-mx-md-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-4,
  .a11y-font-2 .a11y-font-2-my-md-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-4,
  .a11y-font-2 .a11y-font-2-mx-md-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-5,
  .a11y-font-2 .a11y-font-2-my-md-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-5,
  .a11y-font-2 .a11y-font-2-mx-md-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-5,
  .a11y-font-2 .a11y-font-2-my-md-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-5,
  .a11y-font-2 .a11y-font-2-mx-md-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-md-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-md-0,
  .a11y-font-2 .a11y-font-2-py-md-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-md-0,
  .a11y-font-2 .a11y-font-2-px-md-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-md-0,
  .a11y-font-2 .a11y-font-2-py-md-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-md-0,
  .a11y-font-2 .a11y-font-2-px-md-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-md-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-md-1,
  .a11y-font-2 .a11y-font-2-py-md-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-md-1,
  .a11y-font-2 .a11y-font-2-px-md-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-md-1,
  .a11y-font-2 .a11y-font-2-py-md-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-md-1,
  .a11y-font-2 .a11y-font-2-px-md-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-md-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-md-2,
  .a11y-font-2 .a11y-font-2-py-md-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-md-2,
  .a11y-font-2 .a11y-font-2-px-md-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-md-2,
  .a11y-font-2 .a11y-font-2-py-md-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-md-2,
  .a11y-font-2 .a11y-font-2-px-md-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-md-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-md-3,
  .a11y-font-2 .a11y-font-2-py-md-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-md-3,
  .a11y-font-2 .a11y-font-2-px-md-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-md-3,
  .a11y-font-2 .a11y-font-2-py-md-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-md-3,
  .a11y-font-2 .a11y-font-2-px-md-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-md-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-md-4,
  .a11y-font-2 .a11y-font-2-py-md-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-md-4,
  .a11y-font-2 .a11y-font-2-px-md-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-md-4,
  .a11y-font-2 .a11y-font-2-py-md-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-md-4,
  .a11y-font-2 .a11y-font-2-px-md-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-md-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-md-5,
  .a11y-font-2 .a11y-font-2-py-md-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-md-5,
  .a11y-font-2 .a11y-font-2-px-md-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-md-5,
  .a11y-font-2 .a11y-font-2-py-md-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-md-5,
  .a11y-font-2 .a11y-font-2-px-md-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-md-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-md-auto,
  .a11y-font-2 .a11y-font-2-my-md-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-md-auto,
  .a11y-font-2 .a11y-font-2-mx-md-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-md-auto,
  .a11y-font-2 .a11y-font-2-my-md-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-md-auto,
  .a11y-font-2 .a11y-font-2-mx-md-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-2 .a11y-font-2-col-lg-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-0,
  .a11y-font-2 .a11y-font-2-my-lg-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-0,
  .a11y-font-2 .a11y-font-2-mx-lg-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-0,
  .a11y-font-2 .a11y-font-2-my-lg-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-0,
  .a11y-font-2 .a11y-font-2-mx-lg-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-1,
  .a11y-font-2 .a11y-font-2-my-lg-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-1,
  .a11y-font-2 .a11y-font-2-mx-lg-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-1,
  .a11y-font-2 .a11y-font-2-my-lg-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-1,
  .a11y-font-2 .a11y-font-2-mx-lg-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-2,
  .a11y-font-2 .a11y-font-2-my-lg-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-2,
  .a11y-font-2 .a11y-font-2-mx-lg-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-2,
  .a11y-font-2 .a11y-font-2-my-lg-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-2,
  .a11y-font-2 .a11y-font-2-mx-lg-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-3,
  .a11y-font-2 .a11y-font-2-my-lg-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-3,
  .a11y-font-2 .a11y-font-2-mx-lg-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-3,
  .a11y-font-2 .a11y-font-2-my-lg-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-3,
  .a11y-font-2 .a11y-font-2-mx-lg-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-4,
  .a11y-font-2 .a11y-font-2-my-lg-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-4,
  .a11y-font-2 .a11y-font-2-mx-lg-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-4,
  .a11y-font-2 .a11y-font-2-my-lg-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-4,
  .a11y-font-2 .a11y-font-2-mx-lg-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-5,
  .a11y-font-2 .a11y-font-2-my-lg-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-5,
  .a11y-font-2 .a11y-font-2-mx-lg-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-5,
  .a11y-font-2 .a11y-font-2-my-lg-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-5,
  .a11y-font-2 .a11y-font-2-mx-lg-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-lg-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-lg-0,
  .a11y-font-2 .a11y-font-2-py-lg-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-lg-0,
  .a11y-font-2 .a11y-font-2-px-lg-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-lg-0,
  .a11y-font-2 .a11y-font-2-py-lg-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-lg-0,
  .a11y-font-2 .a11y-font-2-px-lg-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-lg-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-lg-1,
  .a11y-font-2 .a11y-font-2-py-lg-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-lg-1,
  .a11y-font-2 .a11y-font-2-px-lg-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-lg-1,
  .a11y-font-2 .a11y-font-2-py-lg-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-lg-1,
  .a11y-font-2 .a11y-font-2-px-lg-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-lg-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-lg-2,
  .a11y-font-2 .a11y-font-2-py-lg-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-lg-2,
  .a11y-font-2 .a11y-font-2-px-lg-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-lg-2,
  .a11y-font-2 .a11y-font-2-py-lg-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-lg-2,
  .a11y-font-2 .a11y-font-2-px-lg-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-lg-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-lg-3,
  .a11y-font-2 .a11y-font-2-py-lg-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-lg-3,
  .a11y-font-2 .a11y-font-2-px-lg-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-lg-3,
  .a11y-font-2 .a11y-font-2-py-lg-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-lg-3,
  .a11y-font-2 .a11y-font-2-px-lg-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-lg-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-lg-4,
  .a11y-font-2 .a11y-font-2-py-lg-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-lg-4,
  .a11y-font-2 .a11y-font-2-px-lg-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-lg-4,
  .a11y-font-2 .a11y-font-2-py-lg-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-lg-4,
  .a11y-font-2 .a11y-font-2-px-lg-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-lg-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-lg-5,
  .a11y-font-2 .a11y-font-2-py-lg-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-lg-5,
  .a11y-font-2 .a11y-font-2-px-lg-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-lg-5,
  .a11y-font-2 .a11y-font-2-py-lg-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-lg-5,
  .a11y-font-2 .a11y-font-2-px-lg-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-lg-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-lg-auto,
  .a11y-font-2 .a11y-font-2-my-lg-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-lg-auto,
  .a11y-font-2 .a11y-font-2-mx-lg-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-lg-auto,
  .a11y-font-2 .a11y-font-2-my-lg-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-lg-auto,
  .a11y-font-2 .a11y-font-2-mx-lg-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-2 .a11y-font-2-col-xl-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-0,
  .a11y-font-2 .a11y-font-2-my-xl-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-0,
  .a11y-font-2 .a11y-font-2-mx-xl-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-0,
  .a11y-font-2 .a11y-font-2-my-xl-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-0,
  .a11y-font-2 .a11y-font-2-mx-xl-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-1,
  .a11y-font-2 .a11y-font-2-my-xl-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-1,
  .a11y-font-2 .a11y-font-2-mx-xl-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-1,
  .a11y-font-2 .a11y-font-2-my-xl-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-1,
  .a11y-font-2 .a11y-font-2-mx-xl-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-2,
  .a11y-font-2 .a11y-font-2-my-xl-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-2,
  .a11y-font-2 .a11y-font-2-mx-xl-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-2,
  .a11y-font-2 .a11y-font-2-my-xl-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-2,
  .a11y-font-2 .a11y-font-2-mx-xl-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-3,
  .a11y-font-2 .a11y-font-2-my-xl-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-3,
  .a11y-font-2 .a11y-font-2-mx-xl-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-3,
  .a11y-font-2 .a11y-font-2-my-xl-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-3,
  .a11y-font-2 .a11y-font-2-mx-xl-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-4,
  .a11y-font-2 .a11y-font-2-my-xl-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-4,
  .a11y-font-2 .a11y-font-2-mx-xl-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-4,
  .a11y-font-2 .a11y-font-2-my-xl-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-4,
  .a11y-font-2 .a11y-font-2-mx-xl-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-5,
  .a11y-font-2 .a11y-font-2-my-xl-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-5,
  .a11y-font-2 .a11y-font-2-mx-xl-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-5,
  .a11y-font-2 .a11y-font-2-my-xl-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-5,
  .a11y-font-2 .a11y-font-2-mx-xl-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-xl-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-xl-0,
  .a11y-font-2 .a11y-font-2-py-xl-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-xl-0,
  .a11y-font-2 .a11y-font-2-px-xl-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-xl-0,
  .a11y-font-2 .a11y-font-2-py-xl-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-xl-0,
  .a11y-font-2 .a11y-font-2-px-xl-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-xl-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-xl-1,
  .a11y-font-2 .a11y-font-2-py-xl-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-xl-1,
  .a11y-font-2 .a11y-font-2-px-xl-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-xl-1,
  .a11y-font-2 .a11y-font-2-py-xl-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-xl-1,
  .a11y-font-2 .a11y-font-2-px-xl-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-xl-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-xl-2,
  .a11y-font-2 .a11y-font-2-py-xl-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-xl-2,
  .a11y-font-2 .a11y-font-2-px-xl-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-xl-2,
  .a11y-font-2 .a11y-font-2-py-xl-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-xl-2,
  .a11y-font-2 .a11y-font-2-px-xl-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-xl-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-xl-3,
  .a11y-font-2 .a11y-font-2-py-xl-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-xl-3,
  .a11y-font-2 .a11y-font-2-px-xl-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-xl-3,
  .a11y-font-2 .a11y-font-2-py-xl-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-xl-3,
  .a11y-font-2 .a11y-font-2-px-xl-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-xl-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-xl-4,
  .a11y-font-2 .a11y-font-2-py-xl-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-xl-4,
  .a11y-font-2 .a11y-font-2-px-xl-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-xl-4,
  .a11y-font-2 .a11y-font-2-py-xl-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-xl-4,
  .a11y-font-2 .a11y-font-2-px-xl-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-p-xl-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pt-xl-5,
  .a11y-font-2 .a11y-font-2-py-xl-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pr-xl-5,
  .a11y-font-2 .a11y-font-2-px-xl-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pb-xl-5,
  .a11y-font-2 .a11y-font-2-py-xl-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-pl-xl-5,
  .a11y-font-2 .a11y-font-2-px-xl-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-m-xl-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mt-xl-auto,
  .a11y-font-2 .a11y-font-2-my-xl-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mr-xl-auto,
  .a11y-font-2 .a11y-font-2-mx-xl-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-mb-xl-auto,
  .a11y-font-2 .a11y-font-2-my-xl-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-2 .a11y-font-2-ml-xl-auto,
  .a11y-font-2 .a11y-font-2-mx-xl-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

.a11y-font-1 .a11y-fonts-col,
.a11y-font-2 .a11y-fonts-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.a11y-font-1 .a11y-fonts-col-auto,
.a11y-font-2 .a11y-fonts-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.a11y-font-1 .a11y-fonts-col-1,
.a11y-font-2 .a11y-fonts-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.a11y-font-1 .a11y-fonts-col-2,
.a11y-font-2 .a11y-fonts-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.a11y-font-1 .a11y-fonts-col-3,
.a11y-font-2 .a11y-fonts-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.a11y-font-1 .a11y-fonts-col-4,
.a11y-font-2 .a11y-fonts-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.a11y-font-1 .a11y-fonts-col-5,
.a11y-font-2 .a11y-fonts-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.a11y-font-1 .a11y-fonts-col-6,
.a11y-font-2 .a11y-fonts-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.a11y-font-1 .a11y-fonts-col-7,
.a11y-font-2 .a11y-fonts-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.a11y-font-1 .a11y-fonts-col-8,
.a11y-font-2 .a11y-fonts-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.a11y-font-1 .a11y-fonts-col-9,
.a11y-font-2 .a11y-fonts-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.a11y-font-1 .a11y-fonts-col-10,
.a11y-font-2 .a11y-fonts-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.a11y-font-1 .a11y-fonts-col-11,
.a11y-font-2 .a11y-fonts-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.a11y-font-1 .a11y-fonts-col-12,
.a11y-font-2 .a11y-fonts-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.a11y-font-1 .a11y-fonts-offset-1,
.a11y-font-2 .a11y-fonts-offset-1 {
  margin-left: 8.3333333333%; }

.a11y-font-1 .a11y-fonts-offset-2,
.a11y-font-2 .a11y-fonts-offset-2 {
  margin-left: 16.6666666667%; }

.a11y-font-1 .a11y-fonts-offset-3,
.a11y-font-2 .a11y-fonts-offset-3 {
  margin-left: 25%; }

.a11y-font-1 .a11y-fonts-offset-4,
.a11y-font-2 .a11y-fonts-offset-4 {
  margin-left: 33.3333333333%; }

.a11y-font-1 .a11y-fonts-offset-5,
.a11y-font-2 .a11y-fonts-offset-5 {
  margin-left: 41.6666666667%; }

.a11y-font-1 .a11y-fonts-offset-6,
.a11y-font-2 .a11y-fonts-offset-6 {
  margin-left: 50%; }

.a11y-font-1 .a11y-fonts-offset-7,
.a11y-font-2 .a11y-fonts-offset-7 {
  margin-left: 58.3333333333%; }

.a11y-font-1 .a11y-fonts-offset-8,
.a11y-font-2 .a11y-fonts-offset-8 {
  margin-left: 66.6666666667%; }

.a11y-font-1 .a11y-fonts-offset-9,
.a11y-font-2 .a11y-fonts-offset-9 {
  margin-left: 75%; }

.a11y-font-1 .a11y-fonts-offset-10,
.a11y-font-2 .a11y-fonts-offset-10 {
  margin-left: 83.3333333333%; }

.a11y-font-1 .a11y-fonts-offset-11,
.a11y-font-2 .a11y-fonts-offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .a11y-font-1 .a11y-fonts-col-sm,
  .a11y-font-2 .a11y-fonts-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-col-sm-auto,
  .a11y-font-2 .a11y-fonts-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-fonts-col-sm-1,
  .a11y-font-2 .a11y-fonts-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-sm-2,
  .a11y-font-2 .a11y-fonts-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-sm-3,
  .a11y-font-2 .a11y-fonts-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-fonts-col-sm-4,
  .a11y-font-2 .a11y-fonts-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-sm-5,
  .a11y-font-2 .a11y-fonts-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-sm-6,
  .a11y-font-2 .a11y-fonts-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-fonts-col-sm-7,
  .a11y-font-2 .a11y-fonts-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-sm-8,
  .a11y-font-2 .a11y-fonts-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-sm-9,
  .a11y-font-2 .a11y-fonts-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-fonts-col-sm-10,
  .a11y-font-2 .a11y-fonts-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-sm-11,
  .a11y-font-2 .a11y-fonts-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-sm-12,
  .a11y-font-2 .a11y-fonts-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-offset-sm-0,
  .a11y-font-2 .a11y-fonts-offset-sm-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-fonts-offset-sm-1,
  .a11y-font-2 .a11y-fonts-offset-sm-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-sm-2,
  .a11y-font-2 .a11y-fonts-offset-sm-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-sm-3,
  .a11y-font-2 .a11y-fonts-offset-sm-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-fonts-offset-sm-4,
  .a11y-font-2 .a11y-fonts-offset-sm-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-sm-5,
  .a11y-font-2 .a11y-fonts-offset-sm-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-sm-6,
  .a11y-font-2 .a11y-fonts-offset-sm-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-fonts-offset-sm-7,
  .a11y-font-2 .a11y-fonts-offset-sm-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-sm-8,
  .a11y-font-2 .a11y-fonts-offset-sm-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-sm-9,
  .a11y-font-2 .a11y-fonts-offset-sm-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-fonts-offset-sm-10,
  .a11y-font-2 .a11y-fonts-offset-sm-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-sm-11,
  .a11y-font-2 .a11y-fonts-offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .a11y-font-1 .a11y-fonts-col-md,
  .a11y-font-2 .a11y-fonts-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-col-md-auto,
  .a11y-font-2 .a11y-fonts-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-fonts-col-md-1,
  .a11y-font-2 .a11y-fonts-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-md-2,
  .a11y-font-2 .a11y-fonts-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-md-3,
  .a11y-font-2 .a11y-fonts-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-fonts-col-md-4,
  .a11y-font-2 .a11y-fonts-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-md-5,
  .a11y-font-2 .a11y-fonts-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-md-6,
  .a11y-font-2 .a11y-fonts-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-fonts-col-md-7,
  .a11y-font-2 .a11y-fonts-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-md-8,
  .a11y-font-2 .a11y-fonts-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-md-9,
  .a11y-font-2 .a11y-fonts-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-fonts-col-md-10,
  .a11y-font-2 .a11y-fonts-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-md-11,
  .a11y-font-2 .a11y-fonts-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-md-12,
  .a11y-font-2 .a11y-fonts-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-offset-md-0,
  .a11y-font-2 .a11y-fonts-offset-md-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-fonts-offset-md-1,
  .a11y-font-2 .a11y-fonts-offset-md-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-md-2,
  .a11y-font-2 .a11y-fonts-offset-md-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-md-3,
  .a11y-font-2 .a11y-fonts-offset-md-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-fonts-offset-md-4,
  .a11y-font-2 .a11y-fonts-offset-md-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-md-5,
  .a11y-font-2 .a11y-fonts-offset-md-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-md-6,
  .a11y-font-2 .a11y-fonts-offset-md-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-fonts-offset-md-7,
  .a11y-font-2 .a11y-fonts-offset-md-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-md-8,
  .a11y-font-2 .a11y-fonts-offset-md-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-md-9,
  .a11y-font-2 .a11y-fonts-offset-md-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-fonts-offset-md-10,
  .a11y-font-2 .a11y-fonts-offset-md-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-md-11,
  .a11y-font-2 .a11y-fonts-offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .a11y-font-1 .a11y-fonts-col-lg,
  .a11y-font-2 .a11y-fonts-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-col-lg-auto,
  .a11y-font-2 .a11y-fonts-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-fonts-col-lg-1,
  .a11y-font-2 .a11y-fonts-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-lg-2,
  .a11y-font-2 .a11y-fonts-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-lg-3,
  .a11y-font-2 .a11y-fonts-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-fonts-col-lg-4,
  .a11y-font-2 .a11y-fonts-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-lg-5,
  .a11y-font-2 .a11y-fonts-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-lg-6,
  .a11y-font-2 .a11y-fonts-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-fonts-col-lg-7,
  .a11y-font-2 .a11y-fonts-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-lg-8,
  .a11y-font-2 .a11y-fonts-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-lg-9,
  .a11y-font-2 .a11y-fonts-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-fonts-col-lg-10,
  .a11y-font-2 .a11y-fonts-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-lg-11,
  .a11y-font-2 .a11y-fonts-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-lg-12,
  .a11y-font-2 .a11y-fonts-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-offset-lg-0,
  .a11y-font-2 .a11y-fonts-offset-lg-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-fonts-offset-lg-1,
  .a11y-font-2 .a11y-fonts-offset-lg-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-lg-2,
  .a11y-font-2 .a11y-fonts-offset-lg-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-lg-3,
  .a11y-font-2 .a11y-fonts-offset-lg-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-fonts-offset-lg-4,
  .a11y-font-2 .a11y-fonts-offset-lg-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-lg-5,
  .a11y-font-2 .a11y-fonts-offset-lg-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-lg-6,
  .a11y-font-2 .a11y-fonts-offset-lg-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-fonts-offset-lg-7,
  .a11y-font-2 .a11y-fonts-offset-lg-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-lg-8,
  .a11y-font-2 .a11y-fonts-offset-lg-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-lg-9,
  .a11y-font-2 .a11y-fonts-offset-lg-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-fonts-offset-lg-10,
  .a11y-font-2 .a11y-fonts-offset-lg-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-lg-11,
  .a11y-font-2 .a11y-fonts-offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .a11y-font-1 .a11y-fonts-col-xl,
  .a11y-font-2 .a11y-fonts-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-col-xl-auto,
  .a11y-font-2 .a11y-fonts-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .a11y-font-1 .a11y-fonts-col-xl-1,
  .a11y-font-2 .a11y-fonts-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-xl-2,
  .a11y-font-2 .a11y-fonts-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-xl-3,
  .a11y-font-2 .a11y-fonts-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .a11y-font-1 .a11y-fonts-col-xl-4,
  .a11y-font-2 .a11y-fonts-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-xl-5,
  .a11y-font-2 .a11y-fonts-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-xl-6,
  .a11y-font-2 .a11y-fonts-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .a11y-font-1 .a11y-fonts-col-xl-7,
  .a11y-font-2 .a11y-fonts-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-xl-8,
  .a11y-font-2 .a11y-fonts-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-xl-9,
  .a11y-font-2 .a11y-fonts-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .a11y-font-1 .a11y-fonts-col-xl-10,
  .a11y-font-2 .a11y-fonts-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-col-xl-11,
  .a11y-font-2 .a11y-fonts-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .a11y-font-1 .a11y-fonts-col-xl-12,
  .a11y-font-2 .a11y-fonts-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .a11y-font-1 .a11y-fonts-offset-xl-0,
  .a11y-font-2 .a11y-fonts-offset-xl-0 {
    margin-left: 0; }
  .a11y-font-1 .a11y-fonts-offset-xl-1,
  .a11y-font-2 .a11y-fonts-offset-xl-1 {
    margin-left: 8.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-xl-2,
  .a11y-font-2 .a11y-fonts-offset-xl-2 {
    margin-left: 16.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-xl-3,
  .a11y-font-2 .a11y-fonts-offset-xl-3 {
    margin-left: 25%; }
  .a11y-font-1 .a11y-fonts-offset-xl-4,
  .a11y-font-2 .a11y-fonts-offset-xl-4 {
    margin-left: 33.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-xl-5,
  .a11y-font-2 .a11y-fonts-offset-xl-5 {
    margin-left: 41.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-xl-6,
  .a11y-font-2 .a11y-fonts-offset-xl-6 {
    margin-left: 50%; }
  .a11y-font-1 .a11y-fonts-offset-xl-7,
  .a11y-font-2 .a11y-fonts-offset-xl-7 {
    margin-left: 58.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-xl-8,
  .a11y-font-2 .a11y-fonts-offset-xl-8 {
    margin-left: 66.6666666667%; }
  .a11y-font-1 .a11y-fonts-offset-xl-9,
  .a11y-font-2 .a11y-fonts-offset-xl-9 {
    margin-left: 75%; }
  .a11y-font-1 .a11y-fonts-offset-xl-10,
  .a11y-font-2 .a11y-fonts-offset-xl-10 {
    margin-left: 83.3333333333%; }
  .a11y-font-1 .a11y-fonts-offset-xl-11,
  .a11y-font-2 .a11y-fonts-offset-xl-11 {
    margin-left: 91.6666666667%; } }

.a11y-font-1 .a11y-fonts-d-none,
.a11y-font-2 .a11y-fonts-d-none {
  display: none !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-inline,
.a11y-font-2 .a11y-fonts-d-inline {
  display: inline !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-inline-block,
.a11y-font-2 .a11y-fonts-d-inline-block {
  display: inline-block !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-block,
.a11y-font-2 .a11y-fonts-d-block {
  display: block !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-table,
.a11y-font-2 .a11y-fonts-d-table {
  display: table !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-table-row,
.a11y-font-2 .a11y-fonts-d-table-row {
  display: table-row !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-table-cell,
.a11y-font-2 .a11y-fonts-d-table-cell {
  display: table-cell !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-flex,
.a11y-font-2 .a11y-fonts-d-flex {
  display: flex !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-d-inline-flex,
.a11y-font-2 .a11y-fonts-d-inline-flex {
  display: inline-flex !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-monospace,
.a11y-font-2 .a11y-fonts-text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.a11y-font-1 .a11y-fonts-text-justify,
.a11y-font-2 .a11y-fonts-text-justify {
  text-align: justify !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-nowrap,
.a11y-font-2 .a11y-fonts-text-nowrap {
  white-space: nowrap !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-truncate,
.a11y-font-2 .a11y-fonts-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-left,
.a11y-font-2 .a11y-fonts-text-left {
  text-align: left !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-right,
.a11y-font-2 .a11y-fonts-text-right {
  text-align: right !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-center,
.a11y-font-2 .a11y-fonts-text-center {
  text-align: center !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-1 .a11y-fonts-text-sm-left,
  .a11y-font-2 .a11y-fonts-text-sm-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-sm-right,
  .a11y-font-2 .a11y-fonts-text-sm-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-sm-center,
  .a11y-font-2 .a11y-fonts-text-sm-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-1 .a11y-fonts-text-md-left,
  .a11y-font-2 .a11y-fonts-text-md-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-md-right,
  .a11y-font-2 .a11y-fonts-text-md-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-md-center,
  .a11y-font-2 .a11y-fonts-text-md-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-1 .a11y-fonts-text-lg-left,
  .a11y-font-2 .a11y-fonts-text-lg-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-lg-right,
  .a11y-font-2 .a11y-fonts-text-lg-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-lg-center,
  .a11y-font-2 .a11y-fonts-text-lg-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-1 .a11y-fonts-text-xl-left,
  .a11y-font-2 .a11y-fonts-text-xl-left {
    text-align: left !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-xl-right,
  .a11y-font-2 .a11y-fonts-text-xl-right {
    text-align: right !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-text-xl-center,
  .a11y-font-2 .a11y-fonts-text-xl-center {
    text-align: center !important;
    /* stylelint-disable-line */ } }

.a11y-font-1 .a11y-fonts-text-lowercase,
.a11y-font-2 .a11y-fonts-text-lowercase {
  text-transform: lowercase !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-uppercase,
.a11y-font-2 .a11y-fonts-text-uppercase {
  text-transform: uppercase !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-capitalize,
.a11y-font-2 .a11y-fonts-text-capitalize {
  text-transform: capitalize !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-font-weight-light,
.a11y-font-2 .a11y-fonts-font-weight-light {
  font-weight: 300 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-font-weight-normal,
.a11y-font-2 .a11y-fonts-font-weight-normal {
  font-weight: 400 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-font-weight-bold,
.a11y-font-2 .a11y-fonts-font-weight-bold {
  font-weight: 700 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-font-italic,
.a11y-font-2 .a11y-fonts-font-italic {
  font-style: italic !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-white,
.a11y-font-2 .a11y-fonts-text-white {
  color: #fff !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-body,
.a11y-font-2 .a11y-fonts-text-body {
  color: #212529 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-muted,
.a11y-font-2 .a11y-fonts-text-muted {
  color: #6c757d !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-black-50,
.a11y-font-2 .a11y-fonts-text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-text-white-50,
.a11y-font-2 .a11y-fonts-text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-col-padding,
.a11y-font-2 .a11y-fonts-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-col-padding,
.a11y-font-2 .a11y-fonts-col-padding {
  padding-right: 15px !important;
  /* stylelint-disable-line */
  padding-left: 15px !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-0,
.a11y-font-2 .a11y-fonts-m-0 {
  margin: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-0,
.a11y-font-1 .a11y-fonts-my-0,
.a11y-font-2 .a11y-fonts-mt-0,
.a11y-font-2 .a11y-fonts-my-0 {
  margin-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-0,
.a11y-font-1 .a11y-fonts-mx-0,
.a11y-font-2 .a11y-fonts-mr-0,
.a11y-font-2 .a11y-fonts-mx-0 {
  margin-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-0,
.a11y-font-1 .a11y-fonts-my-0,
.a11y-font-2 .a11y-fonts-mb-0,
.a11y-font-2 .a11y-fonts-my-0 {
  margin-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-0,
.a11y-font-1 .a11y-fonts-mx-0,
.a11y-font-2 .a11y-fonts-ml-0,
.a11y-font-2 .a11y-fonts-mx-0 {
  margin-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-1,
.a11y-font-2 .a11y-fonts-m-1 {
  margin: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-1,
.a11y-font-1 .a11y-fonts-my-1,
.a11y-font-2 .a11y-fonts-mt-1,
.a11y-font-2 .a11y-fonts-my-1 {
  margin-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-1,
.a11y-font-1 .a11y-fonts-mx-1,
.a11y-font-2 .a11y-fonts-mr-1,
.a11y-font-2 .a11y-fonts-mx-1 {
  margin-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-1,
.a11y-font-1 .a11y-fonts-my-1,
.a11y-font-2 .a11y-fonts-mb-1,
.a11y-font-2 .a11y-fonts-my-1 {
  margin-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-1,
.a11y-font-1 .a11y-fonts-mx-1,
.a11y-font-2 .a11y-fonts-ml-1,
.a11y-font-2 .a11y-fonts-mx-1 {
  margin-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-2,
.a11y-font-2 .a11y-fonts-m-2 {
  margin: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-2,
.a11y-font-1 .a11y-fonts-my-2,
.a11y-font-2 .a11y-fonts-mt-2,
.a11y-font-2 .a11y-fonts-my-2 {
  margin-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-2,
.a11y-font-1 .a11y-fonts-mx-2,
.a11y-font-2 .a11y-fonts-mr-2,
.a11y-font-2 .a11y-fonts-mx-2 {
  margin-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-2,
.a11y-font-1 .a11y-fonts-my-2,
.a11y-font-2 .a11y-fonts-mb-2,
.a11y-font-2 .a11y-fonts-my-2 {
  margin-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-2,
.a11y-font-1 .a11y-fonts-mx-2,
.a11y-font-2 .a11y-fonts-ml-2,
.a11y-font-2 .a11y-fonts-mx-2 {
  margin-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-3,
.a11y-font-2 .a11y-fonts-m-3 {
  margin: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-3,
.a11y-font-1 .a11y-fonts-my-3,
.a11y-font-2 .a11y-fonts-mt-3,
.a11y-font-2 .a11y-fonts-my-3 {
  margin-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-3,
.a11y-font-1 .a11y-fonts-mx-3,
.a11y-font-2 .a11y-fonts-mr-3,
.a11y-font-2 .a11y-fonts-mx-3 {
  margin-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-3,
.a11y-font-1 .a11y-fonts-my-3,
.a11y-font-2 .a11y-fonts-mb-3,
.a11y-font-2 .a11y-fonts-my-3 {
  margin-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-3,
.a11y-font-1 .a11y-fonts-mx-3,
.a11y-font-2 .a11y-fonts-ml-3,
.a11y-font-2 .a11y-fonts-mx-3 {
  margin-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-4,
.a11y-font-2 .a11y-fonts-m-4 {
  margin: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-4,
.a11y-font-1 .a11y-fonts-my-4,
.a11y-font-2 .a11y-fonts-mt-4,
.a11y-font-2 .a11y-fonts-my-4 {
  margin-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-4,
.a11y-font-1 .a11y-fonts-mx-4,
.a11y-font-2 .a11y-fonts-mr-4,
.a11y-font-2 .a11y-fonts-mx-4 {
  margin-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-4,
.a11y-font-1 .a11y-fonts-my-4,
.a11y-font-2 .a11y-fonts-mb-4,
.a11y-font-2 .a11y-fonts-my-4 {
  margin-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-4,
.a11y-font-1 .a11y-fonts-mx-4,
.a11y-font-2 .a11y-fonts-ml-4,
.a11y-font-2 .a11y-fonts-mx-4 {
  margin-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-5,
.a11y-font-2 .a11y-fonts-m-5 {
  margin: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-5,
.a11y-font-1 .a11y-fonts-my-5,
.a11y-font-2 .a11y-fonts-mt-5,
.a11y-font-2 .a11y-fonts-my-5 {
  margin-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-5,
.a11y-font-1 .a11y-fonts-mx-5,
.a11y-font-2 .a11y-fonts-mr-5,
.a11y-font-2 .a11y-fonts-mx-5 {
  margin-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-5,
.a11y-font-1 .a11y-fonts-my-5,
.a11y-font-2 .a11y-fonts-mb-5,
.a11y-font-2 .a11y-fonts-my-5 {
  margin-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-5,
.a11y-font-1 .a11y-fonts-mx-5,
.a11y-font-2 .a11y-fonts-ml-5,
.a11y-font-2 .a11y-fonts-mx-5 {
  margin-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-p-0,
.a11y-font-2 .a11y-fonts-p-0 {
  padding: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pt-0,
.a11y-font-1 .a11y-fonts-py-0,
.a11y-font-2 .a11y-fonts-pt-0,
.a11y-font-2 .a11y-fonts-py-0 {
  padding-top: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pr-0,
.a11y-font-1 .a11y-fonts-px-0,
.a11y-font-2 .a11y-fonts-pr-0,
.a11y-font-2 .a11y-fonts-px-0 {
  padding-right: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pb-0,
.a11y-font-1 .a11y-fonts-py-0,
.a11y-font-2 .a11y-fonts-pb-0,
.a11y-font-2 .a11y-fonts-py-0 {
  padding-bottom: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pl-0,
.a11y-font-1 .a11y-fonts-px-0,
.a11y-font-2 .a11y-fonts-pl-0,
.a11y-font-2 .a11y-fonts-px-0 {
  padding-left: 0 !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-p-1,
.a11y-font-2 .a11y-fonts-p-1 {
  padding: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pt-1,
.a11y-font-1 .a11y-fonts-py-1,
.a11y-font-2 .a11y-fonts-pt-1,
.a11y-font-2 .a11y-fonts-py-1 {
  padding-top: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pr-1,
.a11y-font-1 .a11y-fonts-px-1,
.a11y-font-2 .a11y-fonts-pr-1,
.a11y-font-2 .a11y-fonts-px-1 {
  padding-right: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pb-1,
.a11y-font-1 .a11y-fonts-py-1,
.a11y-font-2 .a11y-fonts-pb-1,
.a11y-font-2 .a11y-fonts-py-1 {
  padding-bottom: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pl-1,
.a11y-font-1 .a11y-fonts-px-1,
.a11y-font-2 .a11y-fonts-pl-1,
.a11y-font-2 .a11y-fonts-px-1 {
  padding-left: 0.25rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-p-2,
.a11y-font-2 .a11y-fonts-p-2 {
  padding: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pt-2,
.a11y-font-1 .a11y-fonts-py-2,
.a11y-font-2 .a11y-fonts-pt-2,
.a11y-font-2 .a11y-fonts-py-2 {
  padding-top: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pr-2,
.a11y-font-1 .a11y-fonts-px-2,
.a11y-font-2 .a11y-fonts-pr-2,
.a11y-font-2 .a11y-fonts-px-2 {
  padding-right: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pb-2,
.a11y-font-1 .a11y-fonts-py-2,
.a11y-font-2 .a11y-fonts-pb-2,
.a11y-font-2 .a11y-fonts-py-2 {
  padding-bottom: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pl-2,
.a11y-font-1 .a11y-fonts-px-2,
.a11y-font-2 .a11y-fonts-pl-2,
.a11y-font-2 .a11y-fonts-px-2 {
  padding-left: 0.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-p-3,
.a11y-font-2 .a11y-fonts-p-3 {
  padding: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pt-3,
.a11y-font-1 .a11y-fonts-py-3,
.a11y-font-2 .a11y-fonts-pt-3,
.a11y-font-2 .a11y-fonts-py-3 {
  padding-top: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pr-3,
.a11y-font-1 .a11y-fonts-px-3,
.a11y-font-2 .a11y-fonts-pr-3,
.a11y-font-2 .a11y-fonts-px-3 {
  padding-right: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pb-3,
.a11y-font-1 .a11y-fonts-py-3,
.a11y-font-2 .a11y-fonts-pb-3,
.a11y-font-2 .a11y-fonts-py-3 {
  padding-bottom: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pl-3,
.a11y-font-1 .a11y-fonts-px-3,
.a11y-font-2 .a11y-fonts-pl-3,
.a11y-font-2 .a11y-fonts-px-3 {
  padding-left: 1rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-p-4,
.a11y-font-2 .a11y-fonts-p-4 {
  padding: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pt-4,
.a11y-font-1 .a11y-fonts-py-4,
.a11y-font-2 .a11y-fonts-pt-4,
.a11y-font-2 .a11y-fonts-py-4 {
  padding-top: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pr-4,
.a11y-font-1 .a11y-fonts-px-4,
.a11y-font-2 .a11y-fonts-pr-4,
.a11y-font-2 .a11y-fonts-px-4 {
  padding-right: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pb-4,
.a11y-font-1 .a11y-fonts-py-4,
.a11y-font-2 .a11y-fonts-pb-4,
.a11y-font-2 .a11y-fonts-py-4 {
  padding-bottom: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pl-4,
.a11y-font-1 .a11y-fonts-px-4,
.a11y-font-2 .a11y-fonts-pl-4,
.a11y-font-2 .a11y-fonts-px-4 {
  padding-left: 1.5rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-p-5,
.a11y-font-2 .a11y-fonts-p-5 {
  padding: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pt-5,
.a11y-font-1 .a11y-fonts-py-5,
.a11y-font-2 .a11y-fonts-pt-5,
.a11y-font-2 .a11y-fonts-py-5 {
  padding-top: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pr-5,
.a11y-font-1 .a11y-fonts-px-5,
.a11y-font-2 .a11y-fonts-pr-5,
.a11y-font-2 .a11y-fonts-px-5 {
  padding-right: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pb-5,
.a11y-font-1 .a11y-fonts-py-5,
.a11y-font-2 .a11y-fonts-pb-5,
.a11y-font-2 .a11y-fonts-py-5 {
  padding-bottom: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-pl-5,
.a11y-font-1 .a11y-fonts-px-5,
.a11y-font-2 .a11y-fonts-pl-5,
.a11y-font-2 .a11y-fonts-px-5 {
  padding-left: 3rem !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-m-auto,
.a11y-font-2 .a11y-fonts-m-auto {
  margin: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mt-auto,
.a11y-font-1 .a11y-fonts-my-auto,
.a11y-font-2 .a11y-fonts-mt-auto,
.a11y-font-2 .a11y-fonts-my-auto {
  margin-top: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mr-auto,
.a11y-font-1 .a11y-fonts-mx-auto,
.a11y-font-2 .a11y-fonts-mr-auto,
.a11y-font-2 .a11y-fonts-mx-auto {
  margin-right: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-mb-auto,
.a11y-font-1 .a11y-fonts-my-auto,
.a11y-font-2 .a11y-fonts-mb-auto,
.a11y-font-2 .a11y-fonts-my-auto {
  margin-bottom: auto !important;
  /* stylelint-disable-line */ }

.a11y-font-1 .a11y-fonts-ml-auto,
.a11y-font-1 .a11y-fonts-mx-auto,
.a11y-font-2 .a11y-fonts-ml-auto,
.a11y-font-2 .a11y-fonts-mx-auto {
  margin-left: auto !important;
  /* stylelint-disable-line */ }

@media (min-width: 576px) {
  .a11y-font-1 .a11y-fonts-col-sm-padding,
  .a11y-font-2 .a11y-fonts-col-sm-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-0,
  .a11y-font-2 .a11y-fonts-m-sm-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-0,
  .a11y-font-1 .a11y-fonts-my-sm-0,
  .a11y-font-2 .a11y-fonts-mt-sm-0,
  .a11y-font-2 .a11y-fonts-my-sm-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-0,
  .a11y-font-1 .a11y-fonts-mx-sm-0,
  .a11y-font-2 .a11y-fonts-mr-sm-0,
  .a11y-font-2 .a11y-fonts-mx-sm-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-0,
  .a11y-font-1 .a11y-fonts-my-sm-0,
  .a11y-font-2 .a11y-fonts-mb-sm-0,
  .a11y-font-2 .a11y-fonts-my-sm-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-0,
  .a11y-font-1 .a11y-fonts-mx-sm-0,
  .a11y-font-2 .a11y-fonts-ml-sm-0,
  .a11y-font-2 .a11y-fonts-mx-sm-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-1,
  .a11y-font-2 .a11y-fonts-m-sm-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-1,
  .a11y-font-1 .a11y-fonts-my-sm-1,
  .a11y-font-2 .a11y-fonts-mt-sm-1,
  .a11y-font-2 .a11y-fonts-my-sm-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-1,
  .a11y-font-1 .a11y-fonts-mx-sm-1,
  .a11y-font-2 .a11y-fonts-mr-sm-1,
  .a11y-font-2 .a11y-fonts-mx-sm-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-1,
  .a11y-font-1 .a11y-fonts-my-sm-1,
  .a11y-font-2 .a11y-fonts-mb-sm-1,
  .a11y-font-2 .a11y-fonts-my-sm-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-1,
  .a11y-font-1 .a11y-fonts-mx-sm-1,
  .a11y-font-2 .a11y-fonts-ml-sm-1,
  .a11y-font-2 .a11y-fonts-mx-sm-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-2,
  .a11y-font-2 .a11y-fonts-m-sm-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-2,
  .a11y-font-1 .a11y-fonts-my-sm-2,
  .a11y-font-2 .a11y-fonts-mt-sm-2,
  .a11y-font-2 .a11y-fonts-my-sm-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-2,
  .a11y-font-1 .a11y-fonts-mx-sm-2,
  .a11y-font-2 .a11y-fonts-mr-sm-2,
  .a11y-font-2 .a11y-fonts-mx-sm-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-2,
  .a11y-font-1 .a11y-fonts-my-sm-2,
  .a11y-font-2 .a11y-fonts-mb-sm-2,
  .a11y-font-2 .a11y-fonts-my-sm-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-2,
  .a11y-font-1 .a11y-fonts-mx-sm-2,
  .a11y-font-2 .a11y-fonts-ml-sm-2,
  .a11y-font-2 .a11y-fonts-mx-sm-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-3,
  .a11y-font-2 .a11y-fonts-m-sm-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-3,
  .a11y-font-1 .a11y-fonts-my-sm-3,
  .a11y-font-2 .a11y-fonts-mt-sm-3,
  .a11y-font-2 .a11y-fonts-my-sm-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-3,
  .a11y-font-1 .a11y-fonts-mx-sm-3,
  .a11y-font-2 .a11y-fonts-mr-sm-3,
  .a11y-font-2 .a11y-fonts-mx-sm-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-3,
  .a11y-font-1 .a11y-fonts-my-sm-3,
  .a11y-font-2 .a11y-fonts-mb-sm-3,
  .a11y-font-2 .a11y-fonts-my-sm-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-3,
  .a11y-font-1 .a11y-fonts-mx-sm-3,
  .a11y-font-2 .a11y-fonts-ml-sm-3,
  .a11y-font-2 .a11y-fonts-mx-sm-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-4,
  .a11y-font-2 .a11y-fonts-m-sm-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-4,
  .a11y-font-1 .a11y-fonts-my-sm-4,
  .a11y-font-2 .a11y-fonts-mt-sm-4,
  .a11y-font-2 .a11y-fonts-my-sm-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-4,
  .a11y-font-1 .a11y-fonts-mx-sm-4,
  .a11y-font-2 .a11y-fonts-mr-sm-4,
  .a11y-font-2 .a11y-fonts-mx-sm-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-4,
  .a11y-font-1 .a11y-fonts-my-sm-4,
  .a11y-font-2 .a11y-fonts-mb-sm-4,
  .a11y-font-2 .a11y-fonts-my-sm-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-4,
  .a11y-font-1 .a11y-fonts-mx-sm-4,
  .a11y-font-2 .a11y-fonts-ml-sm-4,
  .a11y-font-2 .a11y-fonts-mx-sm-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-5,
  .a11y-font-2 .a11y-fonts-m-sm-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-5,
  .a11y-font-1 .a11y-fonts-my-sm-5,
  .a11y-font-2 .a11y-fonts-mt-sm-5,
  .a11y-font-2 .a11y-fonts-my-sm-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-5,
  .a11y-font-1 .a11y-fonts-mx-sm-5,
  .a11y-font-2 .a11y-fonts-mr-sm-5,
  .a11y-font-2 .a11y-fonts-mx-sm-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-5,
  .a11y-font-1 .a11y-fonts-my-sm-5,
  .a11y-font-2 .a11y-fonts-mb-sm-5,
  .a11y-font-2 .a11y-fonts-my-sm-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-5,
  .a11y-font-1 .a11y-fonts-mx-sm-5,
  .a11y-font-2 .a11y-fonts-ml-sm-5,
  .a11y-font-2 .a11y-fonts-mx-sm-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-sm-0,
  .a11y-font-2 .a11y-fonts-p-sm-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-sm-0,
  .a11y-font-1 .a11y-fonts-py-sm-0,
  .a11y-font-2 .a11y-fonts-pt-sm-0,
  .a11y-font-2 .a11y-fonts-py-sm-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-sm-0,
  .a11y-font-1 .a11y-fonts-px-sm-0,
  .a11y-font-2 .a11y-fonts-pr-sm-0,
  .a11y-font-2 .a11y-fonts-px-sm-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-sm-0,
  .a11y-font-1 .a11y-fonts-py-sm-0,
  .a11y-font-2 .a11y-fonts-pb-sm-0,
  .a11y-font-2 .a11y-fonts-py-sm-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-sm-0,
  .a11y-font-1 .a11y-fonts-px-sm-0,
  .a11y-font-2 .a11y-fonts-pl-sm-0,
  .a11y-font-2 .a11y-fonts-px-sm-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-sm-1,
  .a11y-font-2 .a11y-fonts-p-sm-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-sm-1,
  .a11y-font-1 .a11y-fonts-py-sm-1,
  .a11y-font-2 .a11y-fonts-pt-sm-1,
  .a11y-font-2 .a11y-fonts-py-sm-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-sm-1,
  .a11y-font-1 .a11y-fonts-px-sm-1,
  .a11y-font-2 .a11y-fonts-pr-sm-1,
  .a11y-font-2 .a11y-fonts-px-sm-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-sm-1,
  .a11y-font-1 .a11y-fonts-py-sm-1,
  .a11y-font-2 .a11y-fonts-pb-sm-1,
  .a11y-font-2 .a11y-fonts-py-sm-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-sm-1,
  .a11y-font-1 .a11y-fonts-px-sm-1,
  .a11y-font-2 .a11y-fonts-pl-sm-1,
  .a11y-font-2 .a11y-fonts-px-sm-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-sm-2,
  .a11y-font-2 .a11y-fonts-p-sm-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-sm-2,
  .a11y-font-1 .a11y-fonts-py-sm-2,
  .a11y-font-2 .a11y-fonts-pt-sm-2,
  .a11y-font-2 .a11y-fonts-py-sm-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-sm-2,
  .a11y-font-1 .a11y-fonts-px-sm-2,
  .a11y-font-2 .a11y-fonts-pr-sm-2,
  .a11y-font-2 .a11y-fonts-px-sm-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-sm-2,
  .a11y-font-1 .a11y-fonts-py-sm-2,
  .a11y-font-2 .a11y-fonts-pb-sm-2,
  .a11y-font-2 .a11y-fonts-py-sm-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-sm-2,
  .a11y-font-1 .a11y-fonts-px-sm-2,
  .a11y-font-2 .a11y-fonts-pl-sm-2,
  .a11y-font-2 .a11y-fonts-px-sm-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-sm-3,
  .a11y-font-2 .a11y-fonts-p-sm-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-sm-3,
  .a11y-font-1 .a11y-fonts-py-sm-3,
  .a11y-font-2 .a11y-fonts-pt-sm-3,
  .a11y-font-2 .a11y-fonts-py-sm-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-sm-3,
  .a11y-font-1 .a11y-fonts-px-sm-3,
  .a11y-font-2 .a11y-fonts-pr-sm-3,
  .a11y-font-2 .a11y-fonts-px-sm-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-sm-3,
  .a11y-font-1 .a11y-fonts-py-sm-3,
  .a11y-font-2 .a11y-fonts-pb-sm-3,
  .a11y-font-2 .a11y-fonts-py-sm-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-sm-3,
  .a11y-font-1 .a11y-fonts-px-sm-3,
  .a11y-font-2 .a11y-fonts-pl-sm-3,
  .a11y-font-2 .a11y-fonts-px-sm-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-sm-4,
  .a11y-font-2 .a11y-fonts-p-sm-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-sm-4,
  .a11y-font-1 .a11y-fonts-py-sm-4,
  .a11y-font-2 .a11y-fonts-pt-sm-4,
  .a11y-font-2 .a11y-fonts-py-sm-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-sm-4,
  .a11y-font-1 .a11y-fonts-px-sm-4,
  .a11y-font-2 .a11y-fonts-pr-sm-4,
  .a11y-font-2 .a11y-fonts-px-sm-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-sm-4,
  .a11y-font-1 .a11y-fonts-py-sm-4,
  .a11y-font-2 .a11y-fonts-pb-sm-4,
  .a11y-font-2 .a11y-fonts-py-sm-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-sm-4,
  .a11y-font-1 .a11y-fonts-px-sm-4,
  .a11y-font-2 .a11y-fonts-pl-sm-4,
  .a11y-font-2 .a11y-fonts-px-sm-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-sm-5,
  .a11y-font-2 .a11y-fonts-p-sm-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-sm-5,
  .a11y-font-1 .a11y-fonts-py-sm-5,
  .a11y-font-2 .a11y-fonts-pt-sm-5,
  .a11y-font-2 .a11y-fonts-py-sm-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-sm-5,
  .a11y-font-1 .a11y-fonts-px-sm-5,
  .a11y-font-2 .a11y-fonts-pr-sm-5,
  .a11y-font-2 .a11y-fonts-px-sm-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-sm-5,
  .a11y-font-1 .a11y-fonts-py-sm-5,
  .a11y-font-2 .a11y-fonts-pb-sm-5,
  .a11y-font-2 .a11y-fonts-py-sm-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-sm-5,
  .a11y-font-1 .a11y-fonts-px-sm-5,
  .a11y-font-2 .a11y-fonts-pl-sm-5,
  .a11y-font-2 .a11y-fonts-px-sm-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-sm-auto,
  .a11y-font-2 .a11y-fonts-m-sm-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-sm-auto,
  .a11y-font-1 .a11y-fonts-my-sm-auto,
  .a11y-font-2 .a11y-fonts-mt-sm-auto,
  .a11y-font-2 .a11y-fonts-my-sm-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-sm-auto,
  .a11y-font-1 .a11y-fonts-mx-sm-auto,
  .a11y-font-2 .a11y-fonts-mr-sm-auto,
  .a11y-font-2 .a11y-fonts-mx-sm-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-sm-auto,
  .a11y-font-1 .a11y-fonts-my-sm-auto,
  .a11y-font-2 .a11y-fonts-mb-sm-auto,
  .a11y-font-2 .a11y-fonts-my-sm-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-sm-auto,
  .a11y-font-1 .a11y-fonts-mx-sm-auto,
  .a11y-font-2 .a11y-fonts-ml-sm-auto,
  .a11y-font-2 .a11y-fonts-mx-sm-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 768px) {
  .a11y-font-1 .a11y-fonts-col-md-padding,
  .a11y-font-2 .a11y-fonts-col-md-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-0,
  .a11y-font-2 .a11y-fonts-m-md-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-0,
  .a11y-font-1 .a11y-fonts-my-md-0,
  .a11y-font-2 .a11y-fonts-mt-md-0,
  .a11y-font-2 .a11y-fonts-my-md-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-0,
  .a11y-font-1 .a11y-fonts-mx-md-0,
  .a11y-font-2 .a11y-fonts-mr-md-0,
  .a11y-font-2 .a11y-fonts-mx-md-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-0,
  .a11y-font-1 .a11y-fonts-my-md-0,
  .a11y-font-2 .a11y-fonts-mb-md-0,
  .a11y-font-2 .a11y-fonts-my-md-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-0,
  .a11y-font-1 .a11y-fonts-mx-md-0,
  .a11y-font-2 .a11y-fonts-ml-md-0,
  .a11y-font-2 .a11y-fonts-mx-md-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-1,
  .a11y-font-2 .a11y-fonts-m-md-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-1,
  .a11y-font-1 .a11y-fonts-my-md-1,
  .a11y-font-2 .a11y-fonts-mt-md-1,
  .a11y-font-2 .a11y-fonts-my-md-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-1,
  .a11y-font-1 .a11y-fonts-mx-md-1,
  .a11y-font-2 .a11y-fonts-mr-md-1,
  .a11y-font-2 .a11y-fonts-mx-md-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-1,
  .a11y-font-1 .a11y-fonts-my-md-1,
  .a11y-font-2 .a11y-fonts-mb-md-1,
  .a11y-font-2 .a11y-fonts-my-md-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-1,
  .a11y-font-1 .a11y-fonts-mx-md-1,
  .a11y-font-2 .a11y-fonts-ml-md-1,
  .a11y-font-2 .a11y-fonts-mx-md-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-2,
  .a11y-font-2 .a11y-fonts-m-md-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-2,
  .a11y-font-1 .a11y-fonts-my-md-2,
  .a11y-font-2 .a11y-fonts-mt-md-2,
  .a11y-font-2 .a11y-fonts-my-md-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-2,
  .a11y-font-1 .a11y-fonts-mx-md-2,
  .a11y-font-2 .a11y-fonts-mr-md-2,
  .a11y-font-2 .a11y-fonts-mx-md-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-2,
  .a11y-font-1 .a11y-fonts-my-md-2,
  .a11y-font-2 .a11y-fonts-mb-md-2,
  .a11y-font-2 .a11y-fonts-my-md-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-2,
  .a11y-font-1 .a11y-fonts-mx-md-2,
  .a11y-font-2 .a11y-fonts-ml-md-2,
  .a11y-font-2 .a11y-fonts-mx-md-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-3,
  .a11y-font-2 .a11y-fonts-m-md-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-3,
  .a11y-font-1 .a11y-fonts-my-md-3,
  .a11y-font-2 .a11y-fonts-mt-md-3,
  .a11y-font-2 .a11y-fonts-my-md-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-3,
  .a11y-font-1 .a11y-fonts-mx-md-3,
  .a11y-font-2 .a11y-fonts-mr-md-3,
  .a11y-font-2 .a11y-fonts-mx-md-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-3,
  .a11y-font-1 .a11y-fonts-my-md-3,
  .a11y-font-2 .a11y-fonts-mb-md-3,
  .a11y-font-2 .a11y-fonts-my-md-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-3,
  .a11y-font-1 .a11y-fonts-mx-md-3,
  .a11y-font-2 .a11y-fonts-ml-md-3,
  .a11y-font-2 .a11y-fonts-mx-md-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-4,
  .a11y-font-2 .a11y-fonts-m-md-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-4,
  .a11y-font-1 .a11y-fonts-my-md-4,
  .a11y-font-2 .a11y-fonts-mt-md-4,
  .a11y-font-2 .a11y-fonts-my-md-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-4,
  .a11y-font-1 .a11y-fonts-mx-md-4,
  .a11y-font-2 .a11y-fonts-mr-md-4,
  .a11y-font-2 .a11y-fonts-mx-md-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-4,
  .a11y-font-1 .a11y-fonts-my-md-4,
  .a11y-font-2 .a11y-fonts-mb-md-4,
  .a11y-font-2 .a11y-fonts-my-md-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-4,
  .a11y-font-1 .a11y-fonts-mx-md-4,
  .a11y-font-2 .a11y-fonts-ml-md-4,
  .a11y-font-2 .a11y-fonts-mx-md-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-5,
  .a11y-font-2 .a11y-fonts-m-md-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-5,
  .a11y-font-1 .a11y-fonts-my-md-5,
  .a11y-font-2 .a11y-fonts-mt-md-5,
  .a11y-font-2 .a11y-fonts-my-md-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-5,
  .a11y-font-1 .a11y-fonts-mx-md-5,
  .a11y-font-2 .a11y-fonts-mr-md-5,
  .a11y-font-2 .a11y-fonts-mx-md-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-5,
  .a11y-font-1 .a11y-fonts-my-md-5,
  .a11y-font-2 .a11y-fonts-mb-md-5,
  .a11y-font-2 .a11y-fonts-my-md-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-5,
  .a11y-font-1 .a11y-fonts-mx-md-5,
  .a11y-font-2 .a11y-fonts-ml-md-5,
  .a11y-font-2 .a11y-fonts-mx-md-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-md-0,
  .a11y-font-2 .a11y-fonts-p-md-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-md-0,
  .a11y-font-1 .a11y-fonts-py-md-0,
  .a11y-font-2 .a11y-fonts-pt-md-0,
  .a11y-font-2 .a11y-fonts-py-md-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-md-0,
  .a11y-font-1 .a11y-fonts-px-md-0,
  .a11y-font-2 .a11y-fonts-pr-md-0,
  .a11y-font-2 .a11y-fonts-px-md-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-md-0,
  .a11y-font-1 .a11y-fonts-py-md-0,
  .a11y-font-2 .a11y-fonts-pb-md-0,
  .a11y-font-2 .a11y-fonts-py-md-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-md-0,
  .a11y-font-1 .a11y-fonts-px-md-0,
  .a11y-font-2 .a11y-fonts-pl-md-0,
  .a11y-font-2 .a11y-fonts-px-md-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-md-1,
  .a11y-font-2 .a11y-fonts-p-md-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-md-1,
  .a11y-font-1 .a11y-fonts-py-md-1,
  .a11y-font-2 .a11y-fonts-pt-md-1,
  .a11y-font-2 .a11y-fonts-py-md-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-md-1,
  .a11y-font-1 .a11y-fonts-px-md-1,
  .a11y-font-2 .a11y-fonts-pr-md-1,
  .a11y-font-2 .a11y-fonts-px-md-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-md-1,
  .a11y-font-1 .a11y-fonts-py-md-1,
  .a11y-font-2 .a11y-fonts-pb-md-1,
  .a11y-font-2 .a11y-fonts-py-md-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-md-1,
  .a11y-font-1 .a11y-fonts-px-md-1,
  .a11y-font-2 .a11y-fonts-pl-md-1,
  .a11y-font-2 .a11y-fonts-px-md-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-md-2,
  .a11y-font-2 .a11y-fonts-p-md-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-md-2,
  .a11y-font-1 .a11y-fonts-py-md-2,
  .a11y-font-2 .a11y-fonts-pt-md-2,
  .a11y-font-2 .a11y-fonts-py-md-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-md-2,
  .a11y-font-1 .a11y-fonts-px-md-2,
  .a11y-font-2 .a11y-fonts-pr-md-2,
  .a11y-font-2 .a11y-fonts-px-md-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-md-2,
  .a11y-font-1 .a11y-fonts-py-md-2,
  .a11y-font-2 .a11y-fonts-pb-md-2,
  .a11y-font-2 .a11y-fonts-py-md-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-md-2,
  .a11y-font-1 .a11y-fonts-px-md-2,
  .a11y-font-2 .a11y-fonts-pl-md-2,
  .a11y-font-2 .a11y-fonts-px-md-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-md-3,
  .a11y-font-2 .a11y-fonts-p-md-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-md-3,
  .a11y-font-1 .a11y-fonts-py-md-3,
  .a11y-font-2 .a11y-fonts-pt-md-3,
  .a11y-font-2 .a11y-fonts-py-md-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-md-3,
  .a11y-font-1 .a11y-fonts-px-md-3,
  .a11y-font-2 .a11y-fonts-pr-md-3,
  .a11y-font-2 .a11y-fonts-px-md-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-md-3,
  .a11y-font-1 .a11y-fonts-py-md-3,
  .a11y-font-2 .a11y-fonts-pb-md-3,
  .a11y-font-2 .a11y-fonts-py-md-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-md-3,
  .a11y-font-1 .a11y-fonts-px-md-3,
  .a11y-font-2 .a11y-fonts-pl-md-3,
  .a11y-font-2 .a11y-fonts-px-md-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-md-4,
  .a11y-font-2 .a11y-fonts-p-md-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-md-4,
  .a11y-font-1 .a11y-fonts-py-md-4,
  .a11y-font-2 .a11y-fonts-pt-md-4,
  .a11y-font-2 .a11y-fonts-py-md-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-md-4,
  .a11y-font-1 .a11y-fonts-px-md-4,
  .a11y-font-2 .a11y-fonts-pr-md-4,
  .a11y-font-2 .a11y-fonts-px-md-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-md-4,
  .a11y-font-1 .a11y-fonts-py-md-4,
  .a11y-font-2 .a11y-fonts-pb-md-4,
  .a11y-font-2 .a11y-fonts-py-md-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-md-4,
  .a11y-font-1 .a11y-fonts-px-md-4,
  .a11y-font-2 .a11y-fonts-pl-md-4,
  .a11y-font-2 .a11y-fonts-px-md-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-md-5,
  .a11y-font-2 .a11y-fonts-p-md-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-md-5,
  .a11y-font-1 .a11y-fonts-py-md-5,
  .a11y-font-2 .a11y-fonts-pt-md-5,
  .a11y-font-2 .a11y-fonts-py-md-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-md-5,
  .a11y-font-1 .a11y-fonts-px-md-5,
  .a11y-font-2 .a11y-fonts-pr-md-5,
  .a11y-font-2 .a11y-fonts-px-md-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-md-5,
  .a11y-font-1 .a11y-fonts-py-md-5,
  .a11y-font-2 .a11y-fonts-pb-md-5,
  .a11y-font-2 .a11y-fonts-py-md-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-md-5,
  .a11y-font-1 .a11y-fonts-px-md-5,
  .a11y-font-2 .a11y-fonts-pl-md-5,
  .a11y-font-2 .a11y-fonts-px-md-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-md-auto,
  .a11y-font-2 .a11y-fonts-m-md-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-md-auto,
  .a11y-font-1 .a11y-fonts-my-md-auto,
  .a11y-font-2 .a11y-fonts-mt-md-auto,
  .a11y-font-2 .a11y-fonts-my-md-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-md-auto,
  .a11y-font-1 .a11y-fonts-mx-md-auto,
  .a11y-font-2 .a11y-fonts-mr-md-auto,
  .a11y-font-2 .a11y-fonts-mx-md-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-md-auto,
  .a11y-font-1 .a11y-fonts-my-md-auto,
  .a11y-font-2 .a11y-fonts-mb-md-auto,
  .a11y-font-2 .a11y-fonts-my-md-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-md-auto,
  .a11y-font-1 .a11y-fonts-mx-md-auto,
  .a11y-font-2 .a11y-fonts-ml-md-auto,
  .a11y-font-2 .a11y-fonts-mx-md-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 992px) {
  .a11y-font-1 .a11y-fonts-col-lg-padding,
  .a11y-font-2 .a11y-fonts-col-lg-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-0,
  .a11y-font-2 .a11y-fonts-m-lg-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-0,
  .a11y-font-1 .a11y-fonts-my-lg-0,
  .a11y-font-2 .a11y-fonts-mt-lg-0,
  .a11y-font-2 .a11y-fonts-my-lg-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-0,
  .a11y-font-1 .a11y-fonts-mx-lg-0,
  .a11y-font-2 .a11y-fonts-mr-lg-0,
  .a11y-font-2 .a11y-fonts-mx-lg-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-0,
  .a11y-font-1 .a11y-fonts-my-lg-0,
  .a11y-font-2 .a11y-fonts-mb-lg-0,
  .a11y-font-2 .a11y-fonts-my-lg-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-0,
  .a11y-font-1 .a11y-fonts-mx-lg-0,
  .a11y-font-2 .a11y-fonts-ml-lg-0,
  .a11y-font-2 .a11y-fonts-mx-lg-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-1,
  .a11y-font-2 .a11y-fonts-m-lg-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-1,
  .a11y-font-1 .a11y-fonts-my-lg-1,
  .a11y-font-2 .a11y-fonts-mt-lg-1,
  .a11y-font-2 .a11y-fonts-my-lg-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-1,
  .a11y-font-1 .a11y-fonts-mx-lg-1,
  .a11y-font-2 .a11y-fonts-mr-lg-1,
  .a11y-font-2 .a11y-fonts-mx-lg-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-1,
  .a11y-font-1 .a11y-fonts-my-lg-1,
  .a11y-font-2 .a11y-fonts-mb-lg-1,
  .a11y-font-2 .a11y-fonts-my-lg-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-1,
  .a11y-font-1 .a11y-fonts-mx-lg-1,
  .a11y-font-2 .a11y-fonts-ml-lg-1,
  .a11y-font-2 .a11y-fonts-mx-lg-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-2,
  .a11y-font-2 .a11y-fonts-m-lg-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-2,
  .a11y-font-1 .a11y-fonts-my-lg-2,
  .a11y-font-2 .a11y-fonts-mt-lg-2,
  .a11y-font-2 .a11y-fonts-my-lg-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-2,
  .a11y-font-1 .a11y-fonts-mx-lg-2,
  .a11y-font-2 .a11y-fonts-mr-lg-2,
  .a11y-font-2 .a11y-fonts-mx-lg-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-2,
  .a11y-font-1 .a11y-fonts-my-lg-2,
  .a11y-font-2 .a11y-fonts-mb-lg-2,
  .a11y-font-2 .a11y-fonts-my-lg-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-2,
  .a11y-font-1 .a11y-fonts-mx-lg-2,
  .a11y-font-2 .a11y-fonts-ml-lg-2,
  .a11y-font-2 .a11y-fonts-mx-lg-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-3,
  .a11y-font-2 .a11y-fonts-m-lg-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-3,
  .a11y-font-1 .a11y-fonts-my-lg-3,
  .a11y-font-2 .a11y-fonts-mt-lg-3,
  .a11y-font-2 .a11y-fonts-my-lg-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-3,
  .a11y-font-1 .a11y-fonts-mx-lg-3,
  .a11y-font-2 .a11y-fonts-mr-lg-3,
  .a11y-font-2 .a11y-fonts-mx-lg-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-3,
  .a11y-font-1 .a11y-fonts-my-lg-3,
  .a11y-font-2 .a11y-fonts-mb-lg-3,
  .a11y-font-2 .a11y-fonts-my-lg-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-3,
  .a11y-font-1 .a11y-fonts-mx-lg-3,
  .a11y-font-2 .a11y-fonts-ml-lg-3,
  .a11y-font-2 .a11y-fonts-mx-lg-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-4,
  .a11y-font-2 .a11y-fonts-m-lg-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-4,
  .a11y-font-1 .a11y-fonts-my-lg-4,
  .a11y-font-2 .a11y-fonts-mt-lg-4,
  .a11y-font-2 .a11y-fonts-my-lg-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-4,
  .a11y-font-1 .a11y-fonts-mx-lg-4,
  .a11y-font-2 .a11y-fonts-mr-lg-4,
  .a11y-font-2 .a11y-fonts-mx-lg-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-4,
  .a11y-font-1 .a11y-fonts-my-lg-4,
  .a11y-font-2 .a11y-fonts-mb-lg-4,
  .a11y-font-2 .a11y-fonts-my-lg-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-4,
  .a11y-font-1 .a11y-fonts-mx-lg-4,
  .a11y-font-2 .a11y-fonts-ml-lg-4,
  .a11y-font-2 .a11y-fonts-mx-lg-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-5,
  .a11y-font-2 .a11y-fonts-m-lg-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-5,
  .a11y-font-1 .a11y-fonts-my-lg-5,
  .a11y-font-2 .a11y-fonts-mt-lg-5,
  .a11y-font-2 .a11y-fonts-my-lg-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-5,
  .a11y-font-1 .a11y-fonts-mx-lg-5,
  .a11y-font-2 .a11y-fonts-mr-lg-5,
  .a11y-font-2 .a11y-fonts-mx-lg-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-5,
  .a11y-font-1 .a11y-fonts-my-lg-5,
  .a11y-font-2 .a11y-fonts-mb-lg-5,
  .a11y-font-2 .a11y-fonts-my-lg-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-5,
  .a11y-font-1 .a11y-fonts-mx-lg-5,
  .a11y-font-2 .a11y-fonts-ml-lg-5,
  .a11y-font-2 .a11y-fonts-mx-lg-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-lg-0,
  .a11y-font-2 .a11y-fonts-p-lg-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-lg-0,
  .a11y-font-1 .a11y-fonts-py-lg-0,
  .a11y-font-2 .a11y-fonts-pt-lg-0,
  .a11y-font-2 .a11y-fonts-py-lg-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-lg-0,
  .a11y-font-1 .a11y-fonts-px-lg-0,
  .a11y-font-2 .a11y-fonts-pr-lg-0,
  .a11y-font-2 .a11y-fonts-px-lg-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-lg-0,
  .a11y-font-1 .a11y-fonts-py-lg-0,
  .a11y-font-2 .a11y-fonts-pb-lg-0,
  .a11y-font-2 .a11y-fonts-py-lg-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-lg-0,
  .a11y-font-1 .a11y-fonts-px-lg-0,
  .a11y-font-2 .a11y-fonts-pl-lg-0,
  .a11y-font-2 .a11y-fonts-px-lg-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-lg-1,
  .a11y-font-2 .a11y-fonts-p-lg-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-lg-1,
  .a11y-font-1 .a11y-fonts-py-lg-1,
  .a11y-font-2 .a11y-fonts-pt-lg-1,
  .a11y-font-2 .a11y-fonts-py-lg-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-lg-1,
  .a11y-font-1 .a11y-fonts-px-lg-1,
  .a11y-font-2 .a11y-fonts-pr-lg-1,
  .a11y-font-2 .a11y-fonts-px-lg-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-lg-1,
  .a11y-font-1 .a11y-fonts-py-lg-1,
  .a11y-font-2 .a11y-fonts-pb-lg-1,
  .a11y-font-2 .a11y-fonts-py-lg-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-lg-1,
  .a11y-font-1 .a11y-fonts-px-lg-1,
  .a11y-font-2 .a11y-fonts-pl-lg-1,
  .a11y-font-2 .a11y-fonts-px-lg-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-lg-2,
  .a11y-font-2 .a11y-fonts-p-lg-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-lg-2,
  .a11y-font-1 .a11y-fonts-py-lg-2,
  .a11y-font-2 .a11y-fonts-pt-lg-2,
  .a11y-font-2 .a11y-fonts-py-lg-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-lg-2,
  .a11y-font-1 .a11y-fonts-px-lg-2,
  .a11y-font-2 .a11y-fonts-pr-lg-2,
  .a11y-font-2 .a11y-fonts-px-lg-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-lg-2,
  .a11y-font-1 .a11y-fonts-py-lg-2,
  .a11y-font-2 .a11y-fonts-pb-lg-2,
  .a11y-font-2 .a11y-fonts-py-lg-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-lg-2,
  .a11y-font-1 .a11y-fonts-px-lg-2,
  .a11y-font-2 .a11y-fonts-pl-lg-2,
  .a11y-font-2 .a11y-fonts-px-lg-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-lg-3,
  .a11y-font-2 .a11y-fonts-p-lg-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-lg-3,
  .a11y-font-1 .a11y-fonts-py-lg-3,
  .a11y-font-2 .a11y-fonts-pt-lg-3,
  .a11y-font-2 .a11y-fonts-py-lg-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-lg-3,
  .a11y-font-1 .a11y-fonts-px-lg-3,
  .a11y-font-2 .a11y-fonts-pr-lg-3,
  .a11y-font-2 .a11y-fonts-px-lg-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-lg-3,
  .a11y-font-1 .a11y-fonts-py-lg-3,
  .a11y-font-2 .a11y-fonts-pb-lg-3,
  .a11y-font-2 .a11y-fonts-py-lg-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-lg-3,
  .a11y-font-1 .a11y-fonts-px-lg-3,
  .a11y-font-2 .a11y-fonts-pl-lg-3,
  .a11y-font-2 .a11y-fonts-px-lg-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-lg-4,
  .a11y-font-2 .a11y-fonts-p-lg-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-lg-4,
  .a11y-font-1 .a11y-fonts-py-lg-4,
  .a11y-font-2 .a11y-fonts-pt-lg-4,
  .a11y-font-2 .a11y-fonts-py-lg-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-lg-4,
  .a11y-font-1 .a11y-fonts-px-lg-4,
  .a11y-font-2 .a11y-fonts-pr-lg-4,
  .a11y-font-2 .a11y-fonts-px-lg-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-lg-4,
  .a11y-font-1 .a11y-fonts-py-lg-4,
  .a11y-font-2 .a11y-fonts-pb-lg-4,
  .a11y-font-2 .a11y-fonts-py-lg-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-lg-4,
  .a11y-font-1 .a11y-fonts-px-lg-4,
  .a11y-font-2 .a11y-fonts-pl-lg-4,
  .a11y-font-2 .a11y-fonts-px-lg-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-lg-5,
  .a11y-font-2 .a11y-fonts-p-lg-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-lg-5,
  .a11y-font-1 .a11y-fonts-py-lg-5,
  .a11y-font-2 .a11y-fonts-pt-lg-5,
  .a11y-font-2 .a11y-fonts-py-lg-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-lg-5,
  .a11y-font-1 .a11y-fonts-px-lg-5,
  .a11y-font-2 .a11y-fonts-pr-lg-5,
  .a11y-font-2 .a11y-fonts-px-lg-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-lg-5,
  .a11y-font-1 .a11y-fonts-py-lg-5,
  .a11y-font-2 .a11y-fonts-pb-lg-5,
  .a11y-font-2 .a11y-fonts-py-lg-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-lg-5,
  .a11y-font-1 .a11y-fonts-px-lg-5,
  .a11y-font-2 .a11y-fonts-pl-lg-5,
  .a11y-font-2 .a11y-fonts-px-lg-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-lg-auto,
  .a11y-font-2 .a11y-fonts-m-lg-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-lg-auto,
  .a11y-font-1 .a11y-fonts-my-lg-auto,
  .a11y-font-2 .a11y-fonts-mt-lg-auto,
  .a11y-font-2 .a11y-fonts-my-lg-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-lg-auto,
  .a11y-font-1 .a11y-fonts-mx-lg-auto,
  .a11y-font-2 .a11y-fonts-mr-lg-auto,
  .a11y-font-2 .a11y-fonts-mx-lg-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-lg-auto,
  .a11y-font-1 .a11y-fonts-my-lg-auto,
  .a11y-font-2 .a11y-fonts-mb-lg-auto,
  .a11y-font-2 .a11y-fonts-my-lg-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-lg-auto,
  .a11y-font-1 .a11y-fonts-mx-lg-auto,
  .a11y-font-2 .a11y-fonts-ml-lg-auto,
  .a11y-font-2 .a11y-fonts-mx-lg-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

@media (min-width: 1200px) {
  .a11y-font-1 .a11y-fonts-col-xl-padding,
  .a11y-font-2 .a11y-fonts-col-xl-padding {
    padding-right: 15px !important;
    /* stylelint-disable-line */
    padding-left: 15px !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-0,
  .a11y-font-2 .a11y-fonts-m-xl-0 {
    margin: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-0,
  .a11y-font-1 .a11y-fonts-my-xl-0,
  .a11y-font-2 .a11y-fonts-mt-xl-0,
  .a11y-font-2 .a11y-fonts-my-xl-0 {
    margin-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-0,
  .a11y-font-1 .a11y-fonts-mx-xl-0,
  .a11y-font-2 .a11y-fonts-mr-xl-0,
  .a11y-font-2 .a11y-fonts-mx-xl-0 {
    margin-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-0,
  .a11y-font-1 .a11y-fonts-my-xl-0,
  .a11y-font-2 .a11y-fonts-mb-xl-0,
  .a11y-font-2 .a11y-fonts-my-xl-0 {
    margin-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-0,
  .a11y-font-1 .a11y-fonts-mx-xl-0,
  .a11y-font-2 .a11y-fonts-ml-xl-0,
  .a11y-font-2 .a11y-fonts-mx-xl-0 {
    margin-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-1,
  .a11y-font-2 .a11y-fonts-m-xl-1 {
    margin: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-1,
  .a11y-font-1 .a11y-fonts-my-xl-1,
  .a11y-font-2 .a11y-fonts-mt-xl-1,
  .a11y-font-2 .a11y-fonts-my-xl-1 {
    margin-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-1,
  .a11y-font-1 .a11y-fonts-mx-xl-1,
  .a11y-font-2 .a11y-fonts-mr-xl-1,
  .a11y-font-2 .a11y-fonts-mx-xl-1 {
    margin-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-1,
  .a11y-font-1 .a11y-fonts-my-xl-1,
  .a11y-font-2 .a11y-fonts-mb-xl-1,
  .a11y-font-2 .a11y-fonts-my-xl-1 {
    margin-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-1,
  .a11y-font-1 .a11y-fonts-mx-xl-1,
  .a11y-font-2 .a11y-fonts-ml-xl-1,
  .a11y-font-2 .a11y-fonts-mx-xl-1 {
    margin-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-2,
  .a11y-font-2 .a11y-fonts-m-xl-2 {
    margin: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-2,
  .a11y-font-1 .a11y-fonts-my-xl-2,
  .a11y-font-2 .a11y-fonts-mt-xl-2,
  .a11y-font-2 .a11y-fonts-my-xl-2 {
    margin-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-2,
  .a11y-font-1 .a11y-fonts-mx-xl-2,
  .a11y-font-2 .a11y-fonts-mr-xl-2,
  .a11y-font-2 .a11y-fonts-mx-xl-2 {
    margin-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-2,
  .a11y-font-1 .a11y-fonts-my-xl-2,
  .a11y-font-2 .a11y-fonts-mb-xl-2,
  .a11y-font-2 .a11y-fonts-my-xl-2 {
    margin-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-2,
  .a11y-font-1 .a11y-fonts-mx-xl-2,
  .a11y-font-2 .a11y-fonts-ml-xl-2,
  .a11y-font-2 .a11y-fonts-mx-xl-2 {
    margin-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-3,
  .a11y-font-2 .a11y-fonts-m-xl-3 {
    margin: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-3,
  .a11y-font-1 .a11y-fonts-my-xl-3,
  .a11y-font-2 .a11y-fonts-mt-xl-3,
  .a11y-font-2 .a11y-fonts-my-xl-3 {
    margin-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-3,
  .a11y-font-1 .a11y-fonts-mx-xl-3,
  .a11y-font-2 .a11y-fonts-mr-xl-3,
  .a11y-font-2 .a11y-fonts-mx-xl-3 {
    margin-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-3,
  .a11y-font-1 .a11y-fonts-my-xl-3,
  .a11y-font-2 .a11y-fonts-mb-xl-3,
  .a11y-font-2 .a11y-fonts-my-xl-3 {
    margin-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-3,
  .a11y-font-1 .a11y-fonts-mx-xl-3,
  .a11y-font-2 .a11y-fonts-ml-xl-3,
  .a11y-font-2 .a11y-fonts-mx-xl-3 {
    margin-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-4,
  .a11y-font-2 .a11y-fonts-m-xl-4 {
    margin: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-4,
  .a11y-font-1 .a11y-fonts-my-xl-4,
  .a11y-font-2 .a11y-fonts-mt-xl-4,
  .a11y-font-2 .a11y-fonts-my-xl-4 {
    margin-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-4,
  .a11y-font-1 .a11y-fonts-mx-xl-4,
  .a11y-font-2 .a11y-fonts-mr-xl-4,
  .a11y-font-2 .a11y-fonts-mx-xl-4 {
    margin-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-4,
  .a11y-font-1 .a11y-fonts-my-xl-4,
  .a11y-font-2 .a11y-fonts-mb-xl-4,
  .a11y-font-2 .a11y-fonts-my-xl-4 {
    margin-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-4,
  .a11y-font-1 .a11y-fonts-mx-xl-4,
  .a11y-font-2 .a11y-fonts-ml-xl-4,
  .a11y-font-2 .a11y-fonts-mx-xl-4 {
    margin-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-5,
  .a11y-font-2 .a11y-fonts-m-xl-5 {
    margin: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-5,
  .a11y-font-1 .a11y-fonts-my-xl-5,
  .a11y-font-2 .a11y-fonts-mt-xl-5,
  .a11y-font-2 .a11y-fonts-my-xl-5 {
    margin-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-5,
  .a11y-font-1 .a11y-fonts-mx-xl-5,
  .a11y-font-2 .a11y-fonts-mr-xl-5,
  .a11y-font-2 .a11y-fonts-mx-xl-5 {
    margin-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-5,
  .a11y-font-1 .a11y-fonts-my-xl-5,
  .a11y-font-2 .a11y-fonts-mb-xl-5,
  .a11y-font-2 .a11y-fonts-my-xl-5 {
    margin-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-5,
  .a11y-font-1 .a11y-fonts-mx-xl-5,
  .a11y-font-2 .a11y-fonts-ml-xl-5,
  .a11y-font-2 .a11y-fonts-mx-xl-5 {
    margin-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-xl-0,
  .a11y-font-2 .a11y-fonts-p-xl-0 {
    padding: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-xl-0,
  .a11y-font-1 .a11y-fonts-py-xl-0,
  .a11y-font-2 .a11y-fonts-pt-xl-0,
  .a11y-font-2 .a11y-fonts-py-xl-0 {
    padding-top: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-xl-0,
  .a11y-font-1 .a11y-fonts-px-xl-0,
  .a11y-font-2 .a11y-fonts-pr-xl-0,
  .a11y-font-2 .a11y-fonts-px-xl-0 {
    padding-right: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-xl-0,
  .a11y-font-1 .a11y-fonts-py-xl-0,
  .a11y-font-2 .a11y-fonts-pb-xl-0,
  .a11y-font-2 .a11y-fonts-py-xl-0 {
    padding-bottom: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-xl-0,
  .a11y-font-1 .a11y-fonts-px-xl-0,
  .a11y-font-2 .a11y-fonts-pl-xl-0,
  .a11y-font-2 .a11y-fonts-px-xl-0 {
    padding-left: 0 !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-xl-1,
  .a11y-font-2 .a11y-fonts-p-xl-1 {
    padding: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-xl-1,
  .a11y-font-1 .a11y-fonts-py-xl-1,
  .a11y-font-2 .a11y-fonts-pt-xl-1,
  .a11y-font-2 .a11y-fonts-py-xl-1 {
    padding-top: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-xl-1,
  .a11y-font-1 .a11y-fonts-px-xl-1,
  .a11y-font-2 .a11y-fonts-pr-xl-1,
  .a11y-font-2 .a11y-fonts-px-xl-1 {
    padding-right: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-xl-1,
  .a11y-font-1 .a11y-fonts-py-xl-1,
  .a11y-font-2 .a11y-fonts-pb-xl-1,
  .a11y-font-2 .a11y-fonts-py-xl-1 {
    padding-bottom: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-xl-1,
  .a11y-font-1 .a11y-fonts-px-xl-1,
  .a11y-font-2 .a11y-fonts-pl-xl-1,
  .a11y-font-2 .a11y-fonts-px-xl-1 {
    padding-left: 0.25rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-xl-2,
  .a11y-font-2 .a11y-fonts-p-xl-2 {
    padding: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-xl-2,
  .a11y-font-1 .a11y-fonts-py-xl-2,
  .a11y-font-2 .a11y-fonts-pt-xl-2,
  .a11y-font-2 .a11y-fonts-py-xl-2 {
    padding-top: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-xl-2,
  .a11y-font-1 .a11y-fonts-px-xl-2,
  .a11y-font-2 .a11y-fonts-pr-xl-2,
  .a11y-font-2 .a11y-fonts-px-xl-2 {
    padding-right: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-xl-2,
  .a11y-font-1 .a11y-fonts-py-xl-2,
  .a11y-font-2 .a11y-fonts-pb-xl-2,
  .a11y-font-2 .a11y-fonts-py-xl-2 {
    padding-bottom: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-xl-2,
  .a11y-font-1 .a11y-fonts-px-xl-2,
  .a11y-font-2 .a11y-fonts-pl-xl-2,
  .a11y-font-2 .a11y-fonts-px-xl-2 {
    padding-left: 0.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-xl-3,
  .a11y-font-2 .a11y-fonts-p-xl-3 {
    padding: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-xl-3,
  .a11y-font-1 .a11y-fonts-py-xl-3,
  .a11y-font-2 .a11y-fonts-pt-xl-3,
  .a11y-font-2 .a11y-fonts-py-xl-3 {
    padding-top: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-xl-3,
  .a11y-font-1 .a11y-fonts-px-xl-3,
  .a11y-font-2 .a11y-fonts-pr-xl-3,
  .a11y-font-2 .a11y-fonts-px-xl-3 {
    padding-right: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-xl-3,
  .a11y-font-1 .a11y-fonts-py-xl-3,
  .a11y-font-2 .a11y-fonts-pb-xl-3,
  .a11y-font-2 .a11y-fonts-py-xl-3 {
    padding-bottom: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-xl-3,
  .a11y-font-1 .a11y-fonts-px-xl-3,
  .a11y-font-2 .a11y-fonts-pl-xl-3,
  .a11y-font-2 .a11y-fonts-px-xl-3 {
    padding-left: 1rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-xl-4,
  .a11y-font-2 .a11y-fonts-p-xl-4 {
    padding: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-xl-4,
  .a11y-font-1 .a11y-fonts-py-xl-4,
  .a11y-font-2 .a11y-fonts-pt-xl-4,
  .a11y-font-2 .a11y-fonts-py-xl-4 {
    padding-top: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-xl-4,
  .a11y-font-1 .a11y-fonts-px-xl-4,
  .a11y-font-2 .a11y-fonts-pr-xl-4,
  .a11y-font-2 .a11y-fonts-px-xl-4 {
    padding-right: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-xl-4,
  .a11y-font-1 .a11y-fonts-py-xl-4,
  .a11y-font-2 .a11y-fonts-pb-xl-4,
  .a11y-font-2 .a11y-fonts-py-xl-4 {
    padding-bottom: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-xl-4,
  .a11y-font-1 .a11y-fonts-px-xl-4,
  .a11y-font-2 .a11y-fonts-pl-xl-4,
  .a11y-font-2 .a11y-fonts-px-xl-4 {
    padding-left: 1.5rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-p-xl-5,
  .a11y-font-2 .a11y-fonts-p-xl-5 {
    padding: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pt-xl-5,
  .a11y-font-1 .a11y-fonts-py-xl-5,
  .a11y-font-2 .a11y-fonts-pt-xl-5,
  .a11y-font-2 .a11y-fonts-py-xl-5 {
    padding-top: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pr-xl-5,
  .a11y-font-1 .a11y-fonts-px-xl-5,
  .a11y-font-2 .a11y-fonts-pr-xl-5,
  .a11y-font-2 .a11y-fonts-px-xl-5 {
    padding-right: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pb-xl-5,
  .a11y-font-1 .a11y-fonts-py-xl-5,
  .a11y-font-2 .a11y-fonts-pb-xl-5,
  .a11y-font-2 .a11y-fonts-py-xl-5 {
    padding-bottom: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-pl-xl-5,
  .a11y-font-1 .a11y-fonts-px-xl-5,
  .a11y-font-2 .a11y-fonts-pl-xl-5,
  .a11y-font-2 .a11y-fonts-px-xl-5 {
    padding-left: 3rem !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-m-xl-auto,
  .a11y-font-2 .a11y-fonts-m-xl-auto {
    margin: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mt-xl-auto,
  .a11y-font-1 .a11y-fonts-my-xl-auto,
  .a11y-font-2 .a11y-fonts-mt-xl-auto,
  .a11y-font-2 .a11y-fonts-my-xl-auto {
    margin-top: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mr-xl-auto,
  .a11y-font-1 .a11y-fonts-mx-xl-auto,
  .a11y-font-2 .a11y-fonts-mr-xl-auto,
  .a11y-font-2 .a11y-fonts-mx-xl-auto {
    margin-right: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-mb-xl-auto,
  .a11y-font-1 .a11y-fonts-my-xl-auto,
  .a11y-font-2 .a11y-fonts-mb-xl-auto,
  .a11y-font-2 .a11y-fonts-my-xl-auto {
    margin-bottom: auto !important;
    /* stylelint-disable-line */ }
  .a11y-font-1 .a11y-fonts-ml-xl-auto,
  .a11y-font-1 .a11y-fonts-mx-xl-auto,
  .a11y-font-2 .a11y-fonts-ml-xl-auto,
  .a11y-font-2 .a11y-fonts-mx-xl-auto {
    margin-left: auto !important;
    /* stylelint-disable-line */ } }

.a11y-font-0 .btn.btn-primary:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-primary:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-secondary:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-secondary:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-success:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-success:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-info:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-info:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-warning:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-warning:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-danger:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-danger:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-light:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-light:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-dark:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-dark:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-accent:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-accent:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-neutral:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-neutral:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-0 .btn.btn-tertiary:not(.btn-sm):not(.btn-lg), .a11y-font-0 .btn.btn-outline-tertiary:not(.btn-sm):not(.btn-lg) {
  padding-top: 18px;
  padding-bottom: 18px; }

.a11y-font-1 .btn.btn-primary:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-primary:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-secondary:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-secondary:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-success:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-success:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-info:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-info:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-warning:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-warning:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-danger:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-danger:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-light:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-light:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-dark:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-dark:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-accent:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-accent:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-neutral:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-neutral:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-1 .btn.btn-tertiary:not(.btn-sm):not(.btn-lg), .a11y-font-1 .btn.btn-outline-tertiary:not(.btn-sm):not(.btn-lg) {
  padding-top: 14px;
  padding-bottom: 14px; }

.a11y-font-2 .btn.btn-primary:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-primary:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-secondary:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-secondary:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-success:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-success:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-info:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-info:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-warning:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-warning:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-danger:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-danger:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-light:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-light:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-dark:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-dark:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-accent:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-accent:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-neutral:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-neutral:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-2 .btn.btn-tertiary:not(.btn-sm):not(.btn-lg), .a11y-font-2 .btn.btn-outline-tertiary:not(.btn-sm):not(.btn-lg) {
  padding-top: 10px;
  padding-bottom: 10px; }

.a11y-font-0 nav.navbar .nav-behavior {
  padding: 22.4px 14.4px; }

.a11y-font-0 nav.navbar .nav-separator {
  margin: 22.4px 0; }

.a11y-font-1 nav.navbar .nav-behavior {
  padding: 18.4px 14.4px; }

.a11y-font-1 nav.navbar .nav-separator {
  margin: 18.4px 0; }

.a11y-font-2 nav.navbar .nav-behavior {
  padding: 14.4px 14.4px; }

.a11y-font-2 nav.navbar .nav-separator {
  margin: 14.4px 0; }

.a11y-font-1 nav.navbar .navbar-nav .nav-behavior,
.a11y-font-2 nav.navbar .navbar-nav .nav-behavior {
  display: none; }

.a11y-font-1 nav.navbar .navbar-nav .nav-separator,
.a11y-font-2 nav.navbar .navbar-nav .nav-separator {
  display: none; }

.a11y-contrast body {
  color: #eeeeee;
  background-color: #212529; }

.a11y-contrast .bg-blue {
  background-color: #ff904c !important; }

.a11y-contrast a.bg-blue:hover, .a11y-contrast a.bg-blue:focus,
.a11y-contrast button.bg-blue:hover,
.a11y-contrast button.bg-blue:focus {
  background-color: #ff7019 !important; }

.a11y-contrast .text-blue {
  color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-blue {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-blue::before {
    background-color: #ff904c; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-blue > [class*="col-"]:not(:last-child)::after {
    border-left-color: #ff904c; } }

.a11y-contrast .border-blue {
  border-color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-blue::before {
  color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-blue::before {
  color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-blue::before {
  background-color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-blue::before {
  background-color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-blue-dark {
  background-color: #f5ecd2 !important; }

.a11y-contrast a.bg-blue-dark:hover, .a11y-contrast a.bg-blue-dark:focus,
.a11y-contrast button.bg-blue-dark:hover,
.a11y-contrast button.bg-blue-dark:focus {
  background-color: #ecdaa8 !important; }

.a11y-contrast .text-blue-dark {
  color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-blue-dark {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-blue-dark::before {
    background-color: #f5ecd2; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-blue-dark > [class*="col-"]:not(:last-child)::after {
    border-left-color: #f5ecd2; } }

.a11y-contrast .border-blue-dark {
  border-color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-blue-dark::before {
  color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-blue-dark::before {
  color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-blue-dark::before {
  background-color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-blue-dark::before {
  background-color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-blue-light {
  background-color: #574838 !important; }

.a11y-contrast a.bg-blue-light:hover, .a11y-contrast a.bg-blue-light:focus,
.a11y-contrast button.bg-blue-light:hover,
.a11y-contrast button.bg-blue-light:focus {
  background-color: #382e24 !important; }

.a11y-contrast .text-blue-light {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-blue-light {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-blue-light::before {
    background-color: #574838; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-blue-light > [class*="col-"]:not(:last-child)::after {
    border-left-color: #574838; } }

.a11y-contrast .border-blue-light {
  border-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-blue-light::before {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-blue-light::before {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-blue-light::before {
  background-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-blue-light::before {
  background-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-black {
  background-color: #eeeeee !important; }

.a11y-contrast a.bg-black:hover, .a11y-contrast a.bg-black:focus,
.a11y-contrast button.bg-black:hover,
.a11y-contrast button.bg-black:focus {
  background-color: #d5d4d4 !important; }

.a11y-contrast .text-black {
  color: #eeeeee !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-black {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-black::before {
    background-color: #eeeeee; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-black > [class*="col-"]:not(:last-child)::after {
    border-left-color: #eeeeee; } }

.a11y-contrast .border-black {
  border-color: #eeeeee !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-black::before {
  color: #eeeeee !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-black::before {
  color: #eeeeee !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-black::before {
  background-color: #eeeeee !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-black::before {
  background-color: #eeeeee !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray {
  background-color: #574838 !important; }

.a11y-contrast a.bg-gray:hover, .a11y-contrast a.bg-gray:focus,
.a11y-contrast button.bg-gray:hover,
.a11y-contrast button.bg-gray:focus {
  background-color: #382e24 !important; }

.a11y-contrast .text-gray {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray::before {
    background-color: #574838; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray > [class*="col-"]:not(:last-child)::after {
    border-left-color: #574838; } }

.a11y-contrast .border-gray {
  border-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-gray::before {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-gray::before {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-gray::before {
  background-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-gray::before {
  background-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-red {
  background-color: #019a9a !important; }

.a11y-contrast a.bg-red:hover, .a11y-contrast a.bg-red:focus,
.a11y-contrast button.bg-red:hover,
.a11y-contrast button.bg-red:focus {
  background-color: #016767 !important; }

.a11y-contrast .text-red {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-red {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-red::before {
    background-color: #019a9a; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-red > [class*="col-"]:not(:last-child)::after {
    border-left-color: #019a9a; } }

.a11y-contrast .border-red {
  border-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-red::before {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-red::before {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-red::before {
  background-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-red::before {
  background-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-white {
  background-color: black !important; }

.a11y-contrast a.bg-white:hover, .a11y-contrast a.bg-white:focus,
.a11y-contrast button.bg-white:hover,
.a11y-contrast button.bg-white:focus {
  background-color: black !important; }

.a11y-contrast .text-white {
  color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-white {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-white::before {
    background-color: black; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-white > [class*="col-"]:not(:last-child)::after {
    border-left-color: black; } }

.a11y-contrast .border-white {
  border-color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-white::before {
  color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-white::before {
  color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-white::before {
  background-color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-white::before {
  background-color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-indigo {
  background-color: #99ef0d !important; }

.a11y-contrast a.bg-indigo:hover, .a11y-contrast a.bg-indigo:focus,
.a11y-contrast button.bg-indigo:hover,
.a11y-contrast button.bg-indigo:focus {
  background-color: #7abf0a !important; }

.a11y-contrast .text-indigo {
  color: #99ef0d !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-indigo {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-indigo::before {
    background-color: #99ef0d; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-indigo > [class*="col-"]:not(:last-child)::after {
    border-left-color: #99ef0d; } }

.a11y-contrast .border-indigo {
  border-color: #99ef0d !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-indigo::before {
  color: #99ef0d !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-indigo::before {
  color: #99ef0d !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-indigo::before {
  background-color: #99ef0d !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-indigo::before {
  background-color: #99ef0d !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-purple {
  background-color: #99cc33 !important; }

.a11y-contrast a.bg-purple:hover, .a11y-contrast a.bg-purple:focus,
.a11y-contrast button.bg-purple:hover,
.a11y-contrast button.bg-purple:focus {
  background-color: #7aa329 !important; }

.a11y-contrast .text-purple {
  color: #99cc33 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-purple {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-purple::before {
    background-color: #99cc33; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-purple > [class*="col-"]:not(:last-child)::after {
    border-left-color: #99cc33; } }

.a11y-contrast .border-purple {
  border-color: #99cc33 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-purple::before {
  color: #99cc33 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-purple::before {
  color: #99cc33 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-purple::before {
  background-color: #99cc33 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-purple::before {
  background-color: #99cc33 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-pink {
  background-color: #17c173 !important; }

.a11y-contrast a.bg-pink:hover, .a11y-contrast a.bg-pink:focus,
.a11y-contrast button.bg-pink:hover,
.a11y-contrast button.bg-pink:focus {
  background-color: #129358 !important; }

.a11y-contrast .text-pink {
  color: #17c173 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-pink {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-pink::before {
    background-color: #17c173; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-pink > [class*="col-"]:not(:last-child)::after {
    border-left-color: #17c173; } }

.a11y-contrast .border-pink {
  border-color: #17c173 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-pink::before {
  color: #17c173 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-pink::before {
  color: #17c173 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-pink::before {
  background-color: #17c173 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-pink::before {
  background-color: #17c173 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-orange {
  background-color: #0281eb !important; }

.a11y-contrast a.bg-orange:hover, .a11y-contrast a.bg-orange:focus,
.a11y-contrast button.bg-orange:hover,
.a11y-contrast button.bg-orange:focus {
  background-color: #0265b8 !important; }

.a11y-contrast .text-orange {
  color: #0281eb !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-orange {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-orange::before {
    background-color: #0281eb; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-orange > [class*="col-"]:not(:last-child)::after {
    border-left-color: #0281eb; } }

.a11y-contrast .border-orange {
  border-color: #0281eb !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-orange::before {
  color: #0281eb !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-orange::before {
  color: #0281eb !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-orange::before {
  background-color: #0281eb !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-orange::before {
  background-color: #0281eb !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-yellow {
  background-color: #0d38d7 !important; }

.a11y-contrast a.bg-yellow:hover, .a11y-contrast a.bg-yellow:focus,
.a11y-contrast button.bg-yellow:hover,
.a11y-contrast button.bg-yellow:focus {
  background-color: #0a2ba7 !important; }

.a11y-contrast .text-yellow {
  color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-yellow {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-yellow::before {
    background-color: #0d38d7; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-yellow > [class*="col-"]:not(:last-child)::after {
    border-left-color: #0d38d7; } }

.a11y-contrast .border-yellow {
  border-color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-yellow::before {
  color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-yellow::before {
  color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-yellow::before {
  background-color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-yellow::before {
  background-color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-green {
  background-color: #d28e83 !important; }

.a11y-contrast a.bg-green:hover, .a11y-contrast a.bg-green:focus,
.a11y-contrast button.bg-green:hover,
.a11y-contrast button.bg-green:focus {
  background-color: #c46c5e !important; }

.a11y-contrast .text-green {
  color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-green {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-green::before {
    background-color: #d28e83; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-green > [class*="col-"]:not(:last-child)::after {
    border-left-color: #d28e83; } }

.a11y-contrast .border-green {
  border-color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-green::before {
  color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-green::before {
  color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-green::before {
  background-color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-green::before {
  background-color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-teal {
  background-color: #df3668 !important; }

.a11y-contrast a.bg-teal:hover, .a11y-contrast a.bg-teal:focus,
.a11y-contrast button.bg-teal:hover,
.a11y-contrast button.bg-teal:focus {
  background-color: #c31f50 !important; }

.a11y-contrast .text-teal {
  color: #df3668 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-teal {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-teal::before {
    background-color: #df3668; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-teal > [class*="col-"]:not(:last-child)::after {
    border-left-color: #df3668; } }

.a11y-contrast .border-teal {
  border-color: #df3668 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-teal::before {
  color: #df3668 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-teal::before {
  color: #df3668 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-teal::before {
  background-color: #df3668 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-teal::before {
  background-color: #df3668 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-cyan {
  background-color: #e85d47 !important; }

.a11y-contrast a.bg-cyan:hover, .a11y-contrast a.bg-cyan:focus,
.a11y-contrast button.bg-cyan:hover,
.a11y-contrast button.bg-cyan:focus {
  background-color: #e0371c !important; }

.a11y-contrast .text-cyan {
  color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-cyan {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-cyan::before {
    background-color: #e85d47; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-cyan > [class*="col-"]:not(:last-child)::after {
    border-left-color: #e85d47; } }

.a11y-contrast .border-cyan {
  border-color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-cyan::before {
  color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-cyan::before {
  color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-cyan::before {
  background-color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-cyan::before {
  background-color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-dark {
  background-color: #cbc5bf !important; }

.a11y-contrast a.bg-gray-dark:hover, .a11y-contrast a.bg-gray-dark:focus,
.a11y-contrast button.bg-gray-dark:hover,
.a11y-contrast button.bg-gray-dark:focus {
  background-color: #b4aca3 !important; }

.a11y-contrast .text-gray-dark {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-dark {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-dark::before {
    background-color: #cbc5bf; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-dark > [class*="col-"]:not(:last-child)::after {
    border-left-color: #cbc5bf; } }

.a11y-contrast .border-gray-dark {
  border-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-before-gray-dark::before {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-gray-dark::before {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-gray-dark::before {
  background-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-gray-dark::before {
  background-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-a {
  background-color: #b5b5b5 !important; }

.a11y-contrast a.bg-gray-a:hover, .a11y-contrast a.bg-gray-a:focus,
.a11y-contrast button.bg-gray-a:hover,
.a11y-contrast button.bg-gray-a:focus {
  background-color: #9c9b9b !important; }

.a11y-contrast .text-gray-a {
  color: #b5b5b5 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-a {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-a::before {
    background-color: #b5b5b5; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-a > [class*="col-"]:not(:last-child)::after {
    border-left-color: #b5b5b5; } }

.a11y-contrast .border-gray-a {
  border-color: #b5b5b5 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-a::before {
  color: #b5b5b5 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-a::before {
  color: #b5b5b5 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-a::before {
  background-color: #b5b5b5 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-a::before {
  background-color: #b5b5b5 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-b {
  background-color: #757575 !important; }

.a11y-contrast a.bg-gray-b:hover, .a11y-contrast a.bg-gray-b:focus,
.a11y-contrast button.bg-gray-b:hover,
.a11y-contrast button.bg-gray-b:focus {
  background-color: #5c5b5b !important; }

.a11y-contrast .text-gray-b {
  color: #757575 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-b {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-b::before {
    background-color: #757575; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-b > [class*="col-"]:not(:last-child)::after {
    border-left-color: #757575; } }

.a11y-contrast .border-gray-b {
  border-color: #757575 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-b::before {
  color: #757575 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-b::before {
  color: #757575 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-b::before {
  background-color: #757575 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-b::before {
  background-color: #757575 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-c {
  background-color: #111111 !important; }

.a11y-contrast a.bg-gray-c:hover, .a11y-contrast a.bg-gray-c:focus,
.a11y-contrast button.bg-gray-c:hover,
.a11y-contrast button.bg-gray-c:focus {
  background-color: black !important; }

.a11y-contrast .text-gray-c {
  color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-c {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-c::before {
    background-color: #111111; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-c > [class*="col-"]:not(:last-child)::after {
    border-left-color: #111111; } }

.a11y-contrast .border-gray-c {
  border-color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-c::before {
  color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-c::before {
  color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-c::before {
  background-color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-c::before {
  background-color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-100 {
  background-color: #070605 !important; }

.a11y-contrast a.bg-gray-100:hover, .a11y-contrast a.bg-gray-100:focus,
.a11y-contrast button.bg-gray-100:hover,
.a11y-contrast button.bg-gray-100:focus {
  background-color: black !important; }

.a11y-contrast .text-gray-100 {
  color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-100 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-100::before {
    background-color: #070605; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-100 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #070605; } }

.a11y-contrast .border-gray-100 {
  border-color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-100::before {
  color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-100::before {
  color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-100::before {
  background-color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-100::before {
  background-color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-200 {
  background-color: #161310 !important; }

.a11y-contrast a.bg-gray-200:hover, .a11y-contrast a.bg-gray-200:focus,
.a11y-contrast button.bg-gray-200:hover,
.a11y-contrast button.bg-gray-200:focus {
  background-color: black !important; }

.a11y-contrast .text-gray-200 {
  color: #161310 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-200 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-200::before {
    background-color: #161310; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-200 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #161310; } }

.a11y-contrast .border-gray-200 {
  border-color: #161310 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-200::before {
  color: #161310 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-200::before {
  color: #161310 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-200::before {
  background-color: #161310 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-200::before {
  background-color: #161310 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-300 {
  background-color: #211d19 !important; }

.a11y-contrast a.bg-gray-300:hover, .a11y-contrast a.bg-gray-300:focus,
.a11y-contrast button.bg-gray-300:hover,
.a11y-contrast button.bg-gray-300:focus {
  background-color: #040403 !important; }

.a11y-contrast .text-gray-300 {
  color: #211d19 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-300 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-300::before {
    background-color: #211d19; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-300 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #211d19; } }

.a11y-contrast .border-gray-300 {
  border-color: #211d19 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-300::before {
  color: #211d19 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-300::before {
  color: #211d19 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-300::before {
  background-color: #211d19 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-300::before {
  background-color: #211d19 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-400 {
  background-color: #312b25 !important; }

.a11y-contrast a.bg-gray-400:hover, .a11y-contrast a.bg-gray-400:focus,
.a11y-contrast button.bg-gray-400:hover,
.a11y-contrast button.bg-gray-400:focus {
  background-color: #14120f !important; }

.a11y-contrast .text-gray-400 {
  color: #312b25 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-400 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-400::before {
    background-color: #312b25; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-400 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #312b25; } }

.a11y-contrast .border-gray-400 {
  border-color: #312b25 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-400::before {
  color: #312b25 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-400::before {
  color: #312b25 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-400::before {
  background-color: #312b25 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-400::before {
  background-color: #312b25 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-500 {
  background-color: #524a42 !important; }

.a11y-contrast a.bg-gray-500:hover, .a11y-contrast a.bg-gray-500:focus,
.a11y-contrast button.bg-gray-500:hover,
.a11y-contrast button.bg-gray-500:focus {
  background-color: #36312b !important; }

.a11y-contrast .text-gray-500 {
  color: #524a42 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-500 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-500::before {
    background-color: #524a42; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-500 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #524a42; } }

.a11y-contrast .border-gray-500 {
  border-color: #524a42 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-500::before {
  color: #524a42 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-500::before {
  color: #524a42 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-500::before {
  background-color: #524a42 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-500::before {
  background-color: #524a42 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-600 {
  background-color: #938a82 !important; }

.a11y-contrast a.bg-gray-600:hover, .a11y-contrast a.bg-gray-600:focus,
.a11y-contrast button.bg-gray-600:hover,
.a11y-contrast button.bg-gray-600:focus {
  background-color: #797169 !important; }

.a11y-contrast .text-gray-600 {
  color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-600 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-600::before {
    background-color: #938a82; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-600 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #938a82; } }

.a11y-contrast .border-gray-600 {
  border-color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-600::before {
  color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-600::before {
  color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-600::before {
  background-color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-600::before {
  background-color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-700 {
  background-color: #b6afa8 !important; }

.a11y-contrast a.bg-gray-700:hover, .a11y-contrast a.bg-gray-700:focus,
.a11y-contrast button.bg-gray-700:hover,
.a11y-contrast button.bg-gray-700:focus {
  background-color: #9f968c !important; }

.a11y-contrast .text-gray-700 {
  color: #b6afa8 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-700 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-700::before {
    background-color: #b6afa8; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-700 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #b6afa8; } }

.a11y-contrast .border-gray-700 {
  border-color: #b6afa8 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-700::before {
  color: #b6afa8 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-700::before {
  color: #b6afa8 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-700::before {
  background-color: #b6afa8 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-700::before {
  background-color: #b6afa8 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-800 {
  background-color: #cbc5bf !important; }

.a11y-contrast a.bg-gray-800:hover, .a11y-contrast a.bg-gray-800:focus,
.a11y-contrast button.bg-gray-800:hover,
.a11y-contrast button.bg-gray-800:focus {
  background-color: #b4aca3 !important; }

.a11y-contrast .text-gray-800 {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-800 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-800::before {
    background-color: #cbc5bf; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-800 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #cbc5bf; } }

.a11y-contrast .border-gray-800 {
  border-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-800::before {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-800::before {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-800::before {
  background-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-800::before {
  background-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-900 {
  background-color: #dedad6 !important; }

.a11y-contrast a.bg-gray-900:hover, .a11y-contrast a.bg-gray-900:focus,
.a11y-contrast button.bg-gray-900:hover,
.a11y-contrast button.bg-gray-900:focus {
  background-color: #c7c1ba !important; }

.a11y-contrast .text-gray-900 {
  color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-layer-gray-900 {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-gray-900::before {
    background-color: #dedad6; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-gray-900 > [class*="col-"]:not(:last-child)::after {
    border-left-color: #dedad6; } }

.a11y-contrast .border-gray-900 {
  border-color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-before-900::before {
  color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-gray-after-900::before {
  color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-before-900::before {
  background-color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-gray-after-900::before {
  background-color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-accent {
  background-color: #574838 !important; }

.a11y-contrast a.bg-accent:hover, .a11y-contrast a.bg-accent:focus,
.a11y-contrast button.bg-accent:hover,
.a11y-contrast button.bg-accent:focus {
  background-color: #382e24 !important; }

.a11y-contrast .alert-accent {
  color: #606770;
  background-color: #eef1f4;
  border-color: #e7ebef; }
  .a11y-contrast .alert-accent hr {
    border-top-color: #d8dee5; }
  .a11y-contrast .alert-accent .alert-link {
    color: #484e55; }

.a11y-contrast .bg-layer-accent {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-accent::before {
    background-color: #574838; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-accent > [class*="col-"]:not(:last-child)::after {
    border-left-color: #574838; } }

.a11y-contrast .text-before-accent::before {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-accent::before {
  color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-accent::before {
  background-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-accent::before {
  background-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-accent {
  border-color: #574838 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-accent {
  color: #574838 !important; }

.a11y-contrast a.text-accent:hover, .a11y-contrast a.text-accent:focus {
  color: #28211a !important; }

.a11y-contrast .bg-neutral {
  background-color: #111111 !important; }

.a11y-contrast a.bg-neutral:hover, .a11y-contrast a.bg-neutral:focus,
.a11y-contrast button.bg-neutral:hover,
.a11y-contrast button.bg-neutral:focus {
  background-color: black !important; }

.a11y-contrast .alert-neutral {
  color: #848484;
  background-color: #fcfcfc;
  border-color: #fafafa; }
  .a11y-contrast .alert-neutral hr {
    border-top-color: #ededed; }
  .a11y-contrast .alert-neutral .alert-link {
    color: #6b6a6a; }

.a11y-contrast .bg-layer-neutral {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-neutral::before {
    background-color: #111111; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-neutral > [class*="col-"]:not(:last-child)::after {
    border-left-color: #111111; } }

.a11y-contrast .text-before-neutral::before {
  color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-neutral::before {
  color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-neutral::before {
  background-color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-neutral::before {
  background-color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-neutral {
  border-color: #111111 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-neutral {
  color: #111111 !important; }

.a11y-contrast a.text-neutral:hover, .a11y-contrast a.text-neutral:focus {
  color: black !important; }

.a11y-contrast .bg-primary {
  background-color: #ff904c !important; }

.a11y-contrast a.bg-primary:hover, .a11y-contrast a.bg-primary:focus,
.a11y-contrast button.bg-primary:hover,
.a11y-contrast button.bg-primary:focus {
  background-color: #ff7019 !important; }

.a11y-contrast .alert-primary {
  color: #084265;
  background-color: #cce2f0;
  border-color: #b8d7ea; }
  .a11y-contrast .alert-primary hr {
    border-top-color: #a4cce4; }
  .a11y-contrast .alert-primary .alert-link {
    color: #042336; }

.a11y-contrast .bg-layer-primary {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-primary::before {
    background-color: #ff904c; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-primary > [class*="col-"]:not(:last-child)::after {
    border-left-color: #ff904c; } }

.a11y-contrast .text-before-primary::before {
  color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-primary::before {
  color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-primary::before {
  background-color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-primary::before {
  background-color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-primary {
  border-color: #ff904c !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-primary {
  color: #ff904c !important; }

.a11y-contrast a.text-primary:hover, .a11y-contrast a.text-primary:focus {
  color: #ff6100 !important; }

.a11y-contrast .bg-secondary {
  background-color: #019a9a !important; }

.a11y-contrast a.bg-secondary:hover, .a11y-contrast a.bg-secondary:focus,
.a11y-contrast button.bg-secondary:hover,
.a11y-contrast button.bg-secondary:focus {
  background-color: #016767 !important; }

.a11y-contrast .alert-secondary {
  color: #8c3d3d;
  background-color: #ffe0e0;
  border-color: #ffd4d4; }
  .a11y-contrast .alert-secondary hr {
    border-top-color: #ffbbbb; }
  .a11y-contrast .alert-secondary .alert-link {
    color: #682e2e; }

.a11y-contrast .bg-layer-secondary {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-secondary::before {
    background-color: #019a9a; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-secondary > [class*="col-"]:not(:last-child)::after {
    border-left-color: #019a9a; } }

.a11y-contrast .text-before-secondary::before {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-secondary::before {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-secondary::before {
  background-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-secondary::before {
  background-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-secondary {
  border-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-secondary {
  color: #019a9a !important; }

.a11y-contrast a.text-secondary:hover, .a11y-contrast a.text-secondary:focus {
  color: #014e4e !important; }

.a11y-contrast .bg-tertiary {
  background-color: #f5ecd2 !important; }

.a11y-contrast a.bg-tertiary:hover, .a11y-contrast a.bg-tertiary:focus,
.a11y-contrast button.bg-tertiary:hover,
.a11y-contrast button.bg-tertiary:focus {
  background-color: #ecdaa8 !important; }

.a11y-contrast .alert-tertiary {
  color: #0d1220;
  background-color: #ced0d5;
  border-color: #babdc4; }
  .a11y-contrast .alert-tertiary hr {
    border-top-color: #acb0b8; }
  .a11y-contrast .alert-tertiary .alert-link {
    color: black; }

.a11y-contrast .bg-layer-tertiary {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-tertiary::before {
    background-color: #f5ecd2; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-tertiary > [class*="col-"]:not(:last-child)::after {
    border-left-color: #f5ecd2; } }

.a11y-contrast .text-before-tertiary::before {
  color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-tertiary::before {
  color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-tertiary::before {
  background-color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-tertiary::before {
  background-color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-tertiary {
  border-color: #f5ecd2 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-tertiary {
  color: #f5ecd2 !important; }

.a11y-contrast a.text-tertiary:hover, .a11y-contrast a.text-tertiary:focus {
  color: #e7d293 !important; }

.a11y-contrast .bg-success {
  background-color: #d28e83 !important; }

.a11y-contrast a.bg-success:hover, .a11y-contrast a.bg-success:focus,
.a11y-contrast button.bg-success:hover,
.a11y-contrast button.bg-success:focus {
  background-color: #c46c5e !important; }

.a11y-contrast .alert-success {
  color: #204349;
  background-color: #d5e3e5;
  border-color: #c4d7da; }
  .a11y-contrast .alert-success hr {
    border-top-color: #b4ccd0; }
  .a11y-contrast .alert-success .alert-link {
    color: #102226; }

.a11y-contrast .bg-layer-success {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-success::before {
    background-color: #d28e83; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-success > [class*="col-"]:not(:last-child)::after {
    border-left-color: #d28e83; } }

.a11y-contrast .text-before-success::before {
  color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-success::before {
  color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-success::before {
  background-color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-success::before {
  background-color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-success {
  border-color: #d28e83 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-success {
  color: #d28e83 !important; }

.a11y-contrast a.text-success:hover, .a11y-contrast a.text-success:focus {
  color: #be5b4b !important; }

.a11y-contrast .bg-info {
  background-color: #e85d47 !important; }

.a11y-contrast a.bg-info:hover, .a11y-contrast a.bg-info:focus,
.a11y-contrast button.bg-info:hover,
.a11y-contrast button.bg-info:focus {
  background-color: #e0371c !important; }

.a11y-contrast .alert-info {
  color: #145c68;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .a11y-contrast .alert-info hr {
    border-top-color: #abdde5; }
  .a11y-contrast .alert-info .alert-link {
    color: #0c363d; }

.a11y-contrast .bg-layer-info {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-info::before {
    background-color: #e85d47; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-info > [class*="col-"]:not(:last-child)::after {
    border-left-color: #e85d47; } }

.a11y-contrast .text-before-info::before {
  color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-info::before {
  color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-info::before {
  background-color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-info::before {
  background-color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-info {
  border-color: #e85d47 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-info {
  color: #e85d47 !important; }

.a11y-contrast a.text-info:hover, .a11y-contrast a.text-info:focus {
  color: #c93119 !important; }

.a11y-contrast .bg-warning {
  background-color: #0d38d7 !important; }

.a11y-contrast a.bg-warning:hover, .a11y-contrast a.bg-warning:focus,
.a11y-contrast button.bg-warning:hover,
.a11y-contrast button.bg-warning:focus {
  background-color: #0a2ba7 !important; }

.a11y-contrast .alert-warning {
  color: #86701d;
  background-color: #fcf4d4;
  border-color: #fbefc3; }
  .a11y-contrast .alert-warning hr {
    border-top-color: #f9e9ab; }
  .a11y-contrast .alert-warning .alert-link {
    color: #5c4d14; }

.a11y-contrast .bg-layer-warning {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-warning::before {
    background-color: #0d38d7; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-warning > [class*="col-"]:not(:last-child)::after {
    border-left-color: #0d38d7; } }

.a11y-contrast .text-before-warning::before {
  color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-warning::before {
  color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-warning::before {
  background-color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-warning::before {
  background-color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-warning {
  border-color: #0d38d7 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-warning {
  color: #0d38d7 !important; }

.a11y-contrast a.text-warning:hover, .a11y-contrast a.text-warning:focus {
  color: #09258f !important; }

.a11y-contrast .bg-danger {
  background-color: #019a9a !important; }

.a11y-contrast a.bg-danger:hover, .a11y-contrast a.bg-danger:focus,
.a11y-contrast button.bg-danger:hover,
.a11y-contrast button.bg-danger:focus {
  background-color: #016767 !important; }

.a11y-contrast .alert-danger {
  color: #8c3d3d;
  background-color: #ffe0e0;
  border-color: #ffd4d4; }
  .a11y-contrast .alert-danger hr {
    border-top-color: #ffbbbb; }
  .a11y-contrast .alert-danger .alert-link {
    color: #682e2e; }

.a11y-contrast .bg-layer-danger {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-danger::before {
    background-color: #019a9a; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-danger > [class*="col-"]:not(:last-child)::after {
    border-left-color: #019a9a; } }

.a11y-contrast .text-before-danger::before {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-danger::before {
  color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-danger::before {
  background-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-danger::before {
  background-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-danger {
  border-color: #019a9a !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-danger {
  color: #019a9a !important; }

.a11y-contrast a.text-danger:hover, .a11y-contrast a.text-danger:focus {
  color: #014e4e !important; }

.a11y-contrast .bg-light {
  background-color: #070605 !important; }

.a11y-contrast a.bg-light:hover, .a11y-contrast a.bg-light:focus,
.a11y-contrast button.bg-light:hover,
.a11y-contrast button.bg-light:focus {
  background-color: black !important; }

.a11y-contrast .alert-light {
  color: #898a8a;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .a11y-contrast .alert-light hr {
    border-top-color: #ececf6; }
  .a11y-contrast .alert-light .alert-link {
    color: #707070; }

.a11y-contrast .bg-layer-light {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-light::before {
    background-color: #070605; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-light > [class*="col-"]:not(:last-child)::after {
    border-left-color: #070605; } }

.a11y-contrast .text-before-light::before {
  color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-light::before {
  color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-light::before {
  background-color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-light::before {
  background-color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-light {
  border-color: #070605 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-light {
  color: #070605 !important; }

.a11y-contrast a.text-light:hover, .a11y-contrast a.text-light:focus {
  color: black !important; }

.a11y-contrast .bg-dark {
  background-color: #cbc5bf !important; }

.a11y-contrast a.bg-dark:hover, .a11y-contrast a.bg-dark:focus,
.a11y-contrast button.bg-dark:hover,
.a11y-contrast button.bg-dark:focus {
  background-color: #b4aca3 !important; }

.a11y-contrast .alert-dark {
  color: #232629;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .a11y-contrast .alert-dark hr {
    border-top-color: #b9bbbe; }
  .a11y-contrast .alert-dark .alert-link {
    color: #0c0d0d; }

.a11y-contrast .bg-layer-dark {
  background-color: transparent !important;
  /* stylelint-disable-line */ }
  .a11y-contrast .bg-layer-dark::before {
    background-color: #cbc5bf; }

@media (min-width: 992px) {
  .a11y-contrast .col-separator-dark > [class*="col-"]:not(:last-child)::after {
    border-left-color: #cbc5bf; } }

.a11y-contrast .text-before-dark::before {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-after-dark::before {
  color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-before-dark::before {
  background-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .bg-after-dark::before {
  background-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .border-dark {
  border-color: #cbc5bf !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-dark {
  color: #cbc5bf !important; }

.a11y-contrast a.text-dark:hover, .a11y-contrast a.text-dark:focus {
  color: #a99f95 !important; }

.a11y-contrast .text-white {
  color: black !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-body {
  color: #dedad6 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-muted {
  color: #938a82 !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-black-50 {
  color: rgba(238, 238, 238, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-contrast .text-white-50 {
  color: rgba(0, 0, 0, 0.5) !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-none {
  display: none !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-inline {
  display: inline !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-inline-block {
  display: inline-block !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-block {
  display: block !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-table {
  display: table !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-table-row {
  display: table-row !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-table-cell {
  display: table-cell !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-flex {
  display: flex !important;
  /* stylelint-disable-line */ }

.a11y-contrast .a11y-contrast-d-inline-flex {
  display: inline-flex !important;
  /* stylelint-disable-line */ }

.a11y-contrast p {
  color: #b5b5b5; }

.a11y-contrast a {
  color: #eeeeee; }
  .a11y-contrast a:hover {
    color: #eeeeee; }

.a11y-contrast .cl-close-line::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-pause::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-play::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-logout::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-download::before {
  color: #eeeeee;
  content: ""; }

.a11y-contrast .cl-breadcrumb-arrow::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-arrow-line::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-close::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-decrease-text::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-increase-text::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-contrast::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-sound::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-youtube::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-instagram::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-messenger::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-facebook::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-twitter::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-arrow::before {
  color: #574838;
  content: ""; }

.a11y-contrast .cl-telephone::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-grant::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-bonuses::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-burger::before {
  color: #eeeeee;
  content: ""; }

.a11y-contrast .cl-certificates::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-key-unique::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-meet-authorities::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-filter::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-procedures::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-login::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-login-fill::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-question::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-doctor::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-search::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-woman::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-health::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-modules::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-navigation::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-recent-news::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-mobile-offices::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-pensions::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-social-networks::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-subsidy-of-cesantia::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-subsidies::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-accessibility::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-branches::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-arrow-right::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-add-circle-line::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-add-circle-fill::before {
  color: #ff904c;
  content: ""; }

.a11y-contrast .cl-m-arrow-right::before {
  color: #eeeeee;
  content: ""; }

.a11y-contrast .cl-m-arrow-down::before {
  color: #eeeeee;
  content: ""; }

.a11y-contrast .cl-m-arrow-up::before {
  color: #eeeeee;
  content: ""; }

.a11y-contrast .cl-img-preview .path1::before {
  color: #111111; }

.a11y-contrast .cl-img-preview .path2::before {
  color: #757575; }

.a11y-contrast .toolbar .toolbar-btn {
  border-color: #eeeeee; }
  .a11y-contrast .toolbar .toolbar-btn.toolbar-toggler .toolbar-behavior-off {
    color: #eeeeee; }
  .a11y-contrast .toolbar .toolbar-btn .cl::before {
    color: #eeeeee; }
  .a11y-contrast .toolbar .toolbar-btn:hover {
    border-color: #ff904c; }
    .a11y-contrast .toolbar .toolbar-btn:hover .toolbar-behavior-off {
      color: #ff904c; }
    .a11y-contrast .toolbar .toolbar-btn:hover .cl::before {
      color: #ff904c; }

.a11y-contrast .search .form-control {
  background-color: black; }

.a11y-contrast .search .search-action .btn {
  background-color: black;
  border-color: #eeeeee; }
  .a11y-contrast .search .search-action .btn:hover {
    color: #ff904c;
    background-color: black;
    border-color: #eeeeee; }

.a11y-contrast .card {
  background-color: #212529; }

.a11y-contrast .card-featured {
  border-color: #574838; }
  .a11y-contrast .card-featured:hover {
    color: #ff904c;
    text-decoration: none;
    border: 1px solid #ff904c; }
    .a11y-contrast .card-featured:hover::before {
      color: #ff904c; }

.a11y-contrast .card-links .card-links-body ul.list-unstyled li a {
  color: #b5b5b5; }

.a11y-contrast .card-links .card-links-body ul.list-inline li a, .a11y-contrast .card-links .card-links-body ul.list-inline li::after {
  color: #b5b5b5; }

.a11y-contrast .card-links .card-links-body p {
  color: #b5b5b5; }

.a11y-contrast .card-data-list .card-data {
  border-color: #574838; }
  .a11y-contrast .card-data-list .card-data:hover {
    color: black;
    background-color: #ff904c !important;
    /* stylelint-disable-line */ }
    .a11y-contrast .card-data-list .card-data:hover p {
      color: black; }
  @media (min-width: 992px) {
    .a11y-contrast .card-data-list .card-data:not(:last-child) {
      border-color: #574838; } }
  .a11y-contrast .card-data-list .card-data:first-child {
    background-color: #0a0908; }

.a11y-contrast .card-news {
  border-color: #574838; }
  .a11y-contrast .card-news:hover {
    color: #ff904c;
    border: 1px solid #ff904c; }
    .a11y-contrast .card-news:hover a {
      color: #ff904c; }
  .a11y-contrast .card-news .card-news-body > a {
    display: block;
    height: 4rem;
    margin: 0;
    overflow: hidden; }
    @media (min-width: 768px) {
      .a11y-contrast .card-news .card-news-body > a {
        height: 5.5rem; } }

.a11y-contrast .card-informative {
  border-color: #574838; }
  .a11y-contrast .card-informative .card-informative-body > a {
    color: #b5b5b5; }

.a11y-contrast .card-featured-news .card-featured-news-body a {
  color: #b5b5b5; }

.a11y-contrast .btn.btn-accent {
  color: #fff;
  background-color: #574838;
  border-color: #574838; }
  .a11y-contrast .btn.btn-accent:hover {
    color: #fff;
    background-color: #403529;
    border-color: #382e24; }
  .a11y-contrast .btn.btn-accent:focus, .a11y-contrast .btn.btn-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(112, 99, 86, 0.5); }
  .a11y-contrast .btn.btn-accent.disabled, .a11y-contrast .btn.btn-accent:disabled {
    color: #fff;
    background-color: #574838;
    border-color: #574838; }
  .a11y-contrast .btn.btn-accent:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-accent:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #382e24;
    border-color: #30281f; }
    .a11y-contrast .btn.btn-accent:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-accent:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(112, 99, 86, 0.5); }

.a11y-contrast .btn.btn-neutral {
  color: #fff;
  background-color: #111111;
  border-color: #111111; }
  .a11y-contrast .btn.btn-neutral:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .a11y-contrast .btn.btn-neutral:focus, .a11y-contrast .btn.btn-neutral.focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 53, 53, 0.5); }
  .a11y-contrast .btn.btn-neutral.disabled, .a11y-contrast .btn.btn-neutral:disabled {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }
  .a11y-contrast .btn.btn-neutral:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-neutral:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-neutral.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .a11y-contrast .btn.btn-neutral:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(53, 53, 53, 0.5); }

.a11y-contrast .btn.btn-primary {
  color: #212529;
  background-color: #ff904c;
  border-color: #ff904c; }
  .a11y-contrast .btn.btn-primary:hover {
    color: #212529;
    background-color: #ff7826;
    border-color: #ff7019; }
  .a11y-contrast .btn.btn-primary:focus, .a11y-contrast .btn.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 128, 71, 0.5); }
  .a11y-contrast .btn.btn-primary.disabled, .a11y-contrast .btn.btn-primary:disabled {
    color: #212529;
    background-color: #ff904c;
    border-color: #ff904c; }
  .a11y-contrast .btn.btn-primary:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-primary:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff7019;
    border-color: #ff680c; }
    .a11y-contrast .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 128, 71, 0.5); }

.a11y-contrast .btn.btn-secondary {
  color: #fff;
  background-color: #019a9a;
  border-color: #019a9a; }
  .a11y-contrast .btn.btn-secondary:hover {
    color: #fff;
    background-color: #017474;
    border-color: #016767; }
  .a11y-contrast .btn.btn-secondary:focus, .a11y-contrast .btn.btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 169, 169, 0.5); }
  .a11y-contrast .btn.btn-secondary.disabled, .a11y-contrast .btn.btn-secondary:disabled {
    color: #fff;
    background-color: #019a9a;
    border-color: #019a9a; }
  .a11y-contrast .btn.btn-secondary:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #016767;
    border-color: #015b5b; }
    .a11y-contrast .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 169, 169, 0.5); }

.a11y-contrast .btn.btn-tertiary {
  color: #212529;
  background-color: #f5ecd2;
  border-color: #f5ecd2; }
  .a11y-contrast .btn.btn-tertiary:hover {
    color: #212529;
    background-color: #eedfb3;
    border-color: #ecdaa8; }
  .a11y-contrast .btn.btn-tertiary:focus, .a11y-contrast .btn.btn-tertiary.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 206, 185, 0.5); }
  .a11y-contrast .btn.btn-tertiary.disabled, .a11y-contrast .btn.btn-tertiary:disabled {
    color: #212529;
    background-color: #f5ecd2;
    border-color: #f5ecd2; }
  .a11y-contrast .btn.btn-tertiary:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-tertiary.dropdown-toggle {
    color: #212529;
    background-color: #ecdaa8;
    border-color: #e9d69e; }
    .a11y-contrast .btn.btn-tertiary:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 206, 185, 0.5); }

.a11y-contrast .btn.btn-success {
  color: #212529;
  background-color: #d28e83;
  border-color: #d28e83; }
  .a11y-contrast .btn.btn-success:hover {
    color: #fff;
    background-color: #c87467;
    border-color: #c46c5e; }
  .a11y-contrast .btn.btn-success:focus, .a11y-contrast .btn.btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(183, 126, 118, 0.5); }
  .a11y-contrast .btn.btn-success.disabled, .a11y-contrast .btn.btn-success:disabled {
    color: #212529;
    background-color: #d28e83;
    border-color: #d28e83; }
  .a11y-contrast .btn.btn-success:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-success:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #c46c5e;
    border-color: #c16354; }
    .a11y-contrast .btn.btn-success:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(183, 126, 118, 0.5); }

.a11y-contrast .btn.btn-info {
  color: #fff;
  background-color: #e85d47;
  border-color: #e85d47; }
  .a11y-contrast .btn.btn-info:hover {
    color: #fff;
    background-color: #e43f25;
    border-color: #e0371c; }
  .a11y-contrast .btn.btn-info:focus, .a11y-contrast .btn.btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 117, 99, 0.5); }
  .a11y-contrast .btn.btn-info.disabled, .a11y-contrast .btn.btn-info:disabled {
    color: #fff;
    background-color: #e85d47;
    border-color: #e85d47; }
  .a11y-contrast .btn.btn-info:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-info:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #e0371c;
    border-color: #d5341b; }
    .a11y-contrast .btn.btn-info:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 117, 99, 0.5); }

.a11y-contrast .btn.btn-warning {
  color: #fff;
  background-color: #0d38d7;
  border-color: #0d38d7; }
  .a11y-contrast .btn.btn-warning:hover {
    color: #fff;
    background-color: #0b2fb3;
    border-color: #0a2ba7; }
  .a11y-contrast .btn.btn-warning:focus, .a11y-contrast .btn.btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(49, 86, 221, 0.5); }
  .a11y-contrast .btn.btn-warning.disabled, .a11y-contrast .btn.btn-warning:disabled {
    color: #fff;
    background-color: #0d38d7;
    border-color: #0d38d7; }
  .a11y-contrast .btn.btn-warning:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-warning:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #0a2ba7;
    border-color: #09289b; }
    .a11y-contrast .btn.btn-warning:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(49, 86, 221, 0.5); }

.a11y-contrast .btn.btn-danger {
  color: #fff;
  background-color: #019a9a;
  border-color: #019a9a; }
  .a11y-contrast .btn.btn-danger:hover {
    color: #fff;
    background-color: #017474;
    border-color: #016767; }
  .a11y-contrast .btn.btn-danger:focus, .a11y-contrast .btn.btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 169, 169, 0.5); }
  .a11y-contrast .btn.btn-danger.disabled, .a11y-contrast .btn.btn-danger:disabled {
    color: #fff;
    background-color: #019a9a;
    border-color: #019a9a; }
  .a11y-contrast .btn.btn-danger:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-danger:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #016767;
    border-color: #015b5b; }
    .a11y-contrast .btn.btn-danger:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 169, 169, 0.5); }

.a11y-contrast .btn.btn-light {
  color: #fff;
  background-color: #070605;
  border-color: #070605; }
  .a11y-contrast .btn.btn-light:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .a11y-contrast .btn.btn-light:focus, .a11y-contrast .btn.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 43, 43, 0.5); }
  .a11y-contrast .btn.btn-light.disabled, .a11y-contrast .btn.btn-light:disabled {
    color: #fff;
    background-color: #070605;
    border-color: #070605; }
  .a11y-contrast .btn.btn-light:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-light:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-light.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .a11y-contrast .btn.btn-light:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 43, 43, 0.5); }

.a11y-contrast .btn.btn-dark {
  color: #212529;
  background-color: #cbc5bf;
  border-color: #cbc5bf; }
  .a11y-contrast .btn.btn-dark:hover {
    color: #212529;
    background-color: #bab2aa;
    border-color: #b4aca3; }
  .a11y-contrast .btn.btn-dark:focus, .a11y-contrast .btn.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(178, 173, 169, 0.5); }
  .a11y-contrast .btn.btn-dark.disabled, .a11y-contrast .btn.btn-dark:disabled {
    color: #212529;
    background-color: #cbc5bf;
    border-color: #cbc5bf; }
  .a11y-contrast .btn.btn-dark:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-dark:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-dark.dropdown-toggle {
    color: #212529;
    background-color: #b4aca3;
    border-color: #aea59c; }
    .a11y-contrast .btn.btn-dark:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 173, 169, 0.5); }

.a11y-contrast .btn.btn-outline-accent {
  color: #574838;
  border-color: #574838; }
  .a11y-contrast .btn.btn-outline-accent:hover {
    color: #fff;
    background-color: #574838;
    border-color: #574838; }
  .a11y-contrast .btn.btn-outline-accent:focus, .a11y-contrast .btn.btn-outline-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 72, 56, 0.5); }
  .a11y-contrast .btn.btn-outline-accent.disabled, .a11y-contrast .btn.btn-outline-accent:disabled {
    color: #574838;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-accent:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-accent:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-accent.dropdown-toggle {
    color: #fff;
    background-color: #574838;
    border-color: #574838; }
    .a11y-contrast .btn.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 72, 56, 0.5); }

.a11y-contrast .btn.btn-outline-neutral {
  color: #111111;
  border-color: #111111; }
  .a11y-contrast .btn.btn-outline-neutral:hover {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }
  .a11y-contrast .btn.btn-outline-neutral:focus, .a11y-contrast .btn.btn-outline-neutral.focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5); }
  .a11y-contrast .btn.btn-outline-neutral.disabled, .a11y-contrast .btn.btn-outline-neutral:disabled {
    color: #111111;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-neutral:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-neutral:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-neutral.dropdown-toggle {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }
    .a11y-contrast .btn.btn-outline-neutral:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5); }

.a11y-contrast .btn.btn-outline-primary {
  color: #ff904c;
  border-color: #ff904c; }
  .a11y-contrast .btn.btn-outline-primary:hover {
    color: #212529;
    background-color: #ff904c;
    border-color: #ff904c; }
  .a11y-contrast .btn.btn-outline-primary:focus, .a11y-contrast .btn.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 144, 76, 0.5); }
  .a11y-contrast .btn.btn-outline-primary.disabled, .a11y-contrast .btn.btn-outline-primary:disabled {
    color: #ff904c;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-primary:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ff904c;
    border-color: #ff904c; }
    .a11y-contrast .btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 144, 76, 0.5); }

.a11y-contrast .btn.btn-outline-secondary {
  color: #019a9a;
  border-color: #019a9a; }
  .a11y-contrast .btn.btn-outline-secondary:hover {
    color: #fff;
    background-color: #019a9a;
    border-color: #019a9a; }
  .a11y-contrast .btn.btn-outline-secondary:focus, .a11y-contrast .btn.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 154, 154, 0.5); }
  .a11y-contrast .btn.btn-outline-secondary.disabled, .a11y-contrast .btn.btn-outline-secondary:disabled {
    color: #019a9a;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #019a9a;
    border-color: #019a9a; }
    .a11y-contrast .btn.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(1, 154, 154, 0.5); }

.a11y-contrast .btn.btn-outline-tertiary {
  color: #f5ecd2;
  border-color: #f5ecd2; }
  .a11y-contrast .btn.btn-outline-tertiary:hover {
    color: #212529;
    background-color: #f5ecd2;
    border-color: #f5ecd2; }
  .a11y-contrast .btn.btn-outline-tertiary:focus, .a11y-contrast .btn.btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 236, 210, 0.5); }
  .a11y-contrast .btn.btn-outline-tertiary.disabled, .a11y-contrast .btn.btn-outline-tertiary:disabled {
    color: #f5ecd2;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-tertiary:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-tertiary.dropdown-toggle {
    color: #212529;
    background-color: #f5ecd2;
    border-color: #f5ecd2; }
    .a11y-contrast .btn.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 236, 210, 0.5); }

.a11y-contrast .btn.btn-outline-success {
  color: #d28e83;
  border-color: #d28e83; }
  .a11y-contrast .btn.btn-outline-success:hover {
    color: #212529;
    background-color: #d28e83;
    border-color: #d28e83; }
  .a11y-contrast .btn.btn-outline-success:focus, .a11y-contrast .btn.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(210, 142, 131, 0.5); }
  .a11y-contrast .btn.btn-outline-success.disabled, .a11y-contrast .btn.btn-outline-success:disabled {
    color: #d28e83;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-success:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #d28e83;
    border-color: #d28e83; }
    .a11y-contrast .btn.btn-outline-success:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(210, 142, 131, 0.5); }

.a11y-contrast .btn.btn-outline-info {
  color: #e85d47;
  border-color: #e85d47; }
  .a11y-contrast .btn.btn-outline-info:hover {
    color: #fff;
    background-color: #e85d47;
    border-color: #e85d47; }
  .a11y-contrast .btn.btn-outline-info:focus, .a11y-contrast .btn.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 93, 71, 0.5); }
  .a11y-contrast .btn.btn-outline-info.disabled, .a11y-contrast .btn.btn-outline-info:disabled {
    color: #e85d47;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-info:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #e85d47;
    border-color: #e85d47; }
    .a11y-contrast .btn.btn-outline-info:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 93, 71, 0.5); }

.a11y-contrast .btn.btn-outline-warning {
  color: #0d38d7;
  border-color: #0d38d7; }
  .a11y-contrast .btn.btn-outline-warning:hover {
    color: #fff;
    background-color: #0d38d7;
    border-color: #0d38d7; }
  .a11y-contrast .btn.btn-outline-warning:focus, .a11y-contrast .btn.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 56, 215, 0.5); }
  .a11y-contrast .btn.btn-outline-warning.disabled, .a11y-contrast .btn.btn-outline-warning:disabled {
    color: #0d38d7;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #0d38d7;
    border-color: #0d38d7; }
    .a11y-contrast .btn.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(13, 56, 215, 0.5); }

.a11y-contrast .btn.btn-outline-danger {
  color: #019a9a;
  border-color: #019a9a; }
  .a11y-contrast .btn.btn-outline-danger:hover {
    color: #fff;
    background-color: #019a9a;
    border-color: #019a9a; }
  .a11y-contrast .btn.btn-outline-danger:focus, .a11y-contrast .btn.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 154, 154, 0.5); }
  .a11y-contrast .btn.btn-outline-danger.disabled, .a11y-contrast .btn.btn-outline-danger:disabled {
    color: #019a9a;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #019a9a;
    border-color: #019a9a; }
    .a11y-contrast .btn.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(1, 154, 154, 0.5); }

.a11y-contrast .btn.btn-outline-light {
  color: #070605;
  border-color: #070605; }
  .a11y-contrast .btn.btn-outline-light:hover {
    color: #fff;
    background-color: #070605;
    border-color: #070605; }
  .a11y-contrast .btn.btn-outline-light:focus, .a11y-contrast .btn.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(7, 6, 5, 0.5); }
  .a11y-contrast .btn.btn-outline-light.disabled, .a11y-contrast .btn.btn-outline-light:disabled {
    color: #070605;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-light:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #070605;
    border-color: #070605; }
    .a11y-contrast .btn.btn-outline-light:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(7, 6, 5, 0.5); }

.a11y-contrast .btn.btn-outline-dark {
  color: #cbc5bf;
  border-color: #cbc5bf; }
  .a11y-contrast .btn.btn-outline-dark:hover {
    color: #212529;
    background-color: #cbc5bf;
    border-color: #cbc5bf; }
  .a11y-contrast .btn.btn-outline-dark:focus, .a11y-contrast .btn.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 197, 191, 0.5); }
  .a11y-contrast .btn.btn-outline-dark.disabled, .a11y-contrast .btn.btn-outline-dark:disabled {
    color: #cbc5bf;
    background-color: transparent; }
  .a11y-contrast .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .a11y-contrast .btn.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .a11y-contrast .btn.btn-outline-dark.dropdown-toggle {
    color: #212529;
    background-color: #cbc5bf;
    border-color: #cbc5bf; }
    .a11y-contrast .btn.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .a11y-contrast .btn.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .a11y-contrast .btn.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 197, 191, 0.5); }

.a11y-contrast .breadcrumb .breadcrumb-item:not(:first-child)::before {
  color: #ff904c; }

.a11y-contrast .breadcrumb .breadcrumb-item.active {
  color: #ff904c; }

@media (min-width: 992px) {
  .a11y-contrast .social:hover .social-icon .cl-twitter::before {
    color: #00aced; } }

@media (min-width: 992px) {
  .a11y-contrast .social:hover .social-icon .cl-youtube::before {
    color: #f00; } }

@media (min-width: 992px) {
  .a11y-contrast .social:hover .social-icon .cl-instagram::before {
    color: #bc2a8d; } }

@media (min-width: 992px) {
  .a11y-contrast .social:hover .social-icon .cl-facebook::before {
    color: #3b5998; } }

.a11y-contrast .social .social-icon .cl-twitter::before {
  color: #00aced; }
  @media (min-width: 992px) {
    .a11y-contrast .social .social-icon .cl-twitter::before {
      color: #ff904c; } }

.a11y-contrast .social .social-icon .cl-youtube::before {
  color: #f00; }
  @media (min-width: 992px) {
    .a11y-contrast .social .social-icon .cl-youtube::before {
      color: #ff904c; } }

.a11y-contrast .social .social-icon .cl-instagram::before {
  color: #bc2a8d; }
  @media (min-width: 992px) {
    .a11y-contrast .social .social-icon .cl-instagram::before {
      color: #ff904c; } }

.a11y-contrast .social .social-icon .cl-facebook::before {
  color: #3b5998; }
  @media (min-width: 992px) {
    .a11y-contrast .social .social-icon .cl-facebook::before {
      color: #ff904c; } }

.a11y-contrast .social .social-name {
  color: #b5b5b5; }

.a11y-contrast .social .social-url {
  color: #b5b5b5; }

.a11y-contrast nav.navbar {
  box-shadow: 0 2px 0 0 #1c1a10; }
  .a11y-contrast nav.navbar .navbar-brand {
    color: #eeeeee; }
    .a11y-contrast nav.navbar .navbar-brand:hover, .a11y-contrast nav.navbar .navbar-brand:focus {
      color: #eeeeee; }
  .a11y-contrast nav.navbar .navbar-nav .nav-separator {
    background-color: #f5ecd2; }
  .a11y-contrast nav.navbar .navbar-nav .nav-behavior .nav-link {
    color: #eeeeee;
    border: 1px solid #eeeeee; }
    .a11y-contrast nav.navbar .navbar-nav .nav-behavior .nav-link .cl::before {
      color: #eeeeee; }
    .a11y-contrast nav.navbar .navbar-nav .nav-behavior .nav-link:active, .a11y-contrast nav.navbar .navbar-nav .nav-behavior .nav-link.active {
      background-color: #fff;
      border-color: #ff904c; }
    .a11y-contrast nav.navbar .navbar-nav .nav-behavior .nav-link:hover {
      color: #ff904c;
      border-color: #ff904c; }
      .a11y-contrast nav.navbar .navbar-nav .nav-behavior .nav-link:hover .cl::before {
        color: #ff904c; }
  .a11y-contrast nav.navbar .navbar-nav .nav-item:not(.dropdown) .nav-link:hover {
    color: #ff904c; }
  .a11y-contrast nav.navbar .navbar-nav .nav-item.dropdown.show .dropdown-toggle {
    color: black;
    background-color: #ff904c; }
  .a11y-contrast nav.navbar .navbar-nav .nav-item.dropdown.show .dropdown-menu.show {
    background-color: #ff904c; }
    .a11y-contrast nav.navbar .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item {
      color: black; }
      .a11y-contrast nav.navbar .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item:hover {
        color: black;
        background-color: #f5ecd2; }
  .a11y-contrast nav.navbar .navbar-nav .nav-link {
    color: rgba(238, 238, 238, 0.7); }
    .a11y-contrast nav.navbar .navbar-nav .nav-link:hover, .a11y-contrast nav.navbar .navbar-nav .nav-link:focus {
      color: rgba(238, 238, 238, 0.9); }
    .a11y-contrast nav.navbar .navbar-nav .nav-link.disabled {
      color: rgba(238, 238, 238, 0.5); }
  .a11y-contrast nav.navbar .navbar-nav .show > .nav-link,
  .a11y-contrast nav.navbar .navbar-nav .active > .nav-link,
  .a11y-contrast nav.navbar .navbar-nav .nav-link.show,
  .a11y-contrast nav.navbar .navbar-nav .nav-link.active {
    color: #eeeeee; }
  .a11y-contrast nav.navbar .navbar-toggler {
    color: rgba(238, 238, 238, 0.7);
    border-color: rgba(238, 238, 238, 0.2); }
  .a11y-contrast nav.navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(238, 238, 238, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .a11y-contrast nav.navbar .navbar-text {
    color: rgba(238, 238, 238, 0.7); }
    .a11y-contrast nav.navbar .navbar-text a {
      color: #eeeeee; }
      .a11y-contrast nav.navbar .navbar-text a:hover, .a11y-contrast nav.navbar .navbar-text a:focus {
        color: #eeeeee; }

.a11y-contrast .section-social::before {
  border-top: 1px solid #574838; }

.a11y-contrast .social .social-name {
  color: #b5b5b5; }

.a11y-contrast .social .social-url {
  color: #b5b5b5; }

.a11y-contrast section.onboarding {
  background-color: black; }

.a11y-contrast section.contingency {
  background-color: black; }
  .a11y-contrast section.contingency .contingency-body > section.section-jumbotron .section-body .line::after {
    background: black; }
  .a11y-contrast section.contingency .contingency-body > section.section .section-header h2 {
    color: #b5b5b5; }

.a11y-contrast section.redirecting {
  background-color: black; }

.a11y-contrast footer {
  background-color: black; }
  .a11y-contrast footer .footer-social li a {
    font-size: 32px;
    color: #111111; }
  .a11y-contrast footer .footer-links a {
    color: #eeeeee; }

.a11y-contrast .form-control {
  color: #b5b5b5;
  background-color: black; }
  .a11y-contrast .form-control:focus {
    border-color: #ff904c;
    box-shadow: 0 0 0 0.2rem rgba(255, 144, 76, 0.25); }

.a11y-contrast .toc-entry a:hover {
  color: #ff904c; }

.a11y-contrast .bd-search-docs-toggle {
  color: #dedad6; }

.a11y-contrast .bd-sidenav {
  display: none; }

.a11y-contrast .bd-toc-link:hover {
  color: #ff904c;
  background-color: black; }

.a11y-contrast .bd-toc-item {
  border-top: 1px solid #111111; }
  .a11y-contrast .bd-toc-item:last-child {
    border-bottom: 1px solid #111111; }
  .a11y-contrast .bd-toc-item.active {
    background-color: black; }
    .a11y-contrast .bd-toc-item.active > .bd-toc-link {
      color: #ff904c; }

.a11y-contrast .bd-sidebar .nav > li > a {
  color: rgba(238, 238, 238, 0.6); }

.a11y-contrast .select2.select2-container .select2-selection {
  background-color: black;
  border-color: #ced4da;
  outline: none; }
  .a11y-contrast .select2.select2-container .select2-selection .select2-selection__rendered:hover {
    border-color: #ff904c;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(255, 144, 76, 0.25); }

.a11y-contrast .select2-container .select2-search {
  background-color: black; }
  .a11y-contrast .select2-container .select2-search .select2-search__field {
    outline: 0; }

.a11y-contrast .select2-container .select2-results .select2-results__option {
  color: #b5b5b5;
  background-color: black; }
  .a11y-contrast .select2-container .select2-results .select2-results__option.select2-results__option--highlighted {
    color: black;
    background-color: #ff904c; }

.a11y-contrast .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read > a .rsbtn_left .rsbtn_text {
  color: #eeeeee; }

.a11y-contrast .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read:hover > a .rsbtn_text {
  color: #ff904c; }

.a11y-contrast .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_progress_container {
  border: 1px solid #eeeeee; }
  .a11y-contrast .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_progress_container .rsbtn_progress_handle.rsimg {
    background-color: #eeeeee; }
  .a11y-contrast .toolbar.with-read-speaker .toolbar-player.toolbar-btn.toolbar-behavior-read.rsexpanded .rsbtn_exp.rsimg.rspart .rsbtn_box .rsbtn_progress_container .rsbtn_progress_played {
    background-color: #eeeeee; }

/* empty */
