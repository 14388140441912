.btn {
  font-family: $primary-font;
  font-size: rem($font-size-base-in-px);
  font-weight: $font-weight-normal;

  @each $color, $value in $theme-colors {
    &.btn-#{$color}
    ,
    &.btn-outline-#{$color} {
      text-decoration: underline;
      word-break: break-word;
      white-space: normal;

      &:not(.btn-sm):not(.btn-lg) {
        min-height: $button-height;
        padding-top: $button-padding-y;
        padding-bottom: $button-padding-y;
        line-height: rem($button-line-height);
      }
    }
  }

  &.btn-default-size {
    display: block;

    @include media($from: desktop) {
      display: inline-block;
      width: unset;
      min-width: $button-default-size-lg-width;
    }
  }

  &.btn-card-xl {
    min-height: 48px !important;
    padding: 10px 30px !important;
    border-radius: 3px;
  }

  &.btn-card-xs {
    min-height: 48px !important;
    padding: 10px 15px !important;
    border-radius: 3px;
  }

  &.btn-gray-c {
    padding: 7px 30px;
    margin: 0 20px 20px 0;
    text-decoration: underline;
    background-color: #d8d8d8;
  }

  &.btn-maps {
    background-color: #eee;
    border: 1px solid #8a8a8a;
    border-radius: 3px;
  }

  i {
    width: rem($button-line-height);
    height: rem($button-line-height);
    font-size: rem($button-line-height);
    font-style: normal;
    font-weight: $font-weight-light;
    vertical-align: top;
  }
}

