@each $index, $pixel in $a11y-fonts {
  .a11y-font-#{$index} {
    nav.navbar {
      .nav-behavior {
        padding: ($navbar-lg-nav-behavior-padding-y - ($a11y-scale-base * $index)) $navbar-lg-nav-behavior-padding-x;
      }

      .nav-separator {
        margin: ($navbar-lg-nav-behavior-padding-y - ($a11y-scale-base * $index))  0;
      }
    }
  }
}

.a11y-font-1,
.a11y-font-2 {
  nav.navbar {
    .navbar-nav {
      .nav-behavior {
        display: none;
      }

      .nav-separator {
        display: none;
      }
    }
  }
}
