.georeferens-search {
  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 28px;
  }

  span {
    font-size: 18px;
  }

  .searchw {
    background-color: #f0eaea;
  }
}
