section.contingency {
  background-color: $invert-body-bg;

  .contingency-body {
    > section.section-jumbotron {
      .section-body {
        .line::after {
          background: invert-color("white");
        }
      }
    }

    > section.section {
      .section-header {
        h2 {
          color: invert-gray("a");
        }
      }
    }
  }
}
