@font-face {
  font-family: icongob-cl;
  font-style: $font-weight-normal;
  font-weight: $font-weight-normal;
  src: url("#{$fonts-path}fontello.eot?kyr2k2");
  src:
    url("#{$fonts-path}fontello.eot?kyr2k2#iefix") format("embedded-opentype"),
    url("#{$fonts-path}fontello.ttf?kyr2k2") format("truetype"),
    url("#{$fonts-path}fontello.woff2?kyr2k2") format("woff2"),
    url("#{$fonts-path}fontello.woff?kyr2k2") format("woff"),
    url("#{$fonts-path}fontello.svg?kyr2k2#icongob-cl") format("svg");
}

.cl {

  @extend %cl;

  @for $i from 2 through $icon-sizes {
    &.cl-#{$i}x {
      font-size: $font-size-base * $i;
    }
  }
}

@each $key, $value in $icons {
  .cl-#{$key} {
    color: nth($value, 2);

    &::before {
      color: inherit;
      content: nth($value, 1);
    }
  }
}

.cl-img-preview .path1::before {
  color: gray("c");
  content: "\e906";
}

.cl-img-preview .path2::before {
  margin-left: -1em;
  color: gray("b");
  content: "\e907";
}
