.toolbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  height: $toolbar-height;

  @include media($from: mobile) {
    width: $toolbar-width + ($toolbar-button-icon-size * 2);
  }

  @include media($from: tablet) {
    width: $toolbar-width;
  }

  .toolbar-btn {
    display: none;
    width: $toolbar-button-size;
    height: $toolbar-button-size;
    padding: $toolbar-button-padding;
    text-decoration: none;
    vertical-align: top;
    border: $toolbar-button-border-width solid $gray-a;

    &:hover {
      border-color: theme-color("primary");

      .cl::before {
        color: theme-color("primary");
      }
    }

    .toolbar-btn-icon-content {
      display: inline-block;
      height: $toolbar-button-size - ($toolbar-button-border-width * 2);
      padding: $toolbar-button-padding;
    }

    @include media($from: tablet) {
      display: inline-block;
    }

    .cl {
      display: block;
      width: $toolbar-button-icon-size;
      height: $toolbar-button-icon-size;
      font-size: $toolbar-button-icon-size;
      line-height: $toolbar-button-icon-size;
      text-align: center;

      &::before {
        color: $gray-a;
      }
    }

    &.toolbar-toggler {
      display: inline-block;

      .toolbar-behavior-on {
        display: block;
      }

      .toolbar-behavior-off {
        display: none;
        font-size: $toolbar-button-icon-size;
        line-height: $toolbar-button-icon-size;
        color: theme-color("accent");
        text-align: center;
      }

      @include media($from: tablet) {
        display: none;
      }
    }

    &.toolbar-behavior-decrease {
      padding: $toolbar-behavior-decrease-padding;

      .cl {
        width: $toolbar-behavior-decrease-icon-size;
        height: $toolbar-behavior-decrease-icon-size;
        font-size: $toolbar-behavior-decrease-icon-size;
        line-height: $toolbar-behavior-decrease-icon-size;
      }
    }

    &.toolbar-behavior-increase {
      padding: $toolbar-behavior-increase-padding;

      .cl {
        width: $toolbar-behavior-increase-icon-size;
        height: $toolbar-behavior-increase-icon-size;
        font-size: $toolbar-behavior-increase-icon-size;
        line-height: $toolbar-behavior-increase-icon-size;
      }
    }

    &.toolbar-behavior-read {
      width: $toolbar-button-read-width;
      padding: $toolbar-behavior-read-padding;

      .toolbar-btn-icon-content {
        padding: $toolbar-behavior-read-icon-content-padding;
      }

      .cl {
        width: $toolbar-behavior-read-icon-size;
        height: $toolbar-behavior-read-icon-size;
        font-size: $toolbar-behavior-read-icon-size;
        line-height: $toolbar-behavior-read-icon-size;
      }
    }
  }

  .toolbar-btn-group {
    display: inline-block;
    height: $toolbar-button-size;

    .toolbar-btn {
      &:not(:first-child) {
        margin-left: $toolbar-button-group-margin;
      }
    }
  }

  &.active {
    .toolbar-btn {
      display: inline-block;

      &.toolbar-toggler {

        @include media($from: tablet) {
          display: none;
        }

        .toolbar-behavior-on {
          display: none;
        }

        .toolbar-behavior-off {
          display: block;
        }
      }
    }
  }

  &.light {
    .toolbar-btn {
      border-color: color("white");

      .cl::before {
        color: color("white");
      }

      &:hover {
        border-color: theme-color("primary");

        .cl::before {
          color: theme-color("primary");
        }
      }
    }
  }
}
