nav.navbar {
  align-items: initial;
  min-height: 135px;

  @media (max-width: 768px) {
    min-height: 60px;
  }

  .text-md {
    display: none;

    @media (max-width: 768px) {
      display: inline-block;
      margin-right: 10px;
      font-size: 16px;
      text-decoration: underline;
    }
  }

  .logo {
    display: block;

    .md-logo {
      display: none;
    }

    .lg-logo {
      display: block;
    }

    .lg-logo-text {
      .franja-azul {
        display: block;
        width: 70px;
        height: 15px;
        background-color: #0167b7;
      }

      .franja-roja {
        display: block;
        width: 87px;
        height: 15px;
        background-color: #ee3a41;
      }
    }

    .logo-title {
      max-width: 183px;
      margin-top: 20px;
      font-family: gobCl;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      white-space: initial;
    }

    @media (max-width: 768px) {
      .md-logo {
        display: block;
      }

      .lg-logo {
        display: none;
      }

      .lg-logo-text {
        .franja-azul {
          width: 54px;
          height: 14px;
        }

        .franja-roja {
          width: 67px;
          height: 14px;
        }
      }

      .logo-title {
        margin-top: 10px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  &.bg_light {
    background-color: #fff;

    @media (max-width: 768px) {
      background-color: #eee;
    }
  }

  &.bg_dark {
    background-color: rgba(0, 28, 65, .86);

    .logo-title {
      color: #fff;
    }

    .input-navbar {
      border-color: #fff !important;
    }

    .navbar-primary {
      .nav-link {
        color: #fff;

        &:hover {
          color: #000;
        }
      }
    }

    @media (max-width: 768px) {
      background-color: #eee;

      .logo-title {
        color: #111;
      }

      .navbar-primary {
        .nav-link {
          color: #000;

          &:hover {
            color: #000;
          }
        }
      }
    }
  }

  .navbar-collapse {
    .cl-search {
      height: 38px !important;
      color: #fff;
      background-color: #0f69b4;
      border-radius: 0 3px 3px 0;
    }

    .input-navbar {
      border-color: #000;
      border-right: 0;
      border-radius: 3px 0 0 3px;
    }

    .navbar-primary {
      > li a {
        &:hover {
          background-color: #eee;
        }
      }

      @media (max-width: 768px) {
        .border-md-0 {
          border: 0 !important;
        }

        .dropdown-menu {
          background-color: #eee;

          a {
            padding-left: 40px;
          }
        }

        > li a {
          &:hover {
            background-color: #d5d5d5;
          }
        }

        .input-navbar {
          width: 85%;
        }
      }
    }

    .navbar-top {

      @media (max-width: 768px) {
        border-bottom: 1px solid #999;

        > li a {
          &:hover {
            background-color: #d5d5d5;
          }
        }
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 50px;
    }
  }

  @include media($from: desktop) {
    .navbar-primary {
      position: relative;
      top: 30px;
    }

    .navbar-top {
      position: absolute;
      top: 0;

      li:last-child a {
        text-decoration: none;
      }

      .btn-navbar {
        padding: 8px 15px;
        margin-left: 10px;
        font-family: Roboto, sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #001c41;
        border: 1px solid transparent;
        border-radius: 0;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        &:hover {
          color: #fff;
        }

        .cl-claveunica {
          margin-right: 5px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }
}

nav.navbar.navbar-fr {
  min-height: $navbar-height;
  padding: 0;
  box-shadow: 0 2px 0 0 $shadow-color;

  .container {
    position: relative;
    padding: 0 15px;

    a.navbar-brand img {
      position: absolute;
      top: 0;
      width: $logo-width;
    }
  }

  @include media($from: desktop) {
    min-height: $navbar-lg-height;
  }

  .navbar-brand {
    min-height: $navbar-height;
    text-decoration: none;

    @include media($from: desktop) {
      min-height: $navbar-lg-height;
    }
  }

  .navbar-toggler {
    min-height: $navbar-height;
    padding: $navbar-toggler-padding-y px($navbar-toggler-padding-x);
    font-size: px($navbar-toggler-font-size);
    border: none;

    @include media($from: desktop) {
      min-height: $navbar-lg-height;
    }

    &:focus {
      outline: none;
    }
  }

  @each $key, $value in $grid-breakpoints {
    &.navbar-expand-#{$key} {
      .navbar-nav {

        @include media-breakpoint-up($key) {
          height: $navbar-lg-height;
        }

        .nav-separator
        ,
        .nav-behavior {

          @include media-breakpoint-up($key) {
            display: block;
          }
        }

        .nav-item {
          .nav-link {
            height: auto;
            padding: $navbar-nav-item-link-padding-y $navbar-nav-item-link-padding-x;
            line-height: $navbar-nav-link-line-height;

            @include media-breakpoint-up($key) {
              height: $navbar-lg-height;
              padding: $navbar-lg-nav-item-link-padding-y $navbar-lg-nav-item-link-padding-x;
            }
          }
        }
      }
    }
  }

  .navbar-nav {
    .nav-separator {
      display: none;
      width: $navbar-nav-separator-width;
      height: rem($navbar-nav-behavior-link-size);
      margin: $navbar-lg-nav-behavior-padding-y 0;
      background-color: theme-color("tertiary");
    }

    .nav-behavior {
      display: none;
      height: $navbar-lg-height;
      padding: $navbar-lg-nav-behavior-padding-y $navbar-lg-nav-behavior-padding-x;

      .nav-link {
        width: rem($navbar-nav-behavior-link-size);
        height: rem($navbar-nav-behavior-link-size);
        padding: 0;
        font-size: rem($navbar-nav-behavior-link-font-size);
        line-height: rem($navbar-nav-behavior-link-line-height);
        color: theme-color("accent");
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        border: 1px solid theme-color("accent");

        &:active
        ,
        &.active {
          background-color: color("white");
          border-color: theme-color("primary");
        }

        &:hover {
          color: theme-color("primary");
          border-color: theme-color("primary");

          .cl::before {
            color: theme-color("primary");
          }
        }
      }
    }

    .nav-item {
      .nav-link {
        padding: $navbar-nav-item-link-padding-y $navbar-nav-item-link-padding-x;
        font-size: rem(16);
        line-height: $navbar-nav-link-line-height;

        @include media($from: desktop) {
          height: $navbar-lg-height;
          padding: $navbar-lg-nav-item-link-padding-y $navbar-lg-nav-item-link-padding-x;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &:not(.dropdown) {
        .nav-link:hover {
          color: theme-color();
        }
      }

      &.dropdown {
        .dropdown-menu {
          margin: 0;
          border: none;

          .dropdown-item {
            text-decoration: underline;
          }
        }

        &.show {
          .dropdown-toggle {
            color: color("white");
            background-color: theme-color("primary");
          }

          .dropdown-menu {
            &.show {
              background-color: theme-color("primary");

              .dropdown-item {
                padding: rem($navbar-dropdown-item-padding-y) rem($navbar-dropdown-item-padding-x);
                color: color("white");

                &:hover {
                  color: color("white");
                  background-color: theme-color("tertiary");
                }
              }
            }
          }
        }
      }
    }
  }
}

main {
  margin-top: map-get($navbar-heights, xs);

  @include media($from: desktop) {
    margin-top: map-get($navbar-heights, md);
  }
}
