.select2 {
  &.select2-container {
    .select2-selection {
      background-color: invert-color("white");
      border-color: $input-border-color;
      outline: none;

      .select2-selection__rendered {
        &:hover {
          border-color: invert-theme-color();
          outline: none;
          box-shadow: 0 0 0 $input-btn-focus-width invert($input-btn-focus-color);
        }
      }
    }
  }
}

.select2-container {
  .select2-search {
    background-color: invert-color("white");

    .select2-search__field {
      outline: 0;
    }
  }

  .select2-results {
    .select2-results__option {
      color: invert-gray("a");
      background-color: invert-color("white");

      &.select2-results__option--highlighted {
        color: invert-color("white");
        background-color: invert-theme-color();
      }
    }
  }
}
