.card-featured {
  position: relative;
  display: block;
  margin-bottom: spacer(4);
  text-decoration: none;
  cursor: pointer;
  border: 1px solid theme-color("accent");

  &::before {
    position: absolute;
    right: $card-featured-icon-margin;
    bottom: $card-featured-icon-margin;
    font-size: $small-font-size;
  }

  &:hover {
    color: theme-color();
    text-decoration: none;
    border: 1px solid theme-color();

    &::before {
      color: theme-color();
    }
  }

  .card-featured-image {
    height: $card-featured-image-height;

    @include media($from: desktop) {
      height: $card-featured-lg-image-height;
    }
  }

  .card-featured-body {
    padding: $card-featured-padding;

    > h4 {
      margin: 0 0 $card-featured-padding;
      overflow: hidden;
      font-weight: bold;
      line-height: 29px;
    }

    > p {
      margin: 0;
      overflow: hidden;
    }

    > span {
      display: block;
      margin-top: 20px;
      color: #0f69b4;
      text-decoration: underline;
    }
  }
}
