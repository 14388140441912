
@each $key, $value in $invert-icons {
  .cl-#{$key}::before {
    color: nth($value, 2);
    content: nth($value, 1);
  }
}

.cl-img-preview .path1::before {
  color: invert-gray("c");
}

.cl-img-preview .path2::before {
  color: invert-gray("b");
}
