a {
  &.link-unstyled {
    text-decoration: none;
  }

  &.link-icon {
    margin-left: spacer(2);
    font-size: rem($link-icon-font-size);

    @extend .link-unstyled;
  }
}
