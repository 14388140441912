.card-news {
  margin-bottom: spacer(4);
  border: 1px solid theme-color("accent");

  .card-news-image {
    height: $card-news-image-height;

    @include media($from: desktop) {
      height: $card-news-lg-image-height;
    }
  }

  .card-news-image-list {
    height: 200px;

    h4 {
      margin: 10% 8% 5%;
      font-weight: bold;
    }

    a {
      margin: 0 8%;
    }
  }

  .card-news-image-width {
    width: 140px;
    margin: 30px auto;
  }

  .card-news-footer {
    min-height: 70px;
  }

  .card-news-type {
    display: inline-block;
    padding: 3px 10px;
    margin: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-decoration: underline;
    background-color: #0f69b4;
  }

  &:hover {
    color: theme-color();
    border: 1px solid theme-color();

    a {
      color: theme-color();
    }
  }

  &.with-lead {
    .card-news-body {
      > a {
        height: auto;
      }

      h4
      ,
      .card-news-title {
        display: block;
        margin: 0 0 $card-news-padding;
        overflow: hidden;
      }

      p {
        display: block;
        margin: 0 0 $card-news-padding;
        overflow: hidden;
      }
    }
  }

  .card-news-body {
    padding: $card-news-padding;

    > span {
      display: block;
      margin: 0 0 $card-news-padding;
      overflow: hidden;
      opacity: $font-opacity;

      @include media($from: tablet) {
        height: rem($card-news-lg-date-height);
      }
    }

    > a {
      display: block;
      margin: 0;
      overflow: hidden;
    }

    h4 {
      font-weight: bold;
      line-height: 33px;
    }
  }
}

.card-news-post {
  color: #4a4a4a;

  h2 {
    font-size: 38px;
    font-weight: bold;
    line-height: 50px;
  }

  .card-bajada {
    font-size: 28px;
    font-weight: 300;
    line-height: 37px;
  }

  .card-text-gal-imgs {
    a {
      margin: 0 10px 20px 10px;
    }

    img {
      &:hover {
        color: theme-color();
        border: 1px solid theme-color();

        a {
          color: theme-color();
        }
      }
    }
  }

  .card-new-franja {
    position: relative;
    padding: 20px 0;
    margin: 20px 0;
    border-top: 1px solid #b4b3b3;
    border-bottom: 1px solid #b4b3b3;

    span {
      font-size: 14px;
      line-height: 16px;
    }

    i {
      position: absolute;
      top: 45%;
      right: 15px;
      font-size: 25px;
    }

    a.link-icon div {
      margin-left: 10px;
    }
  }

  .card-text-dest {
    margin: 40px 0;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 300;
    line-height: 37px;
  }

  h4 {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    line-height: 37px;
  }

  h5 {
    font-size: 22px;
    font-weight: bold;
    line-height: 29px;
  }
}
