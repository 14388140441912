.form-search {
  .input-group-append {
    .btn-append-search {
      min-height: 20px !important;
      padding: 0 15px !important;
      text-decoration: none;
      border-radius: 0 3px 3px 0;

      i.cl {
        color: #fff;
      }
    }
  }
}

.search {
  width: $search-width;

  @include media($from: desktop) {
    width: $search-lg-width;
  }

  &:not(.with-label) {
    width: $search-width;

    @include media($from: desktop) {
      width: $search-lg-width;
    }

    @extend .input-group;

    @extend .input-group-lg;

    .form-control {
      font-family: $primary-font;
      font-size: rem(16);
      font-weight: normal;
      border-right: none;
      border-radius: 3px;
    }
  }

  &.with-label {
    .search-content {
      flex-wrap: nowrap;
      justify-content: stretch;

      @extend .input-group;

      @extend .input-group-lg;

      .form-control {
        font-family: $primary-font;
        font-size: rem(16);
        font-weight: normal;
        border-right: none;
        border-radius: 3px;
      }
    }
  }

  .search-action {

    @extend .input-group-append;

    .btn {
      padding: .2rem .7rem;
      background-color: $primary;
      border-color: $primary;
      border-left: none;
      border-radius: 0 3px 3px 0 !important;
      transition: none;

      &:hover {
        color: theme-color();
        background-color: #0f69b4;
        border-color: #0f69b4;
      }

      &:focus {
        box-shadow: none;
      }

      i {
        font-size: rem(20);
        color: #fff;
      }
    }
  }

  &.dom-search {
    .dom-search-behavior-cancel {
      padding-right: 0;
    }
  }
}

.search-not-found {
  display: none;

  &.not-found {
    display: block;
  }
}
